import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import * as API from "../../../api/Api";
import ModalWrapper from "../../Modals/ModalWrapper/ModalWrapper";
import StarsRating from "../StarsRating/StarsRating";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

import "./WriteReview.scss";

const WriteReview = (props) => {
  const { selectedProduct, fetchDetails } = props;
  const [starsSelected, selectStar] = useState(0);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [review, setReview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [autoShipLoading, setAutoShipLoading] = useState(false);
  const [inputSelected, setInputSelected] = useState(0);
  const cartCtx = useCartContext();
  const history = useHistory();

  if (message || errorMessage) {
    setTimeout(() => {
      setMessage("");
      setErrorMessage("");
    }, 3000);
  }

  useEffect(() => {
    API.showStarRating();
  }, []);

  const handleOnChange = (e) => {
    setReview(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append("id", selectedProduct.id);
    data.append("star_rating_id", starsSelected);
    data.append("review", review);
    data.append("submit_for_pick_of_the_week", inputSelected);
    if (starsSelected === 0) {
      setErrorMessage("Star rating is required");
    }
    if (starsSelected !== 0 && !selectedProduct?.customer_review?.review) {
      setIsLoading(true);
      API.postReview(data)
        .then((res) => {
          setMessage(res.data.result);
          fetchDetails();
          setIsLoading(false);
          history.push("/account/my-reviews");
          props.toggle();
        })
        .catch((err) => {
          if (err.response) {
            setErrorMessage(err.response?.data?.error?.message?.review);
            setIsLoading(false);
          }
        });
    }
    if (starsSelected !== 0 && selectedProduct?.customer_review?.review) {
      const updateData = {
        id: selectedProduct.id,
        star_rating_id: starsSelected,
        review: review,
        submit_for_pick_of_the_week: inputSelected,
      };
      setIsLoading(true);
      API.updateReview(selectedProduct?.customer_review?.id, updateData)
        .then((res) => {
          setMessage(res.data.result);
          fetchDetails();
          setIsLoading(false);
          history.push("/account/my-reviews");
          props.toggle();
        })
        .catch((err) => {
          if (err.response) {
            setErrorMessage(err.response?.data?.error?.message?.review);
            setIsLoading(false);
          }
        });
    }
  };

  const handleAddToCart = () => {
    const cartId = localStorage.getItem("cart_id");

    const data = {
      cart_id: cartId,
      product_id: selectedProduct?.product?.id
        ? selectedProduct?.product?.id
        : selectedProduct?.product_id,
      coffee_grind_id: selectedProduct?.coffee_grind
        ? selectedProduct?.coffee_grind?.id
        : selectedProduct?.coffee_grind_id,
    };
    setCartLoading(true);

    if (!cartId) {
      API.addItemToCart(data).then((res) => {
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
        });
        localStorage.setItem("cart_id", res.data.cart_id);
        cartCtx.getCartItems();
        setCartLoading(false);
        history.push("/cart");
      });
    } else {
      API.addItemToCart(data).then((res) => {
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
        });
        cartCtx.getCartItems();
        setCartLoading(false);
        history.push("/cart");
      });
    }
  };

  const handleAddToAutoShip = () => {
    const data = {
      purveyor_id: selectedProduct?.product?.purveyor?.id
        ? selectedProduct?.product?.purveyor?.id
        : selectedProduct?.purveyor_id,
      product_id: selectedProduct?.product?.id
        ? selectedProduct?.product?.id
        : selectedProduct?.product_id,
      coffee_grind_id: selectedProduct?.coffee_grind
        ? selectedProduct?.coffee_grind?.id
        : selectedProduct?.coffee_grind_id,
    };
    setAutoShipLoading(true);
    API.addItemToAutoShip(data).then((res) => {
      localStorage.setItem("cart_id", res.data.cart_id);
      setAutoShipLoading(false);
      history.push("/auto-ship");
    });
  };

  return (
    <ModalWrapper
      closeIcon
      isOpen={props.isOpen}
      toggle={props.toggle}
      backdrop
    >
      <div className="WriteReview">
        {selectedProduct && (
          <form className="WriteReview__form" onSubmit={handleSubmit}>
            <div className="WriteReview__image-wrap">
              <img
                className="WriteReview__image"
                src={
                  selectedProduct?.product?.main_image?.xlg
                    ? selectedProduct?.product?.main_image?.xlg
                    : selectedProduct?.main_image?.xlg
                }
                alt={selectedProduct?.product?.name}
              />

              <a href={selectedProduct?.product?.purveyor?.url}>
                {" "}
                <div className="WriteReview__product-purveyor-name">
                  {" "}
                  {selectedProduct?.product?.purveyor?.name
                    ? selectedProduct?.product?.purveyor?.name
                    : selectedProduct?.purveyor_name}
                </div>
              </a>

              <div className="WriteReview__product-name">
                {" "}
                {selectedProduct?.product?.name
                  ? selectedProduct?.product?.name
                  : selectedProduct?.name}
              </div>
              <StarsRating
                totalStars={5}
                starsSelected={starsSelected}
                selectStar={selectStar}
              />
              <button
                className="WriteReview__button2"
                type="button"
                onClick={() => {
                  handleAddToCart();
                }}
                disabled={cartLoading ? true : false}
              >
                {cartLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  " Order Again"
                )}
              </button>
              <button
                className="WriteReview__button3"
                type="button"
                onClick={() => {
                  handleAddToAutoShip();
                }}
                disabled={autoShipLoading ? true : false}
              >
                {autoShipLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  "Add to Auto-ship"
                )}
              </button>
            </div>

            <div className="WriteReview__container">
              <textarea
                className="WriteReview__textarea"
                placeholder="Write your review"
                rows="4"
                cols="50"
                onChange={(e) => handleOnChange(e)}
              />
              <div className="WriteReview__checkbox-container">
                <input
                  onChange={() => setInputSelected(1)}
                  name="checkbox"
                  className="WriteReview__checkbox-input"
                  type="checkbox"
                  id="checkbox"
                />

                <label className="WriteReview__checkbox-label" for="checkbox">
                  Submit review to Member's Pick of the Week
                </label>
              </div>
              <button
                className="WriteReview__button"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  "Post your review"
                )}
              </button>
            </div>
          </form>
        )}
        {message && <div className="WriteReview__message">{message}</div>}
        {errorMessage && (
          <div className="WriteReview__error-message">{errorMessage}</div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default WriteReview;
