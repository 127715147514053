import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const SouthAmerika = () => {
  const article = {
    title: "South America",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/south-america"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>South America</h3>
      <h3>Brazil</h3>
      <p>
        When I'm feeling a bit randy for a taste of true Brazilian coffee, I
        brush up on my Portuguese and head for exotic, sexy Rio de Janeiro... to
        Carnival, one of the greatest shows on earth. When they're not prancing
        around in sequins and feathers, these lively people are hard at work
        producing thirty-five percent of the world's coffee. They are the
        world's leading exporter. The conditions in this South American coffee
        growing region are perfect! Coffee is grown on some of the highest
        slopes in the world. While much of this coffee is low grown, blended and
        used in massed consumed brands, perpetuating the notion of quantity over
        quality, there are still many wonderful sweet, bright and rich South
        American coffees and special lots to be found.
      </p>
      <p>
        Specialty coffee roasters have discovered and developed close
        relationships with a number of sensational Brazilian farms that truly
        stand out and that are worthy of our love and accolades. Many single
        origins from Brazil can be sweet, complex and rather mind blowing.
        Ruvaldo Delarisse runs his family farm, Chapadao De Ferro. He inherited
        it from his beloved mother, Amelia and produces Micro-lots grown on an
        extinct volcano in Patrocinio that has flavors of Butterscotch, Dutch
        Cocoa and dried fruit.
      </p>
      <p>
        Another notable farmer, Luis Pascoal's obsession with progressive
        farming techniques and research pays off. His Daterra Sweet Blue brings
        about flavors of Nutella, Vanilla, Chocolate candy and root beer. Other
        notables such as Joao Souza's family farm, Fazenda Esperanca and
        Nazareth Dias Pereira's farm, Fazenda Do Sertao are part of a growing
        group devoted producing a superior quality Brazilian product using the
        best ingredient of all... love.
      </p>
      <p>
        Ranked 1st in the world for exports. * Cup of Excellence Country Best
        known varietals: Bourbon, Typica, Caturra, Catimor, Maragogype.
      </p>

      <h3>Colombia</h3>
      <p>
        Following in the footsteps of the legendary Juan Valdez, I too have
        traversed the steep slopes to pick these sun-drenched Arabica beans by
        hand. My perspiration and patience always pays off, the coffee ranges
        from flavorful and mild to exceptionally bold, like the famous Columbian
        Supremo. It may be Columbia's access to two major oceans that makes it
        the world's second largest coffee producer, but it is the ideal
        combination of tropical temps and high altitude that when combined with
        good farming, gives its coffee such character.
      </p>
      <p>
        While it is true that many farms have begun to sacrifice quality to
        focus on more on highyielding varieties, Coffee growing regions are
        accessible only to intrepid souls like myself - and my lovely assistants
        carrying my packs.
      </p>
      <p>
        Ranked 3rd in the world for exports.* Cup of Excellence Country Best
        known varietals: Columbian Supremo, Medellin.
      </p>

      <h3>Ecuador</h3>
      <p>
        The conditions here are perfect and coffee is grown on some of the
        highest growing slopes in the world. Still, Ecuador has yet to crack the
        primo markets and most of its low-grade coffee goes for blending.
      </p>
      <p>Best known varietals: Bourbon, Caturra, Typica.</p>

      <h3>Peru</h3>
      <p>
        South of coffee dynamo Columbia is the huge expanse of Peru. No wonder
        the lovely people in this coffee growing region have so much energy!
        Peru has been somewhat of an underdog in the coffee market, mainly
        because of political strife. Recently the Peruvian coffee industry has
        made headway by seeing a trend in organic and fair trade coffee buyers
        and jumping on it, knowing they could produce it cheaper.
      </p>
      <p>
        However, cheaper is not better, like Vietnam, Peru is producing low
        quality beans and flooding the market with it. In order to compete with
        the sheer volume and cheap price, the high quality organic growers in
        Peru and other countries are forced to lower prices on their superior
        Arabica beans just to compete and survive. Cost conscious buyers put
        this inferior product on their shelves with fancy labels knowing the
        public will buy it, and they do. The public sees the Fair Trade and
        Organic label and assume they are helping the farmer and that it is
        quality coffee.
      </p>
      <p>
        It is important to reiterate, that there are a number of quality farms
        that exist in Peru but it is not the coffee you see on shelves
        everywhere and find for $4 a pound at Cost Plus or Trader Joe's. There
        are some notable coffees that are sweet and bright from the Chanchamaya
        and Cuzco regions.
      </p>
      <p>
        Respect should be given to all farmers; Peruvian included, who grow with
        a focus on quality. Finding quality beans in Peru is not always
        consistent... But give it time - Machu Pichu was not built in day!
      </p>
      <p>Best known varietals: Typica, Bourbon, Caturra, Pache.</p>

      <h3>Venezuela</h3>
      <p>
        Oil is now king in Venezuela and that means the coffee industry has
        suffered, dropping it to one of the lowest ranking coffee producers in
        the world. Still, the regional favorite Merida is a delicious, light and
        sweetly acidic pure bread that makes it a favorite underdog for those
        lucky enough to find it.
      </p>
      <p>Best known varietals: Merida.</p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/africa-middle-east"
      >
        <h3> Click here to explore the Africa and the Middle East. </h3>
      </Link>
    </div>
  );
};

export default SouthAmerika;
