import React, { useRef, useState } from "react";

import "./SelectCountry2.scss";

import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

let cs = require("classnames");

const SelectCountry2 = (props) => {
  const { items, selectedItem, className, className2, label, disabled } = props;
  const dropDownRef = useRef(null);

  const [showItems, setShowItems] = useState(false);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (name, id) => {
    props.onItemSelect(name, id);
    setShowItems(!showItems);
  };

  return (
    <div
      ref={dropDownRef}
      onClick={() => setShowItems(!showItems)}
      className={cs("SelectCountry2", className)}
    >
      <div className="SelectCountry2__header">
        <div>
          <h1 className="SelectCountry2__title"> {label}</h1>
          <h1 className="SelectCountry2__title2"> {selectedItem}</h1>
        </div>
        <span className="SelectCountry__arrow-down" />
        {!disabled && (
          <img className="SelectCountry2__icon" src={Arrow} alt="icon" />
        )}
      </div>

      <div
        className={cs(
          "SelectCountry2__items",
          showItems && !disabled && "active",
          className2
        )}
      >
        {items?.map((item) => {
          return (
            <div
              key={item?.id}
              onClick={() => onItemSelect(item.name, item.id)}
              className={`SelectCountry2__item-container ${
                props.selectedItem === item.id ? "activeLabel" : ""
              }`}
            >
              <p className="SelectCountry2__item">{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCountry2;
