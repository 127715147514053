import React, { useEffect, useState } from "react";

import * as API from "../../api/Api";
import ManageMyAccount from "../../components/Account/ManageMyAccount/ManageMyAccount";
import PhotoSection from "../../components/Account/PhotoSection/PhotoSection";
import QuizPreferences from "../../components/Account/QuizPreferences/QuizPreferences";
import PurchaseHistory from "../../components/Account/PurchaseHistory/PurchaseHistory";
import PreferencesBased from "../../components/Account/PreferencesBased/PreferencesBased";
import QuizRecommendations from "../../components/Quiz/QuizRecommendations/QuizRecommendations";
import Line from "../../components/shared/Line/Line";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";

import "./Account.scss";

const Account = () => {
  const [details, setDetails] = useState([]);
  const [orders, setOrders] = useState([]);
  const [quizRecommendations, setQuizRecommendations] = useState([]);
  const msgCtx = useMessageContext();
  const filterCtx = useFilterProductsContext();

  useEffect(() => {
    getDetails();

    API.getOrders()
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => msgCtx.handleError(err));

    filterCtx.fetchQuizPreferences();

    API.getQuizCoffeeRecommendations()
      .then((res) => setQuizRecommendations(res.data["coffee-recommendations"]))
      .catch((err) => msgCtx.handleError(err));
  }, []);

  const getDetails = () => {
    API.getProfileById()
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => console.log(err, "err"));
  };

  return (
    <div className="Account" id="Account">
      <PhotoSection refetchDetails={getDetails} details={details} />
      <div className="Account__container">
        <div className="Account__wrapper">
          <ManageMyAccount />
          <QuizPreferences quiz={filterCtx.quizResult} />
        </div>
        <PurchaseHistory
          orders={orders}
          history={details}
          fetchDetails={getDetails}
        />
      </div>
      <Line />
      <Line />
      {details?.recommendation && details?.recommendation.length !== 0 && (
        <QuizRecommendations quizRecommendations={quizRecommendations} />
      )}
      {details?.recommendation && details?.recommendation.length !== 0 && (
        <PreferencesBased recommendation={details?.recommendation} />
      )}
    </div>
  );
};

export default Account;
