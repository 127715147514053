import React, { useState, useEffect } from "react";
import PhotoUploaderModal from "./PhotoUploaderModal/PhotoUploaderModal";

import "./PhotoSection.scss";
import PrimaryTitle from "../../shared/PrimaryTitle/PrimaryTitle";

const PhotoSection = (props) => {
  const { details } = props;
  const [openModal, setOpenModal] = useState(false);
  const [imageData, setImageData] = useState();
  const [user, setUser] = useState();

  const image = localStorage.getItem("image");

  useEffect(() => {
    openModal && (document.body.style.overflow = "hidden");
    !openModal && document.body.style.removeProperty("overflow");
  }, [openModal]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUser(JSON.parse(user));
  }, []);

  const content = () => {
    switch (true) {
      case openModal:
        return (
          <PhotoUploaderModal
            refetchDetails={props.refetchDetails}
            details={details}
            imageData={imageData}
            setImageData={setImageData}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        );
      default:
        return (
          <div onClick={() => setOpenModal(!openModal)}>
            {image !== "null" ? "Change your photo" : "Upload your photo"}
          </div>
        );
    }
  };

  return (
    <div className="PhotoSection">
      <div className="PhotoSection__main-title-wrap">
        <PrimaryTitle title="WELCOME" className="PhotoSection__main-title" />
      </div>

      <div className="PhotoSection__wrap">
        <div className="PhotoSection__img-section">
          <div className="PhotoSection__img-wrap">
            <div className="PhotoSection__img-circle" />
            {details?.image ? (
              <img
                /*  src={details.image} */
                src={image}
                alt="my data"
                className="PhotoSection__img"
              />
            ) : (
              <div className="PhotoSection__img">
                <span>YOUR PHOTO GOES HERE</span>
              </div>
            )}
          </div>
          <div className="PhotoSection__upload-img">{content()}</div>
        </div>
        <div className="PhotoSection__welcome-section">
          <h2 className="PhotoSection__welcome-title">
            Greetings {user?.first_name}
          </h2>
          <p className="PhotoSection__welcome-text">
            Welcome to GoCoffeeGo! This is your personal page where you can
            access tailored information about our fabulous coffees and your
            account with us. Fell free to poke around, leave a review or two,
            learn more about your taste preferences, and check out your curated
            recommendations!
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhotoSection;
