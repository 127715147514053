import React from "react";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import NewPasswordForm from "../../components/Login/NewPasswordForm/NewPasswordForm";

import "./NewPassword.scss";

const NewPassword = () => {
  return (
    <div className="NewPassword">
      <PrimaryTitle
        primaryClassName="NewPassword__title"
        title="CREATE NEW PASSWORD"
      />

      <div className="NewPassword__form">
        <NewPasswordForm />
      </div>
    </div>
  );
};

export default NewPassword;
