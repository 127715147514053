//import AudioPlayer from "../AudioPlayer/AudioPlayer";
import "./StartQuiz.scss";

const StartQuiz = ({ startQuiz, quizResult }) => {
  return (
    <div className="StartQuiz">
      <div className="StartQuiz__small-img" />
      <div className="StartQuiz__img" onClick={startQuiz}>
        <span className="StartQuiz__text">
          {quizResult && quizResult.id ? "RETAKE QUIZ" : "TAKE QUIZ"}
        </span>
      </div>
      <h1 className="StartQuiz__title">Find the perfect coffee</h1>
      {/* <AudioPlayer /> */}
    </div>
  );
};

export default StartQuiz;
