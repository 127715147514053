import { useFormik } from "formik";
import * as Yup from "yup";

const NewPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Please provide email"),
  password: Yup.string()
    .required("Please provide new password")
    .min(8, "Passwords must be at least 8 characters."),
  confirmPassword: Yup.string()
    .required("Please provide confirm password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and confirm password does not match "
      ),
    }),
});

export const useNewPasswordFormik = (opts) => {
  return useFormik({
    initialValues: {
      temporaryPassword: "",
      email: "",
      password: "",
      confirmPassword: "",
      ...opts?.initialValues,
      NewPasswordSchema,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: NewPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
