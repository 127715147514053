import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";
import Seo from "../lib/helpers/SEO/Seo";

export const PrivateRoute = (props) => {
  const pathName = props?.location?.pathname;
  const authCtx = useAuthContext();

  if (authCtx.isLoading) {
    return <div> Loading...</div>;
  }

  if (pathName) {
    localStorage.setItem("route_from", pathName);
  }

  // @TODO commented for testing redirects
  // if (props?.location?.pathname?.startsWith("/order-success")) {
  //   localStorage.setItem("route_from", props.location.pathname);
  // }

  if (props?.location?.pathname?.startsWith("/checkout") || props?.location?.pathname?.startsWith("/order-success")) {
    if (!authCtx.isGuestAuthenticated && !authCtx.isAuthenticated) {
      return <Redirect to="/login" />;
    }
  } else if (!authCtx.isAuthenticated || authCtx.isGuestAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Seo title={props.metaTagName} description={props.metaTagDescription} />
      <Route {...props} />
    </>
  );
};
