import { apiRequest } from "./Api";

export const login = async (data) => {
  return apiRequest("post", "login", data);
};

export const register = async (data) => {
  return apiRequest("post", "register", data);
};

export const registerGuest = async (data) => {
  return apiRequest("post", "register-guest", data);
};

export const forgotPassword = async (email) => {
  return apiRequest("post", "forgot-password", { email: email });
};

export const resetPassword = async (token, data) => {
  return apiRequest("post", `reset-password?${token}`, data);
};
