import { useFormik } from "formik";
import * as Yup from "yup";

const SubscriptionSchema = Yup.object().shape({
  first_name: Yup.string().required("Enter your firstname"),
  last_name: Yup.string().required("Enter your lastname"),
  address1: Yup.string().required("Enter your address"),
  city: Yup.string().required("Enter your city"),
  zip: Yup.string().required("Enter Postal Code"),
  email: Yup.string()
    .required("Enter recipient email ")
    .email("Enter a valid email address"),
  gift_from: Yup.string().required("Enter Sender name"),
  gift_to: Yup.string().required("Enter Recipient name"),
  gift_msg: Yup.string()
    .required("Required")
    .max(60, "The maximum length of a gift message is 60 characters!"),
});

export const useSubscriptionFormik = (itemDetails, opts) => {
  return useFormik({
    initialValues: {
      first_name: itemDetails ? itemDetails.gift_recipient.first_name : "",
      last_name: itemDetails ? itemDetails.gift_recipient.last_name : "",
      address1: itemDetails ? itemDetails.gift_recipient.address1 : "",
      city: itemDetails ? itemDetails.gift_recipient.city : "",
      zip: itemDetails ? itemDetails.gift_recipient.zip : "",
      email: itemDetails ? itemDetails.gift_recipient.email : "",
      gift_from: itemDetails ? itemDetails.gift_from : "",
      gift_to: itemDetails ? itemDetails.gift_to : "",
      gift_msg: itemDetails ? itemDetails.gift_msg : "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SubscriptionSchema,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
