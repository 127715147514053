import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../lib/context/AuthContext/AuthContext";
import Seo from "../lib/helpers/SEO/Seo";

export const RestrictedRoute = (props) => {
  const authCtx = useAuthContext();
  const route_from = localStorage.getItem("route_from");
  if (authCtx.isLoading) {
    return <div> Loading...</div>;
  }

  if (authCtx.isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (authCtx.isGuestAuthenticated && !route_from) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Seo title={props.metaTagName} description={props.metaTagDescription} />
      <Route {...props} />;
    </>
  );
};
