import React from "react";

import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";

const SeoImage = (props) => {
  const {
    src,
    alt,
    style,
    className
  } = props;

  const seoCtx = useSeoContext();

  let foundImage = null

  if (seoCtx?.imageMetadata) {
    foundImage = seoCtx.imageMetadata.find(
      (el) => el.src.toLowerCase() === src.toLowerCase()
    );
  }

  return (
    <img
      src={src}
      alt={foundImage?.alt || alt}
      style={style}
      className={className}
    />
  );
};

export default SeoImage;
