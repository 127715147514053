import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const AfricaMiddleEast = () => {
  const article = {
    title: "Africa & The Middle East",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/africa-middle-east"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>Africa & The Middle East</h3>
      <h3>Ethiopia</h3>
      <p>
        It's quite a trek by camel caravan heading due southwest from Addis
        across rocky, mountainous terrain. If you remember your history - pay
        attention - coffee was first discovered in the southern regions of
        Ethiopia, now part of the Kaffa province. What an honor to visit the
        motherland of coffee, which is today Africa's largest producer of
        Arabica beans and Ethiopia's largest employer.
      </p>
      <p>
        Coffee here never disappoints. Delicious. Wild coffee cherries are still
        harvested in the mountains by tribes' people. I truly believe that one
        has not lived until they try Ethiopian Yergachffe coffee. Its sweet
        berry notes completely intoxicate and overwhelm me with its sweet
        loveliness. One taste and you will be under Ethiopia's spell. I am.
      </p>
      <p>
        Ranked 6th in the world.* Best known varietals: Ethiopian Harrar,
        Yirgacheffe, Sidamo.
      </p>

      <h3>Kenya</h3>
      <p>
        To gaze the slopes of Kilimanjaro! Kenya has become an African coffee
        powerhouse with a significant crop of Arabica beans. Coffees here share
        many characteristics of the region, with a bright, vibrant and winey
        taste, but these beans stand apart with richer, deeply fruity notes as
        well.
      </p>
      <p>Best known varietals: SL 28, Bourbon, Kents, Ruiru II.</p>

      <h3>Tanzania</h3>
      <p>
        Around the other side of Kilimanjaro, Tanzanian wet-processed coffees
        are giving Kenya a run for its money.
      </p>
      <p>
        Best known varietals: Typica/ Nyara, Arusha, Bourbon, Kents, Blue
        Mountain.
      </p>

      <h3>Uganda</h3>
      <p>
        Uganda grows huge quantities of Robusta, indigenous to the region and
        much of it wild. The improving political situation and relations with
        neighboring port countries has allowed more of the precious few Arabicas
        to move for export as well.
      </p>
      <p>
        Ranked 10th in the world.* (Mostly Robusta) Best known varietals:
        Typica, Kents, Bugisu.
      </p>

      <h3>Yemen</h3>
      <p>
        Across the sea from Ethiopia, I trek once again from the Yemeni city of
        Al Mokha (a.k.a. Mocha. Sound familiar?) northward on one of my odorous,
        humped friends into the highlands where Arabs first cultivated coffee.
        The growers here dry-process a hearty bean for a bright, spicy brew.
      </p>
      <p>Best known varietals: Mocha.</p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/asia-indonesia"
      >
        <h3>Click here to explore Asia and Indonesia. </h3>
      </Link>
    </div>
  );
};

export default AfricaMiddleEast;
