import ModalWrapper from "../../Modals/ModalWrapper/ModalWrapper";

import "./PriceRange.scss";

const PriceRange = (props) => {
  return (
    <ModalWrapper closeIcon isOpen={props.isOpen} toggle={props.toggle}>
    <div className="PriceRange">
      <h2 className="PriceRange__title">Why A Price Range?</h2>
      <p className="PriceRange__text">We have so many amazing coffees and roasters to choose from, a price range approach is a best way to go, in order to have as many coffees as possible to offer for the Coffee Club.</p>
      <p className="PriceRange__text">In the Coffee Club you pay the exact price listed on the website for the selected coffee. There is no price mark-up on coffees selected for the club. You pay the price of the coffee and the posted shipping and that's all. It truly is "Pay as You Go." We just make it easy and do the choosing for you</p>
      <p className="PriceRange__text">Your coffee will be roasted to order just for you and sent USPS Priority, so you will get it fresh and fast!</p>
      <p className="PriceRange__text">There are two different plans to choose from: The Supreme Coffee Plan and The Ultimate Coffee Plan.</p>
    </div>
    </ModalWrapper>
  );
};

export default PriceRange;