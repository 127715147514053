import React from "react";

import ArticleContainer from "../ArticleContainer/ArticleContainer";
import Seo from "../../../lib/helpers/SEO/Seo";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";

import "./BrewingTips.scss";

const BrewingTips = () => {
  const seoCtx = useSeoContext();

  const Nun =
    "https://assets.gocoffeego.com/v2/assets/images/nun-with-ruler.jpg";
  const David =
    "https://assets.gocoffeego.com/v2/assets/images/david-niven.jpg";
  const Sophia =
    "https://assets.gocoffeego.com/v2/assets/images/sophia-loren-dreamy.jpg";
  const RetroCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/retro-coffee-drinking-dad.jpg";
  const CoffeeBeans =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-beans.jpg";
  const CoffeeContainer =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-container.jpg";
  const SingingCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/singing-coffee-beans.jpg";
  const CrashingWaves =
    "https://assets.gocoffeego.com/v2/assets/images/spectacular-crashing-waves.jpg";
  const CoffeeGunk =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-gunk-2.jpg";
  const WasteCleanup =
    "https://assets.gocoffeego.com/v2/assets/images/waste-clean-up.jpg";
  const GroundCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/ground-coffee-measuring.jpg";
  const Clock =
    "https://assets.gocoffeego.com/v2/assets/images/15.jpg";
  const Pyrex =
    "https://assets.gocoffeego.com/v2/assets/images/pyrex-measuring-cup.jpg";
  const Weighing =
    "https://assets.gocoffeego.com/v2/assets/images/weighing-coffee-beans-scale.jpg";
  const CoffeeTermos =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-thermos-1.jpg";
  const Vintage =
    "https://assets.gocoffeego.com/v2/assets/images/vintage-refrigerator-ad.jpg";

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/professor-peaberry/brewing-tips/coffee-brewing-tips"
  );

  const article = {
    title: "How to make a great cup of coffee, espresso, latte and other concoctions...",
    image: "https://assets.gocoffeego.com/v2/assets/images/brewing-tips-bg.png",
    url: "https://www.gocoffeego.com/learn/category/brewing-tips"
  };

  return (
    <ArticleContainer
      shapeStyle={{ background: "#FF008E" }}
      title="Coffee Brewing Tips"
    >
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
          article={article}
        />
      )}

      <div id="BrewingTips" className="BrewingTips">
        <h2>
          How to make a great cup of coffee, espresso, latte and other
          concoctions...
        </h2>

        <h2 className="BrewingTips__title">Coffee Brewing Tips </h2>

        <div className="BrewingTips__container">
          <p className="BrewingTips__description">
            <img
              className="BrewingTips__description-image left"
              src={Nun}
              alt="nun"
            />
            So far there has been a tremendous amount of work by a multitude of
            lovely people to get you the best coffee bean possible. What’s next?
            Well, just don’t sit there, you Ninny, it’s time to take some
            responsibility. Take those exquisite beans in hand and brew them!
            Brew them I say!
          </p>
        </div>

        <div>
          <h2 className="BrewingTips__title">First Things First</h2>
          <p className="BrewingTips__description">
            <img
              className="BrewingTips__description-image right"
              src={David}
              alt="david"
            />
            Rules, rules and more rules... Oh, stop complaining! I’m trying to
            teach you how to make a great cup of coffee. Remember our goal here
            is perfection, nothing less. Follow these simple principles and
            you’ll soar to new coffee heights, as your taste buds lead you to
            pleasurably-pontificate-endless-exclamations such as:
          </p>

          <p className="BrewingTips__description">
            “Professor Peaberry, old boy, you’re positively genius! Who knew
            that
            <img
              className="BrewingTips__description-image left"
              src={Sophia}
              alt="sophia"
            />
            cleaning the sludge from the bottom of the coffee pot could be the
            Sophia Loren is dreamy and absolutely beautiful.key to coffee
            nirvana?”
          </p>
          <p className="BrewingTips__description">
            “Ohhhh, you brilliant, fat, little sexy man! Mother was right.
            Technique is everything!”
          </p>
        </div>
        <h2 className="BrewingTips__title1">
          ~The Professor Peaberry Principles~
        </h2>
        <h2 className="BrewingTips__title2">Buy Only High Quality Beans</h2>

        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image right"
            src={CoffeeBeans}
            alt="coffe-beans"
          />
          Start with freshly roasted coffee beans from any one of the fabulous
          roasters we feature on our site.
          <img
            className="BrewingTips__description-image left"
            src={RetroCoffee}
            alt="retro-coffee"
          />
          Retro coffee drinking Dad from the 1950's.They are the crema of the
          cup. The coffee elite. The best. The ultimate. The superior. Anyone
          else is most likely inferior. Yes, I am biased. Go-Coffee-Go!
          Rah-rah-rah-sis-boom-bah.
        </p>
        <h2 className="BrewingTips__title">Keep it Fresh</h2>
        <p className="BrewingTips__description">
          Buy your toilet paper at Costco, not your coffee. If your coffee is
          not fresh, the notes fade and so will its potential in your cup. Buy
          coffee that is roasted-to-order. Look for the roast date as proof on
          the bag.
        </p>
        <p className="BrewingTips__description">
          <img
            style={{ height: "252px" }}
            className="BrewingTips__description-image right"
            src={CoffeeContainer}
            alt="coffee-container"
          />
          Air, heat, light and moisture are coffee’s enemy. You see oxygen is
          the biggest antagonist to our little bean friends and quite the bully.
          So with that, fresh roasted coffee is the only way to go - unless a
          gamma ray burst from a distant star blasts away our oxygen rich
          atmosphere - well we wouldn’t be here anyway, so this is a moot point.
          Therefore, coffee beans should be stored properly in an airtight
          container in a cool, dark place. I prefer a ceramic container with a
          rubber gasket on the lid for a tight seal.
        </p>
        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image left"
            src={SingingCoffee}
            alt="singing-coffee"
          />
          Coffee reaches its joyful peak 3-10 days after it is roasted.Sadly, it
          degrades quickly after two weeks, losing its voice, its song, thus its
          vanquished into an abyss, into a proverbial Coffee Black Hole after a
          month. Yes, this roasted bean’s short life is over - like that of the
          Hu-hu-ma-tu-tu.
          {/*<img*/}
          {/*  className="BrewingTips__description-image left"*/}
          {/*  src={BurrCoffee}*/}
          {/*  alt="burr-coffee"*/}
          {/*/>*/}
          Baratza burr coffee grinder.But don’t be sad. Just order some more and
          the notes will sing and dance across your palette once again!{" "}
        </p>
        <p className="BrewingTips__description">
          Freezing your beans -- A chilly proposition. Though hotly debated
          amongst the coffee elite, some experts believe, if done properly, you
          can indeed extend the life and freshness of your beans for up to a
          month by freezing them. (See the chilling details below.)
        </p>
        <h2 className="BrewingTips__title">
          Only grind your beans right before use
        </h2>
        <p className="BrewingTips__description">
          Once coffee is ground, it degrades rapidly! Purchase a Burr Grinder
          and grind beans only as needed. No dilly-dallying here, grind and brew
          for results that are truest to the bean itself.
        </p>
        <h2 className="BrewingTips__title">Water</h2>
        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image right"
            src={CrashingWaves}
            alt="crashing-waves"
          />
          It’s simple: good water is the start of good coffee. Since coffee is
          99% water, any unpleasant taste in your water will pass into your
          coffee. Just make sure it is fresh and clean. Avoid tap water that is
          heavy with minerals or tastes chemically treated, such as with
          chlorine. Pool water is a no-no! Fish pond as well! For best results
          used filtered water. Likewise, avoid water that is free of every
          mineral, like distilled or heavily filtered water.
        </p>
        <h2 className="BrewingTips__title">Cleanliness</h2>
        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image left"
            src={CoffeeGunk}
            alt="coffee-gunk"
          />
          <img
            className="BrewingTips__description-image right"
            src={WasteCleanup}
            alt="coffee-gunk"
          />
          Don’t let the innards of your coffee making device du jour get coated
          or encrusted with icky, brownish goop. Coffee Gunk. Keep your coffee
          machine clean.Gadzooks! Coffee contains oils and these oils that are
          left behind can grow rancid. Pew! Clean up after yourself, you Dirty
          Bird! Use a gentle sponge and little or no dish soap, so your coffee
          doesn’t end up tasting lemony fresh. Remember: Cleanliness is next to
          flavorableness.
        </p>
        <h2 className="BrewingTips__title">Temperature and Time</h2>
        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image left"
            src={GroundCoffee}
            alt="ground-coffee"
          />
          Coffee’s ideal brewing temperature is between 199 and 205 degrees
          Fahrenheit. Period. Two Timers. Coffee ideal brewing temperature
          <img
            className="BrewingTips__description-image right"
            src={Clock}
            alt="clock"
          />
          and time to brew.If, for instance, you boil water separately for a
          French Press or Pour-Over Cone, let the water sit for a minute just
          after your kettle whistles, then use. This allows the temperature to
          drop. Depending on the method and grind choice, coffee brews anywhere
          from 4 to 8 minutes. You may use a stopwatch, if you wish. I do.
        </p>

        <h2 className="BrewingTips__title">Good Measures</h2>
        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image left"
            src={Pyrex}
            alt="pyrex"
          />
          Check any pertinent directions on your coffee device regarding what
          measure constitutes a cup. Trial and error is often needed, but a good
          coffee brewing tip is that you should use one tablespoon of coffee,
          properly ground, per 6 ounces of water.. What’s that? You prefer a mug
          and its 8 ounces? Then measure up! Some use 2 tablespoons of coffee
          for an extra rich cup. Here's a coffee brewing tip: taste is
          subjective, thus proceed with reckless abandon. Experiment, I say...
          Brew in the nude! Brew standing on your head! Just remember to brew a
          cup worth brewing! For those of you who are anal enough to weigh their
          beans for precision brewing, I applaud you. My rectum does as well. So
          pull out your digital scales and weigh those beans!
        </p>
        <div>
          <p className="BrewingTips__description">
            <img
              className="BrewingTips__description-image right"
              src={Weighing}
              alt="weighing"
            />
            Important Factoid: Light and dark roasted coffee beans may measure
            the same, however they do not weigh the same and this is why
            weighing is the most precise manner for initiating your cup.
          </p>
          <p className="BrewingTips__description">
            French Press: 25 grams of beans for 10 oz. of water. Melitta
            Brewers: 25 Grams of beans for 10 oz. of water. Vacuum Pots: 7 to 8
            grams for every 5 oz of water. Electric Drip: 7 to 8 grams for every
            5 oz of water.
          </p>
        </div>

        <h2 className="BrewingTips__title">Serve Promptly</h2>
        <p className="BrewingTips__description">
          <img
            className="BrewingTips__description-image left"
            src={CoffeeTermos}
            alt="coffee-termos"
          />
          After brewing, a tardy cup of coffee is faux pas. Coffee that sits for
          as little as 15 minutes can lose its true flavor as natural oils that
          provide taste break down and evaporate. It becomes the proverbial
          sludge. After an hour of “warming” coffee on its “burner,” you might
          as well discard the remaining scorched glop in the pot and make haste
          by moving to another voting district because your reputation as a
          coffee aficionado is ruined. I highly suggest if this is you to --
          Quick hide and find your Mormon disguise. You see, Mormons do not
          drink coffee, thus no one will suspect it was you. Perhaps the
          solution is truly simple to avoid such difficulties -- just pour your
          precious brew into a fine quality insulated carafe or thermos
          immediately after brewing to preserve all of its goodness.
        </p>

        <h2 className="BrewingTips__title">Storage</h2>
        <p className="BrewingTips__description">
          As stated earlier, keep your beans in an airtight container, out of
          the light in a dark cupboard in a temperature of 50 to 70 degrees
          Fahrenheit. Coffee is best brewed fresh, so when it comes to storing
          coffee beans for long periods of time, simply don’t do it, unless one
          chooses to go cryogenic. Do so at you own risk. No human has ever come
          back from the deep freeze - Shall a bean? Thus...
        </p>

        <h2 className="BrewingTips__title">
          To freeze or not to freeze – that is the question?
        </h2>
        <div>
          <img
            className="BrewingTips__description-image right"
            src={Vintage}
            alt="vintage"
          />
          <p className="BrewingTips__description">
            Would I roast my ice cream? Then why in the hooey would I freeze my
            beans! Let’s explore this controversial paradox, shall we?
          </p>
          <p className="BrewingTips__description">
            Yes, it is true; many experts believe that freezing does indeed help
            preserve the freshness of coffee. But rules must be followed. If you
            choose to freeze your beans, never do it for longer than one month.
            It’s actually the process of freezing and thawing, and freezing and
            thawing that can harm the quality and flavor notes of your beans. In
            the freezer store your beans in individual Ziploc Baggies,
            preferably in one-day to a maximum of one-week portions. This way no
            condensation or unpleasant frostbite will occur. So once you take
            them out of the icebox, do not put them back in.
          </p>
        </div>
      </div>
    </ArticleContainer>
  );
};

export default BrewingTips;
