import React from "react";
import { useParams } from "react-router-dom";
import CoffeKnowledge from "../../components/Learn/CoffeKnowledge/CoffeKnowledge";
import PeaberryRecommendations from "../../components/Learn/PeaberryRecommendations/PeaberryRecommendations";
import CoffeeFaqHeadline from "../../components/Learn/CoffeeFaqHeadline/CoffeeFaqHeadline";
import PeaberryRecommendedCoffees from "../../components/Learn/PeaberryRecommendedCoffees/PeaberryRecommendedCoffees.";
import TheHistoryOfCoffee from "../../components/Learn/TheHistoryOfCoffee/TheHistoryOfCoffee";
import Recipes from "../../components/Learn/Recipes/Recipes";
import Roasting from "../../components/Learn/Roasting/Roasting";
import BrewingTips from "../../components/Learn/BrewingTips/BrewingTips";
import Regions from "../../components/Learn/Regions/Regions";
import EsspressoDrinks from "../../components/Learn/EsspressoDrinks/EsspressoDrinks";
import Grinding from "../../components/Learn/Grinding/Grinding";
import MakingYourBrew from "../../components/Learn/MakingYourBrew/MakingYourBrew";

import "./Learn.scss";

const Learn = () => {
  const params = useParams();

  const slug = params.pathParam2;
  // console.log('slug', slug)

  return (
    <div className="Learn">
      <CoffeKnowledge />
      {slug?.includes("recipes") && <Recipes />}
      {slug?.includes("the-history-of-coffee") && <TheHistoryOfCoffee />}
      {slug?.includes("roasting") && <Roasting />}
      {slug?.includes("brewing-tips") && <BrewingTips />}
      {slug?.includes("regions") && <Regions />}
      {slug?.includes("espresso-drinks") && <EsspressoDrinks />}
      {slug?.includes("grinding") && <Grinding />}
      {slug?.includes("making-your-brew") && <MakingYourBrew />}
      <PeaberryRecommendations />
      <CoffeeFaqHeadline />
      <PeaberryRecommendedCoffees />
    </div>
  );
};

export default Learn;
