import React, { useRef, useState, useEffect } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";

import "./SelectPlan.scss";
let cs = require("classnames");

const SelectPlan = (props) => {
  const {
    selectedItem,
    className,
    className2,
    label,
    supremeCoffeeClubs,
    ultimateCoffeeClubs,
    clubId,
    setSelectedClub,
    selectedClub,
    assignedClub,
  } = props;
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";
  const dropDownRef = useRef(null);

  const [showItems, setShowItems] = useState(false);

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (description, price, id) => {
    props.onItemSelect(description, price, id);
    setShowItems(!showItems);
    if (clubId) {
      setSelectedClub(clubId);
    }
  };

  return (
    <div
      ref={dropDownRef}
      onClick={() => setShowItems(!showItems)}
      className={cs("SelectPlan", className)}
    >
      <div className="SelectPlan__header">
        <>
          <h2 className="SelectPlan__title">{label}</h2>

          <h2 className="SelectPlan__title2">
            <span>{selectedItem.description}</span>
            <span>{selectedItem.price}</span>
          </h2>
        </>

        <span className="SelectPlan__arrow-down" />
        <img className="SelectPlan__icon" src={Arrow} alt="arrow down icon" />
      </div>
      <div
        className={cs("SelectPlan__items", showItems && "active", className2)}
      >
        <h3 className="SelectPlan__plan-title">Supreme Coffee Plan</h3>
        {supremeCoffeeClubs?.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() =>
                onItemSelect(item.description, item.price, item.id)
              }
              className={`SelectPlan__item-container ${
                props.selectedItem === item.id ? "activeLabel" : ""
              }`}
            >
              <p className="SelectPlan__item">
                <span>{item.description}</span>
                <span>{item.price}</span>
              </p>
            </div>
          );
        })}
        <h3 className="SelectPlan__plan-title">Ultimate Coffee Plan</h3>
        {ultimateCoffeeClubs?.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() =>
                onItemSelect(item.description, item.price, item.id)
              }
              className={`SelectPlan__item-container ${
                props.selectedItem === item.id ? "activeLabel" : ""
              }`}
            >
              <p className="SelectPlan__item">
                <span>{item.description}</span>
                <span>{item.price}</span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectPlan;
