import ReactGA from "react-ga";

export const Event = (props) => {
  const { category, action, label, options } = props;

  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
