import React from "react";

import Button from "../../shared/Button/Button";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useHistory } from "react-router-dom";

import cs from "classnames";
import "./PeaberryRecommendsCard.scss";

const PeaberryRecommendsCard = (props) => {
  const Peaberry =
    "https://assets.gocoffeego.com/v2/assets/images/prof-peaberry-with-a-cup-new.png";
  const history = useHistory();
  const isMobile = useResponsiveDimensions().isMobile;
  const { product, review, updatedAt, peaberryRecommends } = props;

  return (
    <div className={cs("PeaberryRecommendsCard")}>
      <div className="PeaberryRecommendsCard__grid">
        <div className="PeaberryRecommendsCard__column-1">
          <img
            className="PeaberryRecommendsCard__product"
            src={product?.main_image?.xlg}
            alt=""
          />
          {isMobile && (
            <>
              <div className="PeaberryRecommendsCard__title font-cairo">
                {product?.purveyor?.name}
              </div>
              <p className="PeaberryRecommendsCard__description">
                {product?.name}
              </p>
              <p className="PeaberryRecommendsCard__weight">
                ${product?.retail_price.toFixed(2)} / {product?.weight}
              </p>
            </>
          )}
        </div>
        <div className="PeaberryRecommendsCard__column-2">
          {!isMobile && (
            <div className="PeaberryRecommendsCard__title-container">
              <div className="PeaberryRecommendsCard__title font-cairo">
                {product?.purveyor?.name}
              </div>
              <p className="PeaberryRecommendsCard__description">
                {product?.name}
              </p>
              <p className="PeaberryRecommendsCard__weight">
                ${product?.retail_price.toFixed(2)} / {product?.weight}
              </p>
            </div>
          )}
          <p className="PeaberryRecommendsCard__review">{review}</p>
          <div className="PeaberryRecommendsCard__date">{updatedAt}</div>
          <Button
            label="VIEW DETAILS"
            className="PeaberryRecommendsCard__button"
            onClick={() => history.push(`/${product.product_url}`)}
          />
        </div>
        {peaberryRecommends && (
          <div className="PeaberryRecommendsCard__column-3">
            <img
              className="PeaberryRecommendsCard__peaberry"
              src={Peaberry}
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PeaberryRecommendsCard;
