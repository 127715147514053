import { apiRequest } from "./Api";

export const getQuizPreferences = async () => {
  return apiRequest("get", `account/quiz`, null);
};

export const getQuizCoffeeRecommendations = async () => {
  return apiRequest("get", `account/quiz/coffee-recommendations`, null);
};

export const addQuizPreferences = async (data) => {
  return apiRequest("post", `account/quiz`, data);
};

export const addQuiz = async (data) => {
  return apiRequest("get", `quiz?${data}`, null);
};

export const updateQuizPreferences = async (id, data) => {
  return apiRequest("put", `account/quiz/${id}`, data);
};

export const getQuizQuestionOne = async () => {
  return apiRequest("get", `coffee-know-how`);
};

export const getQuizQuestionTwo = async () => {
  return apiRequest("get", `quiz-coffee-types`);
};

export const getQuizQuestionThree = async () => {
  return apiRequest("get", `coffee-brew`);
};

export const getQuizQuestionFour = async () => {
  return apiRequest("get", `coffee-by-roast`);
};

export const getQuizQuestionFive = async () => {
  return apiRequest("get", `coffee-by-region`);
};
