import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import ShippingAddress from "../../components/Checkout/ShippingAddress/ShippingAddress";
import BillingAddress from "../../components/Checkout/BillingAddress/BillingAddress";
import PaymentMethod from "../../components/Checkout/PaymentMethod/PaymentMethod";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";

import "./Checkout.scss";
import TotalCart from "../../components/Checkout/TotalCart/TotalCart";
import Progress from "../../components/shared/Progress/Progress";
import ContactUsModal from "../../components/Modals/ContactUsModal/ContactUsModal";
import ApplePay from "../../components/shared/ApplePay/ApplePay";

const Checkout = () => {
  const history = useHistory();
  const params = useParams();
  const CartCtx = useCartContext();
  const msgCtx = useMessageContext();
  const pathParam = params.pathParam;
  const AuthCtx = useAuthContext();
  const items = CartCtx?.cartItems?.items;
  const billingAddressRequired = CartCtx.cartItems.billing_address_required;
  const [activeStep, setActiveStep] = useState(0);

  const even = (element) => element.gift_from === null;

  const route_from = localStorage.getItem("route_from");

  if (route_from?.startsWith("/order-success")) {
    history.push("/shop");
    localStorage.removeItem("route_from");
  }

  const placeOrderWithApplePay = (id) => {
    if (id) {
      history.push(`/order-success/${id}`);
      Event({
        category: "ORDER",
        action: "CREATE_ORDER",
        label: "PLACE_ORDER_APPLE_PAY",
      });
    }
  };

  useEffect(() => {
    AuthCtx.getCustomer();
  }, []);

  useEffect(() => {
    if (CartCtx?.cartItems?.shipping_address) {
      setActiveStep(1);
    }
    if (CartCtx?.cartItems?.shipping_address_id === null) {
      setActiveStep(0);
    }
    if (!items?.some(even)) {
      setActiveStep(1);
    }
  }, [CartCtx?.cartItems?.shipping_address]);

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            {" "}
            {items?.some(even) ? (
              <ShippingAddress
                setActiveStep={setActiveStep}
                activeStep={step}
              />
            ) : (
              <div />
            )}{" "}
          </>
        );
      case 1:
        return (
          <>
            {items?.some(even) ? (
              <ShippingAddress
                setActiveStep={setActiveStep}
                activeStep={step}
              />
            ) : (
              <div />
            )}
            {billingAddressRequired !== false && <BillingAddress />}
            <PaymentMethod
              pathParam={pathParam}
              activeStep={step}
              checkout
            />{" "}
          </>
        );
      case 2:
        return (
          <>
            {items?.some(even) ? <ShippingAddress /> : <div />}
            {billingAddressRequired !== false && <BillingAddress />}
            <PaymentMethod pathParam={pathParam} checkout />
          </>
        );
      default:
        return (
          <>
            {items?.some(even) ? <ShippingAddress /> : <div />}
            {billingAddressRequired !== false && <BillingAddress />}
            <PaymentMethod pathParam={pathParam} checkout />
          </>
        );
    }
  };

  return (
    <div className="Checkout">
      {msgCtx.openContactUsModal && (
        <ContactUsModal
          isOpen={true}
          toggle={() => msgCtx.setOpenContactUsModal(false)}
        />
      )}

      <PrimaryTitle title="Payment Method" />

      <div id="Checkout__content" className="Checkout__content">
        <div
          className={
            billingAddressRequired
              ? "Checkout__addresses"
              : "Checkout__addresses Checkout__addresses--billing"
          }
        >
          <Progress
            activeStep={activeStep}
            shippingAddress={CartCtx?.cartItems?.shipping_address}
          />
          <ApplePay />
          {renderStepContent(activeStep)}
        </div>
        <div>
          <TotalCart pathParam={pathParam} showCode checkout />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
