import React from "react";
import { useLocation } from "react-router-dom";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import Line from "../../components/shared/Line/Line";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./AboutUs.scss";

const AboutUs = () => {
  const image =
    "https://assets.gocoffeego.com/v2/assets/images/scottnelise.png";
  const stars =
    "https://assets.gocoffeego.com/v2/assets/images/stars-about.png";
  const Elise =
    "https://assets.gocoffeego.com/v2/assets/images/Elise.png";
  const Timothy =
    "https://assets.gocoffeego.com/v2/assets/images/Timothy.png";
  const John =
    "https://assets.gocoffeego.com/v2/assets/images/John.png";
  const Christopher =
    "https://assets.gocoffeego.com/v2/assets/images/Christopher.png";
  const Penelope =
    "https://assets.gocoffeego.com/v2/assets/images/Penelope.png";
  const Edin =
    "https://assets.gocoffeego.com/v2/assets/images/Edin.png";
  const xoxo =
    "https://assets.gocoffeego.com/v2/assets/images/XOXO-Elise.png";

  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  return (
    <div className="AboutUs">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <div className="AboutUs__main-title-wrapper">
        <PrimaryTitle
          title="A TEAM WITH THE BUZZ"
          className="AboutUs__main-title"
        />
      </div>
      <div className="AboutUs__history-wrapper">
        <h2 className="AboutUs__subtitle">OUR HISTORY</h2>
        <div className="AboutUs__grid-history">
          <img className="AboutUs__image" src={image} alt="about us" />
          <div className="AboutUs__description-container">
            <p className="AboutUs__description-history">
              Since 2008, GoCoffeeGo was founded as the first online specialty
              coffee marketplace of its kind, bringing consumer access to rare,
              exotic, and unique roasts in one easy-to-use site. Often imitated,
              never duplicated, over 10 million customers look to GCG as the
              premier niche marketplace for their caffeinated culinary
              adventure. All coffee beans are fresh-roasted to order and shipped
              on the same day, ensuring optimal flavor nuances. The way coffee
              was meant to taste.
            </p>
            <p className="AboutUs__description-history">
              Dedicated to a lifelong pursuit of knowledge in all things coffee,
              Co-Founders Scott Pritikin and Elise Papazian traveled to the
              farthest reaches of the globe, learning every facet of the magical
              bean from seed to cup. This dedication motivates GoCoffeeGo today
              to educate others into becoming lifelong coffee aficionados …
            </p>
            <p className="AboutUs__description-history">
              All because life is too short for mediocre coffee!{" "}
            </p>
            <img className="AboutUs__xoxo" src={xoxo} alt="xoxo" />
            <img className="AboutUs__stars" src={stars} alt="stars" />
          </div>
        </div>
      </div>
      <Line />
      <Line />
      <div className="AboutUs__container">
        <div className="AboutUs__grid">
          <img className="AboutUs__image" src={Elise} alt="Elise" />
          <div className="AboutUs__description-container">
            <div className="AboutUs__name">Elise Papazian Pritikin</div>
            <div className="AboutUs__role">
              Founder, Chairman, Chief Evangelist
            </div>
            <p className="AboutUs__description">
              A pioneer in the coffee industry, Elise's love of coffee started
              at age 16 when she was a barista at a Parisian-style café. After
              learning about the many nuances and varietals of coffee, she spent
              two years traveling across 26 countries, learning everything about
              this magical bean from seed to cup. Upon returning to the U.S.,
              she accepted a job selling exotic beans in the early days of
              specialty coffee. Elise and fellow coffee aficionado turned future
              husband, Scott Pritikin co-founded GoCoffeeGo in San Francisco
              from an idea first born over cappuccinos in a London café.
            </p>
            <p className="AboutUs__description">
              Founded in 2008, GoCoffeeGo is dedicated to offering the world's
              top micro-roasted coffees direct to consumers. Revered as the
              undisputed Contessa of Coffee, Elise possesses a Bachelor of Arts
              in Communications from USC's Annenberg School for Communication
              and Journalism.
            </p>
          </div>
        </div>
        <div className="AboutUs__grid">
          <img className="AboutUs__image" src={Timothy} alt="Timothy" />
          <div className="AboutUs__description-container">
            <div className="AboutUs__name">Timothy Johnson</div>
            <div className="AboutUs__role">COO, Board Member</div>
            <p className="AboutUs__description">
              Timothy is a career-long serial entrepreneur with a strong
              background in technology, finance, media, marketing, regenerative
              business, and various board memberships. His skill sets provide
              global strategies and agile leadership to assist GoCoffeeGo to
              achieve successful growth through adaption of next-generation
              Digital Transformation; best-of-class standards in both Corporate
              Social Responsibility (CSR) and Sustainable Development Goals
              (SDG); cross-border conscious branding, and; scaling omnichannel
              commerce.
            </p>
            <p className="AboutUs__description">
              Previously, he held senior positions at Trilennia Group, Holding
              Capital, Lehman Bros, Morgan Stanley, and UBS. He holds a
              Postgraduate Diploma in Digital Business from MIT Sloan School of
              Management and Columbia Business School and Certifications in
              Sustainable Business Strategy and Entrepreneurship and Innovation
              from Harvard Business School. He received his BA Degree from San
              Francisco State University following his undergraduate studies at
              UCLA.
            </p>
          </div>
        </div>
        <div className="AboutUs__grid">
          <img className="AboutUs__image" src={John} alt="John" />
          <div className="AboutUs__description-container">
            <div className="AboutUs__name">John Peterson</div>
            <div className="AboutUs__role">CTO</div>
            <p className="AboutUs__description">
              John is the founding engineer of the GoCoffeeGo development team.
              He is a seasoned versatile IT professional with over 17 years of
              experience, successfully developing and executing technology
              strategies to promote organizational growth in the US and Asia. A
              move to Hong Kong, led him to become fluent in Mandarin Chinese
              and conversant in Cantonese. He has a strong technical background
              in projects. John possesses a Bachelor of Computer Science from
              Brigham Young University.
            </p>
          </div>
        </div>
        <div className="AboutUs__grid">
          <img className="AboutUs__image" src={Christopher} alt="Christopher" />
          <div className="AboutUs__description-container">
            <div className="AboutUs__name">Christopher Papazian</div>
            <div className="AboutUs__role">Vice President of Marketing</div>
            <p className="AboutUs__description">
              Mr. Papazian returns to GoCoffeeGo as its Vice President of
              Marketing after previously serving five years (2008-2013) as its
              marketing director. Throughout his 20-year history, Christopher
              has led the global strategy and campaign execution for several
              diverse international brands such as Limos.com, iRiver, GERO, and
              Wireless Online, quantifiably elevating market share, revenue, and
              brand awareness. He possesses both a Master of Communication
              Management degree and a B.A. in Political Science from The
              University of Southern California.
            </p>
          </div>
        </div>
        <div className="AboutUs__grid">
          <img className="AboutUs__image" src={Penelope} alt="Penelope" />
          <div className="AboutUs__description-container">
            <div className="AboutUs__name">Penelope Francis</div>
            <div className="AboutUs__role">Executive Creative Director</div>
            <p className="AboutUs__description">
              Known as a keen storyteller and pushing creative boundaries,
              Penelope's unique business and artistic backgrounds are deeply
              steeped in lifestyle brand development. GoCoffeeGo tapped her to
              direct the brand's new visual design and identity transformation.
              Her proven branding track record and international partnerships/
              licensing arrangements include Neiman­-Marcus, Saks 5th Avenue,
              Browns (London), Quartier 206 (Berlin), and Naigai Co., Ltd.
              (across Japan). She holds a BFA in Design and Marketing from The
              University of North Texas.
            </p>
          </div>
        </div>
      </div>
      <Line />
      <Line />
      <div className="AboutUs__wrapper">
        <h2 className="AboutUs__subtitle">ADVISORY BOARD</h2>
        <div>
          <div className="AboutUs__grid">
            <img className="AboutUs__image" src={Edin} alt="Edin" />
            <div className="AboutUs__description-container">
              <div className="AboutUs__name">Edin Memisevic</div>
              <div className="AboutUs__role">
                Technical Consultant, Strategic Advisor
              </div>
              <p className="AboutUs__description-history">
                A world class technology innovator and troubleshooter with
                in-depth corporate and startup senior level experience.
                Previously Delivery Hero, Foodpanda, Bild and Axel Springer.
              </p>
            </div>
          </div>
          <div className="AboutUs__grid">
            <img className="AboutUs__image" src={Timothy} alt="Timothy" />
            <div className="AboutUs__description-container">
              <div className="AboutUs__name">Timothy Johnson</div>
              <div className="AboutUs__role">COO, Strategic Advisor</div>
              <p className="AboutUs__description-history">
                Silicon Valley career entrepreneur in advertising, finance,
                private equity, founder, manager and adviser to startups.
                Trilennia Group, Holding Capital, Lehman Bros, Morgan Stanley
                and UBS.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*  <div className="AboutUs__grid">
        <img className="AboutUs__image" src={image} alt="Scott Pritikin and Elise Papazian" />
        <div className="AboutUs__description-container">
          {/* <img className="AboutUs__flowers" src={flowers} alt="flowers" />
          <img className="AboutUs__stars" src={stars} alt="stars" />
          <p className="AboutUs__description">
            GoCoffeeGo is a San Francisco company founded by Scott Pritikin and
            Elise Papazian, a couple of creative and crazy, caffeinated coffee
            devotees.
          </p>
          <p className="AboutUs__description">
            We sought to expand our "cup" and fill our Espresso machines, French
            Presses, Pour-Overs, Vacuum Pots, Moccamasters and yes... even a
            vintage Mr. Coffee machine with the ultimate beans from Super-Star
            Specialty Roasters throughout the country, who are known in their
            local communities as the "gods and goddesses" of coffee.
          </p>
          <p className="AboutUs__description">
            GoCoffeeGo brings together the top award winning roasters on one
            site with one easy checkout. These artisans roast only the best
            coffee sourced from the highest quality, hand-picked beans grown
            throughout the world.
          </p>
          <p className="AboutUs__description">
            All coffee beans and espresso are fresh roasted to order and your
            coffee delivery is shipped the same day, direct from the roaster to
            you.
          </p>
          <p className="AboutUs__description">
            If you are on a search to find a great single origin coffee,
            espresso or coffee blend, you've come to the right place. Whether
            you live in a luxury Penthouse in a metropolitan city or a shack in
            the backwoods, you deserve access to great coffee.
          </p>
          <p className="AboutUs__description">
            Order the best coffee beans and espresso from GoCoffeeGo and
            experience all that gourmet coffee and fast coffee delivery has to
            offer!
          </p>
          <p className="AboutUs__description">
            You'll thank yourself and you will thank me.
          </p>
          <p className="AboutUs__description">Xo Elise</p>
        </div>
      </div> */}
    </div>
  );
};

export default AboutUs;
