import ContentLoader from "react-content-loader";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

const RoasterLoader = () => {
  const isMobile = useResponsiveDimensions().isMobile;
  const heightMobile = 130;
  const widthMobile = 150;
  return (
    <ContentLoader
      height={isMobile ? heightMobile : 190}
      width={isMobile ? widthMobile : 210}
      // speed={2}
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  );
};

export default RoasterLoader;
