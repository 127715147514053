import LinkButton from "../../shared/LinkButton/LinkButton";
import Button from "../../shared/Button/Button";
import { useHistory } from "react-router-dom";
import * as API from "../../../api/Api";
//import { saveAs } from "file-saver";

import "./OrderProduct.scss";
import { downloadPdf } from "../../../lib/helpers/downloadPdf";

const OrderProduct = ({
  product,
  isLoading,
  setOpenReview,
  openReview,
  setSelectedProduct,
  order,
  fromPurchaseHistory,
}) => {
  const history = useHistory();

  const PinterestIcon =
    "https://assets.gocoffeego.com/v2/assets/icons/pinterest-icon.svg";

  return (
    <div className="OrderProduct" key={product.id}>
      <div className="OrderProduct__wrap">
        {product.main_image?.xlg && !order?.gs_plan_text ? (
          <a
            className="OrderProduct__pinterest"
            target="_blank"
            rel="noreferrer"
            data-pin-do="buttonPin"
            data-pin-custom
            data-pin-tall="true"
            data-pin-round="true"
            href={`https://www.pinterest.com/pin/create/button/?url=${
              product.url
            }${`&description=${product.name}`}${`&media=${product.main_image?.xlg}`}`}
          >
            <img
              src={PinterestIcon}
              alt="pinterest"
              className="OrderProduct__pinterest-icon"
            />
          </a>
        ) : null}
        <div className="OrderProduct__label-wrap">
          <div className="OrderProduct__label-black-background"></div>
          {product.new_arrival ? (
            <div className="OrderProduct__label-new">{product.new_arrival}</div>
          ) : null}
          {product.discount ? (
            <div className="OrderProduct__label-sale">
              <div className="OrderProduct__label-sale-wrap">
                Save{" "}
                <span className="OrderProduct__label-sale-procent">
                  {product.discount}%
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {product?.main_image?.xlg && order && !order?.gs_plan_text && (
          <img
            onClick={() => history.push(`/${product?.product_url}`)}
            className="OrderProduct__image"
            src={product.main_image?.xlg}
            alt={product.name}
          />
        )}
        {order?.gc_url && (
          <img
            onClick={() => history.push(`/coffee-gift-certificates`)}
            className="OrderProduct__image"
            src={product?.image}
            alt="gift certificate"
          />
        )}
        {order && order?.gs_plan_text && (
          <img
            onClick={() => history.push(`/coffee-gifts`)}
            className="OrderProduct__image"
            src={product?.main_image?.xlg}
            alt={product?.name}
          />
        )}
      </div>
      <div className="OrderProduct__text-wrap">
        <div className="OrderProduct__title">
          <span
            className={product.coffee_body ? "OrderProduct__coffee-body" : null}
          >
            {" "}
            {product.coffee_body}{" "}
          </span>
        </div>
        <div className="OrderProduct__desc">{product.name} </div>
        <div className="OrderProduct__size-wrap">
          {!order?.gc_url ? (
            <div className="OrderProduct__size">{product?.coffee_roast}</div>
          ) : null}
          {order && order?.gs_plan_text && (
            <div className="OrderProduct__price">
              {product?.retail_price && product?.retail_price !== 0
                ? `$${product?.retail_price.toFixed(2)} / ${product.weight}`
                : null}
            </div>
          )}
          {order && !order?.gs_plan_text && product?.retail_price && (
            <div className="OrderProduct__price">
              {product?.retail_price
                ? `$${product?.retail_price.toFixed(2)} / ${product.weight}`
                : null}
            </div>
          )}
          {product?.gift_amount && (
            <div className="OrderProduct__price">
              ${product?.gift_amount.toFixed(2)}
            </div>
          )}

          {order && order?.gs_plan_text && (
            <div className="OrderProduct__price">{order?.gs_plan_text}</div>
          )}
        </div>
        {product.main_image?.xlg &&
        !order?.gs_plan_text &&
        !fromPurchaseHistory ? (
          <Button
            className="OrderProduct__button-review"
            label="Write Review"
            onClick={() => {
              setOpenReview(!openReview);
              setSelectedProduct(order);
            }}
          />
        ) : null}

        {product?.main_image?.xlg && product?.retail_price !== 0 && (
          <a href={product?.url}>
            <Button
              // path={`/product/${product.slug}/${product.id}`}
              className="OrderProduct__button"
              label="View Details"
            />
          </a>
        )}
        {order && order?.gc_url && (
          <>
            <Button
              onClick={() => {
                API.downloadGift(order.gc_id).then((res) => {
                  downloadPdf(res, "GoCoffeeGoGiftCertificate");
                });
              }}
              className="OrderProduct__button OrderProduct__button--download"
              label="Download Gift Certificate"
            />
            <LinkButton
              path={`/coffee-gift-certificates`}
              className="OrderProduct__button"
              label="View Details"
            />
          </>
        )}
        {order && order?.gs_plan_text && (
          <>
            <Button
              onClick={() => {
                API.downloadSubscriptionGift(order.gs_id).then((res) => {
                  downloadPdf(res, "GoCoffeeGoCoffeeSubscription");
                });
              }}
              className="OrderProduct__button OrderProduct__button--download"
              label="Download Subscription"
            />
            <LinkButton
              path={`/coffee-gifts`}
              className="OrderProduct__button"
              label="View Details"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default OrderProduct;
