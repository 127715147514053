import React, { useEffect, useState } from "react";

import * as API from "../../../api/Api";
import ViewAddress from "../ViewAddress/ViewAddress";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import AddressForm from "../AddressForm/AddressForm";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { isEmpty } from "../../../lib/helpers/isEmpty";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import { addressCreated, addressUpdated } from "../../../lib/helpers/messages";
import "./ShippingAddress.scss";

const ShippingAddress = (props) => {
  const { joinClub } = props;
  const [newAddress, setNewAddress] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState();
  const [saveAddress, setSaveAddress] = useState();
  const [choosenAddress, setChoosenAddress] = useState({});
  const [viewForm, setViewForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();
  const [addressesLoading, setAddressesLoading] = useState(false);
  const msgCtx = useMessageContext();

  useEffect(() => {
    getUpdatedData();
  }, []);

  const shipping_address = authCtx?.customerData?.shipping_address;
  const gift_subscription_adress =
    authCtx?.customerData?.gift_subscription_adress;

  useEffect(() => {
    if (joinClub && !viewForm && !updateForm) {
      setSelectedAddress(gift_subscription_adress);
      return;
    }

    if (shipping_address) {
      setSelectedAddress(shipping_address);
    } else {
      setSelectedAddress();
    }
  }, [shipping_address, gift_subscription_adress]);

  const getUpdatedData = () => {
    authCtx.getCustomer();
    setTimeout(() => {
      setViewForm(false);
      localStorage.setItem("shipping-form-opened", "false");
      setAddressesLoading(false);
    }, 2000);
  };

  const updateJoinClub = () => {
    if (joinClub) {
      API.setAddressForGiftSubscription(selectedAddress?.id).then(() => {
        props?.setAddressIsSelected(true);
        getUpdatedData();
        setViewForm(false);
        localStorage.setItem("shipping-form-opened", "false");
      });
    }
    return;
  };

  const addNewClubAddress = (res) => {
    API.setAddressForGiftSubscription(res.data.address_id).then(() => {
      props?.setAddressIsSelected(true);
      setViewForm(false);
      localStorage.setItem("shipping-form-opened", "false");
      getUpdatedData();
    });
  };

  const onFormSubmit = (
    values,
    selectedState,
    selectedCountry,
    sameAddressess
  ) => {
    const data = new FormData();
    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    data.append("zip", values.zip);
    data.append("address1", values.address1);
    data.append("address2", values.address2);
    data.append("location_country_id", selectedCountry.id);
    data.append(
      "location_state_id",
      selectedState.name === "no-id" ? null : selectedState.id
    );
    data.append("city", values.city);
    data.append("phone", values.phone);
    data.append("company_name", values.company_name);

    // @TODO Write jest and enzyme tests here

    /*    let stateMatch = selectedCountry?.states.filter(
      (state) => state.id === selectedAddress?.state?.id
    );

    if (stateMatch.length === 0) {
      setMessage("Please select correct state");
    } else {
      setMessage("");
    } */

    setAddressesLoading(true);

    if (updateForm) {
      API.updateAddress(
        selectedAddress,
        values,
        !isEmpty(selectedState) ? selectedState.id : selectedAddress?.state?.id,
        !isEmpty(selectedCountry)
          ? selectedCountry.id
          : choosenAddress.location_country_id
      )
        .then((res) => {
          // if page is join club - set address as gift subscription

          if (res.status === 200) {
            msgCtx.handleSuccessMessage(addressUpdated);
          }

          if (joinClub) {
            updateJoinClub();
            return;
          }

          if (sameAddressess) {
            API.selectAddressToBilling(selectedAddress.id).then(() => {
              getUpdatedData();
            });
          }

          API.selectAddressToShipping(selectedAddress.id).then(() => {
            getUpdatedData();
          });
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
          }
        });
      return;
    }

    if (newAddress) {
      API.addAddressBookContact(data)
        .then((res) => {
          localStorage.setItem(
            "gift_subscription_adress_id",
            res.data.address_id
          );

          // update modal text
          msgCtx.handleSuccessMessage(addressCreated);

          // new address  select as gift subscription
          if (joinClub) {
            addNewClubAddress(res);
            return;
          }

          API.selectAddressToShipping(res.data.address_id).then(() => {
            getUpdatedData();
          });
          if (sameAddressess) {
            API.selectAddressToBilling(res.data?.address_id);
            getUpdatedData();
          }
          setViewForm(false);
          localStorage.setItem("shipping-form-opened", "false");
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
          }
        });
    } else {
      API.selectAddressToShipping(selectedAddress.id).then(() => {
        getUpdatedData();
      });
    }

    setViewForm(false);
    localStorage.setItem("shipping-form-opened", "false");
  };

  const handleEdit = () => {
    setUpdateForm(true);
    setNewAddress(false);
    setViewForm(true);
    localStorage.setItem("shipping-form-opened", "true");
  };

  return (
    <div className="ShippingAddress" id="shipping-address">
      <div className="Checkout__title-container">
        <h1 className="Checkout__title">
          {" "}
          {joinClub ? "Coffee Club Address" : "Shipping Address"}
        </h1>

        {selectedAddress ? (
          <div className="ShippingAddress__buttons">
            <button onClick={handleEdit} className="Checkout__change-button">
              Edit {!isMobile ? "address" : ""}
            </button>

            <p className="ShippingAddress__or"> or </p>
            <button
              onClick={() => {
                setSaveAddress(selectedAddress);
                setViewForm(true);
                localStorage.setItem("shipping-form-opened", "true");
                setNewAddress(true);
                setSelectedAddress(null);
                setUpdateForm(false);
                setChoosenAddress(null);
                setNewAddress(true);
              }}
              className="Checkout__change-button"
            >
              Add new {!isMobile && "address"}
            </button>
          </div>
        ) : (
          // @TODO commented until new solution is found
          <></>
          // <button onClick={handleEdit} className="Checkout__change-button">
          //   Select saved addresses
          // </button>
        )}

        {viewForm && newAddress && !selectedAddress ? (
          <button
            onClick={() => {
              setViewForm(false);
              localStorage.setItem("shipping-form-opened", "false");
              setNewAddress(false);
              setUpdateForm(false);
              setSelectedAddress(saveAddress);
            }}
            className="Checkout__change-button"
          >
            Go Back
          </button>
        ) : null}
      </div>

      {selectedAddress?.id && !viewForm ? (
        <ViewAddress address={selectedAddress} />
      ) : null}

      {selectedAddress?.id && selectedAddress?.country?.id !== 64 && (
        <div className="ShippingAddress__warning">
          PLEASE NOTE: For all billing addresses outside the USA, you must
          choose PAYPAL as your payment method.{" "}
          <span
            className="ShippingAddress__warning-link"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo({
                  top: 1200,
                  left: 100,
                  behavior: "smooth",
                });
              }, 500);
            }}
          >
            Click here!{" "}
          </span>
        </div>
      )}

      {!selectedAddress?.address1 || viewForm ? (
        <AddressForm
          shippingAddress
          addressesLoading={addressesLoading}
          joinClub={joinClub}
          newAddress={newAddress}
          updateForm={updateForm}
          addressName="shipping"
          selectedAddress={selectedAddress}
          setSelectedAddress={(item) => {
            setSelectedAddress(item);

            API.selectAddressToShipping(item?.id).then(() => {
              getUpdatedData();
            });
            props?.setActiveStep(1);
            updateJoinClub(item.id);
          }}
          onSubmit={onFormSubmit}
          setActiveStep={props?.setActiveStep}
        />
      ) : null}
    </div>
  );
};

export default ShippingAddress;
