export const scrollInto = (id, offset) => {
  if (id) {
    setTimeout(() => {
      const el = document.getElementById(id);

      window.scroll({
        top: el?.offsetTop - offset,
        left: 0,
        behavior: "smooth",
      });
    }, 500);
  }
};
