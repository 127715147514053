export const PAY_AS_YOU_GO_OPTIONS = [
  {
    id: 1,
    image:
      "https://assets.gocoffeego.com/pay-as-you-go/pay-as-you-go-coffee.4aafd8ac.png",
    question: "HOW DO YOU TAKE YOUR COFFEE?",
    answerOptions: [
      {
        id: 1,
        title: "PURE, NOIR, BLACK!",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/black.d5421a80.svg",
        slug: "black",
      },
      {
        id: 2,
        title: "WITH A DASH OF DAIRY",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/milk-cream.3d0f72ce.svg",
        slug: "white",
      },
    ],
  },
  {
    id: 2,
    question: "WHICH BUZZ IS BEST?",
    answerOptions: [
      {
        id: 1,
        title: "REGULAR ",
        title2: "CAFFEINATE ME!",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/regular-coffee-drinker.f6857b16.svg",
        slug: "regular",
      },
      {
        id: 2,
        title: "DECAFFEINATED",
        title2: "KEEP IT MELLOW!",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/decaff.b3557b69.svg",
        slug: "decaffeinated",
      },
    ],
  },
  {
    id: 3,
    question: "WHICH ROAST DO YOU DIG THE MOST?",
    answerOptions: [
      {
        id: 1,
        title: "LIGHT",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/light.598e9e5f.png",
        slug: "light",
      },
      {
        id: 2,
        title: "MEDIUM-LIGHT",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/mediumlight.79e140eb.png",
        slug: "medium-light",
      },
      {
        id: 3,
        title: "MEDIUM",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/medium.8b5cbf79.png",
        slug: "medium",
      },
      {
        id: 4,
        title: "MEDIUM-DARK",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/mediumdark.9650fd9c.png",
        slug: "medium-dark",
      },
      {
        id: 5,
        title: "DARK",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/dark.d1d9d16b.png",
        slug: "dark",
      },
      {
        id: 6,
        title: "UNSURE",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Unsure.4139f0b8.png",
        slug: "unsure",
      },
    ],
  },
  {
    id: 3,
    question: "WHEN IT COMES TO TASTE, WHAT GETS YOU GROOVIN'?",
    answerOptions: [
      {
        id: 1,
        title: "Traditional",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/traditional.e9eb4473.png",
        description: "Coffee that tastes like coffee",
        slug: "traditional",
      },
      {
        id: 2,
        title: "Classic",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/classic.e8b6e756.png",
        description: "I WANT A HINT OF SOMETHING UNIQUE (BUT NOTHING TI WILD)",
        slug: "classic",
      },
      {
        id: 3,
        title: "Fruity or Floral",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/fruity.7018ffe2.png",
        description:
          "I APPRECIATE COMPLEXITIES AND I WANT SOMETHING ADVENTUROUS",
        slug: "fruity",
      },
      {
        id: 4,
        title: "ALL OF THE ABOVE",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/all.aeefe1e8.png",
        description: "I LOVE IT ALL. BRING IT. ON AND SURPRISE ME!",
        slug: "love-it-all",
      },
    ],
  },
  {
    id: 4,
    question: "HOW DO YOU BREW:",
    answerOptions: [
      {
        id: 1,
        title: "DRIP",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/drip.d4cf049a.png",
        slug: "drip",
      },
      {
        id: 2,
        title: "Pour Over Chemex",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Chemex.b8c5d7b6.png",
        slug: "pour-over-chemex",
      },
      {
        id: 3,
        title: "Aero Press",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/AERO-PRESS.dcf46f1d.png",
        slug: "aero-press",
      },
      {
        id: 4,
        title: "French Press",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/French-Press.5133a393.png",
        slug: "french-press",
      },
      {
        id: 5,
        title: "Moka Pot",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Moka-Pot.0f3cf459.png",
        slug: "moka-pot",
      },
      {
        id: 6,
        title: "Percolator",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Perculator.f041f1bc.png",
        slug: "perculator",
      },
      {
        id: 7,
        title: "Espresso",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Esspreso.0a1af0ab.png",
        slug: "espresso",
      },
      {
        id: 8,
        title: "Cold Brew",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Cold-Brew.c52da4e6.png",
        slug: "cold",
      },
      {
        id: 9,
        title: "REFILLABLE POD",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Refillable.990d999c.png",
        slug: "refillable",
      },
    ],
  },
  {
    id: 6,
    question: "ARE YOU KEEN ON BEAN OR DOWN FOR GROUND?",
    answerOptions: [
      {
        id: 1,
        title: "Whole Bean",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/whole-bean.ed62a102.png",
        slug: "whole-bean",
      },
      {
        id: 2,
        title: "Ground",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/ground.ede70877.png",
        slug: "ground",
      },
    ],
  },
  {
    id: 7,
    question: "HOW MANY FAB FINDS PER SHIPMENT?",
    answerOptions: [
      {
        id: 1,
        title: "SEND ME ONE 12oz BAG EVERY SHIPMENT",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Bicycle-Girl.385bc987.png",
        slug: "one",
      },
      {
        id: 2,
        title: "SEND ME TWO UNIQUE 12oz BAGS EVERY SHIPMENT",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Girl-on-Scooter.e4eae909.png",
        slug: "two",
      },
    ],
  },
  {
    id: 8,
    question: "HOW OFTEN WOULD YOU LIKE SHIPMENTS?",
    answerOptions: [
      {
        id: 1,
        title: "Once a month",
        slug: "once",
      },
      {
        id: 2,
        title: "Twice a month",
        slug: "twice",
      },
    ],
  },
];
