import React, { useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga";

import { AppRoutes } from "./routes/AppRoutes";
import { PublicRoute } from "./routes/PublicRoute";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import FilterProductsContextProvider from "./lib/context/FilterProductsContext/FilterProductsContextProvider";
import Page404 from "./pages/404/Page404";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { SeoContextProvider } from "./lib/context/SeoContext/SeoContextProvider";
import { RestrictedRoute } from "./routes/RestrictedRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import { CartContextProvider } from "./lib/context/CartContext/CartContextProvider";
import { MessageModalContextProvider } from "./lib/context/MessageModalContext/MessageModalContextProvider";
import smoothscroll from "smoothscroll-polyfill";
import { WrappedScrollToTop } from "./components/WrappedScrollToTop/WrappedScrollToTop";
import Preloader from "./components/shared/Preloader/Preloader";
import AlertMessage from "./components/AlertMessage/AlertMessage";

import "./App.scss";

ReactGA.initialize("UA-11133438-1", {
  // debug: true,
});

function App() {
  const [isLoading, setIsLoading] = useState(false);
  // support scroll-behavior for all browsers
  smoothscroll.polyfill();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    let local = sessionStorage.getItem("recently-viewed");

    if (!local) {
      sessionStorage.setItem("recently-viewed", JSON.stringify([]));
    }
  }, []);

  if (isLoading) {
    return (
      <div className="App__loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div id="App__container" className="App">
      <WrappedScrollToTop>
        <CartContextProvider>
          <MessageModalContextProvider>
            <AuthContextProvider>
              <FilterProductsContextProvider>
                <SeoContextProvider>
                  <Header />
                  <AlertMessage />

                  <div id="App__content">
                    <Switch>
                      <Redirect
                        from="/:url*(/+)"
                        to={window.location.pathname.slice(0, -1)}
                      />
                      {AppRoutes.map((r, index) => {
                        const { path, ...rest } = r;

                        if (r.restricted) {
                          return (
                            <RestrictedRoute
                              key={index}
                              {...rest}
                              path={`/${path}`}
                            />
                          );
                        }

                        if (r.private) {
                          return (
                            <PrivateRoute
                              key={index}
                              {...rest}
                              path={`/${path}`}
                            />
                          );
                        }

                        return (
                          <PublicRoute
                            key={index}
                            {...rest}
                            path={`/${path}`}
                          />
                        );
                      })}
                      <PublicRoute component={Page404} />
                    </Switch>
                  </div>
                  <Footer />
                </SeoContextProvider>
              </FilterProductsContextProvider>
            </AuthContextProvider>
          </MessageModalContextProvider>
        </CartContextProvider>
      </WrappedScrollToTop>
    </div>
  );
}

export default App;
