import React from "react";
import Line from "../../shared/Line/Line";
import SectionTitle from "../../Cart/SectionTitle/SectionTitle";
import MemberPicksCard from "../MemberPicksCard/MemberPicksCard";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import ViewButton from "../../shared/ViewButton/ViewMoreButton";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";

import "./PreviousMemberPicks.scss";

const PreviousMemberPicks = () => {
  const Background =
    "https://assets.gocoffeego.com/v2/assets/images/memberpicks-background.svg";
  const filtersCtx = useFilterProductsContext();
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="PreviousMemberPicks">
      {!isMobile ? (
        <>
          <Line />
          <Line />
          <div className="PreviousMemberPicks__title-container">
            <img
              className="PreviousMemberPicks__background"
              src={Background}
              alt=""
            />
            <SectionTitle
              middleLine
              white
              className="PreviousMemberPicks__title"
              title="PREVIOUS MEMBERS' PICKS"
            />
          </div>
          <Line />
        </>
      ) : (
        <SectionTitle
          middleLine
          className="PreviousMemberPicks__title"
          title="PREVIOUS MEMBERS' PICKS"
        />
      )}
      <div className="test-bg">
        <div className="sticky" />
        {filtersCtx.memberPicks.length === 0 && (
          <h1 className="PreviousMemberPicks__message">
            No reviews found for this category
          </h1>
        )}

        {filtersCtx.memberPicks?.map((item) => (
          <div className="PreviousMemberPicks__cards">
            <MemberPicksCard
              updatedAt={item?.updated_at}
              product={item?.product}
              review={item?.review}
              image={item?.image}
              customerName={item?.customer_name}
              customerCity={item?.customer_city}
              customerState={item?.customer_state?.code}
            />
            <Line className="PreviousMemberPicks__line" />
          </div>
        ))}
        {filtersCtx.hasMorePage && (
          <ViewButton
            className="PreviousMemberPicks__button"
            handleClick={filtersCtx.handleLoadMore}
          />
        )}
      </div>
    </div>
  );
};

export default PreviousMemberPicks;
