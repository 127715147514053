import "./QuizCta.scss";

import QuizButton from "../../shared/QuizButton/QuizButton";

const QuizCta = () => {
  const italian =
    "https://assets.gocoffeego.com/v2/assets/images/italian.png";
  const coffeeCup =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-cup.jpeg";
  return (
    <div className="QuizCta">
      <img src={italian} alt="italian" className="QuizCta__italian" />
      <div className="QuizCta__wrap">
        <div className="QuizCta__text-container">
          <p className="QuizCta__espresso-text">
            Espresso yourself<span className="reg">&reg;</span>
          </p>
          <p className="QuizCta__text">and take the quiz</p>{" "}
          <p className="QuizCta__text">to find your </p>
          <p className="QuizCta__text">perfect cup </p>
        </div>
        <QuizButton className="QuizCta__quiz-btn" />
      </div>
      <img src={coffeeCup} alt="coffee cup" className="QuizCta__coffee-cup" />
    </div>
  );
};

export default QuizCta;
