import React, { useState } from "react";

import Button from "../../../shared/Button/Button";

import "./RecentlyProduct.scss";
import * as API from "../../../../api/Api";
import { useCartContext } from "../../../../lib/context/CartContext/CartContext";

const RecentlyProduct = (props) => {
  const { image, id, price, savedItem } = props;
  const [message, setMessage] = useState("");

  const cartCtx = useCartContext();

  const handleAddToCart = () => {
    const cartId = localStorage.getItem("cart_id");

    const data = {
      cart_id: cartId,
      product_id: id,
      coffee_grind_id: 1,
      price: price,
    };

    if (!cartId) {
      API.addItemToCart(data).then((res) => {
        localStorage.setItem("cart_id", res.data.cart_id);
        if (res.status === 200) {
          setMessage("Successfully added to cart");
          window.gtag("event", "conversion", {
            send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
          });
        }
      });
    } else {
      API.addItemToCart(data).then((res) => {
        if (res.status === 200) {
          setMessage("Successfully added to cart");
          window.gtag("event", "conversion", {
            send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
          });
        }
      });
    }

    cartCtx.getCartItems(cartId);
  };

  return (
    <div className="RecentlyProduct">
      <div className="RecentlyProduct__image-container">
        <img className="RecentlyProduct__image" src={image} alt="" />
        {/*<h1 className="RecentlyProduct__title">Albion River Inn Blend</h1>*/}
      </div>

      <Button
        onClick={() => handleAddToCart()}
        className="RecentlyProduct__button"
        label="Add to cart"
      />
      {message && <p className="RecentlyProduct__message">{message}</p>}
    </div>
  );
};

export default RecentlyProduct;
