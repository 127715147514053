import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

import "./PaypalButton.scss";

const PaypalButton = (props) => {
  const cartCtx = useCartContext();
  const cartItems = cartCtx.cartItems;
  const amountToPay = cartItems?.totals?.total;

  const sandboxClientId =
    "Ac7jBXzlCLp3MIaUiccnYKeGGWW4BShFvHyDdJ1ykyy1VrOPBYSml0x3a_xzWJ-BiEIuW4CwtBHc62vY";

  const gocoffeegoPaypalId =
    "AVUiEUP22rZkIvaWXSRAVkH2Vqhw6iQCku73fj-4qNoJeKquerJL6DXgEj6_OsHbE_-7MklXeHQWGkeC";

  const handleOnSuccess = (details) => {
    API.sendPaypalResponse(details).then((res) => {
      const id = res.data.order_id;
      if (id) {
        props.handleOrder(id);
      }
    });
  };

  return (
    <PayPalButton
      disableFunding
      onClick={() => API.selectAsPaypalPayment()}
      amount={amountToPay}
      style={{
        layout: "horizontal",
        label: "pay",
        tagline: "false",
      }}
      shippingPreference="NO_SHIPPING"
      options={{
        clientId: gocoffeegoPaypalId,
        disableFunding: "credit,card",
      }}
      onSuccess={handleOnSuccess}
    />
  );
};

export default PaypalButton;
