import React, { useState, useEffect } from "react";
import * as API from "../../../../api/Api";
import { useChangeInfoFormik } from "./useChangeInfoFormik";
import { Form, Label, Spinner } from "reactstrap";
import { useMessageContext } from "../../../../lib/context/MessageModalContext/MessageModalContext";
import { useAuthContext } from "../../../../lib/context/AuthContext/AuthContext";

import "./ChangeInfoForm.scss";

const ChangeInfoForm = () => {
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const msgCtx = useMessageContext();
  const authCtx = useAuthContext();

  useEffect(() => {
    authCtx.getDetails();
  }, []);

  if (message) {
    setTimeout(() => setMessage(""), 6000);
  }

  const formik = useChangeInfoFormik(authCtx.userDetails, {
    onSubmit: async (values) => {
      setIsLoading(true);
      API.changeProfileById(
        values.first_name,
        values.last_name,
        values.email,
        values.old_password,
        values.password,
        values.password_confirmation
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            setSuccessMessage(res.data.result);
          } else if (res.Error) {
            setMessage(res.Error);
          }
        })
        .catch((err) => msgCtx.handleError(err));
    },
  });

  return (
    <div className="ChangeInfoForm">
      <h1 className="ChangeInfoForm__title">Change your profile</h1>
      <Form className="ChangeInfoForm__form" onSubmit={formik.handleSubmit}>
        <div className="ChangeInfoForm__container-wrap">
          <div className="ChangeInfoForm__container">
            <div className="ChangeInfoForm__input-container">
              <Label for="first_name" className="ChangeInfoForm__label">
                New first name
              </Label>
              <div>
                <input
                  id="first_name"
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  className="ChangeInfoForm__input"
                  type="text"
                />
                {formik.errors.first_name && formik.touched.first_name && (
                  <div className="ChangeInfoForm__error">
                    {formik.errors.first_name}
                  </div>
                )}
              </div>
            </div>
            <div className="ChangeInfoForm__input-container">
              <Label for="last_name" className="ChangeInfoForm__label">
                New last name
              </Label>
              <div>
                <input
                  id="last_name"
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                  className="ChangeInfoForm__input"
                  type="text"
                />
                {formik.errors.last_name && formik.touched.last_name && (
                  <div className="ChangeInfoForm__error">
                    {formik.errors.last_name}
                  </div>
                )}
              </div>
            </div>
            <div className="ChangeInfoForm__input-container">
              <Label for="email" className="ChangeInfoForm__label">
                New email address
              </Label>
              <div>
                <input
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="ChangeInfoForm__input"
                  type="text"
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="ChangeInfoForm__error">
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ChangeInfoForm__container">
            <div className="ChangeInfoForm__input-container">
              <Label for="old_password" className="ChangeInfoForm__label">
                Old Password
              </Label>
              <div>
                <input
                  id="old_password"
                  name="old_password"
                  autoComplete="new-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.old_password}
                  className="ChangeInfoForm__input"
                  type="password"
                />
                {formik.errors.old_password && formik.touched.old_password && (
                  <div className="ChangeInfoForm__error">
                    {formik.errors.old_password}
                  </div>
                )}
              </div>
            </div>
            <div className="ChangeInfoForm__input-container">
              <Label for="password" className="ChangeInfoForm__label">
                New Password
              </Label>
              <div>
                <input
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="ChangeInfoForm__input"
                  type="password"
                />
                {formik.errors.password && formik.touched.password && (
                  <div className="ChangeInfoForm__error">
                    {formik.errors.password}
                  </div>
                )}
              </div>
            </div>
            <div className="ChangeInfoForm__input-container">
              <Label
                for="password_confirmation"
                className="ChangeInfoForm__label"
              >
                Re-enter Password
              </Label>
              <div>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password_confirmation}
                  className="ChangeInfoForm__input"
                  type="password"
                />
                {formik.errors.password_confirmation &&
                  formik.touched.password_confirmation && (
                    <div className="ChangeInfoForm__error">
                      {formik.errors.password_confirmation}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <button
          disabled={isLoading ? true : false}
          type="submit"
          className="ChangeInfoForm__button"
        >
          {isLoading ? (
            <Spinner
              style={{
                border: "0.25em solid #00d1d6",
                borderRightColor: "transparent",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
          ) : (
            "Change your profile"
          )}
        </button>
      </Form>
      {message && (
        <div className="ChangeInfoForm__message">
          {message} Please provide correct password!{" "}
        </div>
      )}
      {successMessage && (
        <div className="ChangeInfoForm__success-message">{successMessage}</div>
      )}
    </div>
  );
};

export default ChangeInfoForm;
