import React, { useState, useEffect } from "react";

import "./Story.scss";

const Story = (props) => {
  const Shape =
    "https://assets.gocoffeego.com/v2/assets/images/article-shape.svg";
  const { product, productIsLoading } = props;
  const [description, setDescription] = useState([]);
  const [extractRecommendations, setExtractRecommendations] = useState([]);
  const [desc, setDesc] = useState();

  useEffect(() => {
    if (!productIsLoading) {
      const replacedText = product?.description
        .replace(/(<([^>]+)>)/gi, "")
        .replace("[BREAK]", " ");

      setDesc(replacedText);

      const array = replacedText?.split("EXTRACTION RECOMMENDATIONS");

      if (array?.length > 0) {
        const description = array[0].split("\r\n\r\n");
        setDescription(description);

        if (array[1]) {
          const extractRecommendations = array[1].split("\r\n\r\n");
          setExtractRecommendations(extractRecommendations);
        }
      }
    }
  }, [productIsLoading]);

  return (
    <div className="Story">
      <h2 className="Story__title">
        STORY
        <img className="Story__title-shape" src={Shape} alt="article-shape" />
      </h2>

      {description?.map((text, index) => (
        <p className="Story__description" key={index}>
          {text}
        </p>
      ))}

      {extractRecommendations[0] && (
        <div className="Story__extraction">
          <h2 className="Story__title2"> EXTRACTION RECOMMENDATIONS </h2>
          <div className="Story__line" />
          {extractRecommendations.map((text, index) => (
            <h2 className="Story__title3" key={index}>
              {text}
            </h2>
          ))}
        </div>
      )}

      {product?.member_picks && (
        <img
          src={product?.member_picks?.pick_banner}
          alt="member picks tag"
          className="Story__pick_banner"
        />
      )}
      {product?.peaberry_recommends && (
        <img
          src={product?.peaberry_recommends?.recommend_banner}
          alt="peaberry recommends tag"
          className="Story__peaberry-recommends_banner"
        />
      )}
    </div>
  );
};

export default Story;
