import React from "react";
import "./StarsRating.scss";
const Stars =
  "https://assets.gocoffeego.com/v2/assets/icons/star.svg";
const Star = ({ disabled, selected = false, onClick = (f) => f }) => (
  <img
    role="button"
    src={Stars}
    className={selected ? "star star-selected" : "star"}
    onClick={onClick}
    disabled={disabled}
    alt="star"
  />
);

export default Star;
