import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import LinkButton from "../../shared/LinkButton/LinkButton";

import "./PreferencesBased.scss";

const PreferencesBased = ({ recommendation }) => {
  const snakeCharmer =
    "https://assets.gocoffeego.com/v2/assets/images/snake-charmer.svg";

  return (
    <div className="PreferencesBased">
      <img
        src={snakeCharmer}
        alt="snake charmer"
        className="PreferencesBased__snake-img"
      />
      <div className="PreferencesBased__wrap">
        <MiddleLine className="PreferencesBased__middle-line" />
        <div className="PreferencesBased__text">
          Based on your preferences, we recommend checking out our{" "}
          {recommendation[0]?.name}
        </div>
        <LinkButton
          className="PreferencesBased__btn"
          path={`/products/${recommendation[0]?.slug}/${recommendation[0]?.id}`}
          label="LEARN MORE"
        />
      </div>
      <div className="PreferencesBased__beans-img"></div>
    </div>
  );
};

export default PreferencesBased;
