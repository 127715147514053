import { useFormik } from "formik";
import * as Yup from "yup";

const ContactFormSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, "Email is too Short!")
    .max(50, "Email is too Long!")
    .required("Enter your email"),
  name: Yup.string().required("Please provide Full Name"),
  order_number: Yup.string(),
  phone: Yup.string().required("Please provide Phone Number "),
  comments: Yup.string().required("Please provide Questions and Comments"),
  reason: Yup.string().required("Please select one of the topics"),
});

export const useContactUsFormik = (opts) => {
  return useFormik({
    initialValues: {
      email: "",
      name: "",
      order_number: "",
      phone: "",
      comments: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ContactFormSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
