import React, { useRef, useState } from "react";
//import LinkButton from "../../shared/LinkButton/LinkButton";
//import { useHistory } from "react-router-dom";
import * as API from "../../../api/Api";
import { Icon } from "../../shared/Icon/Icon";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import "./NewsletterSignUpModal.scss";

const NewsletterSignUpModal = (props) => {
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [subscribeSuccess, setSubscribe] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // const history = useHistory();
  const subscribeRef = useRef();

  const peaberryImage =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-lounge.png";

  const handleSubscribeClick = () => {
    setSubmitted(true);

    if (searchKeyWord) {
      API.NewsletterSignUp(searchKeyWord)
        .then((res) => {
          if (res.data.email) {
            setSubscribe(true);
            setEmailExist(false);
            window.gtag("event", "conversion", {
              send_to: "AW-11011335274/PdKKCJmN9IAYEOrIzoIp"
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.error.message.email) {
              setEmailExist(true);
              setSubscribe(false);
            }
          }
        });
    }
    // localStorage.setItem("email-subscribe", subscribeRef.current.value);
  };

  const handleKeypress = (e) => {
    var key = e.keyCode || e.which;
    if (key === 13) {
      handleSubscribeClick();
    }
  };

  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="NewsletterSignUpModal">
        <Icon
          style={{ width: "20px", height: "20px" }}
          onClick={props.toggle}
          className="NewsletterSignUpModal__close"
          icon="close"
        />
        <div className="NewsletterSignUpModal__container">
          <h1 className="NewsletterSignUpModal__title">
            Subscribe to the Peaberry Times Newsletter
          </h1>
          <div>
            <div className="NewsletterSignUpModal__subtitle">
              Sign-up Today for our twice a month Newsletter and receive $5.00
              off your first purchase!
            </div>
            <div className="NewsletterSignUpModal__wrap">
              <div className="NewsletterSignUpModal__text">
                You'll be the first to know about exclusive super deals, new
                coffee arrivals, the latest coffee reviews, as well as learn
                about coffee history, coffee fun facts, brewing tips and more.
              </div>
              {!subscribeSuccess && (
                <div className="NewsletterSignUpModal__input-container">
                  <input
                    ref={subscribeRef}
                    placeholder="YOUR EMAIL"
                    type="text"
                    className="NewsletterSignUpModal__input"
                    onChange={(e) => setSearchKeyWord(e.target.value)}
                    onKeyPress={(e) => handleKeypress(e)}
                  />
                  <div
                    className="NewsletterSignUpModal__input-icon"
                    onClick={handleSubscribeClick}
                  />
                </div>
              )}
            </div>
          </div>

          {subscribeSuccess && (
            <>
              <h2 className="NewsletterSignUpModal__success-subscription">
                Thank you for your subscription.
              </h2>
              <h2 className="NewsletterSignUpModal__success-subscription">
                For $5.00 off your first purchase use promo code:{" "}
                <span className="NewsletterSignUpModal__success-subscription--code">
                  coffee
                </span>
              </h2>
            </>
          )}

          {emailExist && (
            <h2 className="NewsletterSignUpModal__error-subscription">
              The email has already been taken
            </h2>
          )}

          {!searchKeyWord && submitted && (
            <h2 className="NewsletterSignUpModal__error-subscription">
              Please provide an email!
            </h2>
          )}
        </div>
        <img
          className="NewsletterSignUpModal__image"
          src={peaberryImage}
          alt="Peaberry"
        />
      </div>
    </ModalWrapper>
  );
};

export default NewsletterSignUpModal;
