import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Spinner } from "reactstrap";

import * as API from "../../../api/Api";
import "./EmailPreferences.scss";

const EmailPreferences = () => {
  const [emailPreferences, setEmailPreferences] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchEmailPreferences();
  }, []);

  const fetchEmailPreferences = () => {
    API.emailPreferences().then((res) => {
      setEmailPreferences(res.data.send_emails_coffee_reviews);
    });
  };

  if (message) {
    setTimeout(() => setMessage(""), 3000);
  }

  return (
    <div className="EmailPreferences">
      <div className="EmailPreferences__wrap">
        <div className="EmailPreferences__title">
          {emailPreferences === 1
            ? "If you like to unsubscribe to the following e-mail, please uncheck the box and click save changes."
            : "If you like to subscribe to the following e-mail, please check the box and click save changes."}
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            checked: emailPreferences === 1 ? true : false,
          }}
          onSubmit={async (values) => {
            const checkboxData = values.checked ? 1 : 0;
            setIsLoading(true);
            API.changeEmailPreferences(checkboxData).then((res) => {
              // setMessage(res.Result);
              setMessage("Success! We have saved your email preferences");
              setIsLoading(false);
              fetchEmailPreferences();
            });
          }}
        >
          {({ values }) => (
            <Form className="EmailPreferences__form">
              <div className="EmailPreferences__form-wrap">
                <Field
                  className="EmailPreferences__input"
                  type="checkbox"
                  id="checked"
                  name="checked"
                />
                <label htmlFor="checked" className="EmailPreferences__label">
                  {emailPreferences === 1
                    ? "Uncheck this box to unsubscribe"
                    : "Check this box to subscribe"}
                </label>
              </div>
              {message && (
                <div className="EmailPreferences__message">{message}</div>
              )}
              <button
                disabled={isLoading ? true : false}
                className="EmailPreferences__button"
                type="submit"
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  "Save Changes"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmailPreferences;
