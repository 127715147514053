import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import * as API from "../../../api/Api";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import CoffeStyleCircle from "../CoffeStyleCircle/CoffeStyleCircle";

import "./CoffesByRoast.scss";

const CoffesByRoast = (props) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [items, setItems] = useState([]);
  const FiltersCtx = useFilterProductsContext();
  const history = useHistory();

  useEffect(() => {
    API.getCoffeeByRoast()
      .then((res) => setItems(res.data))
      .catch((err) => console.log(err));
  }, []);

  const onSelectItem = (
    name,
    category_name,
    id,
    old_category_name,
    slug,
    item
  ) => {
    setSelectedItem((prev) => {
      if (prev === name) {
        return 0;
      }
      return name;
    });

    FiltersCtx.setSelectedFilter({
      category_name: item?.category_name,
      id: item?.id,
      name: item?.name,
      old_category_name: item?.old_category_name,
      slug: item?.slug,
    });

    history.push(`/shop/${item?.old_category_name}/${item?.slug}`);

    setTimeout(function () {
      document.getElementById("Shop__wrapper").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 1000);
  };

  return (
    <div className="CoffesByRoast">
      <MiddleLine />
      <div className="CoffesByRoast__content">
        <h2 className="CoffesByRoast__content-title">
          CRUISE AND CLICK COFFEES BY ROAST STYLE
        </h2>
        <div className="CoffesByRoast__items">
          {items.map((item) => (
            <CoffeStyleCircle
              selectedItem={selectedItem}
              key={item.id}
              name={item.name}
              id={item.id}
              image={item?.image}
              onSelectItem={() =>
                onSelectItem(
                  item.name,
                  item.category_name,
                  item.id,
                  item.slug,
                  item.old_category_name,
                  item
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoffesByRoast;
