import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

const NewsletterSchema = Yup.object().shape({
 firstName: Yup.string().required("Enter your firstname"),
 lastName: Yup.string().required("Enter your lastname"),
 email: Yup.string().email()
    .required("Enter your email"),
  confirmEmail: Yup.string().when("email", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("email")],
      "Email and confirm email does not match"
    )
  }),
});

export const useNewsletterFormik = (opts) => {
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: NewsletterSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};