import { Link } from "react-router-dom";

import "./AwardWinnersItem.scss";
import PopUp from "../shared/PopUp/PopUp";

const AwardWinnersItem = (props) => {
  const { item } = props;
  const coffeeReviewBadge = item.coffee_review_image;

  return (
    <Link to={`/${item.product_url}`}>
      <div className="AwardWinnersItem">
        <PopUp product={item} />
        {coffeeReviewBadge && (
          <img
            className="AwardWinnersItem__badge"
            src={coffeeReviewBadge}
            alt="badge"
          />
        )}
        <img
          src={item.main_image.xlg}
          alt="name"
          className="AwardWinnersItem__image"
        />
        <div className="AwardWinnersItem__title">{item.name}</div>
        <div className="AwardWinnersItem__details">{item.coffee_roast}</div>
        <div className="AwardWinnersItem__details">
          ${item.retail_price} / {item.weight}
        </div>
        <div className="AwardWinnersItem__description">{item.awards}</div>
      </div>
    </Link>
  );
};

export default AwardWinnersItem;
