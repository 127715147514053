import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";

import "./SelectBox3.scss";

let cs = require("classnames");

const SelectBox3 = (props) => {
  const { items, selectedItem, className, className2 } = props;
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (value, id) => {
    props.onItemSelect(value, id);
    setShowItems(!showItems);
  };

  return (
    <div ref={dropDownRef} className={cs("SelectBox3", className)}>
      <div
        onClick={() => setShowItems(!showItems)}
        className="SelectBox3__container"
      >
        {items && (
          <div className="SelectBox3__header">
            {selectedItem && `$${selectedItem.toFixed(2)}`}
          </div>
        )}
      </div>
      <div
        className={cs("SelectBox3__items", showItems && "active", className2)}
      >
        {items?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => onItemSelect(item.gift_amount, item.id)}
              className={`SelectBox3__item-container ${
                props.selectedItem === item.id ? "activeLabel" : ""
              }`}
            >
              <p className="SelectBox3__item">${item.gift_amount.toFixed(2)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectBox3;
