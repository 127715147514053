import React, { useEffect, useState } from "react";
import * as API from "../../../../api/Api";
import Seo from "../../../../lib/helpers/SEO/Seo";

import "./Ages.scss";

const FifthEra = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    API.getHistoryOfCoffee().then((res) => {
      setContent(res.data);
    });
  }, []);

  const article = {
    title: "The History of Coffee: 1900s - Present",
    image: "https://assets.gocoffeego.com/v2/assets/images/history-of-coffee.png",
    url: "https://www.gocoffeego.com/learn/category/the-history-of-coffee/1900"
  } 

  return (
    <div className="Ages">
      <Seo article={article} />
      {content.slice(44, 66).map((item, index) => (
        <div key={index}>
          <h3 className="Ages__title font-cairo">{item.title}</h3>
          <div className="Ages__content">
            <p>{item.content}</p>
            <div className="Ages__images">
              <img className="Ages__image" src={item.image1} />
              <img className="Ages__image" src={item.image2} />
              <img className="Ages__image" src={item.image3} />
            </div>
          </div>
        </div>
      ))}

      <p style={{ textAlign: "center" }}>
        The History of Coffee <br /> by <br />
        Professor Peaberry
      </p>
    </div>
  );
};

export default FifthEra;
