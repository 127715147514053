import React from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.scss";

const Dropzone = ({ onDrop, accept, multiple }) => {

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <div {...getRootProps()} className="Dropzone">
      <input className="Dropzone__input" {...getInputProps()} />
      <div className="Dropzone__wrap">
        {isDragActive ? (
          <p className="Dropzone__content">Release to drop the files here</p>
        ) : (
          <div className="Dropzone__content">
            <div>Drag 'n' drop image here, or click to select file</div>
            <div className="Dropzone__content-text">You can upload a png, jpg, jpeg, gif file (Do not upload pictures containing
            celebrities, nudity, artwork or copyrighted images.)</div>
          </div>
        )}
        {isDragReject && (<p className="Dropzone__content">File type not accepted, sorry!</p>)}
      </div>
    </div>
  );
};

export default Dropzone;