import React from "react";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import Line from "../../shared/Line/Line";
import Product from "../../Shop/Product/Product";

import "./SimilarCoffees.scss";

const SimilarCoffees = (props) => {
  const Girls =
    "https://assets.gocoffeego.com/v2/assets/images/3-girls.png";
  const { product, productIsLoading } = props;
  return (
    <div className="SimilarCoffees">
      <div className="SimilarCoffes__line">
        <Line />
        <Line />
        <img className="SimilarCoffees__image" src={Girls} alt="girls" />
      </div>
      <div className="SimilarCoffees__content">
        <MiddleLine />
        <div className="SimilarCoffees__title font-cairo"> Similar coffees</div>
        {!productIsLoading && product?.similar_coffees.length <= 0 && (
          <p className="SimilarCoffees__message">
            No similar products for this product
          </p>
        )}
        <div className="SimilarCoffees__products">
          {!productIsLoading &&
            product?.similar_coffees
              ?.slice(0, 3)
              .map((product) => <Product product={product} key={product.id} />)}
        </div>
      </div>
      <Line />
      <Line />
    </div>
  );
};

export default SimilarCoffees;
