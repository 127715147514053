import React, { useContext } from "react";

const FilterValues = {
  selectedSubCategory: "",
  products: [],
  hasMorePage: undefined,
  clubData: undefined,
  clubLoading: undefined,
  getClubs: () => {},
  handleLoadMore: () => {},
  changeSelectedItem: () => {},
  getInitialProducts: () => {},
  onClear: () => {},
  getProductsFromSearch: () => {},
  scrollInto: () => {},
  setQuizResult: () => {},
  selectedItem: "",
  // selectedRoast: undefined,
  // selectedType: undefined,
  //  selectedCoffeeRegion: undefined,
  isLoading: undefined,
  memberPicks: undefined,
  showMemberOfTheWeek: undefined,
  allRecommends: [],
  showLatestRecommend: undefined,
  productsFromSearch: [],
  specials: [],
  quizResult: [],
  awardWinners: [],
  bulkCoffees: [],
  limitedOfferings: [],
  top25: [],
  fetchQuizPreferences: () => {},
  setSelectedFilter: () => {},
  setSelectedRoaster: () => {},
  setSelectedType: () => {},
  setSelectedRoast: () => {},
  setSelectedRegion: () => {},
  selectedRoaster: undefined,
  selectedType: [],
  selectedRoast: undefined,
  selectedRegion: undefined,
};
export const FilterProductsContext = React.createContext(FilterValues);

export const useFilterProductsContext = () => useContext(FilterProductsContext);
