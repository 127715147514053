import React, { useEffect } from "react";
import { Route } from "react-router-dom";

import { pageViewsTracking } from "../lib/helpers/GoogleAnalytics/pageViewsTracking";
import Seo from "../lib/helpers/SEO/Seo";
import * as API from "../api/Api";
import { useSeoContext } from "../lib/context/SeoContext/SeoContext";

export const PublicRoute = (props) => {
  const pathname = props.location.pathname;

  const seoCtx = useSeoContext();

  useEffect(() => {
    pageViewsTracking(pathname);
  });

  useEffect(() => {
    API.pageMetadata().then((res) => seoCtx.setPageMetadata(res.data));
    API.imageMetadata().then((res) => seoCtx.setImageMetadata(res.data));
  }, []);

  let url = `https://www.gocoffeego.com${pathname}`;

  let urlWithoutSlash = url.endsWith("/") ? url.slice(0, -1) : url;

  return (
    <>
      <Seo
        title={props.metaTagName}
        description={props.metaTagDescription}
        keywords={props.metaTagKeywords}
        url={urlWithoutSlash}
      />
      <Route {...props} />
    </>
  );
};
