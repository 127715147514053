import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const AsiaIndonesia = () => {
  const article = {
    title: "Asia",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/asia-indonesia"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>Asia</h3>
      <h3>India</h3>
      <p>
        Ah, the glory of the world's largest democracy! This was the first major
        region outside of Arabian Africa to cultivate coffee, mainly due to some
        clever smuggling of fertile coffee beans. Far from the major coastal
        cities, I love to explore monsoon-ravaged tropical regions of southern
        India and delight in the spicy coffees, hinting of cardamom and pepper.
      </p>
      <p>
        Ranked 7th in the world.* Best known varietals: Indian Mysore, Monsoon
        Malabar.
      </p>

      <h3>Vietnam</h3>
      <p>
        There are few things more enjoyable to me than a stop in Vietnam for a
        leisurely bike ride around the emerald green rice paddies to peacefully
        watch the women in palm-leaf conical hats growing rice in the serenity.
        Normally, I would stop for coffee on my excursion but in Vietnam, I
        generally pass. The 4th highest producer of coffee in the world is
        Vietnam, whose coffee industry was developed in the 1990's via a heavy
        infusion of cash from the World Bank, who saw money to be made by
        exporting beans. Sadly, despite the volume produced, Vietnam specializes
        in low grade Robusta, a bitter bean often used in blends to "cut" the
        amount of Arabica needed. The sheer volume of Vietnamese Robusta
        flooding into the market place, despite its low quality, is hurting many
        coffee growing countries, such as Nicaragua, who must severely lower the
        prices of their higher quality Arabica beans in order to compete against
        Vietnams volume and extremely low prices. Vietnam provides the beans for
        cheap pre-ground and whole bean coffee blends sold in the supermarket.
        Now that's a bitter bean to swallow.
      </p>
      <p>Ranked 4th in the world. *</p>

      <h3>Indonesia</h3>
      <p>
        This enormous, tropical archipelago stretching from Southeast Asia to
        Australia is a challenge to navigate. Three of the region's major
        islands produces a third of the world's coffee beans, though only a
        portion - about 10% - is Arabica. The rest are less flavorable Robustas.
      </p>

      <h3>Java</h3>
      <p>
        Yes, the most notable of the coffee islands. Famous for its name. While
        navigating the muddy roads east of Jakarta in my battered Land Rover,
        one can smell the coffee berries springing from the trees on large
        coffee estates. Thank goodness the Dutch brought coffee here in the 17th
        century and entered a favorite nickname into the coffee lexicon.
      </p>

      <h3>Sumatra</h3>
      <p>
        It's an enormous island, just a skip by boat to the west, produces
        coffee known either by its own name or the Mandheling label. Sweet and
        crisp, this is a truly complex brew. The island of Sulawesi to the east
        produces a similarly complex cup, like that of Sumatra and maple syrupy
        notes. Such character!
      </p>
      <p>Best known varietals: Sumatra Arabica</p>

      <h3>New Guinea</h3>
      <p>
        Farther to the east, the island of New Guinea, part Indonesia, part
        independently governed as Papua New Guinea, is also a noted coffee
        producer, producing a well-balanced bean with a fruity finish.
      </p>
      <p>
        Ranked 3rd in the world. * Best known varietals: Java, Sumatra,
        Mandheling Celebes, Catimor, Djember, Tim Tim.
      </p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/hawaii"
      >
        <h3> Click here to relax in Hawaii -- Aloha! </h3>
      </Link>
    </div>
  );
};

export default AsiaIndonesia;
