export const addressCreated = "Your address is successfully created!";
export const addressUpdated = "Your address is successfully updated!";
export const loggedIn = "You are successfully logged in.";
export const signedUp = "You are successfully registred.";
export const promoFieldRequired = "The promo code field is required.";
export const autoshipHoldMessage = "Auto-Ship now is Inactive";
export const creditCardDeclined =
  "Your card has been declined. Please try another!";
export const deleteGsAddressMessage =
  "You cannot delete address that is selected for pay as you go! ";
export const autoshipUpdatedMessage =
  "You have successfully updated auto-ship!";
export const autoshipActivatedMessage =
  "Congratulations, you have successfully activated auto-ship!";
export const noQuizRecommendations =
  "There are no coffee recommendations based on your selection criteria. Please change your selections.";
