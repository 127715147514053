import React from "react";
import "./ViewAddress.scss";

const ViewAddress = (props) => {
  const { address } = props;
  return (
    <div className="ViewAddress">
      <div className="ViewAddress__content">
        <p className="ViewAddress__description">
          {address?.first_name} {address?.last_name}
        </p>
        <p className="ViewAddress__description">
          {address?.address1}, {address?.address2}
        </p>
        <p className="ViewAddress__description">{address?.company_name}</p>
        <p className="ViewAddress__description">
          {address?.city}, {address?.state?.name}, {address?.zip}
        </p>
        <p className="ViewAddress__description">{address?.country?.name} </p>
        {/*<p className="ViewAddress__description">{address?.phone}</p>*/}
      </div>
    </div>
  );
};

export default ViewAddress;
