import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import { useHistory } from "react-router-dom";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import {
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import "./ApplePay.scss";

const ApplePay = () => {
  const cartCtx = useCartContext();
  const msgCtx = useMessageContext();
  const cartItems = cartCtx.cartItems;
  // const amountToPay = cartItems?.totals?.total;
  const subtotalToPay = cartItems?.totals?.subtotal;
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentisLoading, setPaymentIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    if (stripe && cartItems?.items) {
      const pr = stripe.paymentRequest({
        currency: "usd",
        country: "US",
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          label: "GoCoffeeGo Total",
          amount: Math.round(subtotalToPay * 100),
        },
        displayItems: [
          {
            label: "Delivery",
            amount:
              cartItems?.totals?.delivery === 0
                ? 0.0
                : Math.round(cartItems?.totals?.delivery * 100),
          },
          {
            label: "Promos",
            amount:
              cartItems?.totals?.promos === 0
                ? 0.0
                : Math.round(cartItems?.totals?.promos * 100),
          },
          {
            label: "Specials",
            amount:
              cartItems?.totals?.specials === 0
                ? 0.0
                : Math.round(cartItems?.totals?.specials * 100),
          },
          {
            label: "Subtotal",
            amount: Math.round(cartItems?.totals?.subtotal * 100),
          },
        ],
        requestShipping: cartItems?.shipping_address_required,
      });

      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
          }
        })
        .catch((err) => {
          setPaymentIsLoading(false);
        });

      if (cartItems?.shipping_address_required) {
        pr.on("shippingaddresschange", (ev) => {
          if (ev.shippingAddress.country !== "US") {
            ev.updateWith({ status: "invalid_shipping_address" });
          } else {
            API.getShipping()
              .then((res) => {
                if (res.data) {
                  cartCtx.getCartItems();
                  // console.log('getShipping - cart items', res.data)

                  if (res.data?.delivery === 0) {
                    ev.updateWith({
                      status: "success",
                      shippingOptions: [
                        {
                          id: "free-shipping",
                          label: "Shipping",
                          detail: "Free Shipping",
                          amount: res.data?.delivery,
                        },
                      ],
                    });
                    return;
                  }

                  ev.updateWith({
                    status: "success",
                    total: {
                      amount: Math.round(res?.data?.total * 100),
                      label: "GoCoffeeGo New Total",
                    },
                    displayItems: [
                      {
                        label: "Delivery",
                        amount:
                          res?.data?.delivery === 0
                            ? 0.0
                            : Math.round(res?.data?.delivery * 100),
                      },
                      {
                        label: "Promos",
                        amount:
                          res?.data?.promos === 0
                            ? 0.0
                            : Math.round(res?.data?.promos * 100),
                      },
                      {
                        label: "Specials",
                        amount:
                          res?.data?.specials === 0
                            ? 0.0
                            : Math.round(res?.data?.specials * 100),
                      },
                      {
                        label: "Subtotal",
                        amount: Math.round(res?.data?.subtotal * 100),
                      },
                    ],
                    shippingOptions: [
                      {
                        id: "USPS-Priority-Mail",
                        label: "Shipping",
                        detail: "USPS Priority Mail",
                        amount: Math.round(res.data?.delivery * 100),
                      },
                    ],
                  });
                }
              })
              .catch((err) => {
                msgCtx.handleError(err);
                setPaymentIsLoading(false);
              });
          }
        });
      }

      pr.on("paymentmethod", async (e) => {
        await API.getPaymentIntentApplePay()
          .then((res) => {
            const clientSecret = res?.data?.client_secret;
            stripe
              .confirmCardPayment(
                clientSecret,
                { payment_method: e.paymentMethod?.id },
                { handleActions: false }
              )
              .then((res) => {
                if (res.error) {
                  e.complete("fail");
                  return;
                }

                const formData = new FormData();
                formData.append("payment_intent_id", res.paymentIntent?.id);
                if (cartItems?.shipping_address_required) {
                  formData.append(
                    "address1",
                    e.shippingAddress?.addressLine[0]
                  );
                  formData.append(
                    "address2",
                    e.shippingAddress?.addressLine[1]
                      ? e.shippingAddress?.addressLine[1]
                      : ""
                  );
                  formData.append("recipient", e.shippingAddress?.recipient);
                  formData.append("country", e.shippingAddress?.country);
                  formData.append("city", e.shippingAddress?.city);
                  formData.append("postalCode", e.shippingAddress?.postalCode);
                  formData.append("region", e.shippingAddress?.region);
                }

                API.postPayment(formData)
                  .then((res) => {
                    e.complete("success");
                    if (res?.data?.order_id) {
                      placeOrder(res?.data?.order_id);
                    }
                  })
                  .catch((err) => {
                    msgCtx.handleError(err);
                    setPaymentIsLoading(false);
                  });

                if (res.paymentIntent.status === "requires_action") {
                  //  stripe.confirmCardPayment(clientSecret);
                } else {
                  //payment has succeeded
                  /*  API.postPayment(formData)
                    .then((res) => {
                      e.complete("success");
                      if (res?.data?.order_id) {
                        placeOrder(res?.data?.order_id);
                      }
                    })
                    .catch((err) => {
                      msgCtx.handleError(err);
                      setPaymentIsLoading(false);
                    }); */
                }
              });
          })
          .catch((err) => {
            console.log(err, "err");
            setPaymentIsLoading(false);
          });
      });
    }
  }, [stripe, elements, cartItems?.items]);

  const placeOrder = (id) => {
    if (id) {
      history.push(`/order-success/${id}`);
      cartCtx.getCartItems();
      localStorage.setItem("order_id", id);
      Event({
        category: "ORDER",
        action: "CREATE_ORDER",
        label: "PLACE_ORDER_APPLE_PAY",
      });
    }
  };

  return (
    <>
      {paymentRequest && (
        <div>
          <div className="ApplePay__express-checkout">
            <h2 className="ApplePay__express-checkout-title">Express Checkout</h2>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </div>
          <div className="Checkout__or-divider"><span>Or</span></div>
        </div>
      )}
    </>
  );
};

export default ApplePay;
