import React, { useState } from "react";
import { useFilterProductsContext } from "../../../../lib/context/FilterProductsContext/FilterProductsContext";

import cs from "classnames";
import "./FilterCheckBoxCategory.scss";

const FilterCheckBoxCategory = (props) => {
  const { items, name } = props;
  const [openFilter, setOpenFilter] = useState(false);

  const filtersCtx = useFilterProductsContext();

  return (
    <div className="FilterCheckBoxCategory">
      <div
        className={cs("FilterCheckBoxCategory__wrap", openFilter && "open")}
        onClick={() => setOpenFilter(!openFilter)}
      >
        <div className="FilterCheckBoxCategory__name"> {name} </div>

        {items ? (
          <div
            className={cs(
              "FilterCheckBoxCategory__close",
              openFilter && "open"
            )}
          >
            <div className="FilterCheckBoxCategory__line1" />
            <div className="FilterCheckBoxCategory__line2" />
          </div>
        ) : null}
      </div>
      {openFilter && (
        <div className="FilterCheckBoxCategory__subtitle"> up to three </div>
      )}
      {items?.map((i) => (
        <div
          className={cs(
            "FilterCheckBoxCategory__items",
            openFilter && "open__items"
          )}
          key={i?.id}
        >
          <div
            className={cs(
              "FilterCheckBoxCategory__input-wrap",
              filtersCtx.selectedType?.length >= 3 &&
                filtersCtx.selectedType.some((elem) => elem?.id !== i.id) &&
                "FilterCheckBoxCategory__input-wrap--disabled"
            )}
            onClick={() => {
              if (filtersCtx.selectedType?.length < 3) {
                props.onItemSelect(i.name, i.old_category_name, i?.id, i?.slug);
                const existingIndex = filtersCtx.selectedType.findIndex(
                  (item) => item.id === i?.id
                );

                if (existingIndex === -1) {
                  filtersCtx.setSelectedType((prevData) => [...prevData, i]);
                }
              }
            }}
          >
            <div className="FilterCheckBoxCategory__outer-circles">
              <div
                id="Radio-button"
                className={cs(
                  "FilterCheckBoxCategory__inner-circle",
                  filtersCtx.selectedType.some((elem) => elem?.id === i.id) &&
                    "FilterCheckBoxCategory__inner-circle--active"
                )}
              />
            </div>
            <div className="FilterCheckBoxCategory__label">{i?.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterCheckBoxCategory;
