import React, { useState } from "react";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useGuestSignUpFormik } from "./useGuestSignUpFormik";

import { Form, Input, Spinner } from "reactstrap";

import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./GuestSignUpForm.scss";

const GuestSignUpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const AuthCtx = useAuthContext();
  const msgCtx = useMessageContext();

  const formik = useGuestSignUpFormik({
    onSubmit: async (values) => {
      setIsLoading(true);
      AuthCtx.registerGuest(values).then(() => {
        // msgCtx.handleSuccessMessage(signedUp);
      });
      setIsLoading(false);
    },
  });

  return (
    <Form className="GuestSignUpForm" onSubmit={formik.handleSubmit}>
      <div className="GuestSignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="GuestSignUpForm__input"
          name="email"
          type="email"
          placeholder="E-mail"
          noValidate
        />
        {formik.errors.email && formik.touched.email && (
          <div className="GuestSignUpForm__required">{formik.errors.email}</div>
        )}
      </div>
      <button
        disabled={isLoading ? true : false}
        type="submit"
        className="GuestSignUpForm__button"
      >
        {isLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #000000",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : (
          "Guest Checkout"
        )}
      </button>
      <div className="GuestSignUpForm__gift-certificate-msg">
        Gift certificates cannot be used in guest checkout
      </div>
    </Form>
  );
};

export default GuestSignUpForm;
