import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import Brand from "../Brand/Brand";
import * as API from "../../../api/Api";

import FlickitySlider from "../../FlickitySlider/FlickitySlider";
import ViewMoreButton from "../../shared/ViewButton/ViewMoreButton";

import "./ChooseCoffes.scss";

const ChooseCoffes = () => {
  const [coffeePurveyors, setCoffeePurveyors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    API.getCoffeePurveyors().then((res) => {
      const emptyObject = { name: "all roasters", id: 0 };
      setCoffeePurveyors(res.data);
      setCoffeePurveyors(res.data.concat(emptyObject));

      if (res.data) {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div className="ChooseCoffes">
      <MiddleLine />
      <div className="ChooseCoffes__content">
        <div className="ChooseCoffes__title-container">
          <h2 className="ChooseCoffes__title">
            CHOOSE FROM OVER A THOUSAND COFFEES
          </h2>
        </div>

        <div className="ChooseCoffes__background-line" />
        <div className="ChooseCoffes__slider-container">
          <FlickitySlider
            options={{
              autoPlay: 6000,
              wrapAround: true,
              groupCells: true,
              pageDots: false,
              contain: true,
              prevNextButtons: true,
              draggable: true,
            }}
          >
            {!isLoading &&
              coffeePurveyors.map((item) => (
                <Brand
                  key={item.id}
                  id={item.id}
                  name={item.slug}
                  image={item?.main_image?.md}
                />
              ))}
          </FlickitySlider>
        </div>
        <ViewMoreButton
          label="VIEW ALL"
          handleClick={() => history.push("/roasters")}
        />
      </div>
    </div>
  );
};

export default ChooseCoffes;
