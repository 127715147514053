import React from "react";
import "./Item.scss";

const Item = (props) => {
  const { image, title, description } = props;
  return (
    <div className="WhyChooseGoCoffeGoItem">
      <img src={image} loading="lazy" className="WhyChooseGoCoffeGoItem__image" alt={`${title} icon`} />
      <h2 className="WhyChooseGoCoffeGoItem__title"> {title}</h2>
      <p className="WhyChooseGoCoffeGoItem__description"> {description}</p>
    </div>
  );
};

export default Item;
