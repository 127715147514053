import { apiRequest } from "./Api";

export const addContactForm = async (values, inputSelected) => {
  const data = {
    email: values.email,
    name: values.name,
    order_number: values.order_number,
    phone: values.phone,
    comments: values.comments,
    reason: inputSelected,
  };

  return apiRequest("post", `contact-us`, data);
};
