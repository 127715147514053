import React, { useEffect, useState } from "react";
import { CartContext } from "./CartContext";
import { useLocation, useHistory } from "react-router-dom";
import * as API from "../../../api/Api";

export const CartContextProvider = (props) => {
  const [cartItems, setCartItems] = useState({});
  const [autoshipData, setAutoShipData] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [savedItemsLoading, setSavedItemsLoading] = useState(true);
  const [cartSuggestions, setCartSuggestions] = useState({});
  const [cartSuggestionsLoading, setCartSuggestionsLoading] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [cartSubTotal, setCartSubtotal] = useState();
  const cartId = localStorage.getItem("cart_id");
  const [specialMessages, setSpecialMessages] = useState([]);
  const [cartModalOpen, setCartModalOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();

  useEffect(() => {
    getAutoShips();
    getSavedItems();
    getCartItems();
  }, []);

  useEffect(() => {
    setCartModalOpen(false);
  }, [pathname]);

  const hideCartModal = () => {
    setCartModalOpen(false);
  };

  const toggleCartModal = () => {
    if (pathname?.startsWith("/checkout")) {
      history.push("/cart");
    } else {
      setCartModalOpen(!cartModalOpen);
    }
  };

  const updateSpecialMessages = (items) => {
    setSpecialMessages(items);
  };

  const getShipments = () => {
    API.getShipments(cartId).then((res) => {
      setShipments(res.data);
    });
  };

  const getAutoShips = () => {
    const cartId = localStorage.getItem("cart_id");

    API.getAutoShips(cartId).then((res) => {
      setAutoShipData(res.data);
    });
  };

  const getCartItems = (param) => {
    setIsLoading(true);
    const cartId = localStorage.getItem("cart_id");

    API.getCartItems(cartId, param).then((res) => {
      setCartSubtotal(res?.data?.totals?.subtotal);
      setCartItems(res.data);
      setSpecialMessages(res.data.specials_messages);
      setIsLoading(false);

      if (res.data?.items) {
        setQuantity(res?.data?.items?.length);
      } else {
        setQuantity(0);
      }
    });
  };

  const getCartSuggestions = () => {
    API.getCartSuggestions(cartId).then((res) => {
      setCartSuggestions(res.data);

      if (res.data) {
        setCartSuggestionsLoading(false);
      }
    });
  };

  const getSavedItems = () => {
    const cartId = localStorage.getItem("cart_id");

    API.getSavedItems(cartId).then((res) => {
      setSavedItems(res.data.items);

      if (res.data.items) {
        setSavedItemsLoading(false);
      }
    });
  };

  const clearCartItems = () => {
    setQuantity(0);
    setCartItems(null);
  };

  const updateCartSubtotal = (value) => {
    setCartSubtotal(value);
  };

  const context = {
    specialMessages,
    updateSpecialMessages,
    updateCartSubtotal,
    cartSubTotal,
    isLoading,
    cartItems,
    savedItems,
    savedItemsLoading,
    getCartItems,
    clearCartItems,
    getShipments,
    getCartSuggestions,
    getSavedItems,
    quantity,
    cartSuggestions,
    cartSuggestionsLoading,
    shipments,
    autoshipData,
    toggleCartModal,
    hideCartModal,
    cartModalOpen,
  };

  return (
    <CartContext.Provider value={context}>
      {props.children}
    </CartContext.Provider>
  );
};
