import React from "react";
import cs from "classnames";
import { Spinner } from "reactstrap";

import "./Button.scss";

const Button = (props) => {
  const {
    label,
    style,
    className,
    onClick,
    disabled,
    isLoading,
    spinner,
    color,
  } = props;
  return (
    <button
      disabled={disabled}
      onClick={() => onClick()}
      style={style}
      className={cs("Button", className)}
    >
      {spinner && isLoading ? (
        <Spinner
          style={{
            border: `0.25em solid ${color}`,
            borderRightColor: "transparent",
            width: "2.5rem",
            height: "2.5rem",
          }}
        />
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
