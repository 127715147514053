import React from "react";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";
import { useParams } from "react-router-dom";

import "./FilterRadioButton.scss";

import cs from "classnames";

const RadioButton = (props) => {
  const { selectedItem, style, name, id, slug } = props;
  const params = useParams();
  const filterProductsCtx = useFilterProductsContext();

  return (
    <div
      className="FilterRadioButton"
      style={style}
      onClick={() => {
        props.onClick();
      }}
    >
      <div className="FilterRadioButton__outer-circles">
        <div
          id="Radio-button"
          className={cs(
            "FilterRadioButton__inner-circle",
            params.pathParam1
              ? parseInt(params.pathParam2) === id &&
                  "FilterRadioButton__inner-circle--active"
              : selectedItem === slug &&
                  "FilterRadioButton__inner-circle--active"
          )}
        />
      </div>
      <div className="FilterRadioButton__labels">{name}</div>
    </div>
  );
};

export default RadioButton;
