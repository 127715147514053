import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./index.css";
/* const REACT_APP_STRIPE_PUBLIC_KEY =
  "pk_test_51KS7lUBKvT8RR4H3RdfxK0IsdcrCzBFeheQvnbD3xbxnzSweRsypCMfeCpLqDnMSR4mfrqY2EdZuENW7cTPF9s0m008Xzp0h8n"; */

const PUBLIC_KEY =
  "pk_live_51KS7lUBKvT8RR4H3xAucmxVdr63RghCXnrlcMfelT3e4Zj1mwXMfqVFJFE2920ncERmh9Rbhmk7GzBOgAp1Qgh3c00PCGQnhCS";

if (navigator.userAgent !== "ReactSnap") {
  const stripeTestPromise = loadStripe(PUBLIC_KEY);

  const APP = (
    <React.StrictMode>
      <Router>
        <Elements stripe={stripeTestPromise}>
          <App />
        </Elements>
      </Router>
    </React.StrictMode>
  );

  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
  } else {
    render(APP, rootElement);
  }
} else {
  const APP = (
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  );

  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
  } else {
    render(APP, rootElement);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Account more: https://bit.ly/CRA-vitals
reportWebVitals();
