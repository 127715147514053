import React from "react";

import LinkButton from "../../shared/LinkButton/LinkButton";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";

import "./CoffeeFaqHeadline.scss";

const CoffeeFaqHeadline = () => {
  const ReadingPaper =
    "https://assets.gocoffeego.com/v2/assets/images/reading-paper.png";
  const GizmoPeaberry =
    "https://assets.gocoffeego.com/v2/assets/images/gizmo-peaberry.png";
  const Flowers =
    "https://assets.gocoffeego.com/v2/assets/images/Learn-flowers2.png";
  return (
    <div className="CoffeeFaqHeadline">
      <div className="CoffeeFaqHeadline__mobile">
        <MiddleLine />
        <div className="CoffeeFaqHeadline__title font-cairo">COFFEE FAQ</div>
        <LinkButton
          path="/coffee-faq"
          className="CoffeeFaqHeadline__button"
          label="LET'S GO"
        />
      </div>
      <div className="CoffeeFaqHeadline__background">
        <img
          className="CoffeeFaqHeadline__reading"
          src={ReadingPaper}
          alt="reading-paper"
        />
        <img
          className="CoffeeFaqHeadline__peaberry"
          src={GizmoPeaberry}
          alt="gizmo-peaberry"
        />
        <img
          className="CoffeeFaqHeadline__flowers"
          src={Flowers}
          alt="flowers"
        />
        <div className="CoffeeFaqHeadline__content">
          <div className="CoffeeFaqHeadline__title font-cairo">COFFEE FAQ</div>
          <div className="CoffeeFaqHeadline__button-container">
            <LinkButton
              path="/coffee-faq"
              className="CoffeeFaqHeadline__button"
              label="LET'S GO"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoffeeFaqHeadline;
