import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import * as API from "../../../api/Api";
import cs from "classnames";
import AddContact from "./AddContact/AddContact";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import { deleteGsAddressMessage } from "../../../lib/helpers/messages";

import "./AddressBook.scss";

const AddressBook = () => {
  const PinkTruck =
    "https://assets.gocoffeego.com/v2/assets/images/pink-truck.png";
  const [addressBook, setAddressBook] = useState([]);
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [selectedGiftSubcription, setSelectedGiftSubscription] = useState(null);
  const [selectedDefault, setSelectedDefault] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useAuthContext();
  const location = useLocation();
  const msgCtx = useMessageContext();

  useEffect(() => {
    getAddressBook();
  }, []);

  const getAddressBook = () => {
    API.getAddressBookInfo()
      .then((res) => {
        setAddressBook(res.data);
      })
      .catch((err) => msgCtx.handleError(err));
  };

  const findSubscriptionAddress = () => {
    return addressBook.filter((item) => item.gift_subscription_address === 1);
  };

  const subscriptionAddress = findSubscriptionAddress();

  const deleteContact = (id) => {
    if (subscriptionAddress[0]?.id === id) {
      msgCtx.notAllowedMessage(deleteGsAddressMessage);
      return;
    }

    setSelectedId(id);
    setIsLoading(true);

    API.deleteAddressBookContact(id)
      .then((res) => {
        if (res.status === 200) {
          getAddressBook();
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
        if (res.error) {
          setMessage(res.message);
        }

        // getAddressBook();
      })
      .catch((err) => {
        if (err) {
          msgCtx.handleError(err);
          setIsLoading(false);
        }
      });
  };

  const editAddress = (address) => {
    setOpen(true);
    setSelectedAddress(address);
  };

  const setDefault = (id, e) => {
    setSelectedDefault(id);
    API.setDefaultAddress(id).then((res) => {
      //call customer
      authCtx.getCustomer();
      setMessage(res.data.result);
      getAddressBook();
      setDefaultAddress(true);
    });
  };

  const setGiftAddress = (id, e) => {
    setSelectedGiftSubscription(id);
    API.setGiftSubscriptionAddress(id)
      .then((res) => {
        setMessage(res.data.result);
        getAddressBook();
        //giftSubcriptionAddress(true);
      })
      .catch((err) => msgCtx.handleError(err));
  };

  return (
    <>
      {location.pathname === "/account/address-book/auto-ship" && (
        <Link to="/auto-ship">
          <div className="goBackLink">&#8592; Go Back</div>
        </Link>
      )}
      <div className="AddressBook">
        {open ? (
          <AddContact
            refetchAddress={getAddressBook}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            countries={countries}
            setOpen={setOpen}
            open={open}
            setMessage={setMessage}
            message={message}
          />
        ) : (
          <div className="AddressBook__container">
            {addressBook && addressBook.length !== 0 ? (
              <div className="AddressBook__cards">
                <div className="AddressBook__no-cards">
                  <img
                    src={PinkTruck}
                    alt="no card placeholder"
                    className="AddressBook__no-cards-img"
                  />
                  <button
                    className="AddressBook__button"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setOpen(!open);
                    }}
                  >
                    Add a new address
                  </button>
                </div>
                {addressBook &&
                  addressBook.map((address) => {
                    return (
                      <div className="AddressBook__card" key={address.id}>
                        <div className="AddressBook__card-info">
                          <div>{address.full_name}</div>
                          <div>{address.company_name}</div>
                          <div> {address.address1}</div>
                          {address.address2 && (
                            <div className="AddressBook__item-address2">
                              {address.address2}
                            </div>
                          )}
                          <div className="AddressBook__item-country">
                            {address.city}, {address.zip}
                          </div>
                          {address.state && (
                            <span className="AddressBook__item-address2">
                              {address.state.name},{" "}
                            </span>
                          )}
                          {address.country && (
                            <span className="AddressBook__item-country">
                              {address.country.name}
                            </span>
                          )}
                          <div className="AddressBook__item-country">
                            {address.phone}
                          </div>
                        </div>
                        <div className="MyCreditCards__buttons-wrap">
                          <button
                            className="MyCreditCards__edit-button"
                            onClick={() => editAddress(address)}
                          >
                            Edit
                          </button>
                          {address.default_address === 0 && (
                            <button
                              className="MyCreditCards__delete-button"
                              onClick={() => deleteContact(address.id)}
                              disabled={
                                isLoading && selectedId === address.id
                                  ? true
                                  : false
                              }
                            >
                              {isLoading && selectedId === address.id ? (
                                <Spinner
                                  style={{
                                    border: "0.25em solid #fff",
                                    borderRightColor: "transparent",
                                    width: "2.5rem",
                                    height: "2.5rem",
                                  }}
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          )}
                        </div>
                        {address.country &&
                          address.country.name === "United States" && (
                            <div
                              className="AddressBook__radio-button"
                              onClick={(e) => {
                                setDefault(address.id, e);
                              }}
                            >
                              <div className="AddressBook__outer-circles">
                                <div
                                  id="Radio-button"
                                  className={cs(
                                    "AddressBook__inner-circle",
                                    address.id === selectedDefault ||
                                      address.default_address === 1
                                      ? "AddressBook__inner-circle--active"
                                      : null
                                  )}
                                />
                              </div>
                              <div className="AddressBook__labels">
                                {address.default_address === 1 ||
                                address.id === selectedDefault
                                  ? "This is your current Default / Auto-Ship shipping address."
                                  : "Set your Default / Auto-Ship shipping address"}{" "}
                              </div>
                            </div>
                          )}
                        {address.country &&
                          address.country.name === "United States" && (
                            <div
                              className="AddressBook__radio-button"
                              onClick={(e) => setGiftAddress(address.id, e)}
                            >
                              <div className="AddressBook__outer-circles">
                                <div
                                  id="Radio-button"
                                  className={cs(
                                    "AddressBook__inner-circle",
                                    address.id === selectedGiftSubcription ||
                                      address.gift_subscription_address === 1
                                      ? "AddressBook__inner-circle--active"
                                      : null
                                  )}
                                />
                              </div>
                              <div className="AddressBook__labels">
                                {address.gift_subscription_address === 1 ||
                                address.id === selectedGiftSubcription
                                  ? "This is your Coffee Club address"
                                  : "Set your Coffee Club address"}{" "}
                              </div>
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="AddressBook__no-cards-container">
                <div className="AddressBook__no-cards">
                  <img
                    src={PinkTruck}
                    alt="no card placeholder"
                    className="AddressBook__no-cards-img"
                  />
                  <button
                    className="AddressBook__button"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      setOpen(!open);
                    }}
                  >
                    Add a new address
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {message && (
          <div id="message" className="AddressBook__message">
            {message}
          </div>
        )}
      </div>
    </>
  );
};

export default AddressBook;
