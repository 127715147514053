import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";

import { useNewsletterFormik } from "./useNewsletterFormik";
import { Form, Label } from "reactstrap";

import NewsletterSuccessModal from "../../Modals/NewsletterSucessModal/NewsletterSuccessModal";

import "./NewsletterForm.scss";

const NewsletterForm = () => {
  const [subscribed, setSubscribed] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = useState();
  const [username, setUsername] = useState();

  const subscribeEmail = localStorage.getItem("email-subscribe");

  useEffect(() => {
    subscribeEmail && formik.setFieldValue("email", subscribeEmail);
  }, [subscribeEmail]);

  const formik = useNewsletterFormik({
    onSubmit: async (values) => {
      setUsername(values.firstName);
      const newsletterData = new FormData();
      newsletterData.append("first_name", values.firstName);
      newsletterData.append("last_name", values.lastName);
      newsletterData.append("email", values.email);

      API.NewsletterSignUp(newsletterData)
        .then((res) => {
          if (res.status === 200) {
            setSubscribed(true);
            localStorage.removeItem("email-subscribe");
            window.gtag("event", "conversion", {
              send_to: "AW-11011335274/PdKKCJmN9IAYEOrIzoIp"
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setMessage(err.response.data.error.message.email);
            localStorage.removeItem("email-subscribe");
          }
        });
    },
  });

  return (
    <Form className="NewsletterForm" onSubmit={formik.handleSubmit}>
      <div className="NewsletterForm__input-container">
        <Label className="NewsletterForm__label">First Name</Label>
        <div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            className="NewsletterForm__input"
            name="firstName"
            type="text"
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <div className="NewsletterForm__required">
              {" "}
              {formik.errors.firstName}{" "}
            </div>
          )}
        </div>
      </div>

      <div className="NewsletterForm__input-container">
        <Label className="NewsletterForm__label">Last Name</Label>
        <div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            className="NewsletterForm__input"
            name="lastName"
            type="text"
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <div className="NewsletterForm__required">
              {" "}
              {formik.errors.lastName}{" "}
            </div>
          )}
        </div>
      </div>
      <div className="NewsletterForm__input-container">
        <Label className="NewsletterForm__label">E-mail</Label>
        <div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className="NewsletterForm__input"
            name="email"
            type="text"
          />
          {formik.errors.email && formik.touched.email && (
            <div className="NewsletterForm__required">
              {formik.errors.email}
            </div>
          )}
        </div>
      </div>
      <div className="NewsletterForm__input-container">
        <Label className="NewsletterForm__label">Re-enter e-mail</Label>
        <div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmEmail}
            className="NewsletterForm__input"
            name="confirmEmail"
            type="text"
          />
          {formik.errors.confirmEmail && formik.touched.confirmEmail && (
            <div className="NewsletterForm__required">
              {formik.errors.confirmEmail}
            </div>
          )}
        </div>
      </div>
      <button type="submit" className="NewsletterForm__button">
        Submit
      </button>
      {message && <div className="NewsletterForm__message">{message}</div>}

      {subscribed && (
        <NewsletterSuccessModal
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          name={username}
        />
      )}
    </Form>
  );
};

export default NewsletterForm;
