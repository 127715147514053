import { useFormik } from "formik";
import * as Yup from "yup";

const ChangeInfoSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string()
    // .min(2, "Username is too Short!")
    // .max(50, "Username is too Long!")
    .required("This field is required"),
  old_password: Yup.string()
    .required("This field is required")
    .min(8, "Password is too Short!")
    .max(40, "Username is too Long!"),
  password: Yup.string()
    .min(8, "Password is too Short!")
    .max(40, "Username is too Long!"),
  password_confirmation: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Confirmed password is not the same as new password"
    ),
  }),
});

export const useChangeInfoFormik = (profile, opts) => {
  return useFormik({
    initialValues: {
      first_name: profile ? profile.first_name : "",
      last_name: profile ? profile.last_name : "",
      email: profile ? profile.email : "",
      old_password: "",
      password: "",
      password_confirmation: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ChangeInfoSchema,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
