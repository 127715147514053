import React from "react";
import "./Progress.scss";

const Progress = (props) => {
  return (
    <div className="Progress">
      <div
        className={`${
          props.activeStep === 0 ||
          props.shippingAddress ||
          props.activeStep === 2
            ? "Progress__step active"
            : "Progress__step"
        }`}
      >
        <div>1</div>
        <div>Shipping address</div>
      </div>
      <div
        className={`${
          props.activeStep === 1 || props.activeStep === 2
            ? "Progress__step active"
            : "Progress__step"
        }`}
      >
        <div>2</div>
        <div>Payment & Review</div>
      </div>
      <div
        className={`${
          props.activeStep === 2 ? "Progress__step active" : "Progress__step"
        }`}
      >
        <div>3</div>
        <div>Confirmation</div>
      </div>{" "}
    </div>
  );
};

export default Progress;
