import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";

import ArticleContainer from "../ArticleContainer/ArticleContainer";
import Seo from "../../../lib/helpers/SEO/Seo";

import "./Roasting.scss";

const Roasting = () => {
  const GizmoPeaberry =
    "https://assets.gocoffeego.com/v2/assets/images/gizmo-peaberry.svg";
  const [roasting, setRoasting] = useState([]);

  useEffect(() => {
    API.getRoasting().then((res) => {
      setRoasting(res.data);
    });
  }, []);

  const article = {
    title: "How Are Coffee Beans Roasted?",
    image: GizmoPeaberry,
    url: "https://www.gocoffeego.com/learn/category/roasting"
  };

  return (
    <ArticleContainer shapeStyle={{ background: "#FF0006" }} title="Roasting">
      <Seo
        title="How Are Coffee Beans Roasted? | The Types of Coffee Roast"
        description="How are coffee beans roasted? What are the types of coffee roast? Learn the answers to these questions and more with GoCoffeeGo's Professor Peaberry!"
        keywords="roasting coffee, how to roast coffee, the pop, roasting coffee stages"
        article={article}
      />
      <div id="Roasting" className="Roasting">
        <img
          src={GizmoPeaberry}
          alt="roasting-bg"
          className="Roasting__main-image"
        />

        {roasting &&
          roasting.map((item) => (
            <>
              <h2 className="Roasting__title"> {item.title} </h2>
              <p className="Roasting__description">{item.content}</p>
            </>
          ))}
      </div>
    </ArticleContainer>
  );
};

export default Roasting;
