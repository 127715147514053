import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./MusicTrack.scss";

const MusicTrack = ({
  artist,
  title,
  song,
  isPlaying,
  onNextClick,
  onPrevClick,
  onPlayPauseClick,
}) => {
  const myRef = useRef();

  useEffect(() => {
    if (isPlaying) {
      myRef.current.load();
      myRef.current.play().catch((error) => {
        console.log(error);
      });
    } else {
      myRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <>
      {/*   <Link to="/music">
        {" "}
        <div className="MusicTrack__song-text">{`${artist}, ${title}`}</div>
      </Link> */}
      <audio
        ref={myRef}
        id="audio"
        src={song}
        preload="true"
        onEnded={() => onNextClick()}
      />
      <div className="MusicTrack__controls">
        <button
          type="button"
          className="MusicTrack__prev"
          aria-label="Previous"
          onClick={onPrevClick}
        ></button>
        {isPlaying ? (
          <div className="MusicTrack__play-wrap">
            <button
              type="button"
              className="MusicTrack__pause-icon"
              onClick={() => {
                myRef.current.load();
                onPlayPauseClick(false);
              }}
              aria-label="Pause"
            ></button>
          </div>
        ) : (
          <div className="MusicTrack__play-wrap">
            <button
              type="button"
              className="MusicTrack__play-icon"
              onClick={() => {
                myRef.current.load();
                onPlayPauseClick(true);
              }}
              aria-label="Play"
            ></button>
          </div>
        )}
        <button
          type="button"
          className="MusicTrack__next"
          aria-label="Next"
          onClick={onNextClick}
        ></button>
      </div>
    </>
  );
};

export default MusicTrack;
