export const QUIZ_QUESTIONS = [
  {
    id: 1,
    questionText: "What's your coffee drinking know-how?",
    answerOptions: [
      {
        id: 1,
        answerTitle: "Traditionalist",
        answerText:
          "I like dark and  traditional coffee flavors. I want coffee that tastes like coffee",
      },
      {
        id: 2,
        answerTitle: "Coffee Snob",
        answerText: "I buy speciality coffee and am open to a little adventure",
      },
      {
        id: 3,
        answerTitle: "Coffee Geek",
        answerText:
          "I love lighter roast single origins and am open to wild non-traditional flavors.",
      },
    ],
  },
  {
    id: 2,
    questionText: "What's type of coffee are you looking for?",
    answerOptions: [
      { id: 12, answerText: "Single Origin" },
      { id: 11, answerText: "Blends" },
      { id: 1, answerText: "Organic" },
      { id: 10, answerText: "Espresso" },
      { id: 5, answerText: "Decaffeinated" },
      { id: 92, answerText: "I'm not sure" },
    ],
  },
  {
    id: 3,
    questionText: "HOW DO YOU BREW?",
    answerOptions: [
      {
        id: 1,
        title: "DRIP",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/drip.d4cf049a.png",
        slug: "drip",
      },
      {
        id: 2,
        title: "Pour Over/Chemex",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Chemex.b8c5d7b6.png",
        slug: "pour-over-chemex",
      },
      {
        id: 3,
        title: "Aeropress",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/AERO-PRESS.dcf46f1d.png",
        slug: "aero-press",
      },
      {
        id: 4,
        title: "French Press",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/French-Press.5133a393.png",
        slug: "french-press",
      },
      {
        id: 5,
        title: "Moka Pot",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Moka-Pot.0f3cf459.png",
        slug: "moka-pot",
      },
      {
        id: 6,
        title: "Percolator",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Perculator.f041f1bc.png",
        slug: "perculator",
      },
      {
        id: 7,
        title: "Espresso",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Esspreso.0a1af0ab.png",
        slug: "espresso",
      },
      {
        id: 8,
        title: "Cold Brew",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Cold-Brew.c52da4e6.png",
        slug: "cold",
      },
      {
        id: 9,
        title: "REFILLABLE POD",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Refillable.990d999c.png",
        slug: "refillable",
      },
    ],
  },
  {
    id: 4,
    question: "What's your favorite roast style?",
    answerOptions: [
      {
        id: 1,
        title: "LIGHT",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/light.598e9e5f.png",
        slug: "light",
      },
      {
        id: 4,
        title: "MEDIUM-LIGHT",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/mediumlight.79e140eb.png",
        slug: "medium-light",
      },
      {
        id: 2,
        title: "MEDIUM",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/medium.8b5cbf79.png",
        slug: "medium",
      },
      {
        id: 5,
        title: "MEDIUM-DARK",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/mediumdark.9650fd9c.png",
        slug: "medium-dark",
      },
      {
        id: 3,
        title: "DARK",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/dark.d1d9d16b.png",
        slug: "dark",
      },
      /*  {
        id: 2233,
        title: "UNSURE",
        image:
          "https://assets.gocoffeego.com/pay-as-you-go/Unsure.4139f0b8.png",
        slug: "unsure",
      }, */
    ],
  },
  {
    id: 5,
    questionText: "Which flavor notes do you prefer?",
    answerOptions: [
      {
        id: 1,
        answerTitle: "Central American",
        answerText: "Bright, clean, often hint of cocoa, nut & fruit notes",
      },
      {
        id: 2,
        answerTitle: "South American",
        answerText: "Sweeter, chocolatey, nutty",
      },
      { id: 5, answerTitle: "Indonesian", answerText: "Full bodied & bold" },
      {
        id: 6,
        answerTitle: "African",
        answerText: "Bright, often berry, citrus or fruity flavors",
      },
      {
        id: 22,
        answerTitle: "Other" /* answerText: "Creamy, smooth, & clean"  */,
      },
    ],
  },
];
