import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useNewPasswordFormik } from "./useNewPasswordFormik";
import * as API from "../../../api/Api";

import { Form, Input } from "reactstrap";

import "./NewPasswordForm.scss";

const NewPasswordForm = () => {
  const [successMessage, setSusccessMessage] = useState(false);
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  // let email = localStorage.getItem("email");
  let token = searchParams.get("token");
  const history = useHistory();

  const AuthCtx = useAuthContext();

  const formik = useNewPasswordFormik({
    onSubmit: async (values) => {
      const data = {
        password: values.password,
        password_confirmation: values.confirmPassword,
        email: values.email,
        token: token,
      };

      API.resetPassword(token, data).then((res) => {
        if (res.data.result) {
          setSusccessMessage(true);

          setTimeout(() => {
            AuthCtx.login({ email: values.email, password: values.password });
          }, 2500);

          setTimeout(() => {
            history.push("/");
          }, 3000);
        }
      });
      // AuthCtx.login(values);
    },
  });

  return (
    <Form className="NewPasswordForm" onSubmit={formik.handleSubmit}>
      <p className="NewPasswordForm__description">
        You are almost there. Access to your account is just a step away. Please
        enter your new password then submit.
      </p>
      <div className="NewPasswordForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="NewPasswordForm__input"
          name="email"
          type="email"
          placeholder="E-mail"
        />

        {formik.errors.email && formik.touched.email && (
          <div className="ForgotPasswordForm__required">
            {formik.errors.email}
          </div>
        )}
      </div>

      <div className="NewPasswordForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className="NewPasswordForm__input"
          name="password"
          type="password"
          placeholder="Enter new password"
        />
        {formik.errors.password && formik.touched.password && (
          <div className="NewPasswordForm__required">
            {formik.errors.password}
          </div>
        )}
      </div>

      <div className="NewPasswordForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          className="NewPasswordForm__input"
          name="confirmPassword"
          type="password"
          placeholder="Confirm new password"
        />
        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
          <div className="NewPasswordForm__required">
            {formik.errors.confirmPassword}
          </div>
        )}
      </div>
      {successMessage && (
        <p className="NewPasswordForm__success">
          Your password has been successfully reset
        </p>
      )}
      <button type="submit" className="NewPasswordForm__button">
        SUBMIT
      </button>
    </Form>
  );
};

export default NewPasswordForm;
