import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useOutsideClickDetection } from "../../lib/hooks/useOutSideClickDetection";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import ShippingInfoModal from "../Modals/ShippingInfoModal/ShippingInfoModal";
import MusicPlayer from "../shared/MusicPlayer/MusicPlayer";
import SeoImage from "../shared/SeoImage/SeoImage";
import { Icon } from "../shared/Icon/Icon";

import cs from "classnames";

import TopHeader from "./TopHeader/TopHeader";

import "./Header.scss";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";
import CartPopUp from "../Cart/CartPopUp/CartPopUp";

const Header = () => {
  const [small, setSmall] = useState();
  const [showBox, setShowBox] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState("");
  const AuthCtx = useAuthContext();
  const history = useHistory();
  const dropDownRef = useRef(null);
  const dropDownRef2 = useRef(null);
  const inputRef = useRef();
  const mobileInputRef = useRef();
  const user = AuthCtx.user;
  const isMobile = useResponsiveDimensions().isMobile;
  const isTablet = useResponsiveDimensions().isTablet;
  const cartCtx = useCartContext();
  const [searchKeyword, setSearchKeyWord] = useState("");
  const location = useLocation();
  const msgCtx = useMessageContext();

  const ShoppingCartIcon =
    "https://assets.gocoffeego.com/v2/assets/icons/shopping-cart-icon.svg";
  const Logo = "https://assets.gocoffeego.com/v2/assets/images/logo.png";
  const Facebook =
    "https://assets.gocoffeego.com/v2/assets/images/facebook.png";
  const Instagram =
    "https://assets.gocoffeego.com/v2/assets/images/instagram.png";
  const Pinterest =
    "https://assets.gocoffeego.com/v2/assets/images/pinterest.png";
  const Linkedin =
    "https://assets.gocoffeego.com/v2/assets/images/linkedin.svg";
  const Twitter = "https://assets.gocoffeego.com/v2/assets/images/twitter.svg";

  useEffect(() => {
    if (typeof window !== "undefined") {
      const logo = document.getElementsByClassName(
        "Header__company-logo--small"
      );

      window.addEventListener("scroll", () => {
        setSmall(
          document.documentElement.scrollTop + 7048 >
            500 + document.documentElement.scrollHeight
        );
      });
    }
  });

  useEffect(() => {
    setSearchKeyWord("");
  }, [location]);

  const handleOutSideClick = () => {
    setShowBox(false);
  };

  const handleOutSideCartClick = () => {
    cartCtx.hideCartModal();
  };

  useEffect(() => {
    if (!showMenu && isMobile) {
      setShowSubmenu("");
    }
  }, [showMenu]);

  const handleSearchClick = () => {
    mobileInputRef.current && mobileInputRef.current.focus();
    inputRef.current && inputRef.current.focus();

    if (searchKeyword) {
      history.push(`/search?q=${searchKeyword}`);
      // filtersCtx.scrollInto("AdvancedSearch__results");
      if (showMenu) {
        setShowMenu(false);
      }
    }
  };

  const handleKeypress = (e) => {
    var key = e.keyCode || e.which;
    if (key === 13) {
      handleSearchClick();
    }
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);
  useOutsideClickDetection(dropDownRef2, handleOutSideCartClick);

  const menuItems = [
    // {
    //   path: "/",
    //   name: "HOME",
    // },
    // {
    //   path: "/shop",
    //   name: "SHOP",
    // },
    {
      path: "/subscribe",
      name: "SUBSCRIBE",
    },
    {
      path: "",
      name: "GIFT",
      submenu: [
        {
          path: "/coffee-gifts",
          name: "Coffee Tours",
        },
        {
          path: "/corporate-gifts",
          name: "Corporate gifts",
        },
        {
          path: "/account/redeem",
          name: "Redeem Gift Certificate / Gift Card",
        },
      ],
    },
    {
      path: "/auto-ship",
      name: "AUTO-SHIP",
    },
    {
      path: "",
      name: "LEARN",
      submenu: [
        {
          path: "/peaberry-recommends",
          name: "Peaberry Recommends",
        },
        {
          path: "/learn/category/the-history-of-coffee/850",
          name: "History of Coffee",
        },
        {
          path: "/learn/category/roasting",
          name: "Roasting",
        },
        {
          path: "/learn/category/regions/coffee-regions",
          name: "Regions",
        },
        {
          path: "/learn/category/brewing-tips",
          name: "Brewing Tips",
        },
        {
          path: "/learn/category/espresso-drinks",
          name: "Espresso Drinks",
        },
        {
          path: "/learn/category/grinding",
          name: "Grinding",
        },
        {
          path: "/learn/category/making-your-brew",
          name: "Making Your Brew",
        },
        {
          path: "/learn/category/recipes",
          name: "Recipes",
        },
        {
          path: "/coffee-faq",
          name: "Coffee FAQ's",
        },
      ],
    },
  ];

  const subItemsShop = [
    {
      path: "/shop",
      name: "Shop All",
    },
    {
      path: "/roasters",
      name: "Roasters",
    },
    {
      path: "/shop",
      name: "Specials",
    },
    {
      path: "/top25",
      name: "GoCoffeeGo Top 25",
    },
    {
      path: "/member-picks",
      name: "Members' Picks",
    },
    {
      path: "/award-winners",
      name: "Award Winners",
    },
    {
      path: "/limited-offerings",
      name: "Limited Offerings",
    },

    {
      path: "/bulk-coffees",
      name: "Bulk Coffees",
    },
    {
      path: "/tea",
      name: "Tea",
    },
    {
      path: "/search",
      name: "Advanced Search",
    },
  ];

  return (
    <>
      <TopHeader small={small} />
      <div className={cs("Header", showMenu && "Header--active")}>
        <ShippingInfoModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />
        <div className="Header__menu">
          <div className="Header__nav-mobile">
            <div
              className={cs(`hamburger`, showMenu && "active")}
              onClick={() => setShowMenu(!showMenu)}
            >
              <span />
              <span />
              <span />
            </div>
            <Link to="/">
              <SeoImage src={Logo} className="Header__logo-mb" alt="logo" />
            </Link>
            <div
              onClick={() => {
                cartCtx.toggleCartModal();
              }}
            >
              <div className="cart-icon-mobile">
                <img
                  className="icon"
                  src={ShoppingCartIcon}
                  alt="shopping-cart-icon"
                />
                <span className="quantity"> {cartCtx.quantity} </span>
              </div>
            </div>
            {cartCtx.cartModalOpen && <CartPopUp items={cartCtx?.cartItems} />}
          </div>
          <ul className={cs("Header__nav-left", showMenu && "active")}>
            {/*<div className="Header__nav-container">*/}
            {isMobile && isTablet && (
              <div className="SearchMobile">
                <input
                  type="text"
                  name="search"
                  className="SearchMobile__input"
                  ref={mobileInputRef}
                  onChange={(e) => setSearchKeyWord(e.target.value)}
                  onFocus={(e) => {
                    e.target.value = "";
                  }}
                  onKeyDown={(e) => handleKeypress(e)}
                  aria-label="Search"
                />
                <label
                  className="SearchMobile__input-image"
                  onClick={handleSearchClick}
                />
              </div>
            )}

            <li className="item">
              <NavLink
                onClick={() => setShowMenu(!showMenu)}
                className="navlink"
                to="/"
              >
                HOME
              </NavLink>
            </li>
            <li className="item">
              <div
                onClick={() => setShowSubmenu("SHOP")}
                className="navlink"
                /*    to={() => {
                  let shopHistory = JSON.parse(
                    localStorage.getItem("shop_history")
                  );

                  if (shopHistory) {
                    return `/shop${shopHistory}`;
                  } else {
                    return "/shop";
                  } 
                }} */
              >
                SHOP
              </div>
              <div
                className={cs(
                  "Header__menu-dropdown",
                  small && "Header__menu-dropdown--small",
                  showSubmenu === "SHOP" && "Header__menu-dropdown--active"
                )}
              >
                <div
                  className={cs(
                    "Header__menu-dropdown-submenu",
                    showSubmenu === "SHOP" &&
                      "Header__menu-dropdown-submenu--active"
                  )}
                >
                  <ul>
                    {isMobile && isTablet && (
                      <div
                        className="Header__navlink-mobile-wrapper"
                        onClick={() => setShowSubmenu("")}
                      >
                        {" "}
                        <svg
                          fill="#000000"
                          height="24px"
                          width="24px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 476.213 476.213"
                          xmlSpace="preserve"
                        >
                          <polygon
                            points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5 
	57.427,253.107 476.213,253.107 "
                          />
                        </svg>
                        <div className="Header__navlink-mobile">SHOP</div>
                      </div>
                    )}
                    {subItemsShop.map((item, index) => (
                      <li
                        key={index}
                        className="Header__menu-dropdown-submenu-item"
                      >
                        <NavLink
                          onClick={() => setShowMenu(!showMenu)}
                          className="Header__menu-dropdown-submenu-navlink"
                          to={item.path}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                  <Link to="/shop/coffee-by-type/espresso">
                    {" "}
                    <div
                      className="Header__menu-dropdown-submenu--holiday-promo"
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      <div className="Header__menu-dropdown-submenu-navlink">
                        Espresso
                      </div>
                      <img
                        src="https://assets.gocoffeego.com/v2/brilliant-recommendations/natural-process.png"
                        alt="espresso coffee"
                        className="Header__menu-dropdown-submenu-image"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </li>

            {menuItems.map((item, index) => (
              <li key={index} className="item">
                {item.path !== "" ? (
                  <NavLink
                    onClick={() => setShowMenu(!showMenu)}
                    className="navlink"
                    to={item.path}
                  >
                    {item.name}
                  </NavLink>
                ) : (
                  <div
                    onClick={() => setShowSubmenu(item.name)}
                    className="navlink"
                  >
                    {item.name}
                  </div>
                )}
                {item?.submenu && (
                  <div
                    className={cs(
                      "Header__menu-dropdown",
                      small && "Header__menu-dropdown--small",
                      showSubmenu === item.name &&
                        "Header__menu-dropdown--active"
                    )}
                  >
                    <div
                      className={cs(
                        "Header__menu-dropdown-submenu",
                        item.name === "GIFT" &&
                          "Header__menu-dropdown-submenu--gift",
                        showSubmenu === item.name &&
                          "Header__menu-dropdown-submenu--active"
                      )}
                    >
                      <ul>
                        {isMobile && isTablet && item?.submenu && (
                          <div
                            className="Header__navlink-mobile-wrapper"
                            onClick={() => setShowSubmenu("")}
                          >
                            {" "}
                            <svg
                              fill="#000000"
                              height="24px"
                              width="24px"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 476.213 476.213"
                              xmlSpace="preserve"
                            >
                              <polygon
                                points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5 
	57.427,253.107 476.213,253.107 "
                              />
                            </svg>
                            <div className="Header__navlink-mobile">
                              {" "}
                              {item.name}{" "}
                            </div>
                          </div>
                        )}
                        {item?.submenu &&
                          item?.submenu.map((subitem, index) => (
                            <li
                              key={index}
                              className="Header__menu-dropdown-submenu-item"
                            >
                              <NavLink
                                onClick={() => setShowMenu(!showMenu)}
                                className="Header__menu-dropdown-submenu-navlink"
                                to={subitem.path}
                              >
                                {subitem.name}
                              </NavLink>
                            </li>
                          ))}
                        {item.name === "GIFT" && (
                            <li
                              className="Header__menu-dropdown-submenu--promo"
                              onClick={() => setShowMenu(!showMenu)}
                            >
                            <Link to="/coffee-gift-certificates">
                              <div className="Header__menu-dropdown-submenu-navlink">
                                Buy Coffee Gift Certificate
                              </div>
                              <img
                                src="https://assets.gocoffeego.com/v2/gift-certificates/coffee-gift-certificate-25.png"
                                alt="gift certificate"
                                className="Header__menu-dropdown-submenu-image--cert"
                              />
                            </Link>
                            </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            ))}
            {/* end! */}

          {isMobile && (
              <li className="item">
                <div
                  onClick={() => {
                    setIsOpen(!isOpen);
                    setShowMenu(false);
                  }}
                  className="navlink shippingInfo-button font-cairo isMobile"
                >
                  SHIPPING INFO
                </div>
              </li>
            )}  
            {AuthCtx.isGuestAuthenticated && isMobile && (
              <li className="item">
                <div
                  onClick={() => {
                    AuthCtx.logOut();
                    setShowMenu(!showMenu);
                  }}
                  className="navlink shippingInfo-button font-cairo isMobile"
                >
                  GUEST SIGN OUT
                </div>
              </li>
            )}

            {user && !user?.guest && isMobile && (
              <>
                <li className="item">
                  <NavLink
                    onClick={() => {
                      setShowMenu(!showMenu);
                    }}
                    className="navlink account-mobile-link isMobile"
                    to="/account"
                  >
                    MY ACCOUNT
                  </NavLink>
                </li>
                {isMobile && (
                  <>
                    <li className="item">
                      <NavLink
                        onClick={() => setShowMenu(!showMenu)}
                        className="navlink contact-us-link isMobile"
                        to="/contact-us"
                      >
                        CONTACT US
                      </NavLink>
                    </li>
                  </>
                )} 

                <li className="item">
                  <a
                    href="/"
                    onClick={() => AuthCtx.logOut()}
                    className="navlink isMobile"
                  >
                    SIGN OUT
                  </a>
                </li>
              </>
            )}

            {(!user || (user && user?.guest)) && isMobile ? (
              <>
                <li className="item">
                  <NavLink
                    onClick={() => setShowMenu(!showMenu)}
                    className="navlink isMobile"
                    to="/contact-us"
                  >
                    CONTACT US
                  </NavLink>
                </li>
                <li className="item">
                  <NavLink
                    onClick={() => {
                      setShowMenu(!showMenu);
                    }}
                    className="navlink isMobile"
                    to="/login"
                  >
                    SIGN IN
                  </NavLink>
                </li>
                <li className="item">
                  <NavLink
                    onClick={() => setShowMenu(!showMenu)}
                    className="navlink isMobile"
                    to="/sign-up"
                  >
                    SIGN UP
                  </NavLink>
                </li>
              </>
            ) : null}

            {isMobile && (
              <div className="Social">
                <div className="Social__title">Follow us</div>
                <div className="Soccial__icons">
                  <a
                    href="https://www.facebook.com/GoCoffeeGo"
                    target="_blank"
                    rel="noreferrer"
                    className="Social__link"
                  >
                    <img
                      src={Facebook}
                      alt="facebook icon"
                      className="Social__link-image"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/drinkgocoffeego/"
                    target="_blank"
                    rel="noreferrer"
                    className="Social__link"
                  >
                    <img
                      src={Instagram}
                      alt="instagram icon"
                      className="Social__link-image"
                    />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/gocoffeego/about/"
                    target="_blank"
                    rel="noreferrer"
                    className="Social__link"
                  >
                    <img
                      src={Linkedin}
                      alt="linkedin icon"
                      className="Social__link-image"
                    />
                  </a>
                  <a
                    href="https://twitter.com/GoCoffeeGo"
                    target="_blank"
                    rel="noreferrer"
                    className="Social__link"
                  >
                    <img
                      src={Twitter}
                      alt="twitter icon"
                      className="Social__link-image"
                    />
                  </a>
                  <a
                    href="https://www.pinterest.com/gocoffeego"
                    target="_blank"
                    rel="noreferrer"
                    className="Social__link"
                  >
                    <img
                      src={Pinterest}
                      alt="pinterest icon"
                      className="Social__link-image"
                    />
                  </a>
                </div>
              </div>
            )}
            {/*</div>*/}
          </ul>

          <Link to="/">
            <SeoImage
              src={Logo}
              className={`Header__company-logo ${
                small && "Header__company-logo--small"
              }`}
              alt="company-logo"
            />
          </Link>

          <div className="Header__nav-right">
            <div className="Search">
              <input
                type="text"
                ref={inputRef}
                name="search"
                className="Search__input"
                onChange={(e) => setSearchKeyWord(e.target.value)}
                onFocus={(e) => {
                  e.target.value = "";
                }}
                onKeyDown={(e) => handleKeypress(e)}
                aria-label="Search"
              />
              <label
                className="Search__input-image"
                onClick={handleSearchClick}
              />
            </div>

            <span className="vertical-line" />
            <div className="Header__music-wrapper">
              <Link to="/music">
                {" "}
                <div className="Header__classic-button"> Music </div>
              </Link>
              <MusicPlayer />
            </div>

            <span className="vertical-line" />

            {user && !user?.guest ? (
              <div
                ref={dropDownRef}
                onClick={() => {
                  msgCtx.hideModal();
                  setShowBox(!showBox);
                }}
                className="UserDropDown"
              >
                <div className="UserDropDown__title"> ACCOUNT </div>
                <span className="UserDropDown__arrow-down" />

                <div
                  className={cs(
                    "UserDropDown__body",
                    showBox && "UserDropDown__body--active"
                  )}
                >
                  <div
                    onClick={() => history.push("/account")}
                    className="UserDropDown__details"
                  >
                    <div className="UserDropDown__email">{user?.email}</div>
                  </div>

                  <div
                    onClick={() => history.push("/account")}
                    className="UserDropDown__details"
                  >
                    <div className="UserDropDown__name UserDropDown__name-profile ">
                      My Account
                    </div>
                  </div>
                  <div
                    onClick={() => history.push("/contact-us")}
                    className="UserDropDown__details"
                  >
                    <div className="UserDropDown__name UserDropDown__name-profile ">
                      Contact us
                    </div>
                  </div>

                  <div className="UserDropDown__log-out-container">
                    {/*<div*/}
                    {/*  onClick={() => AuthCtx.logOut()}*/}
                    {/*  className="UserDropDown__log-out"*/}
                    {/*>*/}
                    {/*  SIGN OUT*/}
                    {/*</div>*/}

                    <a
                      href="/"
                      onClick={() => AuthCtx.logOut()}
                      className="UserDropDown__log-out"
                    >
                      SIGN OUT
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div
                ref={dropDownRef}
                onClick={() => setShowBox(!showBox)}
                className="UserDropDown"
              >
                <div className="UserDropDown__title"> ACCOUNT </div>
                <span className="UserDropDown__arrow-down" />

                <div
                  className={cs(
                    "UserDropDown__body",
                    showBox && "UserDropDown__body--active"
                  )}
                >
                  <div
                    onClick={() => history.push("/login")}
                    className="UserDropDown__login"
                  >
                    SIGN IN
                  </div>
                  <div
                    onClick={() => history.push("/sign-up")}
                    className="UserDropDown__login"
                  >
                    SIGN UP
                  </div>
                  {AuthCtx.isGuestAuthenticated && (
                    <div
                      onClick={() => AuthCtx.logOut()}
                      className="UserDropDown__login"
                    >
                      GUEST SIGN OUT
                    </div>
                  )}
                  <div
                    onClick={() => history.push("/contact-us")}
                    className="UserDropDown__login"
                  >
                    CONTACT US
                  </div>
                </div>
              </div>
            )}
            <div
              onClick={() => {
                cartCtx.toggleCartModal();
              }}
            >
              <div className="ShoppingCartIcon">
                <img
                  className="ShoppingCartIcon__icon"
                  src={ShoppingCartIcon}
                  alt="shopping-cart-icon"
                />
                <span className="ShoppingCartIcon__quantity">
                  {cartCtx.quantity}
                </span>
              </div>
            </div>
          </div>
          {cartCtx.cartModalOpen && <CartPopUp items={cartCtx?.cartItems} />}
        </div>
      </div>
    </>
  );
};

export default Header;
