import React from "react";

import cs from "classnames";
import "./Brand.scss";
import { useHistory } from "react-router-dom";

const Brand = (props) => {
  const { image, name, style, title, id } = props;
  const history = useHistory();

  const location = {
    pathname: `/roaster/${name}-${id}`,
    state: { purveyorId: id },
  };

  const onItemSelect = () => {
    if (id === 0) {
      history.push(`/roasters`);
      return;
    }
    history.push(location);
  };

  return (
    <div
      onClick={onItemSelect}
      style={style}
      className={cs("Brand", !image && "Brand--all")}
    >
      {image ? (
        <img className="Brand__image" src={image} alt={name} loading="lazy" />
      ) : (
        <h2 className="Brand__title"> SHOP ALL ROASTERS</h2>
      )}
    </div>
  );
};

export default Brand;
