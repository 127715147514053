import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import * as API from "../../api/Api";

import ProductStory from "../../components/ProductPage/ProductStory/ProductStory";
import ProductDetails from "../../components/ProductPage/ProductDetails/ProductDetails";
import RoasterArticle from "../../components/ProductPage/RoasterArticle/RoasterArticle";
import SimilarCoffees from "../../components/ProductPage/SimilarCoffees/SimilarCoffees";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import Coffe101 from "../../components/Coffe101/Coffe101";
import Line from "../../components/shared/Line/Line";

import "./ProductPage.scss";
import Seo from "../../lib/helpers/SEO/Seo";

const ProductPage = () => {
  const [product, setProduct] = useState({});
  const [productIsLoading, setProductIsLoading] = useState(true);
  const [roasterId, setRoasterId] = useState();
  const isMobile = useResponsiveDimensions().isMobile;
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const params = useParams();
  const history = useHistory();
  const productId = params?.pathParam2?.match(/\b(\d+)\b(?=[^\d]*$)/)[0];
  const purveyorSlug = product?.purveyor?.slug;
  const purveyor = product?.purveyor;
  const seo = product?.seo;
  const seoTitleTag = seo?.seo_title_tag;
  const seoDescription = seo?.seo_meta_description;

  useEffect(() => {
    getProduct();
  }, [productId]);

  const getProduct = () => {
    try {
      API.getProductById(productId)
      .then((res) => {
        setProduct(res.data);

        setRoasterId(res.data.purveyor.id);

        if (res.data) {
          setProductIsLoading(false);
        }
        addItemsInRecentlyViewed();
      })
    }catch(err){
      if (
        (err.response?.status === 301 &&
          !err.response?.data?.error?.redirect) ||
        (err.response?.status === 404 && !err.response?.data?.error?.redirect)
      ) {
        history.push("/shop");
      }
      if (err.response?.data?.error?.redirect) {
        history.push("/shop");
      }
      console.log(err, "err")
    }
  };

  const addItemsInRecentlyViewed = () => {
    let local = sessionStorage.getItem("recently-viewed");

    const recentlyViewedCopy = [...JSON.parse(local)];

    const itemExist = recentlyViewedCopy.find(
      (item) => item === parseInt(productId)
    );

    if (!itemExist) {
      recentlyViewedCopy.push(parseInt(productId));
    }

    setRecentlyViewed(recentlyViewedCopy);
    sessionStorage.setItem(
      "recently-viewed",
      JSON.stringify(recentlyViewedCopy)
    );
  };

  return (
    <div className="ProductPage">
      <Seo title={seoTitleTag} description={seoDescription} product={product} />
      <div className="ProductPage__content">
        <ProductStory productIsLoading={productIsLoading} product={product} />
        <ProductDetails
          roasterId={roasterId}
          purveyorSlug={purveyorSlug}
          productIsLoading={productIsLoading}
          setProductIsLoading={setProductIsLoading}
          refetchProduct={() => getProduct()}
          product={product}
          productSize={product?.weight}
        />
      </div>
      <div className="ProductPage__line">
        <Line />
        <Line style={{ marginBottom: "0px" }} />
      </div>
      {!isMobile && !productIsLoading && (
        <RoasterArticle
          purveyor={purveyor}
          product={product}
          productIsLoading={productIsLoading}
        />
      )}
      <div>
        {product.similar_coffees && (
          <SimilarCoffees
            productIsLoading={productIsLoading}
            product={product}
          />
        )}
      </div>
      <div>
        <Coffe101 />
      </div>
    </div>
  );
};

export default ProductPage;
