import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import Product from "../../Shop/Product/Product";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";

import "./PeaberryRecommendedCoffees.scss";

const PeaberryRecommendedCoffees = () => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(3);

  useEffect(() => {
    API.getProducts().then((res) => setProducts(res.data));

    API.getRecommendations();
  }, []);

  return (
    <div className="PeaberryRecommendedCoffees">
      <MiddleLine />
      <div className="PeaberryRecommendedCoffees__title font-cairo">
        PEABERRY'S RECOMMENDED COFFEES
      </div>
      <div className="PeaberryRecommendedCoffees__products">
        {products.slice(0, visibleProducts).map((product) => (
          <Product product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
};

export default PeaberryRecommendedCoffees;
