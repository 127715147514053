export const REGIONS_ITEMS = [
  {
    name: "COFFEE REGIONS",
    slug: "coffee-regions",
  },
  {
    name: "CENTRAL AMERICA & MEXICO",
    slug: "central-america-mexico",
  },
  {
    name: "CARIBBEAN",
    slug: "caribbean",
  },
  {
    name: "SOUTH AMERICA",
    slug: "south-america",
  },
  {
    name: "AFRICA & MIDDLE EAST",
    slug: "africa-middle-east",
  },
  {
    name: "ASIA & INDONESIA",
    slug: "asia-indonesia",
  },
  {
    name: "HAWAII",
    slug: "hawaii",
  },
];
