import React, { useContext, createContext } from "react";

const CartContextValues = {
  isLoading: false,
  cartItems: undefined,
  specialMessages: undefined,
  getCartItems: () => {},
  updateSpecialMessages: () => {},
  getShipments: () => {},
  getCartSuggestions: () => {},
  clearCartItems: () => {},
  getSavedItems: () => {},
  updateCartSubtotal: () => {},
  toggleCartModal: () => {},
  hideCartModal: () => {},
  quantity: 0,
  cartSubTotal: 0,
  shipments: undefined,
  cartSuggestions: [],
  autoshipData: [],
  cartSuggestionsLoading: undefined,
  savedItems: [],
  savedItemsLoading: undefined,
  cartModalOpen: false,
  setOpenContactUsModal: () => {},
  openContactUsModal: true,
};

export const CartContext = createContext(CartContextValues);
export const useCartContext = () => useContext(CartContext);
