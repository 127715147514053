import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ArticleContainer from "../ArticleContainer/ArticleContainer";

import { REGIONS_ITEMS } from "../../../api/RegionsItems";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";
import Seo from "../../../lib/helpers/SEO/Seo";

import CaribBean from "./RegionsItems/CaribBean";
import CoffeRegions from "./RegionsItems/CoffeeRegions";
import CentralAmericaMexico from "./RegionsItems/CentralAmericaMexico";
import SouthAmerika from "./RegionsItems/SouthAmerika";
import AfricaMiddleEast from "./RegionsItems/AfricaMiddleEast";
import AsiaIndonesia from "./RegionsItems/AsiaIndonesia";
import Hawaii from "./RegionsItems/Hawaii";

import cs from "classnames";

import "./Regions.scss";

const Regions = () => {
  const history = useHistory();
  const seoCtx = useSeoContext();

  const onItemClick = (slug) => {
    history.push(`/learn/category/regions/${slug}`);
    // document.getElementById("Regions").scrollIntoView({ behavior: "smooth" });
  };

  const params = useParams();
  const slug = params.pathParam3;

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === `/professor-peaberry/regions/${slug}`
  );

  return (
    <ArticleContainer shapeStyle={{ background: "#FF8900" }} title="Regions">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <div id="Regions" className="Regions">
        <div className="RegionsItems">
          {REGIONS_ITEMS.map((item, index) => (
            <div
              key={`item--${index}`}
              onClick={() => onItemClick(item.slug)}
              className="RegionsItems__item"
            >
              <h2
                className={cs(
                  "RegionsItems__item-title",
                  params.pathParam3 === item.slug &&
                    "RegionsItems__item-title--active"
                )}
              >
                {item.name}
              </h2>
            </div>
          ))}
        </div>
        <div className="RegionsItems__content">
          {slug?.includes("coffee-regions") && <CoffeRegions />}
          {slug?.includes("central-america-mexico") && <CentralAmericaMexico />}
          {slug?.includes("caribbean") && <CaribBean />}
          {slug?.includes("south-america") && <SouthAmerika />}
          {slug?.includes("africa-middle-east") && <AfricaMiddleEast />}
          {slug?.includes("asia-indonesia") && <AsiaIndonesia />}
          {slug?.includes("hawaii") && <Hawaii />}
        </div>
      </div>
    </ArticleContainer>
  );
};

export default Regions;
