import NewsletterForm from "../../components/Newsletter/NewsletterForm/NewsletterForm";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import "./Newsletter.scss";

const Newsletter = () => {
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/newsletter/sign-up"
  );

  return (
    <div className="Newsletter">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        title="NEWSLETTER SIGN UP"
        className="Newsletter__main-title"
      />
      <div className="Newsletter__container">
        <div className="Newsletter__title">
          Sign-up Today for our twice a month Newsletter and receive $5.00 off
          your first purchase!
        </div>
        <div className="Newsletter__wrap">
          <div className="Newsletter__text">
            You'll be the first to know about exclusive super deals, new coffee
            arrivals, the latest coffee reviews, as well as learn about coffee
            history, coffee fun facts, brewing tips and more.
          </div>
          <NewsletterForm />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
