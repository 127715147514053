import { Link } from "react-router-dom";
import "./ManageMyAccount.scss";

const ManageMyAccount = () => {
  return (
    <div className="ManageMyAccount">
      <h2 className="ManageMyAccount__title">Manage my account</h2>
      <ul className="ManageMyAccount__links">
        <Link to={{ pathname: "/account/change-info" }}>
          <li className="ManageMyAccount__link">
            Change my profile (email & password)
          </li>
        </Link>
        <Link to="/account/address-book">
          <li className="ManageMyAccount__link">Manage my address book</li>
        </Link>
        <Link to="/account/credit-cards">
          <li className="ManageMyAccount__link">Manage my credit cards</li>
        </Link>
        <Link to="/account/coffee-club">
          <li className="ManageMyAccount__link">Manage my coffee clubs</li>
        </Link>
        <Link to="/account/email-preferences">
          <li className="ManageMyAccount__link">Manage my email preferences</li>
        </Link>

        <Link to="/account/check-balance">
          <li className="ManageMyAccount__link">
            View gift card / gift certificate balance
          </li>
        </Link>
        <Link to="/account/redeem">
          <li className="ManageMyAccount__link">
            Redeem gift card / gift certificate
          </li>
        </Link>
        <Link to="/contact-us">
          <li className="ManageMyAccount__link">Contact us</li>
        </Link>
        {/*  <Link to= "/account/gift-subscriptions" }}>
          <li className="ManageMyAccount__link">Manage gift subscription</li>
          </Link> */}
      </ul>
    </div>
  );
};

export default ManageMyAccount;
