import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import MusicTrack from "./MusicTrack/MusicTrack";

import "./MusicPlayer.scss";

const MusicPlayer = () => {
  const [tracks, setTracks] = useState([]);
  const [trackIndex, setTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  //const Song = "https://assets.gocoffeego.com/v2/music/audio/WhoAreYou.mp3";

  useEffect(() => {
    API.getMusic().then((res) => setTracks(res.data));
  }, []);

  const onPrevClick = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const onNextClick = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  const onPlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div id="AudioPlayer" className="MusicPlayer">
      {tracks && tracks.length !== 0 ? (
        <MusicTrack
          key={tracks[trackIndex]?.id}
          artist={tracks[trackIndex]?.artist}
          title={tracks[trackIndex]?.title}
          song={tracks[trackIndex]?.song}
          isPlaying={isPlaying}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          onPlayPauseClick={onPlayPauseClick}
        />
      ) : null}
    </div>
  );
};

export default MusicPlayer;
