import cs from "classnames";

import "./ViewMoreButton.scss";

const ViewMoreButton = (props) => {
  const { handleClick, style, className, label } = props;
  return (
    <div className="ViewMoreButton">
      <button
        style={style}
        className={cs("ViewMoreButton__button", className)}
        onClick={handleClick}
      >
        {label ? label : "View More"}
      </button>
    </div>
  );
};

export default ViewMoreButton;
