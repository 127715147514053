import React from "react";
import { Helmet } from "react-helmet";

const Seo = (props) => {
  const { title, description, meta, keywords, url, product, roaster, recipe, coffeeFaq, article } = props;

  let productJson = {};
  let roasterJson = {};
  let recipeJson = {};
  let coffeeFaqJson = {};
  let articleJson = {};

  if(product) {
    const price = product?.sale?.active && product?.sale_price ? product?.sale_price?.toFixed(2) : product?.retail_price?.toFixed(2);
    const date = new Date();
    date.setDate(date.getDate() + 7);
    const weekFromNow = date.toISOString();

    productJson = {
      "@context": "http://schema.org/",
      "@type": "Product",
      "id": product?.seo?.url,
      "category": "Coffee",
      "brand": product?.purveyor?.name,
      "name": product?.name,
      "image": product?.main_image?.lg,
      "logo": product?.main_image?.lg,
      "url": product?.seo?.url || product?.url,
      "description": product?.seo?.seo_meta_description || product?.description,
      "sku": product?.id,
      "mpn": product?.id,
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": price,
        "priceValidUntil": weekFromNow,
        "itemCondition": "http://schema.org/NewCondition",
        "availability": "http://schema.org/InStock",
        "url": product?.seo?.url,
        "seller": {
          "@type": "Organization",
          "name": product?.purveyor?.name
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "ratingCount": "1"
      },
      "review":[
        {
          "@type": "Review",
          "author": {
              "@type": "Organization",
              "name": "GoCoffeeGo"
          },
          "description": "",
          "name": "",
          "reviewRating": {
            "@type": "Rating",
            "bestRating": "5",
            "ratingValue": "5",
            "worstRating": "1"
          }
        }
      ]
    };
  }

  if(roaster) {
    roasterJson = {
      "@context": "https://schema.org/",
      "@type": "Brand",
      "subjectOf": "WebSite",
      "name": roaster.name,
      "image": roaster?.main_image?.md,
      "logo": roaster?.main_image?.md,
      "description": roaster?.description?.replace(/(<([^>]+)>)/gi, "").replace("[BREAK]", " ").substring(0, roaster?.description.length),
      "url": roaster?.url
    }
  }

  if(recipe) {
    recipeJson = {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      "name": recipe.name,
      "image": [
        recipe.image?.image_url
      ],
      "author": {
        "@type": "Person",
        "name": recipe.courtesy_of_name || "GoCoffeeGo"
      },
      "url": `https://www.gocoffeego.com/recipe/${recipe?.slug}-${recipe?.id}`,
      "datePublished": "2018-03-10",
      "description": recipe.description,
      "prepTime": recipe.prep_time,
      "cookTime": recipe.cook_time,
      "totalTime": recipe.total_time,
      "keywords": recipe.name,
      "recipeYield": recipe.yield,
      "recipeCategory": recipe?.category?.name,
      "recipeIngredient": recipe?.ingredients?.split("\n"),
      "recipeInstructions": recipe?.directions?.split("\n")?.map((item) => {
        return { 
          "@type": "HowToStep",
          "name": item
        }
      })
    };
  }

  if(coffeeFaq) {
    coffeeFaqJson = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "name": "Coffee FAQ",
      "url": "https://www.gocoffeego.com/coffee-faq",
      "mainEntity": coffeeFaq.map((item) => {
        return {
          "@type": "Question",
            "name": item?.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": item?.answer?.replace(/(\r\n|\r|\n)/g, '')
            }
        };
      })
    };
  }

  if(article) {
    articleJson = {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": article?.title,
      "image": article?.image,
      "keywords": article?.title,
      "author": {
          "@type": "Organization",
          "name": "GoCoffeeGo"
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": article?.url
      },
      "publisher": {
        "@type": "Organization",
        "name": "GoCoffeeGo",
        "logo": {
          "@type": "ImageObject",
          "url": "https://assets.gocoffeego.com/v2/assets/images/logo.png"
        }
      },
      "url": article?.url,
      "datePublished": article?.created_at || "2023-03-19",
      "dateCreated": article?.created_at || "2023-03-19",
      "dateModified": article?.created_at || "2023-03-19"
    };
    // console.log('article', article)
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
     
      {/*    <meta name="keywords" content={keywords}></meta> */}
      {url && <link rel="canonical" href={url} />}
      <meta
        property="og:site_name"
        content="GoCoffeeGo"
      />

      {!product && <meta property="og:title" content={title} />}

      {!product && <meta property="og:description" content={description} />}
      {!product && (
        <meta
          property="og:image"
          content="https://assets.gocoffeego.com/v2/assets/images/Trolley.png"
        />
      )}
      {product && <meta property="og:type" content="product" />}
      {product && <meta property="og:title" content={product?.name} />}
      {product && (
        <meta property="og:description" content={product?.description} />
      )}
      {product && <meta property="og:url" content={product?.url} />}
      {product && (
        <meta property="og:image" content={product?.main_image?.md} />
      )}
   
      {product && (
        <meta property="product:price:amount" content={product?.retail_price} />
      )}
      {product && <meta property="product:price:currency" content="USD" />}
      {product && <meta property="og:availability" content="instock" />}

      {!product && <meta property="twitter:card" content="summary_large_image" />}
      {!product && <meta property="twitter:site" content="https://www.gocoffeego.com/" />}
      {!product && <meta property="twitter:title" content={title} />}        
      {!product && <meta property="twitter:description" content={description} />}
      {!product && (
        <meta
          property="twitter:image"
          content="https://assets.gocoffeego.com/v2/assets/images/Trolley.png"
        />
      )}
      {product && <meta property="twitter:card" content="summary" />}
      {product && <meta property="twitter:site" content={product?.url} />}
      {product && <meta property="twitter:title" content={product?.name} />}
      {product && (
        <meta property="twitter:description" content={product?.description} />
      )}
      {product && (
        <meta property="twitter:image" content={product?.main_image?.sm} />
      )}

      {product && <script type="application/ld+json">{`${JSON.stringify(productJson)}`}</script>}
      {roaster && <script type="application/ld+json">{`${JSON.stringify(roasterJson)}`}</script>}
      {recipe && <script type="application/ld+json">{`${JSON.stringify(recipeJson)}`}</script>}
      {coffeeFaq && <script type="application/ld+json">{`${JSON.stringify(coffeeFaqJson)}`}</script>}
      {article && <script type="application/ld+json">{`${JSON.stringify(articleJson)}`}</script>}

    </Helmet>
  );
};

export default Seo;
