import { useFormik } from "formik";
import * as Yup from "yup";

const GuestSignUpSchema = Yup.object().shape({
  email: Yup.string()
    .required("Enter your email")
    .email("Enter a valid email address")
});

export const useGuestSignUpFormik = (opts) => {
  return useFormik({
    initialValues: {
      email: "",
      ...opts?.initialValues,
      GuestSignUpSchema,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: GuestSignUpSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
