import React, { useEffect } from "react";

import FlickitySlider from "../../FlickitySlider/FlickitySlider";

import SectionTitle from "../SectionTitle/SectionTitle";
import ProductItem from "../ProductItem/ProductItem";
import Line from "../../shared/Line/Line";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { isEmpty } from "../../../lib/helpers/isEmpty";

import "./YouMayAlsoLike.scss";

const YouMayAlsoLike = () => {
  const cartCtx = useCartContext();
  const items = cartCtx.cartSuggestions;
  const isLoading = cartCtx.cartSuggestionsLoading;
  const cartId = localStorage.getItem("cart_id");

  useEffect(() => {
    cartCtx.getCartSuggestions();
  }, []);

  let options = {
    draggable: false,
    freeScroll: false,
    contain: true,
    groupCells: true,
    pageDots: false,
    prevNextButtons: true,
  };

  return (
    <div className="YouMayAlsoLike">
      <SectionTitle middleLine title="YOU MAY ALSO LIKE" />
      {(!isLoading && isEmpty(items)) || isEmpty(items.suggestions) ? (
        <p className="YouMayAlsoLike__no-items"> No suggested items </p>
      ) : (
        <div className="YouMayAlsoLike__content">
          <div className="YouMayAlsoLike__line" />
          <div className="YouMayAlsoLike__line" />
          <div className="YouMayAlsoLike__slider">
            <FlickitySlider options={options}>
              {!isLoading &&
                items.suggestions.map((item) => (
                  <ProductItem
                    key={item.id}
                    getCartSuggestions={() =>
                      cartCtx.getCartSuggestions(cartId)
                    }
                    id={item.id}
                    image={item.main_image.sm}
                    weight={item.weight}
                    price={item.retail_price}
                    title={item.name}
                    alsoLike
                  />
                ))}
            </FlickitySlider>
          </div>
        </div>
      )}
      <Line />
      <Line />
    </div>
  );
};

export default YouMayAlsoLike;
