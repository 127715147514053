import React, { useState } from "react";
import LinkButton from "../../shared/LinkButton/LinkButton";
import PrimaryTitle from "../../shared/PrimaryTitle/PrimaryTitle";
import Button from "../../shared/Button/Button";

import "./MyGiftSubscriptions.scss";
import MySubscriptionsPlan from "./MySubscriptionPlan/MySubscriptionPlan";

const MyGiftSubscriptions = () => {
  const [openPlan, setOpenPlan] = useState(false);
  const JetSetTour =
    "https://assets.gocoffeego.com/v2/assets/images/jetset-tour.png";
  return (
    <div className="MyGiftSubscriptions">
      <PrimaryTitle title="GIFT SUBSCRIPTION TOURS" />
      {!openPlan ? (
        <div className="MyGiftSubscriptions__container">
          <div className="MyGiftSubscriptions__wrap">
            <div className="MyGiftSubscriptions__shipment">
              <div className="MyGiftSubscriptions__shipment-info">
                Next shipment processes on
                <div className="MyGiftSubscriptions__shipment-info-date">
                  Tuesday, April 20, 2021{" "}
                </div>
              </div>
            </div>
            <div className="MyGiftSubscriptions__tour">
              <img
                className="MyGiftSubscriptions__image"
                src={JetSetTour}
                alt="name"
              />
              <div className="MyGiftSubscriptions__tour-name">Jet Set Tour</div>
              <div className="MyGiftSubscriptions__plan">
                Premium Single Origin & Micro-lots
              </div>
              <div className="MyGiftSubscriptions__plan-coffees">
                2 Coffees a month
              </div>
            </div>
            <div className="MyGiftSubscriptions__buttons">
              <Button
                label="Change Plan"
                className="MyGiftSubscriptions__btn"
                onClick={() => setOpenPlan(!openPlan)}
              />
              <Button
                label="Pause the Club"
                className="MyGiftSubscriptions__btn"
              />
              <Button
                label="Leave the Club"
                className="MyGiftSubscriptions__btn"
              />
            </div>
          </div>
          <div className="MyGiftSubscriptions__info">
            <div className="MyGiftSubscriptions__info-text">
              Total: 2 coffees a month ($24.00 - $38.00) Plus $2.00 per Shipment{" "}
              <br />
              You are 11 shipments away from qualifying for your Coffee Club
              reward: 50% Off the Coffee! <br />
              Supreme Coffee Plan - 2 Coffees per shipment
            </div>
          </div>
        </div>
      ) : (
        <MySubscriptionsPlan setOpenPlan={setOpenPlan} open={openPlan} />
      )}

      {/*  <div className="MyGiftSubscriptions__default-container">
      <h1 className="MyGiftSubscriptions__title"> You haven't joined any Coffee Tours yet </h1>
      <LinkButton to="/subscription-tours" label="Find a Coffee Tour" className="MyGiftSubscriptions__button"/>
      </div>   */}
    </div>
  );
};

export default MyGiftSubscriptions;
