export function ShippingMessaging() {
    return {
        bags: {
            one: "$5.95",
            two: "$7.55",
            three: "$7.95",
            four: "$9.95",
            fivePlus: "FREE shipping"
        },
        bulkMessage: {
            lineOne: "Ship one 2lb bag for just $7.55.",
            lineTwo: "Any additional bulk bag - regardless of size - ships for $9.95! No limit!",
            fiveOrMore: "FREE shipping on 5 bags or more (in any size or combination) from an individual Roaster."
        }
    };
}
