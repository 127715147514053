import React, { useState } from "react";

import cs from "classnames";
import "./Collapsible.scss";

const Collapsible = (props) => {
  const { children, name } = props;
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div className="Collapsible">
      <div
        className={cs("Collapsible__wrap", openFilter && "open")}
        onClick={() => setOpenFilter(!openFilter)}
      >
        <div className="Collapsible__name">{name}</div>
        <div className={cs("Collapsible__close", openFilter && "open")} />
      </div>
      <div
        className={cs("Collapsible__content", openFilter && "open__content")}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
