import React, { useState, useEffect } from "react";
import QuizQuestionTitle from "../QuizQuestionTitle/QuizQuestionTitle";
import QuizLines from "../QuizLines/QuizLines";

import cs from "classnames";
import "./QuizQuestion5.scss";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import { noQuizRecommendations } from "../../../lib/helpers/messages";
import { QUIZ_QUESTIONS } from "../../../api/QuizQuestions";

const QuizQuestion5 = (props) => {
  const CarAndDog =
    "https://assets.gocoffeego.com/v2/assets/images/car-and-dog.svg";
  const { selectedAnswer, recommendations } = props;
  const [noPreference, setNoProference] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const msgCtx = useMessageContext();
  const question5 = QUIZ_QUESTIONS.filter((item) => item?.id === 5);

  const toggle = () => {
    if (recommendations?.length > 0) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    if (recommendations?.length > 0) {
      setPopoverOpen(false);
      if (msgCtx.isOpen) {
        msgCtx.toggle();
      }
    }
    if (recommendations?.length === 0) {
      setPopoverOpen(true);
      if (popoverOpen) {
        msgCtx.handleWarningMessage(noQuizRecommendations);
      }
    }
  }, [recommendations]);

  return (
    <div id="question-5" className="QuizQuestion5">
      <QuizQuestionTitle
        questionNumber={5}
        question={"Which flavor notes do you prefer?"}
        id="Popover"
      />

      <div className="QuizQuestion5__answers">
        {question5[0].answerOptions.map((item) => (
          <div
            key={item.id}
            className={cs(
              "QuizQuestion5__answer",
              selectedAnswer === item.id &&
                !noPreference &&
                "QuizQuestion5__answer--active"
            )}
            onClick={() => {
              props.onItemSelect(item.id);
              setNoProference();
              if (recommendations?.length === 0) {
                setPopoverOpen(true);
              }
            }}
          >
            <div className="QuizQuestion5__answer-title">
              {" "}
              {item?.answerTitle}
            </div>
            <p className="QuizQuestion5__description"> {item?.answerText}</p>
          </div>
        ))}
        <div
          className={cs(
            "QuizQuestion5__preferences",
            noPreference === 2234 && "QuizQuestion5__preferences--active"
          )}
          onClick={() => {
            props.onItemSelect(2234);
            setNoProference(2234);
          }}
        >
          NO PREFERENCES
        </div>
      </div>

      <div className="QuizQuestion5__lines">
        <img className="QuizQuestion5__car" src={CarAndDog} alt="car" />
        <QuizLines className="QuizQuestion5__lines-style" />
      </div>
    </div>
  );
};

export default QuizQuestion5;
