import { apiRequest } from "./Api";

export const getPaymentIntent = () => {
  return apiRequest("get", "checkout/stripe/payment_intent");
};

export const getPaymentIntentApplePay = () => {
  return apiRequest("get", "checkout/stripe/payment_intent?apple_pay=1");
};

export const postPayment = async (data) => {
  return apiRequest("post", "checkout/confirm/stripe_place_order", data);
};

export const getShipping = async () => {
  return apiRequest("get", "checkout/confirm/cart_totals?country=US");
};
