import React from "react";

import Item from "./Item/Item";
import Line from "../../shared/Line/Line";

import SectionTitle from "../../Cart/SectionTitle/SectionTitle";
import QuizButton from "../../shared/QuizButton/QuizButton";

import "./HowSubscriptionsWork.scss";

const HowSubscriptionsWork = () => {
  const Icon1 =
    "https://assets.gocoffeego.com/v2/assets/icons/subscribe-icon-1.svg";
  const Icon2 =
    "https://assets.gocoffeego.com/v2/assets/icons/subscribe-icon-2.svg";
  const Icon3 =
    "https://assets.gocoffeego.com/v2/assets/icons/subscribe-icon-3.svg";
  return (
    <div className="HowSubscriptionsWork">
      <div className="HowSubscriptionsWork__content">
        <SectionTitle
          titleClassName="HowSubscriptionsWork__section-title"
          middleLine
          title="How it works"
        />
        <div className="HowSubscriptionsWork__items">
          <Item
            image={Icon1}
            title="1."
            description="Either Pay-as-you-go or Pre-pay for a set number of months"
          />
          <Item
            image={Icon2}
            title="2."
            description="Explore the Clubs and Tours to find out who you really are "
          />
          <Item
            image={Icon3}
            title="3."
            description="enjoy the freshest coffee delivered straight from the roaster to you"
          />
        </div>
      </div>

      <div className="HowSubscriptionsWork__line">
        <QuizButton className="HowSubscriptionsWork__quiz" />
        <Line />
        <Line />
      </div>
    </div>
  );
};

export default HowSubscriptionsWork;
