import LinkButton from "../../shared/LinkButton/LinkButton";
import { useHistory } from "react-router-dom";
import PopUp from "../../shared/PopUp/PopUp";

import "./Product.scss";

const Product = (props) => {
  const { product, topNumber } = props;

  const history = useHistory();

  const PinterestIcon =
    "https://assets.gocoffeego.com/v2/assets/icons/pinterest-icon.svg";
  const location2 = {
    pathname: `/roaster/${product?.purveyor?.slug}-${product?.purveyor?.id}`,
    state: { purveyorId: product?.purveyor?.id },
  };

  const coffeeReviewBadge = product.coffee_review_image;

  return (
    <div id="Product" className="Product" key={product.id}>
      <div className="Product__container">
        <PopUp product={product} />
        <div className="Product__wrap">
          {coffeeReviewBadge && (
            <div className="Product__review">
              <img
                src={coffeeReviewBadge}
                alt="review badge"
                className="Product__review-badge"
              />
            </div>
          )}
          <a
            className="Product__pinterest"
            target="_blank"
            rel="noreferrer"
            data-pin-do="buttonPin"
            data-pin-custom
            data-pin-tall="true"
            data-pin-round="true"
            data-pin-description={`${product.name} ${product?.description}`}
            data-pin-url={product?.url}
            href={`https://www.pinterest.com/pin/create/button/?url=${
              product.url
            }${`&description=${product.name}`}${`&media=${product.main_image?.lg}`}`}
          >
            <img
              src={PinterestIcon}
              alt="pinterest"
              className="Product__pinterest-icon"
            />
          </a>
          <div className="Product__label-wrap">
            <div className="Product__label-black-background" />
            {topNumber ? (
              <div className="Product__label-top-number">{topNumber}</div>
            ) : null}
            {product.new_arrival ? (
              <div className="Product__label-new">{product.new_arrival}</div>
            ) : null}
            {product.discount ? (
              <div className="Product__label-sale">
                <div className="Product__label-sale-wrap">
                  Save{" "}
                  <span className="Product__label-sale-procent">
                    {product.discount}%
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          <img
            onClick={() => history.push(`/${product.product_url}`)}
            className="Product__image"
            src={product.main_image?.md}
            alt={product.name}
          />
        </div>
        <div className="Product__text-wrap">
          <div className="Product__title">
            {product.coffee_notes && (
              <div
                className={product.coffee_notes ? "Product__coffee-body" : null}
              >
                {product.coffee_notes}
              </div>
            )}
          </div>
          <div
            className="Product__purveyor"
            onClick={() => history.push(location2)}
          >
            {product.purveyor.name}
          </div>
          <div
            className="Product__desc"
            onClick={() => history.push(`/${product.product_url}`)}
          >
            {product.name}{" "}
            <div>
              {product.coffee_types.length !== 0
                ? `(${product.coffee_types.map((type) => type)})`
                : ""}
            </div>
          </div>
          <div className="Product__size-wrap">
            <div className="Product__size">{product.coffee_roast}</div>
            <div className="Product__price">
              <span
                className={
                  product?.sale.active
                    ? "Product__retail-price--sale"
                    : "Product__retail-price"
                }
              >
                {" "}
                ${product.retail_price.toFixed(2)}
              </span>
              {product?.sale.active && product?.sale_price && (
                <span className="Product__sale-price">
                  {" "}
                  ${product?.sale_price.toFixed(2)}
                </span>
              )}
              <span> / {product?.weight} </span>
            </div>

            {product?.awards && (
              <div className="Product__award-text">
                {product?.awards?.length > 150
                  ? `${product?.awards.substring(0, 155)} ...`
                  : product?.awards}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Product__button">
        <LinkButton
          path={`/${product.product_url}`}
          className="Product__button-label"
          label="View Product"
        />
      </div>
    </div>
  );
};

export default Product;
