import React from "react";

import EspressoItem from "./EspressoItem/EspressoItem";
import Seo from "../../../lib/helpers/SEO/Seo";

import ArticleContainer from "../ArticleContainer/ArticleContainer";

import "./EsspressoDrinks.scss";

const EsspressoDrinks = () => {
  const Ristretto =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/espresso-ristretto.png";
  const SingleShot =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/espresso-single-shot.png";
  const Lungo =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/espresso-lungo.png";
  const DoubleShot =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/espresso-double-shot.png";
  const Macchiato =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/machiatto.png";
  const Pana =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/espresso-con-panna.png";
  const CafeBreve =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/cafe-breve.png";
  const Cappuccino =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/cappuccino.png";
  const CaffeLatte =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/cafe-latte.png";
  const CafeAmericano =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/cafe-americano.png";
  const FlavoredEspresso =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/flavored-espresso.png";
  const IcedCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/espresso/iced-coffee-espresso.png";

  const article = {
    title: "Types of Espresso Drinks: Espresso vs. Latte vs. Cappuccino",
    image: "https://assets.gocoffeego.com/v2/assets/images/espresso-drinks.png",
    url: "https://www.gocoffeego.com/learn/category/espresso-drinks"
  };

  return (
    <ArticleContainer
      shapeStyle={{ background: "#00D1D6" }}
      title="Espresso drinks"
    >
      <Seo
        title="Types of Espresso Drinks: Espresso vs. Latte vs. Cappuccino"
        description="What is an espresso, anyway? Visit GoCoffeeGo to learn more about the types of espresso drinks, including the espresso, café latte, and cappuccino."
        keywords="iced espresso coffee, how to make an espresso, make a latte, make a moka pot, how to make coffee"
        article={article}
      />
      <div id="EspressoDrinks" className="EspressoDrinks">
        {/*<img*/}
        {/*  src={Esspresso}*/}
        {/*  alt="roasting-bg"*/}
        {/*  className="Roasting__main-image"*/}
        {/*/>*/}
        <p className="EspressoDrinks__description">
          In its pure form, espresso is more popular in Europe than America,
          particularly in Italy. Though catching in America, derivatives and
          misconceptions are spreading like cream in coffee. For instance, many
          hold the misconception that espresso is a dark, bitter to
          burnt-flavored roast of coffee.
        </p>

        <p className="EspressoDrinks__description">
          In fact, espresso is not a roast at all; it is a method of preparing
          coffee. Espresso coffee is often blended from several roasts and
          varietals to form a bold - not bitter flavor. The finely ground coffee
          is tightly packed or tamped into a “portafilter”; high-pressure water
          is then forced through the grounds and extracted in small,
          concentrated amounts. Intensity is the key here. Why do you think they
          call it a "shot?"
        </p>
        <p className="EspressoDrinks__description">
          It's now time to learn about the different types of espresso drinks,
          so you can distinguish between espresso, latte, and cappuccino.
        </p>

        <h2 className="EspressoDrinks__title">Pure Espresso</h2>
        <p className="EspressoDrinks__description">
          This is the intense experience of coffee that most Europeans prefer
          and believe Americans are too scared to try. Proper Espresso is served
          in small demitasse-style cups and consumed promptly after extraction
          in the following types of servings:
        </p>
        <div className="Espressos">
          <EspressoItem
            title="Ristretto"
            description="The “short shot” is the first 3/4 -ounce of espresso in an extraction, which many believe is the absolute perfect espresso."
            image={Ristretto}
          />
          <EspressoItem
            title="Single Shot"
            description="A 1-ounce shot of espresso."
            image={SingleShot}
          />
          <EspressoItem
            title="Lungo"
            description="Otherwise known as the “long shot”, this is a 1/2 -ounce shot of espresso."
            image={Lungo}
          />
          <EspressoItem
            title="Double Shot"
            description="This is not merely a 2-ounce shot of espresso; this shot uses twice the amount of coffee in the portafilter, whereas the lesser shots use the same single serving."
            image={DoubleShot}
          />
        </div>
        <EspressoItem
          Item={true}
          title="Espresso Macchiato"
          description="Despite Starbucks’ popularization of the term Macchiato as a brand name, this is a very simple drink devoid of the flavored caramel and chocolate treatment better suited to an ice-cream parlor. It is simply a shot of espresso with a layer of foamed milk"
          image={Macchiato}
        />
        <EspressoItem
          Item={true}
          reverseItem={true}
          title="Espresso con Panna"
          description="A shot of espresso with a layer of whipped cream. "
          image={Pana}
        />
        <EspressoItem
          Item={true}
          title="Café Breve"
          description="A shot of espresso with steamed half and half, a.k.a. light cream."
          image={CafeBreve}
        />
        <EspressoItem
          Item={true}
          reverseItem={true}
          title="Cappuccino"
          description="Another drink warped by misconceptions! Cappuccino, named for its similarity in color to the robes of Capuchin monks, is simply a shot of espresso with steamed, wet milk, not necessarily slathered with a frothy, dry foam."
          image={Cappuccino}
        />
        <EspressoItem
          Item={true}
          title="Café Latte"
          description="This is very popular drink in America probably due to its sweet, mellow flavor. One shot of espresso is mixed with 6 to 8 ounces of steamed milk, then topped with foam - if you prefer. Without the foam it’s officially known as a Flat White. Since it’s hard to find a latte in the super-sized United States smaller than 12 ounces, a double shot of espresso is common. If you prefer greater amperage via caffeine, up the number of shots! <br> With a few minor variations, this also goes by the name Café con Leche or Café Au Lait, depending on whether your coffee spirit is channeling Spanish or French."
          image={CaffeLatte}
        />
        <EspressoItem
          Item={true}
          reverseItem={true}
          title="Café Americano"
          description="This is essentially a watered-down shot of espresso with the resulting flavor arriving very close to simple, brewed coffee. One espresso shot (1 ounce) with 6-8 ounces of hot water."
          image={CafeAmericano}
        />

        <EspressoItem
          Item={true}
          title="Flavored Espresso Drinks"
          description="These are essentially the same drinks listed above with flavored syrups added somewhere in the process. For instance, Café Mocha is simply a latte with chocolate syrup added with the steamed milk."
          image={FlavoredEspresso}
        />

        <EspressoItem
          Item={true}
          reverseItem={true}
          title="Iced Coffee"
          description="Like Sasquatch and Yeti, the perfect iced coffee is very elusive. Coffee with ice cubes makes for watery, cold coffee. For delicious iced espresso coffee, begin with a strong brew - stronger than you would normally brew hot. Brew it strong. You can double brew by pouring hot coffee back onto fresh grinds - like pouring the coffee back into the coffee maker and brewing again. Add sugar or spices like cardamom before chilling so they dissolve thoroughly. You can add ice then, but it’s best to chill in the refrigerator for a few hours or even overnight so the ice doesn’t melt so fast.
   Once chilled, pour over ice and mix with whole milk or, even better, half and half, to taste. Favorite syrups, like chocolate for an iced mocha, can go in to the mix now. Just be sure to use all of that energy or an afternoon workout."
          image={IcedCoffee}
        />
      </div>
    </ArticleContainer>
  );
};

export default EsspressoDrinks;
