import React from "react";

import { MORE_COFFEES } from "../../../api/MoreCoffees";

import Line from "../../shared/Line/Line";
import SectionTitle from "../../Cart/SectionTitle/SectionTitle";
import RecommendationItem from "../../Home/RecommendationItem/RecommendationItem";

import "./MoreCoffees.scss";

const MoreCoffees = () => {
  return (
    <div className="MoreCoffees">
      <div className="MoreCoffees__main">
    </div>
      <Line className="MoreCoffees__line" />
      <div className="MoreCoffees__content">
        <SectionTitle
          titleClassName="MoreCoffees__section-title"
          className="MoreCoffees__section"
          middleLine
          white
          title="CONTINUE TO ADD COFFEES"
        />

        <div className="MoreCoffees__items">
          {MORE_COFFEES.map((item) => (
            <RecommendationItem
              key={item.id}
              image={item.image}
              title={item.title}
              category={item.category}
              categoryId={item.category_id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreCoffees;
