import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

import "./SelectBox2.scss";

let cs = require("classnames");

const SelectBox2 = (props) => {
  const { items, selectedItem, className, className2, shipment } = props;

  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (name, id) => {
    props.onItemSelect(name, id);
    setShowItems(!showItems);
  };

  return (
    <div
      ref={dropDownRef}
      className={cs(
        "SelectBox2",
        className,
        shipment && "SelectBox2--shipment"
      )}
    >
      <div
        onClick={() => setShowItems(!showItems)}
        className="SelectBox2__container"
      >
        <div className="SelectBox2__header">{selectedItem}</div>
        <div className="SelectBox2__icon">
          {items.length > 1 && <img className="SelectBox2__icon" src={Arrow} />}
        </div>
      </div>

      {items.length > 1 && (
        <div
          className={cs("SelectBox2__items", showItems && "active", className2)}
        >
          {items?.map((item) => {
            return (
              <div
                onClick={() => onItemSelect(item.name, item.id)}
                className={`SelectBox2__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectBox2__item">{item.name}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectBox2;
