import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ShippingMessaging } from "../../../lib/helpers/ShippingMessaging";

import { Icon } from "../../shared/Icon/Icon";
import "./AutoShipInfoModal.scss";

const AutoShipInfoModal = (props) => {
  const shippingMessage = ShippingMessaging();
  const addToCartBtn =
    "https://assets.gocoffeego.com/v2/assets/images/add-to-cart-btn.png";
  const addToAutoShipBtn =
    "https://assets.gocoffeego.com/v2/assets/images/add-to-autoship-btn.png";

  const chooseFrequency =
    "https://assets.gocoffeego.com/v2/assets/images/choose-frequency.png";

  const letsGo =
    "https://assets.gocoffeego.com/v2/assets/images/lets-go-btn.png";
  const holdBtn = "https://assets.gocoffeego.com/v2/assets/images/hold-btn.png";
  const holdUntilDate =
    "https://assets.gocoffeego.com/v2/assets/images/hold-until-date.png";
  const holdIndefinitely =
    "https://assets.gocoffeego.com/v2/assets/images/hold-indefinitely.png";
  const startDate =
    "https://assets.gocoffeego.com/v2/assets/images/Start-date.png";

  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="AutoShipInfoModal">
        <div className="AutoShipInfoModal__container">
          <div className="AutoShipInfoModal__header">
            <h1 className="AutoShipInfoModal__header-title">
              How to Auto-Ship
            </h1>
            <Icon
              style={{ width: "20px", height: "20px" }}
              onClick={props.toggle}
              className="AutoShipInfoModal__close"
              icon="close"
            />
          </div>
          <div className="AutoShipInfoModal__body">
            <div className="AutoShipInfoModal__list">
              <ol>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How does Auto-Ship work, you ask?
                  </h2>
                  <div>
                    <p>
                      It's easy and fun to use. You may add, delete or modify
                      the selections in your lineup at any time prior to order
                      processing.
                    </p>
                    <h3 className="AutoShipInfoModal__list-subtitle">
                      Step 1.
                    </h3>
                    <p>
                      As you shop, add an unlimited amount of coffee selections
                      to your Auto-Ship by choosing the{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={addToAutoShipBtn}
                        alt="Add to auto-ship"
                      />
                      button, instead of{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={addToCartBtn}
                        alt="Add to cart"
                      />
                      . You only will be charged when your coffee order is in
                      process to be shipped.
                    </p>
                    <h3 className="AutoShipInfoModal__list-subtitle">
                      Step 2.
                    </h3>
                    <p>
                      On the Auto-Ship page, select the frequency of your coffee
                      shipment using the{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={chooseFrequency}
                        alt="choose Frequency"
                      />
                      drop-down menu, then select the{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={startDate}
                        alt="start date"
                      />
                      of Auto-Ship from the pop-up calendar.
                    </p>
                    <h3 className="AutoShipInfoModal__list-subtitle">
                      Step 3.
                    </h3>
                    <p className="AutoShipInfoModal__list-text">
                      To start Auto-Ship:
                    </p>
                    <p>
                      Click{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={letsGo}
                        alt="lets go button"
                      />
                      . Your coffee ships in the exact order in your Auto-Ship,
                      and then… Your list conveniently repeats over and over
                      again.
                    </p>
                    <h3 className="AutoShipInfoModal__list-subtitle">
                      Step 4.
                    </h3>
                    <p className="AutoShipInfoModal__list-text">
                      To stop or temporarily suspend Auto-Ship:
                    </p>
                    <p>
                      Choose Option,{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={holdIndefinitely}
                        alt="hold indefinitely"
                      />
                      , or{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={holdUntilDate}
                        alt="hold until date"
                      />
                      , select a date from the pop-up calendar when to resume
                      your Auto-Ship, then click{" "}
                      <img
                        className="AutoShipInfoModal__list-image"
                        src={holdBtn}
                        alt="hold button"
                      />
                      . Your shipments are completely stopped, or will start
                      again from the date you choose.
                    </p>
                    <p>It's a simple as that!</p>
                  </div>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    When will my credit card be charged?
                  </h2>
                  <p>
                    You only will be charged when your coffee order is in
                    process to be shipped, not before. You pay as you go. It's
                    as simple as that.
                  </p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How does the shipping work and what's the cost?
                  </h2>
                  <div>
                    <p>
                      We ship to everywhere in the United States, as well as to
                      the US Military Worldwide and all US Territories. USPS
                      Priority Delivery to your home, office or P.O. Box is
                      usually 1 to 3 days from pick-up. USPS delivers six days a
                      week, including Saturday!
                    </p>
                    <p>
                      To ensure the highest quality coffee, all orders are fresh
                      roasted to order and shipped direct from the Roaster to
                      you. A separate shipping charge applies from each
                      individual Roaster.
                    </p>
                    <br />

                    <p>1 Bag = {shippingMessage.bags.one}</p>
                    <p>2 Bags = {shippingMessage.bags.two}</p>
                    <p>3 Bags = {shippingMessage.bags.three}</p>
                    <p>4 Bags = {shippingMessage.bags.four}</p>
                    <p>5+ Bags = {shippingMessage.bags.fivePlus}</p>
                    <br />
                    <p>
                      Bulk Coffee Bags: <br /> 
                      {shippingMessage.bulkMessage.lineOne} <br />
                      {shippingMessage.bulkMessage.lineTwo}
                    </p>
                    <p>
                      {shippingMessage.bulkMessage.fiveOrMore}
                    </p>
                  </div>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    Can international customers use Auto-Ship?
                  </h2>
                  <p>Unfortunately, not.</p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    Can I use a Gift Card or Gift Certificate for Auto-Ship?
                  </h2>
                  <p>
                    You sure can. Just redeem your Gift Card or Gift
                    Certificate. Go to the "My Account", then click on "Redeem
                    Gift Card / Gift Certificate" and enter your code. These
                    funds will be credited to your account at GoCoffeeGo. Once
                    all used up, the Credit Card you dedicated for Auto-Ship
                    will be charged in order to keep your Auto-Ship on-going.
                  </p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How do I move a shipment to the top position of the list?
                  </h2>
                  <p>Click on, grab it and drag to position number 1. Volia!</p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How do I move an entire shipment up or down in my line-up?
                  </h2>
                  <p>
                    Click on, grab it and drag to its new position. It's as easy
                    as that!
                  </p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How do I add more coffee selections to a particular
                    shipment?
                  </h2>
                  <p>
                    Click the "Rearrange Shipment" dropdown and choose in what
                    shipment to place a coffee bag. Go ahead, give it a whirl,
                    it's fun!
                  </p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How do I change the quantity of an item or grind?
                  </h2>
                  <p>
                    Adjust the number in the quantity box, or grind -- simple as
                    that.
                  </p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    How do I delete an item or items from Auto-Ship?
                  </h2>
                  <p>Check the “Remove Item” [x] to delete.</p>
                </li>
                <li className="AutoShipInfoModal__list-item">
                  <h2 className="AutoShipInfoModal__list-title">
                    What's the deal with Auto-Ship and Specials?
                  </h2>
                  <p>
                    Customers with Auto-Ship also enjoy the benefits of weekly
                    “Specials” and “promotions,” for free coffee, shipping, or
                    other goodies, if their order meets the requirements for
                    that specific roaster's promotion. No worry about missing
                    out with Auto-Ship. If it's in your queue and there is a
                    special, you get the deal.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AutoShipInfoModal;
