import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CartItem from "../../components/Cart/CartItem/CartItem";
import SavedItems from "../../components/Cart/SavedItems/SavedItems";
import RecentlyViewed from "../../components/Cart/RecentlyViewed/RecentlyViewed";
import YouMayAlsoLike from "../../components/Cart/YouMayAlsoLike/YouMayAlsoLike";
import Button from "../../components/shared/Button/Button";
import LinkButton from "../../components/shared/LinkButton/LinkButton";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import CartItemMobile from "../../components/Cart/CartItemMobile/CartItemMobile";
import ShippingInfoModal from "../../components/Modals/ShippingInfoModal/ShippingInfoModal";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { isEmpty } from "../../lib/helpers/isEmpty";
import { ShippingMessaging } from "../../lib/helpers/ShippingMessaging";

import "./Cart.scss";
import { useHistory } from "react-router-dom";

const Cart = () => {
  const ScooterGirl =
    "https://assets.gocoffeego.com/v2/assets/images/scooter-girl1.png";
  const isMobile = useResponsiveDimensions().isTablet;
  const AuthCtx = useAuthContext();
  const cartCtx = useCartContext();
  const cartItems = cartCtx.cartItems;
  const isLoading = cartCtx.isLoading;
  const history = useHistory();
  const pathname = history.location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const shippingMessage = ShippingMessaging();

  const profPeaberry =
    "https://assets.gocoffeego.com/v2/assets/images/prof-peaberry-with-a-cup-new.png";

  useEffect(() => {
    cartCtx.getCartItems();
  }, [pathname]);

  return (
    <div className="Cart">
      <PrimaryTitle title="SHOPPING CART" />

      <div className="Cart__content">
        <div className="Cart__banner">
          <div className="Cart__banner-wrapper">
            <div>
              <h2 className="Cart__banner-title">
                Checkout Below <br /> or <br /> Continue Shopping
              </h2>
              <div className="Cart__banner-text">Take me back to:</div>
              <ul className="Cart__banner-list">
                <Link to="/peaberry-recommends">
                  {" "}
                  <li>Professor Peaberry Recommends</li>{" "}
                </Link>
                <Link to="/account/coffee-club">
                  <li>My Coffee Clubs</li>
                </Link>
                <Link to="/roasters">
                  <li>All Roasters</li>
                </Link>
                <Link to="/top25">
                  <li>GoCoffeeGo Top 25</li>
                </Link>
              </ul>
            </div>
            <img
              src={profPeaberry}
              alt="profPeaberry"
              className="Cart__profPeaberry"
            />
          </div>
          <div className="Cart__description1">
            Ship one 12oz coffee from any individual roaster for just {shippingMessage.bags.one}!
            *USA only.
          </div>
          <div className="Cart__description2">
            To ensure the highest quality, all coffee is roasted to order and
            sent directly from the Roaster to you. A separate shipping charge
            applies from each Roaster. <br /> Buy more coffees to save on
            shipping!{" "}
            <span
              onClick={() => setIsOpen(!isOpen)}
              className="Cart__shipping-info"
            >
              Learn More About USA and International Shipping.
            </span>
          </div>
          <ShippingInfoModal
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
          />
        </div>
        {!isEmpty(cartItems.items) && (
          <>
            <h1 className="Cart__title">
              Cart {cartItems?.items && `(${cartItems?.items.length} items)`}
            </h1>

            <div className="Cart__container">
              {!isMobile ? (
                <div className="Items">
                  <div className="Items__row">
                    <div className="Items__menu">
                      <h1 className="Items__title">Item </h1>
                      <h1 className="Items__title">Brew Style </h1>
                      <h1 className="Items__title">Price</h1>
                      <h1 className="Items__title">Qty </h1>
                    </div>
                  </div>
                  {cartItems?.items?.map((item) => {
                    return (
                      <CartItem
                        isLoading={isLoading}
                        specialMessages={cartCtx.specialMessages}
                        item={item}
                        giftFrom={item.gift_from}
                        giftTo={item.gift_to}
                        giftMsg={item.gift_msg}
                        giftSubscription={item.gift_subscription}
                        giftRecipient={item.gift_recipient}
                        itemId={item.id}
                        id={item.id}
                        cart
                        key={item.id}
                        quantity={item.quantity}
                        coffee_grind={item?.coffee_grind}
                        product={item.product}
                      />
                    );
                  })}
                </div>
              ) : (
                <>
                  {cartItems?.items?.map((item) => (
                    <CartItemMobile
                      specialsMessages={cartCtx.specialMessages}
                      item={item}
                      giftFrom={item.gift_from}
                      giftTo={item.gift_to}
                      giftMsg={item.gift_msg}
                      giftSubscription={item.gift_subscription}
                      giftRecipient={item.gift_recipient}
                      itemId={item.id}
                      cart
                      key={item.id}
                      quantity={item.quantity}
                      coffee_grind={item?.coffee_grind}
                      product={item.product}
                    />
                  ))}
                </>
              )}
              <div className="Total">
                <div className="Total__row">
                  <h1 className="Total__title4">Sub-Total</h1>
                  <h1 className="Total__title4">
                    ${cartCtx?.cartSubTotal?.toFixed(2)}
                  </h1>
                </div>

                <Button
                  onClick={() => {
                    if (!AuthCtx.isAuthenticated) {
                      AuthCtx.handleFromCheckout();
                    }
                    window.gtag("event", "conversion", {
                      send_to: "AW-11011335274/Q3wLCJaN9IAYEOrIzoIp",
                    });
                    history.push("/checkout");
                  }}
                  label="PROCEED TO CHECKOUT"
                  className="Total__button"
                />
              </div>
            </div>
          </>
        )}

        {!isLoading && isEmpty(cartItems.items) && (
          <div className="Cart__message">
            <p className="Cart__empty-message"> Your cart is empty.</p>
            <img
              className="Cart__scooter-girl"
              src={ScooterGirl}
              alt="scooter girl"
            />
            <LinkButton
              label="Start Shopping"
              path="/shop"
              className="Cart__shopping-link"
            />
          </div>
        )}
        <div>
          <SavedItems />
        </div>
        <div>
          <YouMayAlsoLike />
        </div>
        <div>
          <RecentlyViewed />
        </div>
      </div>
    </div>
  );
};

export default Cart;
