import { apiRequest } from "./Api";

export const getAllMemberPicks = () => {
  return apiRequest("get", "member-picks");
};

export const getMemberOfTheWeek = () => {
  return apiRequest("get", "member-picks/member_pick_of_the_week");
};

export const getMemberPicksByFilter = async (id, id2, id3, page) => {
  if (!id && !id2 && !id3) {
    return apiRequest("get", `member-picks?${page ? `&page=${page}` : ""}`);
  }

  return apiRequest(
    "get",
    `member-picks?coffee_roast_id=${id}&coffee_type_id=${id2}&coffee_region_id=${id3}${
      page ? `&page=${page}` : ""
    }`
  );
};

// get all reviews for product
export const getReviewsForProduct = (id) => {
  return apiRequest("get", `member-picks?product_id=${id}`);
};

export const getAllReviews = () => {
  return apiRequest("get", "customer-reviews?per_page=50");
};

export const getCustomerReviews = (page) => {
  return apiRequest("get", `customer-reviews?page=${page}`);
};
