import { useFormik } from "formik";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    // .min(2, "Username is too Short!")
    // .max(50, "Username is too Long!")
    .required("Enter your email"),
  password: Yup.string().required("Enter your password"),
});

export const useLoginFormik = (opts) => {
  return useFormik({
    initialValues: {
      email: "",
      password: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
