import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as API from "../../api/Api";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import SelectBox from "../../components/shared/SelectBox/SelectBox";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Blog.scss";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    name: "All Blog Categories",
    id: 0,
  });
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const blogCategory = [
    {
      name: "All Blog Categories",
      id: 0,
    },
    {
      id: 2,
      name: "Roaster Q&A",
    },
    {
      id: 1,
      name: "GoCoffeeGo",
    },
  ];

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  const getAllBlogPosts = () => {
    API.getBlogPosts().then((res) => {
      setPosts(res.data);
    }).catch(err => console.log(err));
  };

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url.toLowerCase() === pathname);

  return (
    <div className="Blog">
      <PrimaryTitle title="BLOG" className="Blog__main-title" notMainTitle />
      <div className="Blog__categories">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
     
    {foundSeo && foundSeo?.h1 && (
      <h1 className="Blog__seo-title">
        {foundSeo?.h1}
      </h1>)}
      {foundSeo && foundSeo?.blurb && (
      <p className="Blog__main-description">
        {foundSeo?.blurb}
      </p>)}
        <SelectBox
          className="Blog__select-box"
          selectedItem={selectedItem}
          onItemSelect={(value, id) => {
            if (id === 0) {
              getAllBlogPosts();
              setSelectedItem({ name: value, id: id });
              return;
            }
            if (id === 1) {
              API.getBlogPosts().then((res) => {
                let categoryPost = res.data.filter(
                  (item) => item.category.id === 1
                );
                setPosts(categoryPost);
              });

              setSelectedItem({ name: value, id: id });
              return;
            }

            if (id === 2) {
              API.getBlogPosts().then((res) => {
                let categoryPost = res.data.filter(
                  (item) => item.category.id === 2
                );
                setPosts(categoryPost);
              });

              setSelectedItem({ name: value, id: id });
              return;
            }
          }}
          items={blogCategory}
        />
      </div>
      <div className="Blog__container">
        {posts &&
          posts.map((post) => (
            <Link
              to={post?.post_url.replace("https://www.gocoffeego.com/", "/")}
            >
              {" "}
              <div key={post.id} className="Blog__post">
                <div className="Blog__image-wrapper">
                  <img
                    src={post?.image?.image_url}
                    alt="post"
                    className="Blog__image"
                  />
                </div>
                <div className="Blog__post-details">
                  <div className="Blog__published">{post?.published_at}</div>
                  <div className="Blog__post-category">
                    {post?.category?.name}
                  </div>
                </div>{" "}
                <h2 className="Blog__post-title">{post?.title}</h2>
              </div>{" "}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Blog;
