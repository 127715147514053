import React, { useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import Filter from "../../components/Shop/Filter/Filter";
import Product from "../../components/Shop/Product/Product";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Top25.scss";

const Top25 = () => {
  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();

  useEffect(() => {
    FiltersCtx.getInitialProducts();

    return history.listen((location) => {
      if (history.action === "POP" && location.pathname === "/top25") {
        FiltersCtx.onClear();
      }
    });
  }, []);

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === pathname
  );
  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/top25/")
  );

  return (
    <div className="Top25">
      <PrimaryTitle title="Top 25" className="Top25__title" notMainTitle />
      <div className="Top25__wrapper">
        {foundSeo && (
          <>
            {foundSeo?.h1 && (
              <h1 className="Top25__subtitle">{foundSeo?.h1}</h1>
            )}
            {foundSeo?.blurb && (
              <p className="Top25__main-description">{foundSeo?.blurb}</p>
            )}
          </>
        )}
      </div>
      {/*   {seoLinks &&
        seoLinks.map((item) => <Link key={item?.id} to={item?.url} />)} */}
      <div className="Top25__container">
        {foundSeo && (
          <Seo
            title={foundSeo?.title}
            description={foundSeo?.description}
            keywords={foundSeo?.keywords}
          />
        )}
        <Filter top25 className="Top25__filter" />
        {FiltersCtx.isLoading ? (
          <div className="Top25__products-wrapper">
            {Array(12)
              .fill(0)
              .map((item, index) => (
                <ProductLoader key={index} />
              ))}
          </div>
        ) : (
          <>
            {FiltersCtx.top25.length > 0 ? (
              <div className="Top25__products-wrapper">
                {FiltersCtx.top25?.map((product, index) => (
                  <Product
                    product={product}
                    topNumber={index + 1}
                    key={product?.id}
                  />
                ))}
              </div>
            ) : (
              <div className="PreviousPeaberryRecommends__message font-cairo">
                No coffees found for this category
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Top25;
