import { apiRequest } from "./Api";

export const getAwardWinners = (page) => {
  return apiRequest("get", `products?award_winners=1&per_page=30&page=${page}`);
};

export const getAwardWinnersByFilter = async (id, id2, id3, id4, page) => {
  /*  if (!category && !id) {
    return apiRequest(
      "get",
      `products?award_winners=1&per_page=30&page=${page}`
    );
  } */

  if (!id && !id2 && !id3 && !id4) {
    return apiRequest(
      "get",
      `products?award_winners=1${page ? `&page=${page}` : ""}`
    );
  }

  return apiRequest(
    "get",
    `products?award_winners=1&coffee_roast_id=${id}&coffee_type_id=${id2}&coffee_region_id=${id3}&purveyor_id=${
      id4 ? id4 : 0
    }${id4 ? `&per_page=100` : ""}${page ? `&page=${page}` : ""}`
  );
};
