import React from "react";
import cs from "classnames";

import "./QuizLines.scss";

const QuizLines = (props) => {
  const { className } = props;
  return (
    <div className={cs("QuizLines", className)}>
      <div className="QuizLines__red" />
      <div className="QuizLines__blue" />
    </div>
  );
};

export default QuizLines;
