import React, { useEffect, useState } from "react";

import * as API from "../../api/Api.js";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import PrePaidSubscription from "../../components/PrePaid/PrePaidSubscription/PrePaidSubscription";
import Line from "../../components/shared/Line/Line";
import CoffeeShipments from "../../components/PrePaid/CoffeeShipments/CoffeeShipments";
import PrePaidImageLoader from "../../components/Loaders/PrePaidImageLoader/PrePaidImageLoader";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./PrePaid.scss";

const PrePaid = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === "/coffee-gifts");

  useEffect(() => {
    API.getAllCoffeeGifts().then((res) => {
      setSubscriptions(res.data);

      if (res.data) {
        setIsLoading(false);
      }
    }).catch(err => console.log(err));
  }, []);

  const selectedSubscription = subscriptions.filter((item) => {
    return item.id === selectedSubscriptionId;
  });

  const onSubscriptionSelect = (id) => {
    const el = document.getElementById("coffee-shipments").offsetTop;
    window.scrollTo({
      top: el + 160,
      behavior: "smooth",
    });

    setSelectedSubscriptionId(id);
    localStorage.setItem("selected-subscription", id);
  };

  return (
    <div className="PrePaid">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        className="PrePaid__title"
        title="Pre-paid coffee subscriptions"
        notMainTitle
      />
      <div className="PrePaid__content">
        <MiddleLine className="PrePaid__line" />
        {foundSeo && (<h1 className="PrePaid__main-title">{foundSeo?.h1}</h1>)}
        {foundSeo && (<p className="PrePaid__main-description">{foundSeo?.blurb}</p>)}
        <h2 className="PrePaid__section-title">
          Please select one of our Pre-paid coffee subscriptions
        </h2>
        <div className="PrePaid__subscriptions">
          <>
            {isLoading ? (
              <>
                {Array(10)
                  .fill(0)
                  .map((item, index) => (
                    <PrePaidImageLoader key={index} />
                  ))}
              </>
            ) : (
              <>
                {subscriptions.map((item) => {
                  return (
                    <PrePaidSubscription
                      shortMessage={item?.short_message}
                      detail={item?.detail}
                      name={item?.name}
                      id={item?.id}
                      selectedSubscriptionId={selectedSubscriptionId}
                      onClick={() => onSubscriptionSelect(item?.id)}
                      key={item?.id}
                      image={item?.image?.image?.image_url}
                    />
                  );
                })}
              </>
            )}
          </>
        </div>
      </div>
      <Line />
      <Line className="PrePaid__line-bottom" />
      <div style={{ paddingTop: "20px" }} id="coffee-shipments">
        {selectedSubscription.length > 0 && (
          <CoffeeShipments selectedSubscription={selectedSubscription} />
        )}
      </div>
    </div>
  );
};

export default PrePaid;
