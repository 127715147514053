import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

import { Icon } from "../../shared/Icon/Icon";
import SelectBox2 from "../CartItem/SelectBox2/SelectBox2";
import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import EditGiftCertificate from "../EditGiftCertificate/EditGiftCertificate";
import SubscriptionFormModal from "../../Modals/SubscriptionFormModal/SubscriptionFormModal";
import cs from "classnames";
import { updateAutoShipOrder } from "../../../api/Api";

import "./CartItemMobile.scss";
import SelectBoxShipments from "../../shared/SelectBoxShipments/SelectBoxShipments";

const CartItemMobile = (props) => {
  const {
    shipmentsData,
    product,
    coffee_grind,
    quantity,
    itemId,
    autoShip,
    cart,
    giftFrom,
    giftTo,
    giftMsg,
    giftSubscription,
    item,
    specialsMessages,
    setCombine,
    combine,
    parent_id,
    shipments,
    shipmentNumber,
  } = props;
  const Close =
    "https://assets.gocoffeego.com/v2/assets/images/close-icon-2.svg";
  const [selectedGrind, setSelectedGrind] = useState(coffee_grind?.name);
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const [iconActive, setIconActive] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSubscriptionEdit, setOpenSubscriptionEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(
    ` ${shipmentNumber}`
  );

  const cartCtx = useCartContext();

  const cartId = localStorage.getItem("cart_id");

  useEffect(() => {
    // manually deep compare here before updating state
    if (quantity === itemQuantity) {
      return;
    }
    setItemQuantity(quantity);
  }, [quantity]);

  const handleUpdateGrind = (name, id) => {
    setSelectedGrind(name);
    if (!autoShip) {
      API.updateItemInCart(itemId, id, null, cartId).then(() => {
        cartCtx.getCartItems();
      });
    }

    if (autoShip) {
      API.updateAutoShipQuantity(itemId, id, itemQuantity, cartId).then(() => {
        cartCtx.getCartItems();
        props.refetchAutoShips();
      });
    }
  };

  //@TODO this function should be optimized
  const handleQuantityChange = (increment) => {
    if (itemQuantity < 15 && itemQuantity <= 15) {
      if (increment) {
        setItemQuantity((prev) => prev + 1);
        if (autoShip) {
          API.updateAutoShipQuantity(
            itemId,
            selectedGrind,
            itemQuantity + 1,
            cartId
          );
        } else {
          API.updateItemInCart(
            itemId,
            selectedGrind,
            itemQuantity + 1,
            cartId
          ).then((res) => {
            cartCtx.updateCartSubtotal(res.data.subtotal);
            cartCtx.updateSpecialMessages(res.data.specials_messages);
            cartCtx.getCartItems();
          });
        }
      }

      if (!increment && itemQuantity !== 1) {
        setItemQuantity((prev) => prev - 1);
        if (autoShip) {
          API.updateAutoShipQuantity(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          );
        } else {
          API.updateItemInCart(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          ).then((res) => {
            cartCtx.updateCartSubtotal(res.data.subtotal);
            cartCtx.updateSpecialMessages(res.data.specials_messages);
            cartCtx.getCartItems();
          });
        }
      }
    } else {
      if (!increment && itemQuantity !== 1) {
        setItemQuantity((prev) => prev - 1);
        if (autoShip) {
          API.updateAutoShipQuantity(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          );
        } else {
          API.updateItemInCart(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          ).then((res) => {
            cartCtx.updateCartSubtotal(res.data.subtotal);
            cartCtx.updateSpecialMessages(res.data.specials_messages);
            cartCtx.getCartItems();
          });
        }
      }
    }
  };

  const handleDelete = (id) => {
    if (autoShip) {
      API.deleteProductById(id, cartId).then(() => {
        props.refetchAutoShips();
        setIsLoading(false);
      });
      return;
    }

    if (cart) {
      API.deleteItemFromCart(id, cartId).then(() => {
        cartCtx.getCartItems();
        setIsLoading(false);
      });
      return;
    }
  };

  const handleSaveForLater = (id) => {
    setIconActive(!iconActive);
    API.moveToSaved(id, cartId).then((res) => {
      cartCtx.getCartItems();
      cartCtx.getSavedItems();
    });
  };

  return (
    <div
      className={
        combine?.active && itemId === combine?.id
          ? "CartItemMobile__combine"
          : shipments?.length > 1 &&
            combine?.active &&
            combine?.shipmentNumber === shipmentNumber
          ? "CartItemMobile__add-more"
          : "CartItemMobile"
      }
    >
      <div
        className={
          shipments?.length > 1
            ? "CartItemMobile__display-none"
            : "CartItemMobile__border-top"
        }
      />
      {shipments && shipments?.length > 1 && shipments[0]?.id === itemId && (
        <div className="CartItemMobile__border-top" />
      )}
      <div className="CartItemMobile__container">
        <div className="CartItemMobile__column1">
          {autoShip && (
            <>
              {parent_id === 0 && (
                <h1 className="CartItemMobile__shipment-number">
                  {shipmentNumber}
                </h1>
              )}
            </>
          )}
          <img
            src={
              giftFrom && product?.image
                ? product?.image
                : product?.main_image.xlg
            }
            className="CartItemMobile__image"
            alt="product"
          />
          <div className="CartItemMobile__details">
            {/*   {giftFrom && !giftSubscription && (
              <h1 className="CartItemMobile__title">Gift Certificate</h1>
            )} */}
            {!giftFrom && !giftSubscription && (
              <Link
                to={`/roaster/${product?.purveyor?.slug}-${product?.purveyor?.id}`}
              >
                <h2 className="CartItemMobile__title CartItemMobile__title--purveyor">
                  {product?.purveyor?.name}
                </h2>
              </Link>
            )}
            {product?.name && (
              <Link
                to={product.product_url}
                className="CartItemMobile__product-name"
              >
                {product?.name}{" "}
              </Link>
            )}
            {giftFrom && giftSubscription && (
              <div className="CartItemMobile__giftSubscription">
                <div className="CartItemMobile__gift-grid">
                  <div className="CartItemMobile__gift-to-wrapper">
                    To{" "}
                    <div className="CartItemMobile__gift-to-name">{giftTo}</div>
                  </div>
                  <div className="CartItemMobile__gift-from-wrapper">
                    From{" "}
                    <div className="CartItemMobile__gift-to-name">
                      {giftFrom}
                    </div>
                  </div>
                </div>
                <div className="CartItemMobile__gift-msg">
                  <span className="CartItemMobile__gift-msg--msg">
                    {giftMsg}
                  </span>
                </div>
              </div>
            )}
            {giftFrom && !giftSubscription && (
              <EditGiftCertificate
                id={props.itemId}
                msg={props.giftMsg}
                reciever={props.giftTo}
                sender={props.giftFrom}
                closeEdit={() => setOpenEdit(!openEdit)}
                openEdit={openEdit}
              />
            )}
            {giftFrom && giftSubscription && !openSubscriptionEdit && (
              <button
                className="CartItemMobile__edit-btn"
                onClick={() => setOpenSubscriptionEdit(true)}
              >
                Edit Note
              </button>
            )}
            {openSubscriptionEdit && (
              <SubscriptionFormModal
                isOpen={openSubscriptionEdit}
                toggle={() => setOpenSubscriptionEdit(!openSubscriptionEdit)}
                image={product?.main_image?.xlg}
                months={giftSubscription?.description.slice(0, 2)}
                item={item}
                fromCart
              />
            )}
            {giftFrom && !giftSubscription && !openEdit && (
              <>
                <button
                  className="CartItemMobile__edit-btn"
                  onClick={() => setOpenEdit(!openEdit)}
                >
                  Edit Note
                </button>
              </>
            )}
            <h1 className="CartItemMobile__title">{product?.coffee_roast}</h1>

            {!giftFrom && product?.coffee_grinds && (
              <SelectBox2
                selectedItem={selectedGrind}
                onItemSelect={(name, id) => handleUpdateGrind(name, id)}
                items={product?.coffee_grinds}
              />
            )}
            {!giftFrom && (
              <div className="CartItemMobile__input-container">
                <button
                  onClick={() => handleQuantityChange(false)}
                  className="CartItemMobile__minus"
                >
                  -
                </button>
                <input
                  className="CartItemMobile__input"
                  type="number"
                  value={itemQuantity}
                  min={1}
                  onChange={(e) => setItemQuantity(e.target.value)}
                  disabled={true}
                />
                <button
                  onClick={() => handleQuantityChange(true)}
                  className="CartItemMobile__plus"
                >
                  +
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="CartItemMobile__column2">
          <>
            <div className="CartItemMobile__row">
              {isLoading ? (
                <Spinner
                  style={{
                    border: `0.25em solid #000000`,
                    borderRightColor: "transparent",
                    width: "2rem",
                    height: "2rem",
                    marginRight: "16px",
                  }}
                />
              ) : (
                <button
                  onClick={() => {
                    setIsLoading(true);
                    handleDelete(itemId);
                  }}
                  className="CartItemMobile__icon"
                >
                  &#10005;
                </button>
              )}
              {!autoShip && (
                <Icon
                  onClick={() => handleSaveForLater(itemId)}
                  className={cs(
                    "CartItemMobile__heart",
                    iconActive && "CartItemMobile__heart--active"
                  )}
                  icon="heart"
                />
              )}
            </div>
            {giftFrom || giftSubscription?.price ? (
              <>
                {giftFrom && product?.gift_amount ? (
                  <h1 className="CartItemMobile__title2">
                    {`$ ${product?.gift_amount.toFixed(2)}`}
                  </h1>
                ) : null}
                {giftSubscription ? (
                  <h1 className="CartItemMobile__title2">
                    {`$ ${giftSubscription?.price.toFixed(2)}`}
                  </h1>
                ) : null}
              </>
            ) : (
              <h1 className="CartItemMobile__title2">
                <span
                  className={
                    product?.sale.active
                      ? "CartItemMobile__retail-price--sale"
                      : "CartItemMobile__retail-price"
                  }
                >{`$ ${product?.retail_price.toFixed(2)} `}</span>
                {product?.sale.active && product?.sale_price && (
                  <>
                    <span className="CartItemMobile__sale-price">
                      ${product?.sale_price.toFixed(2)}
                    </span>
                    <br />
                  </>
                )}
                <span>{` / ${product?.weight}`}</span>
              </h1>
            )}
          </>
          {autoShip && (
            <div className="CartItemMobile__column-2">
              <SelectBoxShipments
                shipment
                selectedItem={shipmentNumber}
                onItemSelect={(number) => {
                  setSelectedShipment(number);
                  updateAutoShipOrder(itemId, number, cartId).then((res) => {
                    props.setShipmentsData(res?.data?.shipments);
                    props.refetchAutoShips();
                  });
                }}
                items={shipmentsData}
              />
            </div>
          )}
        </div>
      </div>

      {specialsMessages &&
        specialsMessages.map((message) => (
          <>
            {message?.purveyor_id === product?.purveyor?.id && (
              <div className="CartItemMobile__specials-message">
                {message?.add_more && (
                  <>
                    {`Add ${message?.add_more} more and qualify for "${message?.special_name}" from`}{" "}
                    <Link
                      to={`/roaster/${message?.purveyor_id}`}
                      className="CartItemMobile__specials-message--link"
                    >{`${message?.purveyor_name}.`}</Link>{" "}
                  </>
                )}
                {message?.text && (
                  <>
                    {`You qualify for "${message?.special_name}" from`}{" "}
                    <Link
                      to={`/roaster/${message?.purveyor_id}`}
                      // href={message.purveyor_url}
                      className="CartItemMobile__specials-message--link"
                    >
                      {`${message?.purveyor_name}.`}
                    </Link>{" "}
                    <br />
                    {message?.text}
                  </>
                )}
              </div>
            )}
          </>
        ))}
    </div>
  );
};

export default CartItemMobile;
