import { apiRequest } from "./Api";
import Axios from "axios";

//get all gift certificates
export const getGiftCertificates = async () => {
  return apiRequest("get", `gift-certificates`);
};

export const downloadGift = async (id) => {
  const auth = localStorage.getItem("access_token");
  const path = `account/orders/download-gift-certificate/${id}`;
  const res = await Axios.request({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: "get",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    responseType: "blob",
  });
  return res.data;
};

export const downloadSubscriptionGift = async (id) => {
  const auth = localStorage.getItem("access_token");
  const path = `account/orders/download-gift-subscription/${id}`;
  const res = await Axios.request({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: "get",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${auth}`,
    },
    responseType: "blob",
  });
  return res.data;
};
