import React from "react";

import SectionTitle from "../SectionTitle/SectionTitle";
import Line from "../../shared/Line/Line";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { isEmpty } from "../../../lib/helpers/isEmpty";

import FlickitySlider from "../../FlickitySlider/FlickitySlider";
import ProductItem from "../ProductItem/ProductItem";

import "./SavedItems.scss";

const SavedItems = () => {
  const cartCtx = useCartContext();

  const savedItems = cartCtx.savedItems;
  const isLoading = cartCtx.savedItemsLoading;

  let options = {
    draggable: false,
    freeScroll: false,
    contain: true,
    groupCells: true,
    pageDots: false,
    prevNextButtons: true,
  };

  return (
    <div className="SavedItems">
      <Line />
      <Line />
      <div className="SavedItems__content">
        <SectionTitle
          middleLine
          white
          className="SavedItems__title"
          title="SAVED ITEMS"
        />
        <div className="SavedItems__slider">
          {(!isLoading && savedItems?.length <= 0) || isEmpty(savedItems) ? (
            <p className="SavedItems__no-items">No saved cart items.</p>
          ) : (
            <FlickitySlider options={options}>
              {!isLoading &&
                savedItems.map((item) => (
                  <ProductItem
                    giftFrom={item.gift_from}
                    giftTo={item.gift_to}
                    giftMsg={item.gift_msg}
                    giftSubscription={item?.gift_subscription}
                    id={item.id}
                    image={
                      item.gift_from && item.gift_subscription === null
                        ? item.product.image
                        : item.product.main_image.xlg
                    }
                    weight={item.product.weight}
                    price={
                      item.gift_from && item.gift_subscription === null
                        ? item.product.gift_amount
                        : item.product.retail_price
                    }
                    title={item.product.name}
                    savedItem
                  />
                ))}
            </FlickitySlider>
          )}
        </div>
      </div>
      <Line className="SavedItems__line" />
      <Line />
    </div>
  );
};

export default SavedItems;
