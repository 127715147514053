import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../lib/context/AuthContext/AuthContext";

import * as API from "../../../../api/Api";

import { useAddressBookFormik } from "./useAddressBookFormik";
import SelectBoxAddressBook from "../SelectBoxAddressBook/SelectBoxAddressBook";
import MiddleLine from "../../../shared/MiddleLine/MiddleLine";
import { isEmpty } from "../../../../lib/helpers/isEmpty";
import { useMessageContext } from "../../../../lib/context/MessageModalContext/MessageModalContext";

import { Form, Input, Spinner } from "reactstrap";

import "./AddContact.scss";

const AddContact = ({
  setOpen,
  open,
  selectedAddress,
  setSelectedAddress,
  refetchAddress,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountry, setSelectedCountry] = useState();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const history = useHistory();
  const pathParam = params.pathParam;
  const authCtx = useAuthContext();
  const msgCtx = useMessageContext();

  useEffect(() => {
    if (selectedAddress || isEmpty(selectedState)) {
      setSelectedState(selectedAddress?.state);
    }

    if (selectedAddress && open) {
      setSelectedCountry(selectedAddress?.country);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedCountry?.states) {
      let stateMatch = selectedCountry?.states.filter(
        (state) => state.id === selectedAddress?.state?.id
      );

      let selectedStateMatch = selectedCountry?.states.filter(
        (state) => state.id === selectedState?.id
      );

      if (stateMatch?.length === 0 || selectedStateMatch?.length === 0) {
        setError("Please select correct state");
      }

      if (
        stateMatch?.length === 1 ||
        selectedStateMatch?.length === 1 ||
        isEmpty(selectedState) ||
        selectedCountry?.states?.length === 0
      ) {
        setError(" ");
      }
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    API.getCountries().then((res) => setCountries(res.data));

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let defaultCountry = countries.find((country) => country.id === 64);
  if (defaultCountry && !selectedCountry) {
    setSelectedCountry(defaultCountry);
  }

  useEffect(() => {
    if (selectedCountry?.id) {
      API.getStateOfCountry(selectedCountry.id).then((res) => {
        setStates(res.data.states);
        if (isEmpty(res.data.states)) {
          setDisabled(true);
          // formik.setFieldValue("state", "no-state");
          setSelectedState({ name: "no-state" });
        } else {
          setDisabled(false);
        }
      });
    }
  }, [selectedCountry]);

  const formik = useAddressBookFormik(selectedAddress, {
    onSubmit: async (values) => {
      setFormSubmitted(true);
      if (open && selectedAddress) {
        const updateData = new FormData();
        updateData.append("id", selectedAddress.id);
        updateData.append("first_name", values.first_name);
        updateData.append("last_name", values.last_name);
        updateData.append("location_country_id", selectedCountry.id);
        updateData.append("company_name", values.company_name);
        updateData.append("address1", values.address1);
        updateData.append("address2", values.address2);
        updateData.append(
          "location_state_id",
          // selectedState.id ? selectedState.id : selectedAddress?.state?.id
          selectedState.name === "no-id" ? null : selectedState.id
        );
        updateData.append("city", values.city);
        updateData.append("zip", values.zip);
        updateData.append("phone", values.phone);

        setIsLoading(true);
        setFormSubmitted(true);
        API.editAddressBookContact(
          selectedAddress.id,
          values.first_name,
          values.last_name,
          selectedCountry.id,
          values.company_name,
          values.address1,
          values.address2,
          values.city,
          selectedState.id ? selectedState.id : selectedAddress?.state?.id,
          values.zip,
          values.phone
        ).then((res) => {
          refetchAddress();
          setResponse(res.Result);
          authCtx.getCustomer();
          if (pathParam === "auto-ship") {
            history.push(`/${pathParam}`);
          }
          // selectedAddress(null);
          setIsLoading(false);
          setFormSubmitted(false);
        });

        setTimeout(() => {
          setOpen(false);
          setIsLoading(false);
        }, 3000);
        setSelectedAddress(null);
      } else if (open && selectedAddress === null) {
        const data = new FormData();
        data.append("first_name", values.first_name);
        data.append("last_name", values.last_name);
        data.append("location_country_id", selectedCountry.id);
        data.append("company_name", values.company_name);
        data.append("address1", values.address1);
        data.append("address2", values.address2);
        data.append("location_state_id", selectedState.id);
        data.append("city", values.city);
        data.append("zip", values.zip);
        data.append("phone", values.phone);

        setFormSubmitted(true);

        if (selectedState) {
          setIsLoading(true);
          API.addAddressBookContact(data)
            .then((res) => {
              const id = res.data.address_id;
              refetchAddress();
              if (pathParam === "auto-ship") {
                API.setDefaultAddress(id).then((res) => {
                  authCtx.getCustomer();
                  if (pathParam === "auto-ship") {
                    history.push(`/${pathParam}`);
                  }
                });
              }
              setResponse(res.Result);
              setFormSubmitted(false);
            })
            .catch((err) => msgCtx.handleError(err));

          setTimeout(() => {
            setOpen(false);
            setIsLoading(false);
          }, 3000);
        }
      }
    },
  });

  return (
    <div className="AddContact">
      <Form className="AddContact__form" onSubmit={formik.handleSubmit}>
        <div className="AddContact__title-wrap">
          <MiddleLine />
          <h2 className="AddContact__title">
            {selectedAddress ? "EDIT ADDRESS" : "ADD NEW ADDRESS"}
          </h2>
        </div>
        <div className="AddContact__grid-container">
          <div className="AddContact__grid">
            <div className="AddContact__input-container">
              <Input
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                placeholder="First Name"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="40"
              />
              {formik.errors.first_name && formik.touched.first_name && (
                <div className="AddContact__error">
                  {formik.errors.first_name}
                </div>
              )}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                placeholder="Last Name"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="40"
              />
              {formik.errors.last_name && formik.touched.last_name && (
                <div className="AddContact__error">
                  {formik.errors.last_name}
                </div>
              )}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="company_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik.values.company_name === null
                    ? ""
                    : formik.values.company_name
                }
                placeholder="Company Name"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="40"
              />
              {formik.errors.company_name && formik.touched.company_name && (
                <div className="AddContact__error">
                  {formik.errors.company_name}
                </div>
              )}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="address1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address1}
                placeholder="Address"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="40"
              />
              {formik.errors.address1 && formik.touched.address1 && (
                <div className="AddContact__error">
                  {" "}
                  {formik.errors.address1}{" "}
                </div>
              )}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="address2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik.values.address2 === null ? "" : formik.values.address2
                }
                placeholder="Address 2 (optional)"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="40"
              />
              {formik.errors.address2 && formik.touched.address2 && (
                <div className="AddContact__error">
                  {" "}
                  {formik.errors.address2}{" "}
                </div>
              )}
            </div>
          </div>
          <div className="AddContact__grid">
            <div className="AddContact__input-container">
              <SelectBoxAddressBook
                selectedBoxId={1}
                className="AddContact__select"
                label="Select Country"
                onItemSelect={(name, id, states) =>
                  setSelectedCountry({ name: name, id: id, states: states })
                }
                onClick={() => setOpenDropdown(1)}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                selectedCountry={
                  selectedAddress ? selectedAddress?.country?.name : null
                }
                selectedItem={selectedCountry?.name}
                items={countries}
              />
            </div>
            <div className="AddContact__input-container">
              <SelectBoxAddressBook
                selectedBoxId={2}
                setTouch={() => {
                  formik.setFieldTouched(true);
                }}
                className="AddContact__select"
                label="Select State"
                onItemSelect={(name, id) =>
                  setSelectedState({ name: name, id: id })
                }
                onClick={() => setOpenDropdown(2)}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                selectedState={
                  selectedAddress ? selectedAddress?.state?.name : null
                }
                selectedItem={selectedState?.name}
                items={states}
                selectedAddress={selectedAddress}
                disabled={disabled}
                state
              />
              {formSubmitted && isEmpty(selectedState) && !selectedAddress && (
                <p className="AddContact__error">Please provide state</p>
              )}
              {error && <p className="AddContact__error">{error}</p>}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                placeholder="City"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="30"
              />
              {formik.errors.city && formik.touched.city && (
                <div className="AddContact__error"> {formik.errors.city} </div>
              )}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="zip"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zip}
                placeholder="Postal Code"
                className="AddContact__input"
                type="text"
                minLength="5"
                maxLength="9"
              />
              {formik.errors.zip && formik.touched.zip && (
                <div className="AddContact__error">{formik.errors.zip}</div>
              )}
            </div>
            <div className="AddContact__input-container">
              <Input
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="Phone"
                className="AddContact__input"
                type="text"
                minLength="2"
                maxLength="30"
              />

              {formik.errors.phone && formik.touched.phone && (
                <div className="AddContact__error"> {formik.errors.phone} </div>
              )}
            </div>
          </div>
        </div>
        <button
          className="AddContact__button"
          type="submit"
          disabled={
            isLoading || error === "Please select correct state" ? true : false
          }
        >
          {isLoading ? (
            <Spinner
              style={{
                border: "0.25em solid #00d1d6",
                borderRightColor: "transparent",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
          ) : (
            "Save Changes"
          )}
        </button>
        {response && <div className="AddContact__message">{response}</div>}
      </Form>
    </div>
  );
};

export default AddContact;
