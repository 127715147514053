import React, { useRef, useState, useEffect } from "react";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

import "./SelectCountry2.scss";
let cs = require("classnames");

const SelectCountry2 = (props) => {
  const {
    items,
    selectedItem,
    className,
    className2,
    label,
    disabled,
    state,
  } = props;
  const dropDownRef = useRef(null);

  const [showItems, setShowItems] = useState(false);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (name, id, states) => {
    props.onItemSelect(name, id, states);
    setShowItems(!showItems);
  };

  return (
    <div
      ref={dropDownRef}
      onClick={() => {
        if (state) {
          props.setTouch();
        }
        if (disabled) {
          return;
        }
        setShowItems(!showItems);
      }}
      className={cs(
        "SelectCountry2",
        className,
        selectedItem && "SelectCountry2--highlight"
      )}
      onFocus={() => setShowItems(!showItems)}
      onBlur={() => setShowItems(false)}
      tabIndex="0"
    >
      <div
        className={`SelectCountry2__header ${
          disabled && "SelectCountry2__header-disabled"
        }`}
      >
        <div className="SelectCountry2__title-wrap">
          <h2 className="SelectCountry2__title"> {label}</h2>
          <h2 className="SelectCountry2__title2"> {selectedItem} </h2>
        </div>
        <span className="SelectCountry__arrow-down" />
        {label && (
          <img className="SelectCountry2__icon" src={Arrow} alt="arrow" />
        )}
      </div>

      <div
        className={cs(
          "SelectCountry2__items",
          showItems && label && "active",
          className2
        )}
      >
        {!state &&
          items?.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => onItemSelect(item.name, item.id, item.states)}
                className={`SelectCountry2__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectCountry2__item">{item.name}</p>
              </div>
            );
          })}
        {state &&
          items?.map((item, index) => {
            return (
              <div
                key={item.id}
                onClick={() => onItemSelect(item.name, item.id)}
                className={`SelectCountry2__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectCountry2__item">{item.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectCountry2;
