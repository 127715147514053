import ContentLoader from "react-content-loader";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

const SpecialBannerLoader = () => {
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <ContentLoader
      width={isMobile ? "90%" : "95%"}
      // speed={2}
      height={isMobile ? 150 : 200}
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
    >
      <rect
        x="0"
        y="0"
        rx="5"
        ry="5"
        width="100%"
        height={isMobile ? "20" : "40"}
      />
      <rect
        x="0"
        y={isMobile ? "30" : "60"}
        rx="5"
        ry="5"
        width="100%"
        height="90%"
      />
    </ContentLoader>
  );
};

export default SpecialBannerLoader;
