import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../shared/Button/Button";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

import cs from "classnames";
import "./MemberPicks.scss";

const MemberPicksCard = (props) => {
  const isMobile = useResponsiveDimensions().isMobile;
  const {
    product,
    review,
    customerName,
    customerCity,
    image,
    customerState,
    updatedAt,
    memberOfTheWeek,
  } = props;

  const history = useHistory();

  return (
    <div className={cs("MemberPicksCard", memberOfTheWeek && "circle")}>
      <div className="MemberPicksCard__column-1">
        <img
          className="MemberPicksCard__product"
          src={product?.main_image?.xlg}
          alt=""
        />
        {isMobile && (
          <>
            <div className="MemberPicksCard__title font-cairo">
              {product?.purveyor?.name}
            </div>
            <p className="MemberPicksCard__description">{product?.name}</p>
            <p className="MemberPicksCard__weight">
              ${product?.retail_price.toFixed(2)} / {product?.weight}
            </p>
          </>
        )}
      </div>
      <div className="MemberPicksCard__content">
        {!isMobile && (
          <>
            <div className="MemberPicksCard__title font-cairo">
              {product?.purveyor?.name}
            </div>
            <p className="MemberPicksCard__description">{product?.name}</p>
            <p className="MemberPicksCard__weight">
              ${product?.retail_price.toFixed(2)} / {product?.weight}
            </p>
          </>
        )}
        <p className="MemberPicksCard__review">{review}</p>
        <div className="MemberPicksCard__type font-cairo">
          {product?.coffee_roast}
          {product?.coffee_types.length >= 1 && ` / ${product.coffee_types[0]}`}
        </div>

        <Button
          label="VIEW DETAILS"
          className="MemberPicksCard__button"
          onClick={() => history.push(`/${product.product_url}`)}
        />
      </div>
      <div className="MemberPicksCard__column-3">
        <img className="MemberPicksCard__person" src={image?.lg} alt="" />
        <p className="MemberPicksCard__person-details">{customerName} </p>
        <p className="MemberPicksCard__person-details">
          {customerCity}, {customerState}
        </p>
      </div>
    </div>
  );
};

export default MemberPicksCard;
