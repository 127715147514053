import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import * as API from "../../../api/Api";
import TermsOfUseModal from "../../Modals/TermsOfUseModal/TermsOfUseModal";

import "./RedeemCard.scss";

const RedeemSchema = Yup.object().shape({
  code: Yup.string()
    .min(12, "Code is too short")
    .max(16, "Code is too long")
    .required("Code is required"),
});

const RedeemCard = () => {
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  if (message || successMessage) {
    setTimeout(() => {
      setMessage("");
      setSuccessMessage("");
    }, 6000);
  }

  return (
    <div className="RedeemCard">
      <div className="RedeemCard__wrap">
        <h1 className="RedeemCard__title">
          {" "}
          Got a Gift Card or Gift Certificate? Choose your perfect gift at
          GoCoffeeGo.{" "}
        </h1>
        <div className="RedeemCard__text-wrap">
          <div className="RedeemCard__text">
            During Checkout enter your 16-digit Gift Card or 12-digit Gift
            Certificate claim code.
          </div>
          <div className="RedeemCard__text">
            We'll do the math and apply your Gift Card or Gift Certificate
            automatically to your purchase amount.
          </div>
          <div className="RedeemCard__text">
            {" "}
            Any unused balance will remain in your account until you choose to
            spend it.
          </div>
        </div>
        <div className="RedeemCard__text-wrap">
          <div className="RedeemCard__text">
            Or save yourself some time by applying your Gift Card or Gift
            Certificate balance to your account right now.{" "}
          </div>
          <div className="RedeemCard__text">
            We'll add the amount to your available funds, and you can use it the
            next time you shop.
          </div>
        </div>
        <Formik
          initialValues={{
            code: "",
          }}
          validationSchema={RedeemSchema}
          onSubmit={(values) => {
            setIsLoading(true);
            API.redeemCard(values.code)
              .then((res) => {
                setIsLoading(false);
                if (res.status === 200) {
                  setSuccessMessage(res.data.result);
                }
              })
              .catch((err) => {
                if (err.response) {
                  setMessage(err.response.data.error.message);
                  setIsLoading(false);
                }
              });
          }}
        >
          {({ errors, touched }) => (
            <Form className="RedeemCard__form">
              <div className="RedeemCard__form-text-wrap">
                <div className="RedeemCard__form-text">
                  Please enter your 16-digit Gift Card or 12-digit Gift
                  Certificate claim code.
                </div>
              </div>
              <div className="RedeemCard__form-group">
                <Field className="RedeemCard__input" type="text" name="code" />
                {errors.code && touched.code ? (
                  <div className="RedeemCard__required">{errors.code}</div>
                ) : null}
                {message && (
                  <div className="RedeemCard__required">{message}</div>
                )}
                {successMessage && (
                  <div className="RedeemCard__success">{successMessage}</div>
                )}
              </div>
              <button
                disabled={isLoading ? true : false}
                className="RedeemCard__button"
                type="submit"
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  "Continue"
                )}
              </button>
            </Form>
          )}
        </Formik>
        <div className="RedeemCard__terms-wrapper">
          <div className="RedeemCard__terms" onClick={() => setIsOpen(!isOpen)}>
            Terms of Use
          </div>
        </div>
        <TermsOfUseModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />
      </div>
    </div>
  );
};

export default RedeemCard;
