import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import SubscriptionForm from "../../PrePaid/SubscriptionForm/SubscriptionForm";

import { Icon } from "../../shared/Icon/Icon";

import "./SubscriptionFormModal.scss";

const SubscriptionFormModal = (props) => {
  const { image, coffeeShipmentQuantity, months, details, fromCart } = props;

  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="SubscriptionFormModal">
        <Icon
          style={{ width: "20px", height: "20px" }}
          onClick={props.toggle}
          className="SubscriptionFormModal__close"
          icon="close"
        />

        <div className="SubscriptionFormModal__image-container">
          <img
            src={image}
            className="SubscriptionFormModal__image"
            alt="tour"
          />
        </div>
        <h1 className="SubscriptionFormModal__title">
          {months} Months Subscriptions - {coffeeShipmentQuantity} Coffee Per
          Shipment
        </h1>
        <p className="SubscriptionFormModal__description">{details}</p>
        <div className="SubscriptionFormModal__description-container">
          <p className="SubscriptionFormModal__description2">
            Let your coffee adventure begin.
          </p>
        </div>
        <SubscriptionForm
          itemDetails={props.item}
          fromCart={fromCart}
          toggle={props.toggle}
        />
      </div>
    </ModalWrapper>
  );
};

export default SubscriptionFormModal;
