import React from "react";

import cs from "classnames";
import "./EspressoItem.scss";

const EspressoItem = (props) => {
  const { image, title, description, Item, reverseItem } = props;
  return (
    <div
      className={cs("EspressoItem", reverseItem && "reverse", Item && "item")}
    >
      <div>
        <h2
          className={cs(
            "EspressoItem__title",
            Item && "color",
            reverseItem && "reverse"
          )}
        >
          {title}
        </h2>
        <p
          className={cs(
            "EspressoItem__description",
            Item && "half-width",
            reverseItem && "reverse"
          )}
        >
          {description}
        </p>
      </div>
      <img className="EspressoItem__image" src={image} />
    </div>
  );
};

export default EspressoItem;
