import React from "react";

import { useHistory } from "react-router-dom";

import "./RecipesItem.scss";

const RecipesItem = (props) => {
  const { name, image, prepTime, yieldd, id, slug } = props;

  const history = useHistory();

  const handleClick = (id) => {
    history.push(`/recipe/${slug}-${id}`);
  };

  return (
    <div onClick={() => handleClick(id)} className="RecipesItem">
      <img className="RecipesItem__image" src={image} alt="" />

      <div className="RecipesItem__title font-cairo">{name}</div>
      <p className="RecipesItem__description">Prep Time : {prepTime}</p>
      <p className="RecipesItem__description">Yield : {yieldd}</p>
    </div>
  );
};

export default RecipesItem;
