import React, { useState, useCallback, createRef } from "react";
import * as API from "../../../../api/Api";

import { Form, Spinner } from "reactstrap";
import Dropzone from "../Dropzone/Dropzone";
import ReactCrop from "react-image-crop";
import { useMessageContext } from "../../../../lib/context/MessageModalContext/MessageModalContext";

import "./PhotoUploaderModal.scss";
import "react-image-crop/lib/ReactCrop.scss";

const PhotoUploaderModal = ({
  details,
  openModal,
  setOpenModal,
  setImageData,
  refetchDetails,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState();
  const [errors, setErrors] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    width: 300,
    aspect: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const imagePrevCanvasRef = createRef();
  const msgCtx = useMessageContext();

  const MAX_SIZE = 2048000;

  if (message) {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }

  // Extract an Base64 Image's File Extension
  const extractImageFileExtensionFromBase64 = (base64Data) => {
    return base64Data.substring(
      "data:image/".length,
      base64Data.indexOf(";base64")
    );
  };

  // Convert a Base64-encoded string to a File object
  const base64StringtoFile = (base64String, filename) => {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const image64toCanvasRef = (canvasRef, image64, pixelCrop) => {
    const canvas = canvasRef;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = image64;
    image.onload = function () {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
    };
  };

  const onDrop = useCallback((acceptedFile, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          setErrors(`Error: ${err.message}`);
        }

        if (err.code === "file-invalid-type") {
          setErrors(`Error: ${err.message}`);
        }
      });
    });

    let reader = new FileReader();
    if (acceptedFile[0]) {
      setImage(acceptedFile[0]);
      reader.onloadend = () => {
        setImageData(acceptedFile[0]);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(acceptedFile[0]);
    }
  }, []);

  const getImageDimensions = async (imagePreviewUrl) => {
    var img = new Image();
    const dimensions = await new Promise((resolve) => {
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      img.src = imagePreviewUrl;
    });
    return dimensions;
  };

  const handleClick = () => {
    const canvasRef = imagePrevCanvasRef.current;
    const fileExtension = extractImageFileExtensionFromBase64(imagePreviewUrl);
    const imageData64 = canvasRef.toDataURL("image/" + fileExtension);

    const myFilename = "previewFile." + fileExtension;
    //file to be uploaded
    const myNewCropppedFile = base64StringtoFile(imageData64, myFilename);
    if (myNewCropppedFile.size > MAX_SIZE) {
      setErrors("Image size should be less then 2 MB");
      setImageData(null);
      setImagePreviewUrl(null);
    }
    if (details.image && myNewCropppedFile.size < MAX_SIZE) {
      let updateData = new FormData();
      updateData.append("id", details.id);
      updateData.append("image", myNewCropppedFile);
      setIsLoading(true);
      API.updateImage(updateData)
        .then((res) => {
          refetchDetails();
          setIsLoading(false);
          localStorage.setItem("image", res.data.image);
          if (res.error) {
            setMessage(res.message);
            setIsLoading(false);
          }
          setImageData(res.image);
          setOpenModal(!openModal);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      API.uploadImage(details.id, myNewCropppedFile)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("image", res.data.image);
            refetchDetails();
            setIsLoading(false);
            setOpenModal(!openModal);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleDeleteImage = (id) => {
    setIsLoadingDelete(true);
    API.deleteImage(id)
      .then((res) => {
        if (res.status === 200) {
          setMessage(res.message);
          setImageData(null);
          setImagePreviewUrl(null);
          refetchDetails();
          setIsLoadingDelete(false);
          setOpenModal(!openModal);
          localStorage.removeItem("image");
        }
      })
      .catch((err) => msgCtx.handleError(err));
  };

  const handleImageLoaded = (image) => {};

  const handleOnCropComplete = async (crop, pixelCrop) => {
    const canvasRef = imagePrevCanvasRef.current;
    const dimensions = await getImageDimensions(imagePreviewUrl);
    const canvasCrop = {
      height: (dimensions.height * pixelCrop.height) / 100,
      width: (dimensions.width * pixelCrop.width) / 100,
      y: (dimensions.height * pixelCrop.y) / 100,
      x: (dimensions.width * pixelCrop.x) / 100,
    };
    image64toCanvasRef(canvasRef, imagePreviewUrl, canvasCrop);
  };

  return (
    <div className="PhotoUploaderModal">
      <div className="PhotoUploaderModal__container">
        <div className="PhotoUploaderModal__subtitle-wrap">
          <h2 className="PhotoUploaderModal__subtitle">Photo Uploader</h2>
          <span
            className="PhotoUploaderModal__close"
            onClick={() => setOpenModal(!openModal)}
          >
            &times;
          </span>
        </div>
        <Dropzone
          onDrop={onDrop}
          accept={"image/*"}
          multiple={false}
          minSize={0}
          maxSize={2000000}
        />

        <Form className="PhotoUploaderModal__content">
          <h1 className="PhotoUploaderModal__title">
            Upload a picture of yourself
          </h1>

          {imagePreviewUrl && (
            <>
              <ReactCrop
                src={imagePreviewUrl}
                crop={crop}
                onImageLoaded={handleImageLoaded}
                onComplete={handleOnCropComplete}
                onChange={(newCrop) => setCrop(newCrop)}
                locked
              />
              <br />
              {/*   <p>Preview Canvas Crop</p> */}
              <canvas
                ref={imagePrevCanvasRef}
                className="PhotoUploaderModal__canvas"
              ></canvas>

              <button
                type="button"
                className="PhotoUploaderModal__button"
                onClick={handleClick}
                disabled={isLoading ? true : false}
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  "Upload"
                )}
              </button>
            </>
          )}
          {details.image && (
            <button
              type="button"
              className="PhotoUploaderModal__button"
              onClick={() => handleDeleteImage(details.image.id)}
              disabled={isLoadingDelete ? true : false}
            >
              {isLoadingDelete ? (
                <Spinner
                  style={{
                    border: "0.25em solid #00d1d6",
                    borderRightColor: "transparent",
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                />
              ) : (
                "Delete"
              )}
            </button>
          )}
          {message && (
            <div className="PhotoUploaderModal__message">{message}</div>
          )}
          {errors && (
            <div className="PhotoUploaderModal__message">{errors}</div>
          )}

          <div className="PhotoUploaderModal__text">
            Note: The photo you specify here will be used across the GoCoffeeGo
            website which display your public photo.
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PhotoUploaderModal;
