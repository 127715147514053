import React, { useContext, createContext } from "react";

const SeoContextValues = {
  pageMetadata: [],
  imageMetadata: [],
  setPageMetadata: () => {},
  setImageMetadata: () => {}
};

export const SeoContext = createContext(SeoContextValues);
export const useSeoContext = () => useContext(SeoContext);
