import React from "react";
import { useHistory } from "react-router-dom";

import "./PopUp.scss";

const PopUp = (props) => {
  const {
    roaster,
    product,
    purveyor,
    prePaid,
    prePaidName,
    PrePaidMessage,
    PrePaidDetail,
    handleClick,
  } = props;
  const history = useHistory();
  const PinterestIcon =
    "https://assets.gocoffeego.com/v2/assets/icons/pinterest-icon.svg";

  return (
    <>
      {product && (
        <div
          id="b"
          className="ProductPopUp"
          onClick={() => history.push(`/${product.product_url}`)}
        >
          <div className="ProductPopUp__details">
            <a
              className="ProductPopUp__pinterest"
              target="_blank"
              rel="noreferrer"
              data-pin-do="buttonPin"
              data-pin-custom
              data-pin-tall="true"
              data-pin-round="true"
              data-pin-description={`${product.name} ${product?.description}`}
              data-pin-url={product?.url}
              href={`https://www.pinterest.com/pin/create/button/?url=${
                product.url
              }${`&description=${product.name}`}${`&media=${product.main_image?.xlg}`}`}
            >
              <img
                src={PinterestIcon}
                alt="pinterest"
                className="ProductPopUp__pinterest-icon"
              />
            </a>
            <img
              className="ProductPopUp__image"
              src={product.main_image?.sm}
              alt="product"
            />
            <div>
              <div className="ProductPopUp__title font-cairo">{product?.purveyor?.name}</div>
              <div className="ProductPopUp__subtitle font-cairo">{product?.name}</div>

              <div className="ProductPopUp__price font-cairo">
                ${product?.retail_price.toFixed(2)} / {product?.weight}
              </div>  
            </div>
          </div>

         {product && (
            <p className="ProductPopUp__description ProductPopUp__description--modifier">
              {product?.description
                ?.replace(/(<([^>]+)>)/gi, "")
                .replace("[BREAK]", " ")
                .substring(0, 150)}
              ...
            </p>
          )}
 
           {purveyor && (
            <p className="ProductPopUp__description">
              {purveyor?.description
                ?.replace(/(<([^>]+)>)/gi, "")
                .replace("[BREAK]", " ")
                .substring(0, 70)}
              ...
            </p>
          )}  
          <div className="ProductPopUp__characteristics">
           {product.coffee_roast && (
            <div className="ProductPopUp__title2">
              <p className="ProductPopUp__title2--name">Roast :</p>
              <p className="ProductPopUp__title2--properties">
                {product.coffee_roast}
              </p>
            </div>
           )} 
           {product.coffee_body && (
            <div className="ProductPopUp__title2">
              <p className="ProductPopUp__title2--name">Body :</p>
              <p className="ProductPopUp__title2--properties">
                {product.coffee_body}
              </p>
            </div>
           )}
           {product.coffee_acidity && (
              <div className="ProductPopUp__title2">
              <p className="ProductPopUp__title2--name">Acidity :</p>
              <p className="ProductPopUp__title2--properties">
                {product.coffee_acidity}
              </p>
              </div>
           )}
           {product.coffee_notes && (
             <div className="ProductPopUp__title2 ProductPopUp__title2--notes ">
              <p className="ProductPopUp__title2--name"> Notes : </p>
              <p className="ProductPopUp__title2--properties">
                {product.coffee_notes}
              </p>
            </div>
           )}
          </div>
        </div>
      )}
      {roaster && (
        <div className="RoasterPopUp">
          <div className="RoasterPopUp__title font-cairo">{roaster.name}</div>
          <img
            className="RoasterPopUp__image"
            src={roaster.main_image?.sm}
            alt="roaster"
          />
          <p className="RoasterPopUp__description">
            {roaster?.description
              ?.replace(/(<([^>]+)>)/gi, "")
              .replace("[BREAK]", " ")
              .substring(0, 120)}
            ...
          </p>
          <p className="RoasterPopUp__location">
            <span>Location: </span>
            <span>
              {roaster.city},{roaster.state?.code}
            </span>
          </p>
        </div>
      )}
      {prePaid && (
        <div className="PrePaidPopUp" onClick={() => handleClick()}>
          <div className="PrePaidPopUp__title font-cairo">{prePaidName}</div>
          <div className="PrePaidPopUp__subtitle font-cairo"> {PrePaidMessage}</div>
          <p className="PrePaidPopUp__description">
            {" "}
            {PrePaidDetail?.replace(/(<([^>]+)>)/gi, "")
              .replace("[BREAK]", " ")
              .substring(0, 280)}
            ...
          </p>
        </div>
      )}
    </>
  );
};

export default PopUp;
