import React from "react";
import QuizButton from "../../shared/QuizButton/QuizButton";
import "./TakeQuiz.scss";

const TakeQuiz = () => {
  return (
    <div className="TakeQuiz">
      <div className="TakeQuiz__content">
        <h2 className="title">FIND YOUR PERFECT COFFEE</h2>
        <div className="button-container">
          <QuizButton />
        </div>
        <h2 className="subtitle">WHO ARE YOU? WE REALLY WANT TO KNOW!</h2>
      </div>
    </div>
  );
};

export default TakeQuiz;
