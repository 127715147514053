import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useLoginFormik } from "./useLoginFormik";

import { Button, Form, Input, Spinner } from "reactstrap";

import "./LoginForm.scss";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

const LoginForm = () => {
  const params = useParams();
  const pathParam = params.pathParam;
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const AuthCtx = useAuthContext();
  const msgCtx = useMessageContext();

  const formik = useLoginFormik({
    onSubmit: async (values) => {
      const cartId = localStorage.getItem("cart_id");
      setIsLoading(true);
      AuthCtx.login(values, cartId, pathParam).then(() => setIsLoading(false));
    },
  });

  return (
    <Form className="LoginForm" onSubmit={formik.handleSubmit}>
      <div className="LoginForm__input-container LoginForm__input-container--sign-in">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="LoginForm__input "
          name="email"
          type="email"
          placeholder="E-mail"
        />
        {formik.errors.email && formik.touched.email && (
          <div className="LoginForm__required"> {formik.errors.email} </div>
        )}
      </div>

      <div className="LoginForm__input-container LoginForm__input-container--sign-up">
        <Input
          onChange={(e) => {
            formik.handleChange(e);
            AuthCtx.resetUserError();
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
          value={formik.values.password}
          className="LoginForm__input"
          name="password"
          type="password"
          placeholder="Password"
        />
        {formik.errors.password && formik.touched.password && (
          <div className="LoginForm__required"> {formik.errors.password} </div>
        )}
        {AuthCtx.userError && (
          <p className="LoginForm__required">
            Incorrect email or password, please try again
          </p>
        )}
      </div>

      <div className="LoginForm__remember-me">
        <div className="LoginForm__remember">
          <input
            onChange={(e) => {
              setRememberMe(e.target.checked);
            }}
            type="checkbox"
            value={rememberMe}
            name="checkbox"
          />
          <label className="LoginForm__label2" htmlFor="checkbox">
            Remember me
          </label>
        </div>
        <Link to="/forgot-password" className="LoginForm__link">
          Forgot your password?
        </Link>
      </div>
      <button
        disabled={isLoading ? true : false}
        type="submit"
        className="LoginForm__button"
      >
        {isLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #000000",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : (
          "SIGN IN"
        )}
      </button>
      <p className="LoginForm__link-container">
        First time shopping here?
        <Link className="LoginForm__link" to="/sign-up">
          Create an account!
        </Link>
      </p>
    </Form>
  );
};

export default LoginForm;
