import React from "react";

import cs from "classnames";
import "./RadioButton.scss";

const RadioButton = (props) => {
  const { selectedItem, style, id, name, addressbox, className } = props;

  return (
    <div
      className={cs("RadioButton", className)}
      style={style}
      onClick={() => {
        if (addressbox) {
          props.onClick(id);
          return;
        }
        props.onClick(name);
      }}
    >
      {addressbox ? (
        <>
          <div className="RadioButton__outer-circle">
            <div
              id="Radio-button"
              className={`RadioButton__inner-circle ${
                selectedItem === id && "RadioButton__inner-circle--active"
              }`}
            />
          </div>
          <div className="RadioButton__label">{name}</div>
        </>
      ) : (
        <>
          <div className="RadioButton__outer-circle">
            <div
              id="Radio-button"
              className={`RadioButton__inner-circle ${
                selectedItem === id && "RadioButton__inner-circle--active"
              }`}
            />
          </div>
          <div className="RadioButton__label">{name}</div>
        </>
      )}
    </div>
  );
};

export default RadioButton;
