import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import LinkButton from "../../shared/LinkButton/LinkButton";

import { Icon } from "../../shared/Icon/Icon";
//import { creditCardDeclined } from "../../../lib/helpers/messages";

import "./ContactUsModal.scss";

const ContactUsModal = (props) => {
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="ContactUsModal">
        <div className="ContactUsModal__container">
          <div className="ContactUsModal__header">
            <h1 className="ContactUsModal__header-title">
              Do you need our help?
            </h1>
            <Icon
              style={{ width: "20px", height: "20px" }}
              onClick={props.toggle}
              className="ContactUsModal__close"
              icon="close"
            />
          </div>
          <div className="ContactUsModal__body">
            <div className="ContactUsModal__paragraph-section">
              <br />
              <p>For further assistance go to:</p>
              <LinkButton
                className="ContactUsModal__button"
                label="Contact us "
                path="/contact-us"
              />
              <p className="ContactUsModal__text">We are happy to help!</p>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ContactUsModal;
