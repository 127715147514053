import React, { useState, useEffect } from "react";
import * as API from "../../../api/Api";
import { Input, Spinner } from "reactstrap";

import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { downloadPdf } from "../../../lib/helpers/downloadPdf";
import Button from "../../shared/Button/Button";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import { promoFieldRequired } from "../../../lib/helpers/messages";

import "./TotalCart.scss";

const TotalCart = (props) => {
  const { showCode, recieptData, checkout } = props;
  const [promoCode, setPromoCode] = useState();
  const cartCtx = useCartContext();
  const authCtx = useAuthContext();
  const [promoError, setPromoError] = useState();
  const [promoDiscount, setPromoDiscount] = useState(false);
  const [totalPromoDiscount, setTotalPromoDiscount] = useState();
  const [giftCertificateError, setGiftCertificateError] = useState("");
  const [giftCertificateDiscount, setGiftCertificateDiscount] = useState(false);
  const [
    totalGiftCertificateDiscount,
    setTotalGiftCertificateDiscount,
  ] = useState();
  const [giftCertificateCode, setGiftCertificateCode] = useState();
  const [isDownloading, setDowloading] = useState({
    giftCert: false,
    subscription: false,
  });
  const [isLoadingSpinner, setIsLoadingSpinner] = useState({
    giftCert: false,
    promo: false,
  });
  const autoshipData = cartCtx.autoshipData;
  const cartItems = cartCtx.cartItems;
  const isLoading = cartCtx.isLoading;
  const shipping_address = authCtx?.customerData?.shipping_address;
  const shipments = cartCtx.shipments.shipments;
  const params = useParams();
  const pathParam = params.pathParam;
  const location = useLocation();
  const history = useHistory();
  const msgCtx = useMessageContext();

  useEffect(() => {
    if (location.pathname !== "/order-success") {
      cartCtx.getCartItems();
    }
  }, [shipping_address]);

  useEffect(() => {
    // console.log('num cart items', cartItems?.items?.length)
    if (
      location.pathname === "/checkout" &&
      cartItems?.items?.length === 0 &&
      !isLoading
    ) {
      // console.log("cart is empty, redirecting back to cart");
      history.push("/cart");
    }
  }, [cartItems]);

  const handlePromoCodeSubmit = () => {
    if (promoCode) {
      setIsLoadingSpinner({ promo: true });
      API.applyPromoCode(promoCode)
        .then((res) => {
          if (res.data.result === "OK") {
            setPromoDiscount(true);
            setTotalPromoDiscount(res.data.total_promos_discount);
          }
          cartCtx.getCartItems();
          setIsLoadingSpinner({ promo: false });
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
            setIsLoadingSpinner({ promo: false });
          }
        });
    } else {
      setPromoError(promoFieldRequired);
    }
  };

  const handleGiftCertificateCodeSubmit = () => {
    setIsLoadingSpinner({ giftCert: true });
    API.applyGiftCertificate(giftCertificateCode)
      .then((res) => {
        if (res.status === 200) {
          setGiftCertificateDiscount(true);
          setTotalGiftCertificateDiscount(res.data?.result);
        }
        cartCtx.getCartItems();
        setIsLoadingSpinner({ giftCert: false });
      })
      .catch((err) => {
        if (err) {
          msgCtx.handleError(err);
          setIsLoadingSpinner({ giftCert: false });
        }
      });
  };

  const totals = recieptData?.totals;
  const orderProducts = recieptData?.order_products;

  return (
    <div className="TotalCart">
      {shipments && !pathParam === "autoship" && (
        <h1 className="TotalCart__title2 TotalCart__title2-shipments">
          Shipments
        </h1>
      )}

      {checkout && (
        <div className="TotalCart__summary-wrapper">
          <h2 className="TotalCart__title2 TotalCart__title2-shipments">
            Order Summary
          </h2>
          <p
            className="TotalCart__edit-link"
            onClick={() => history.push("/cart")}
          >
            Edit Order
          </p>
        </div>
      )}

      {pathParam === undefined ||
      location.pathname.startsWith("/order-success") ? (
        <>
          {/* shipments grouping new look */}

          {!isLoading && cartItems?.shipments && (
            <div className="TotalCart__shipment-container">
              {!orderProducts &&
                /*    cartItems?.shipments && */
                cartItems?.shipments?.map((item) => (
                  <div className="TotalCart__shipment">
                    {item?.items.map((product) => (
                      <div
                        onClick={() => {
                          !product.gc_id ||
                            (!product.gs_id &&
                              history.push(
                                `/products/${product?.product?.slug}/${product?.product?.id}``/products/${product?.product?.slug}/${product?.product?.id}`
                              ));
                        }}
                        className="TotalCart__item"
                      >
                        {/*this is for gift certifiacate*/}
                        {product?.gift_from && product?.product?.image && (
                          <img
                            className="TotalCart__image"
                            src={product?.product?.image}
                            alt={product?.product?.name}
                          />
                        )}
                        {product?.gift_from &&
                          product.product?.main_image?.xlg && (
                            <img
                              className="TotalCart__image"
                              src={product.product?.main_image?.xlg}
                              alt={product?.product?.name}
                            />
                          )}
                        {!product?.gift_from && (
                          <img
                            className="TotalCart__image"
                            src={product.product?.main_image?.xlg}
                            alt={product?.product?.name}
                          />
                        )}
                        {location.pathname.startsWith("/checkout") && (
                          <div className="TotalCart__title-container">
                            <h1 className="TotalCart__title">
                              {product.product.name}
                            </h1>
                            {/*/!*title for gift certificate*!/*/}
                            {/*{!product.name && !product.gift_subscription && (*/}
                            {/*  <h1 className="TotalCart__title">*/}
                            {/*    Gift Certificate*/}
                            {/*  </h1>*/}
                            {/*)}*/}
                            <h1 className="TotalCart__title">
                              {product?.coffee_grind?.name}
                            </h1>
                            <h1 className="TotalCart__title">
                              {" "}
                              Qty : {product.quantity}
                            </h1>
                          </div>
                        )}
                        {product.gift_from && !product.gift_subscription && (
                          <h1 className="TotalCart__price">
                            {product.gift_from && !product.gift_subscription
                              ? `$${product.product.gift_amount?.toFixed(2)}`
                              : `$${product.product.retail_price?.toFixed(
                                  2
                                )} / ${product.product.weight}`}
                          </h1>
                        )}
                        {product?.id && !product.gift_from ? (
                          <h1 className="TotalCart__price">
                            <span
                              className={
                                product?.product?.sale.active
                                  ? "TotalCart__retail-price--sale"
                                  : "TotalCart__retail-price"
                              }
                            >
                              {" "}
                              {`$${product?.product?.retail_price?.toFixed(2)}`}
                            </span>
                            {product?.product?.sale.active &&
                              product?.product?.sale_price && (
                                <>
                                  <span className="TotalCart__sale-price">
                                    {` $${product?.product?.sale_price?.toFixed(
                                      2
                                    )}`}
                                  </span>
                                  <br />
                                </>
                              )}
                            <span>{` / ${product?.product?.weight}`}</span>
                          </h1>
                        ) : null}

                        {product?.gift_from && product?.gift_subscription && (
                          <h1 className="TotalCart__price">
                            {`$${product?.gift_subscription?.price?.toFixed(2)}`}
                          </h1>
                        )}
                      </div>
                    ))}

                    {item?.purveyor?.name && shipping_address && (
                      <div className="TotalCart__shipment-wrapper1">
                        <h1 className="TotalCart__shipment-title">
                          Shipping for {item?.purveyor?.name}
                        </h1>
                        <h1 className="TotalCart__shipment-title">
                          ${item.delivery_cost?.toFixed(2)}
                        </h1>
                      </div>
                    )}
                    {item?.special && shipping_address && (
                      <div className="TotalCart__shipment-wrapper2">
                        <h1 className="TotalCart__shipment-title">
                          {`(Special) ${item?.special?.special?.name}`}
                        </h1>

                        <h1 className="TotalCart__shipment-title">
                          {` - $${item?.special?.discount?.toFixed(2)}`}
                        </h1>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}

          {orderProducts && location.pathname.startsWith("/order-success") && (
            <div className="TotalCart__shipment">
              {orderProducts.map((product) => (
                <>
                  <div
                    onClick={() => {
                      if (product.gs_id !== null || product.gc_id !== null) {
                        return;
                      } else {
                        history.push(
                          `/products/${product?.product?.slug}/${product?.product?.id}`
                        );
                      }
                    }}
                    className="TotalCart__item"
                  >
                    {product?.product?.gift_amount &&
                      product?.product?.image && (
                        <img
                          className="TotalCart__image"
                          src={product?.product?.image}
                          alt={product?.product?.name}
                        />
                      )}
                    {product?.product?.gift_amount &&
                      product.product?.main_image?.xlg && (
                        <img
                          className="TotalCart__image"
                          src={product.product?.main_image?.xlg}
                          alt={product?.product?.name}
                        />
                      )}
                    {!product?.product?.gift_amount && (
                      <img
                        className="TotalCart__image"
                        src={product.product?.main_image?.xlg}
                        alt={product?.product?.name}
                      />
                    )}
                    <div className="TotalCart__title-container">
                      <h1 className="TotalCart__title">
                        {product.product.name}
                      </h1>
                      {/*   {product.gc_id && (
                        <h1 className="TotalCart__title">Gift Certificate</h1>
                      )} */}
                      <h1 className="TotalCart__title">
                        {product?.coffee_grind?.name}
                      </h1>
                      <h1 className="TotalCart__title">
                        Qty: {product.quantity}
                      </h1>
                    </div>
                    {product?.product?.gift_amount &&
                      !product.gift_subscription && (
                        <h1 className="TotalCart__price">
                          {product?.product?.gift_amount &&
                          !product.gift_subscription
                            ? `$${product.product.gift_amount?.toFixed(2)}`
                            : `$${product.product.retail_price?.toFixed(2)} / ${
                                product.product.weight
                              }`}
                        </h1>
                      )}
                    {product?.id &&
                    !product?.product?.gift_amount &&
                    !product?.gift_subscription ? (
                      <h1 className="TotalCart__price">{`$${product?.product?.retail_price?.toFixed(
                        2
                      )} / ${product?.product?.weight}`}</h1>
                    ) : null}

                    {!product?.product?.gift_amount &&
                    product?.gift_subscription ? (
                      <h1 className="TotalCart__price">
                        {`$${product?.gift_subscription?.price?.toFixed(2)}`}
                      </h1>
                    ) : null}
                  </div>
                  {product?.gc_id && (
                    <>
                      <Button
                        label="Download Gift Certificate"
                        onClick={() => {
                          setDowloading({ giftCert: true });
                          API.downloadGift(product.gc_id).then((res) => {
                            downloadPdf(res, "GoCoffeeGoGiftCertificate");
                            setDowloading({ giftCert: false });
                          });
                        }}
                        className="OrderProduct__button OrderProduct__button--download-order"
                        isLoading={isDownloading.giftCert}
                        disabled={isDownloading.giftCert ? true : false}
                        spinner={isDownloading.giftCert ? true : false}
                        color={"#ffffff"}
                      />
                      <div className="OrderProduct__download-note">
                        *To print, or redeem and use Gift Certificate please
                        download it here and follow instructions written in gift
                        certificate .pdf file.
                      </div>
                    </>
                  )}
                  {product?.gs_id && (
                    <>
                      <Button
                        onClick={() => {
                          setDowloading({ subscription: true });
                          API.downloadSubscriptionGift(product.gs_id).then(
                            (res) => {
                              downloadPdf(res, "GoCoffeeGoCoffeeSubscription");
                              setDowloading({ subscription: false });
                            }
                          );
                        }}
                        className="OrderProduct__button OrderProduct__button--download-order"
                        label="Download Coffee Subscription"
                        isLoading={isDownloading.subscription}
                        disabled={isDownloading.subscription ? true : false}
                        spinner={isDownloading.subscription ? true : false}
                        color={"#ffffff"}
                      />
                      <div className="OrderProduct__download-note">
                        *To print Coffee Subscription please download it here.
                      </div>
                    </>
                  )}
                </>
              ))}
            </div>
          )}

          <div
            className={`TotalCart__total ${
              !showCode && "TotalCart__total--border"
            }`}
          >
            <h1 className="TotalCart__title2"> Items </h1>
            <h1 className="TotalCart__title2">
              $
              {totals?.subtotal
                ? totals.subtotal?.toFixed(2)
                : cartItems?.totals?.subtotal?.toFixed(2)}
            </h1>
          </div>

          {cartItems?.totals && (
            <div
              className={`TotalCart__total ${
                !showCode && "TotalCart__total--border"
              }`}
            >
              <h1 className="TotalCart__title2">Tax</h1>
              <h1 className="TotalCart__title2">
                ${cartItems?.totals?.tax?.toFixed(2)}
              </h1>
            </div>
          )}

          {cartItems?.totals && orderProducts && (
            <div
              className={`TotalCart__total ${
                !showCode && "TotalCart__total--border"
              }`}
            >
              <h1 className="TotalCart__title2">Delivery</h1>

              <h1 className="TotalCart__title2">
                {totals?.delivery ? `$${totals?.delivery}` : `$0.00`}
              </h1>
            </div>
          )}

          {shipping_address && cartItems?.totals && !orderProducts && (
            <div
              className={`TotalCart__total ${
                !showCode && "TotalCart__total--border"
              }`}
            >
              <h1 className="TotalCart__title2">Delivery</h1>
              {cartItems?.totals?.delivery !== 0 && (
                <h1 className="TotalCart__title2">
                  {cartItems?.totals?.delivery
                    ? ` $${cartItems?.totals?.delivery?.toFixed(2)}`
                    : `$0.00`}
                </h1>
              )}
            </div>
          )}
          {!shipping_address && !orderProducts && (
            <h1 className="TotalCart__shipping-message">
              Shipping price will be added after you choose a shipping address
            </h1>
          )}

          {totals ? (
            <>
              {totals?.specials !== "0.00" && (
                <div
                  className={`TotalCart__total ${
                    !showCode && "TotalCart__total--border"
                  }`}
                >
                  <h1 className="TotalCart__title2">Specials</h1>
                  <h1 className="TotalCart__title2">-${totals.specials}</h1>
                </div>
              )}

              {totals?.credits !== 0 &&
                location.pathname.startsWith("/order-success") && (
                  <div
                    className={`TotalCart__total ${
                      !showCode && "TotalCart__total--border"
                    }`}
                  >
                    <h1 className="TotalCart__title2">Credits</h1>
                    <h1 className="TotalCart__title2">
                      -${totals.credits?.toFixed(2)}
                    </h1>
                  </div>
                )}

              {totals?.promos !== "0.00" && (
                <div
                  className={`TotalCart__total ${
                    !showCode && "TotalCart__total--border"
                  }`}
                >
                  <h1 className="TotalCart__title2">Promos</h1>
                  <h1 className="TotalCart__title2">
                    -${totals.promos?.toFixed(2)}
                  </h1>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={`TotalCart__total ${
                  !showCode && "TotalCart__total--border"
                }`}
              >
                <h1 className="TotalCart__title2">Specials</h1>
                <h1 className="TotalCart__title2">
                  {cartItems?.totals?.specials > 0 && "-"} $
                  {cartItems?.totals?.specials?.toFixed(2)}
                </h1>
              </div>

              <div
                className={`TotalCart__total ${
                  !showCode && "TotalCart__total--border"
                }`}
              >
                <h1 className="TotalCart__title2">Promos</h1>
                <h1 className="TotalCart__title2">
                  {cartItems?.totals?.promos > 0 && "-"} $
                  {cartItems?.totals?.promos?.toFixed(2)}
                </h1>
              </div>

              <div
                className={`TotalCart__total ${
                  !showCode && "TotalCart__total--border"
                }`}
              >
                <h1 className="TotalCart__title2">Credits</h1>
                <h1 className="TotalCart__title2">
                  {cartItems?.totals?.credits > 0 && "-"} $
                  {cartItems?.totals?.credits?.toFixed(2)}
                </h1>
              </div>
            </>
          )}

          {showCode && (
            <>
              <div className="Code__container">
                {!authCtx?.user?.guest && (
                  <div className="Code">
                    <Input
                      onChange={(e) => setGiftCertificateCode(e.target.value)}
                      className="Code__input"
                      placeholder="Gift Card/Certificate number "
                      type="text"
                    />
                    {isLoadingSpinner.giftCert ? (
                      <div className="Code__button">
                        <Spinner
                          style={{
                            border: `0.25em solid #000000`,
                            borderRightColor: "transparent",
                            width: "2rem",
                            height: "2rem",
                            marginLeft: "2.3rem",
                            marginTop: "0.5rem",
                          }}
                        />
                      </div>
                    ) : (
                      <button
                        onClick={handleGiftCertificateCodeSubmit}
                        className="Code__button"
                      >
                        {" "}
                        Apply{" "}
                      </button>
                    )}
                  </div>
                )}
                {giftCertificateError && (
                  <p className="Code__active"> {giftCertificateError} </p>
                )}
                {totalGiftCertificateDiscount && (
                  <p className="Code__success">
                    {totalGiftCertificateDiscount}
                  </p>
                )}
              </div>
              <div className="Code__container">
                <div className="Code">
                  <Input
                    onChange={(e) => setPromoCode(e.target.value)}
                    className="Code__input"
                    placeholder="Promo Code"
                    type="text"
                  />
                  {isLoadingSpinner.promo ? (
                    <div className="Code__button">
                      <Spinner
                        style={{
                          border: `0.25em solid #000000`,
                          borderRightColor: "transparent",
                          width: "2rem",
                          height: "2rem",
                          marginLeft: "2.3rem",
                          marginTop: "0.5rem",
                        }}
                      />
                    </div>
                  ) : (
                    <button
                      onClick={handlePromoCodeSubmit}
                      className="Code__button"
                    >
                      {" "}
                      Apply{" "}
                    </button>
                  )}
                </div>
                {promoError && <p className="Code__active">{promoError} </p>}
                {promoDiscount && (
                  <p className="Code__success">
                    Promo is applied succesfully, you have $
                    {totalPromoDiscount?.toFixed(2)} discount
                  </p>
                )}
              </div>
            </>
          )}
          <div
            className={`TotalCart__total ${
              showCode && "TotalCart__total--border"
            }`}
          >
            <h1 className="TotalCart__title2 TotalCart__title2-total">
              {" "}
              Order Total{" "}
            </h1>
            <h1 className="TotalCart__title2 TotalCart__title2-total">
              $
              {totals?.total
                ? totals.total?.toFixed(2)
                : cartItems?.totals?.total?.toFixed(2)}
            </h1>
          </div>
        </>
      ) : (
        <>
          {!isLoading &&
            autoshipData?.items?.map((item) => (
              <>
                <div className="TotalCart__item">
                  <img
                    className="TotalCart__image"
                    src={
                      item?.gift_from
                        ? item?.product?.image
                        : item.product?.main_image?.xlg
                    }
                    alt="product"
                  />
                  <div>
                    <h1 className="TotalCart__title"> {item.product.name}</h1>
                    <h1 className="TotalCart__title">
                      {item?.coffee_grind?.name}
                    </h1>
                    <h1 className="TotalCart__title"> {item.quantity} pcs</h1>
                  </div>
                  <h1 className="TotalCart__price">
                    {item.gift_from
                      ? `$${item.product.gift_amount}`
                      : `$${item.product.retail_price?.toFixed(2)}/${
                          item.product.weight
                        }`}
                  </h1>
                </div>
              </>
            ))}
        </>
      )}
    </div>
  );
};

export default TotalCart;
