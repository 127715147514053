import { useFormik } from "formik";
import * as Yup from "yup";

const GiftCertificatesSchema = Yup.object().shape({
  sender: Yup.string()
    .required("Required")
    .max(11, "The maximum length is 11 characters"),
  reciever: Yup.string()
    .required("Required")
    .max(11, "The maximum length is 11 characters"),
  giftMessage: Yup.string()
    .required("Required")
    .max(60, "The maximum length of a gift message is 60 characters!"),
});

export const useGiftCertificatesFormik = (opts) => {
  return useFormik({
    initialValues: {
      sender: "",
      reciever: "",
      giftMessage: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: GiftCertificatesSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
