import React, { useState, useEffect } from "react";
import * as API from "../../api/Api";
import { useLocation } from "react-router-dom";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import StarsRating from "../../components/Orders/StarsRating/StarsRating";
import RockstarCoffes from "../../components/Home/RockstarCoffes/RockstarCoffes";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import Line from "../../components/shared/Line/Line";

import "./CustomerReviews.scss";

const CustomerReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(false);
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  useEffect(() => {
    API.getCustomerReviews()
      .then((res) => {
        setReviews(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);
      })
      .catch((err) => console.log(err, "err"));
  }, []);

  const loadMore = () => {
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });
    API.getCustomerReviews(currentPage + 1)
      .then((res) => {
        setReviews((reviews) => reviews.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      })
      .catch((err) => console.log(err, "err"));
  };

  return (
    <div className="CustomerReviews">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        title="Customer reviews"
        className="CustomerReviews__title"
      />
      <div className="CustomerReviews__container">
        {reviews?.map((review) => (
          <div className="CustomerReviews__review-wrap" key={review?.id}>
            <StarsRating
              totalStars={review?.stars ? review?.stars : 5}
              starsSelected={review?.stars}
            />
            {review?.content && (
              <div>
                <div className="CustomerReviews__review-title">
                  {review?.title}
                </div>
                <div className="CustomerReviews__review-text">
                  {review?.content}
                </div>
              </div>
            )}

            {review?.name && (
              <div className="CustomerReviews__customer-name">
                - {review?.name}
              </div>
            )}
          </div>
        ))}
        {hasMorePage && (
          <ViewMoreButton
            handleClick={loadMore}
            style={{ marginTop: "4rem" }}
          />
        )}
      </div>
      <Line style={{ marginTop: "4rem" }} />
      <Line />
      <RockstarCoffes />
    </div>
  );
};

export default CustomerReviews;
