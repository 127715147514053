import React, { useState, useEffect } from "react";
import * as API from "../../api/Api";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import { useLocation } from "react-router-dom";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import Product from "../../components/Shop/Product/Product";
import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Tea.scss";

const Tea = () => {
  const [teaProduct, setTeaProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(false);

  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;
  const pathnamesArray = pathname.split("/");

  const teaLady =
    "https://assets.gocoffeego.com/v2/assets/images/lady.png";
  const flowers =
    "https://assets.gocoffeego.com/v2/assets/images/four-flowers.png";

  useEffect(() => {
    setIsLoading(true);
    try {
      API.getTeaProducts().then((res) => {
        setTeaProducts(res.data);
        setHasMorePage(res.pagination?.has_more);
        setIsLoading(false);
      })
    }catch(error) {
      console.log(error, "err")
    }
  
  }, []);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });

    API.getTeaProducts(currentPage + 1).then((res) => {
      setTeaProducts((products) => products.concat(res.data));
      setHasMorePage(res.pagination?.has_more);
    }).catch(err => console.log(err));
  };

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url.toLowerCase() === pathname);

  return (
    <div className="Tea">
      <PrimaryTitle title="Tea" className="Tea__title" notMainTitle/>

      <div className="Tea__container">
      {foundSeo && (
        <>
        {foundSeo?.h1 && (<h1  className="Tea__main-title">{foundSeo?.h1}</h1>)}
        {foundSeo?.blurb && (<p className="Tea__description">{foundSeo?.blurb}</p>)} 
        </>
        )} 
      {foundSeo && (
          <Seo
            title={foundSeo?.title}
            description={foundSeo?.description}
            keywords={foundSeo?.keywords}
          />
        )}
        {isLoading ? (
          <div className="Tea__products-wrapper">
            {Array(12)
              .fill(0)
              .map((item, index) => (
                <ProductLoader key={index} />
              ))}
          </div>
        ) : (
          <>
            {!isLoading && teaProduct.length !== 0 && (
              <div className="Tea__products-wrapper">
                <img src={teaLady} className="Tea__lady" alt="tea lady" />
                <img
                  src={flowers}
                  className="Tea__flowers"
                  alt="four flowers"
                />
                {teaProduct?.map((product) => (
                  <Product product={product} key={product?.id} />
                ))}
              </div>
            )}
          </>
        )}
        {hasMorePage && (
          <ViewMoreButton
            handleClick={handleLoadMore}
            style={{ marginTop: "2rem", marginBottom: "5rem" }}
          />
        )}
      </div>
    </div>
  );
};

export default Tea;
