import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";
import { Link } from "react-router-dom";
import cs from "classnames";

import "./QuizButton.scss";

const QuizButton = (props) => {
  const { style, className } = props;

  const ProductCtx = useFilterProductsContext();
  const quiz = ProductCtx.quizResult;

  return (
    <Link to="/quiz">
      <div
        className={cs("QuizButton", className)}
        style={style}
        onClick={() => localStorage.setItem("route_from", "/quiz")}
      >
        <span className="QuizButton__text">
          {quiz?.id ? "RETAKE QUIZ" : "TAKE QUIZ"}
        </span>
        <div className="QuizButton__outer">
          <div className="QuizButton__inner" />
        </div>
      </div>
    </Link>
  );
};

export default QuizButton;
