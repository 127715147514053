import React from "react";
import Star from "./Star";
import "./StarsRating.scss";

const StarsRating = ({ totalStars, starsSelected, selectStar, disabled }) => {
  return (
    <div className="StarsRating__rating">
      {[...Array(totalStars)].map((n, i) => (
        <Star
          key={i}
          selected={i < starsSelected}
          onClick={disabled ? null : () => selectStar(i + 1)}
          disabled
        />
      ))}
    </div>
  );
};

export default StarsRating;
