import React from "react";
import cs from "classnames";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";

import "./SectionTitle.scss";

const SectionTitle = (props) => {
  const {
    title,
    className,
    white,
    titleClassName,
    middleLine,
    middleLineBottom,
    titleTag
  } = props;

  const TagName = `${titleTag || "h2"}`;

  return (
    <div className={cs("SectionTitle", className)}>
      {middleLine && <MiddleLine style={white && { background: "#ffffff" }} />}
      <TagName
        style={white && { color: "#ffffff" }}
        className={cs("SectionTitle__title font-cairo", titleClassName)}
      >
        {title}
      </TagName>
      {middleLineBottom && (
        <MiddleLine style={white && { background: "#ffffff" }} />
      )}
    </div>
  );
};

export default SectionTitle;
