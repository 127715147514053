import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import QuizQuestionTitle from "../QuizQuestionTitle/QuizQuestionTitle";

import QuizLines from "../QuizLines/QuizLines";
import cs from "classnames";

import "./QuizQuestion2.scss";

const QuizQuestion2 = (props) => {
  const redCar =
    "https://assets.gocoffeego.com/v2/assets/images/red-car.png";
  const Board =
    "https://assets.gocoffeego.com/v2/assets/images/Checkerboard.png";
  const smallTarget =
    "https://assets.gocoffeego.com/v2/assets/images/sm-target.png";
  const { selectedAnswer } = props;
  const [answer, setAnswer] = useState();

  useEffect(() => {
    API.getQuizQuestionTwo().then((res) => {
      setAnswer(res.data);
    });
  }, []);

  return (
    <div id="question-2" className="QuizQuestion2">
      <QuizQuestionTitle
        questionNumber={2}
        question={"What's type of coffee are you looking for?"}
      />
      <div className="QuizQuestion2__container">
        <img src={redCar} alt="red car" className="QuizQuestion2__red-car" />
        <img
          src={Board}
          alt="Checkerboard"
          className="QuizQuestion2__checkerboard"
        />
        {typeof answer === "object" &&
          answer.map((item) => (
            <div
              key={item.id}
              onClick={() => props.onItemSelect(item.id)}
              className={`QuizQuestion2__wrap  QuizQuestion2__wrap-${item.id}`}
            >
              {/*{item.answerTitle && (*/}
              {/*  <h1 className="QuizQuestion2__answer-title">*/}
              {/*    {" "}*/}
              {/*    {item.answerTitle}*/}
              {/*  </h1>*/}
              {/*)}*/}
              <p
                className={cs(
                  "QuizQuestion2__answer-text",
                  selectedAnswer === item.id &&
                    "QuizQuestion2__answer-text--active"
                )}
              >
                {" "}
                {item.name}
              </p>
            </div>
          ))}
      </div>
      <img
        src={smallTarget}
        alt="small target"
        className="QuizQuestion2__small-target"
      />
      <QuizLines className="QuizQuestion2__quiz-lines" />
    </div>
  );
};

export default QuizQuestion2;
