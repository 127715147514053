import React, { useState } from "react";
import * as API from "../../api/Api";
import { useHistory } from "react-router-dom";

import ShippingAddress from "../../components/Checkout/ShippingAddress/ShippingAddress";
import PaymentMethod from "../../components/Checkout/PaymentMethod/PaymentMethod";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./JoinClub.scss";

const JoinClub = () => {
  const [message, setMessage] = useState("");
  const [loading, isLoading] = useState(false);
  const [addressIsSelected, setAddressIsSelected] = useState();
  const AuthCtx = useAuthContext();

  let history = useHistory();

  // const giftSubscriptionAddress = CartCtx.cartItems?.gift_subscription_adress;
  const giftSubscriptionAddress =
    AuthCtx.customerData?.gift_subscription_adress;

  const proceedToJoinClub = () => {
    const cartId = localStorage.getItem("cart_id");
    const planId = parseInt(localStorage.getItem("plan_id"));

    isLoading(true);

    setTimeout(() => {
      API.joinClub(cartId, planId)
        .then((res) => {
          isLoading(false);
          if (res.status === 200) {
            setTimeout(() => history.push("/account/coffee-club"), 2000);
          }
        })
        .catch((err) => {
          setMessage(err.response.data.error.message);
          isLoading(false);
        });
    }, 2000);
  };

  return (
    <div className="JoinClub">
      <h1 className="JoinClub__title">One more step to join club</h1>
      <h1 className="JoinClub__title JoinClub__title--second">
        Choose your shipping address & credit card
      </h1>

      <div className="JoinClub__container">
        <ShippingAddress
          setAddressIsSelected={() => setAddressIsSelected(true)}
          joinClub
        />

        <PaymentMethod
          canProceed={addressIsSelected || giftSubscriptionAddress}
          joinClubNow={() => proceedToJoinClub()}
          joinClub
        />

        {/*{!addressIsSelected && (*/}
        {/*  <h1 className="JoinClub__message JoinClub__message--required">*/}
        {/*    You must update this address in order to proceed*/}
        {/*  </h1>*/}
        {/*)}*/}
      </div>

      {message && <div className="JoinClub__message">{message}</div>}
    </div>
  );
};

export default JoinClub;
