import React, { useEffect, useState } from "react";
import * as API from "../../../../api/Api";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

import "./Ages.scss";

const ThirdEra = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    API.getHistoryOfCoffee().then((res) => {
      setContent(res.data);
    });
  }, []);

  const article = {
    title: "The History of Coffee: 1700s",
    image: "https://assets.gocoffeego.com/v2/assets/images/history-of-coffee.png",
    url: "https://www.gocoffeego.com/learn/category/the-history-of-coffee/1700"
  } 

  return (
    <div className="Ages">
      <Seo article={article} />
      {content.slice(23, 36).map((item) => (
        <div key={item?.id}>
          <h3 className="Ages__title font-cairo">{item.title}</h3>
          <div className="Ages__content">
            <p> {item?.content.replace(/(<([^>]+)>)/gi, "")} </p>
            <div className="Ages__images">
              {item?.image1 && (
                <img className="Ages__image" src={item?.image1} alt="ages" />
              )}
              {item?.image2 && (
                <img className="Ages__image" src={item?.image2} alt="ages" />
              )}
              {item?.image3 && (
                <img className="Ages__image" src={item?.image3} alt="ages" />
              )}
            </div>
          </div>
        </div>
      ))}

      <Link
        className="Ages__link"
        to="/learn/category/the-history-of-coffee/1800"
        onClick={() => {
          window.scrollTo({ top: 700, behavior: "smooth" });
        }}
      >
        <h3>Click here for the 1800s.</h3>
      </Link>
    </div>
  );
};

export default ThirdEra;
