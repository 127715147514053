import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

import "./SelectAddress.scss";

let cs = require("classnames");

const SelectAddress = (props) => {
  const { items, selectedItem, className, className2, label } = props;
  const dropDownRef = useRef(null);

  const [showItems, setShowItems] = useState(false);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (item) => {
    props.onItemSelect(item);
    setShowItems(!showItems);
  };

  return (
    <div
      ref={dropDownRef}
      onClick={() => setShowItems(!showItems)}
      className={cs(
        "SelectAddress",
        className,
        selectedItem?.first_name && "SelectAddress--highlight"
      )}
    >
      <div className="SelectAddress__header">
        <div>
          <h2 className="SelectAddress__title"> {label}</h2>
          {selectedItem?.first_name && (
            <h2 className="SelectAddress__title2">
              {selectedItem.first_name} {selectedItem.last_name},{" "}
              {selectedItem.address1}, {selectedItem.address2},{" "}
              {selectedItem?.city}, {selectedItem?.state?.name},{" "}
              {selectedItem?.country?.name}
            </h2>
          )}
        </div>
        <span className="SelectCountry__arrow-down" />
        <img className="SelectAddress__icon" src={Arrow} alt="arrow" />
      </div>

      <div
        className={cs(
          "SelectAddress__items",
          showItems && "active",
          className2
        )}
      >
        {items?.map((item) => {
          return (
            <div
              key={item?.id}
              onClick={() => onItemSelect(item)}
              className={`SelectAddress__item-container ${
                props.selectedItem === item.id ? "activeLabel" : ""
              }`}
            >
              <p className="SelectAddress__item">
                {item?.first_name} {item?.last_name}, {item?.address1},{" "}
                {item?.address2}, {item?.city}, {item?.state?.name},{" "}
                {item?.country?.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectAddress;
