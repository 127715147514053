import React, { useState } from "react";
import { useForgotPasswordFormik } from "./useForgotPasswordFormik";

import * as API from "../../../api/Api";
import { Form, Input, Spinner } from "reactstrap";

import "./ForgotPasswordForm.scss";

const ForgotPasswordForm = () => {
  const [emailSent, setEmailSent] = useState(" ");
  const [emailError, setEmailError] = useState(" ");
  const [isLoading, setIsLoading] = useState(false);
  const formik = useForgotPasswordFormik({
    onSubmit: async (values) => {
      setIsLoading(true);
      API.forgotPassword(values.email)
        .then((res) => {
          if (res.status === 200) {
            setEmailSent(
              " We’ve just sent you an email with instructions on how to change your password"
            );
            setEmailError("");
            setIsLoading(false);
            localStorage.setItem("email", values.email);
          }
        })
        .catch((err) => {
          if (err.response) {
            setEmailSent("");
            setIsLoading(false);
            setEmailError(err.response.data.error.message);
          }
        });
    },
  });

  return (
    <Form className="ForgotPasswordForm" onSubmit={formik.handleSubmit}>
      <p className="ForgotPasswordForm__description">
        Having trouble signing in? Don't worry, we can help.{" "}
      </p>
      <p className="ForgotPasswordForm__description">
        Just re-enter your e-mail address and we will e-mail you a link to a
        page where you can easily create a new password.
      </p>
      <div className="ForgotPasswordForm__input-container ForgotPasswordForm__input-container--sign-in">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="ForgotPasswordForm__input "
          name="email"
          type="email"
          placeholder="E-mail"
        />
      </div>
      {formik.errors.email && formik.touched.email && (
        <div className="ForgotPasswordForm__required">
          {formik.errors.email}
        </div>
      )}

      {emailSent && (
        <div className="ForgotPasswordForm__email-sent">{emailSent}</div>
      )}

      {emailError && (
        <div className="ForgotPasswordForm__error">{emailError}</div>
      )}

      <button
        disabled={isLoading ? true : false}
        type="submit"
        className="ForgotPasswordForm__button"
      >
        {isLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #000000",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : (
          "SUBMIT"
        )}
      </button>
    </Form>
  );
};

export default ForgotPasswordForm;
