import React, { useState } from "react";

import Button from "../../../shared/Button/Button";

import cs from "classnames";

import SubscriptionFormModal from "../../../Modals/SubscriptionFormModal/SubscriptionFormModal";

import "./ShipmentCard..scss";

const ShipmentCard = (props) => {
  const { id, months, price, image, coffeeShipmentQuantity, details } = props;
  const [coffeePlanSelected, setCoffeePlanSelected] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const Three =
    "https://assets.gocoffeego.com/v2/assets/images/3-products.png";
  const Six =
    "https://assets.gocoffeego.com/v2/assets/images/6-products.png";
  const Twelve =
    "https://assets.gocoffeego.com/v2/assets/images/12-products.png";
  return (
    <div className="ShipmentCard">
      <SubscriptionFormModal
        coffeePlanSelected={coffeePlanSelected}
        details={details}
        months={months}
        coffeeShipmentQuantity={coffeeShipmentQuantity}
        image={image}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />

      {props.coffeeShipmentQuantity === "One" && (
        <img
          src={cs(
            months === 3 && Three,
            months === 6 && Six,
            months === 12 && Twelve
          )}
          className="ShipmentCard__image"
          alt="coffee bags"
          onClick={() => {
            setCoffeePlanSelected(id);
            localStorage.setItem("coffee-plan", id);
            setIsOpen(!isOpen);
          }}
        />
      )}
      {props.coffeeShipmentQuantity === "Two" && (
        <>
          {months === 12 && (
            <img
              src={Twelve}
              className="ShipmentCard__image"
              alt="coffee bags"
              onClick={() => {
                setCoffeePlanSelected(id);
                localStorage.setItem("coffee-plan", id);
                setIsOpen(!isOpen);
              }}
            />
          )}
          <img
            src={cs(
              months === 3 && Six,
              months === 6 && Twelve,
              months === 12 && Twelve
            )}
            className="ShipmentCard__image"
            alt="coffee bags"
            onClick={() => {
              setCoffeePlanSelected(id);
              localStorage.setItem("coffee-plan", id);
              setIsOpen(!isOpen);
            }}
          />
        </>
      )}

      <Button
        onClick={() => {
          setCoffeePlanSelected(id);
          localStorage.setItem("coffee-plan", id);
          setIsOpen(!isOpen);
        }}
        className="ShipmentCard__button"
        label={` ${months} months - $${price}`}
      />
    </div>
  );
};

export default ShipmentCard;
