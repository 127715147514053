import React from "react";

import Line from "../../shared/Line/Line";
import SectionTitle from "../../Cart/SectionTitle/SectionTitle";
import PeaberryRecommendsCard from "../PeaberryRecommendsCard/PeaberryRecommendsCard";
import ViewButton from "../../shared/ViewButton/ViewMoreButton";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";

import "./PreviuousPeaberryRecommends.scss";

const PreviousPeaberryRecommends = () => {
  const filtersCtx = useFilterProductsContext();

  const isLoading = filtersCtx.isLoading;

  return (
    <div className="PreviousPeaberryRecommends">
      {filtersCtx.showLatestRecommend && (
        <>
          <Line />
          <Line />
          <div className="PreviousPeaberryRecommends__title-container">
            <SectionTitle
              middleLine
              white
              title="PREVIOUS PEABERRY RECOMMENDS"
              className="PreviousPeaberryRecommends__title"
            />
          </div>
          <Line style={{ marginBottom: "30px" }} />
        </>
      )}
      {filtersCtx.allRecommends.length === 0 && (
        <h2 className="PreviousPeaberryRecommends__message">
          No recommends found for this category
        </h2>
      )}

      {!isLoading &&
        filtersCtx.allRecommends.map((item, index) => (
          <div className="PreviousPeaberryRecommends__card" key={index}>
            <PeaberryRecommendsCard
              peaberryRecommends
              updatedAt={item?.updated_at}
              product={item?.product}
              review={item?.review}
              image={item?.image}
            />
            <Line className="PreviousPeaberryRecommends__line" />
          </div>
        ))}
      {filtersCtx.hasMorePage && (
        <ViewButton
          className="PreviousMemberPicks__button"
          handleClick={filtersCtx.handleLoadMore}
        />
      )}
    </div>
  );
};

export default PreviousPeaberryRecommends;
