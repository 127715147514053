import { Modal } from "reactstrap";
import { Icon } from "../../shared/Icon/Icon";

import "./ModalWrapper.scss";

const ModalWrapper = (props) => {
  const { icon, toggle, isOpen, closeIcon } = props;
  return (
    <Modal className="Modal" centered={true} isOpen={isOpen} toggle={toggle}>
      <div className="Modal__header">
        {closeIcon && (
          <Icon
            style={{ width: "20px", height: "20px" }}
            onClick={toggle}
            className="Modal__icon"
            icon="close"
          />
        )}
      </div>
      {props.children}
    </Modal>
  );
};

export default ModalWrapper;
