import ContentLoader from "react-content-loader";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

const OrderLoader = () => {
  const isMobile = useResponsiveDimensions().isMobile;
  return (
    <ContentLoader
      width={isMobile ? "90%" : "100%"}
      height={isMobile ? 150 : 300}
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
    >
      <rect x="31" y="28" rx="0" ry="0" width="90%" height="30" />
      <rect x="42" y="100" rx="0" ry="0" width="60%" height="23" />
      <rect x="42" y="150" rx="0" ry="0" width="40%" height="20" />
      <rect x="31" y="250" rx="0" ry="0" width="90%" height="30" />
    </ContentLoader>
  );
};

export default OrderLoader;
