import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";

import "./SelectBoxShipments.scss";

let cs = require("classnames");

const SelectBoxShipments = (props) => {
  const { items, selectedItem, className, className2, shipment } = props;
  const [showItems, setShowItems] = useState(false);
  const dropDownRef = useRef(null);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (id) => {
    props.onItemSelect(id);
    setShowItems(!showItems);
  };

  return (
    <div
      ref={dropDownRef}
      className={cs(
        "SelectBoxShipments",
        className,
        shipment && "SelectBoxShipments--shipment"
      )}
    >
      <div
        onClick={() => setShowItems(!showItems)}
        className="SelectBoxShipments__container"
      >
        <div className="SelectBoxShipments__header">
          {" "}
          Shipment {selectedItem}
        </div>
        <div className="SelectBoxShipments__icon">
          <img className="SelectBoxShipments__icon" src={Arrow} alt="arrrow" />
        </div>
      </div>

      <div
        className={cs(
          "SelectBoxShipments__items",
          showItems && "active",
          className2
        )}
      >
        {items?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => onItemSelect(item.shipment_number)}
              className={`SelectBoxShipments__item-container ${
                props.selectedItem === item.shipment_number ? "activeLabel" : ""
              }`}
            >
              <p className="SelectBoxShipments__item">
                Shipment {item.shipment_number}
              </p>
            </div>
          );
        })}
        {items?.map((item, index) => {
          if (
            props.selectedItem === item.shipment_number &&
            item?.items.length > 1
          ) {
            return (
              <div
                key={index}
                onClick={() => onItemSelect(999999)}
                className="SelectBoxShipments__item-container"
              >
                <p className="SelectBoxShipments__item">New Shipment</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SelectBoxShipments;
