//
// WARNING
//
// Do not make manual changes to this file.
// This file was generated by generate-AllBusinessIcons-component.js.
//
//


import React from 'react';

/**
 * A list of all available icons in the icon set.
 */
export type IconDefinition = 'close' | 'heart';

export const availableIcons: IconDefinition[] = ['close', 'heart'];

export const IconSvg = {
  'close': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.414 14.414"><g fill="none" stroke="#000" stroke-width="2" data-name="Group 2846"><path d="M.707.707l13 13" data-name="Line 32"/><path d="M.707 13.707l13-13" data-name="Line 33"/></g></svg>,
  'heart': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.355 28.348"><path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M29.437 2.918a8.25 8.25 0 00-11.67 0l-1.59 1.59-1.59-1.59a8.252 8.252 0 10-11.67 11.67l1.59 1.59 11.67 11.67 11.67-11.67 1.59-1.59a8.25 8.25 0 000-11.67z" data-name="Icon feather-heart"/></svg>,
};
