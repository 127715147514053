import React, { useState } from "react";

import Product from "../../Shop/Product/Product";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import ViewMoreButton from "../../shared/ViewButton/ViewMoreButton";

import "./QuizRecommendations.scss";

const QuizRecommendations = (props) => {
  const { quizRecommendations } = props;

  const [visibleProducts, setVisibleProducts] = useState(8);

  const loadMore = () => {
    setVisibleProducts((prev) => prev + 4);
  };

  return (
    <div id="quiz-recommendations" className="QuizRecommendations">
      <MiddleLine />

      {quizRecommendations && quizRecommendations.length !== 0 ? (
        <>
          <h1 className="QuizRecommendations__title">WE THINK YOU'LL LIKE</h1>
          <div className="QuizRecommendations__products-container">
            {quizRecommendations && quizRecommendations.length !== 0 ? (
              <div
                className={
                  quizRecommendations.length < 4
                    ? "QuizRecommendations__products products-auto"
                    : "QuizRecommendations__products"
                }
              >
                {quizRecommendations ? (
                  quizRecommendations
                    .slice(0, visibleProducts)
                    .map((product) => (
                      <div
                        className="QuizRecommendations__product"
                        key={product.id}
                      >
                        <Product product={product} key={product.id} />
                      </div>
                    ))
                ) : (
                  <div className="QuizRecommendations__no-recommendations">
                    No coffee recommendations, take the quiz!
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {quizRecommendations.length !== 0 &&
            visibleProducts < quizRecommendations.length && (
              <ViewMoreButton handleClick={loadMore} />
            )}
        </>
      ) : (
        <>
          <div className="QuizRecommendations__no-recommendations">
            No coffee recommendations, take the quiz!
          </div>
          <MiddleLine />
        </>
      )}
    </div>
  );
};

export default QuizRecommendations;
