import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import SectionTitle from "../../components/Cart/SectionTitle/SectionTitle";
import TotalCart from "../../components/Checkout/TotalCart/TotalCart";
import * as API from "../../api/Api";
import "./OrderSuccess.scss";
import Progress from "../../components/shared/Progress/Progress";

const OrderSuccess = () => {
  const [recieptData, setRecieptData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const orderId = params.pathParam;
  const orderIdLocal = localStorage.getItem("order_id");

  useEffect(() => {
    API.getReciept(orderId)
      .then((res) => {
        setRecieptData(res.data);
        if (res.data) {
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  //TODO commented for testing purpose
  if (orderIdLocal === null) {
    // return <Redirect to="/" />;
  }

  if (orderIdLocal !== orderId) {
    // return;
  }

  const paymentNumber = recieptData?.payment_method?.replace(
    "Visa ending in",
    ""
  );

  return (
    <div className="OrderSuccess">
      <Progress activeStep={2} />
      <SectionTitle
        titleClassName="OrderSuccess__title"
        middleLineBottom
        title="ORDER COMPLETED SUCCESFULLY!"
      />
      {!isLoading && (
        <>
          <div className="OrderSuccess__container">
            <div className="OrderSuccess__details">
              <div className="OrderSuccess__column">
                <h1 className="OrderSuccess__subtitle">Billing Address</h1>
                {recieptData?.billing_address ? (
                  <>
                    <h1 className="OrderSuccess__text">
                      {recieptData.billing_address?.first_name}{" "}
                      {recieptData.billing_address?.last_name}
                    </h1>
                    <h1 className="OrderSuccess__text">
                      {recieptData.billing_address?.address1},{" "}
                      {recieptData.billing_address?.address2}
                    </h1>
                    <h1 className="OrderSuccess__text">
                      {recieptData.billing_address?.city},{" "}
                      {recieptData.billing_address?.state?.name},{" "}
                      {recieptData.billing_address?.zip}
                    </h1>
                    <h1 className="OrderSuccess__text">
                      {recieptData?.billing_address?.country.name}
                    </h1>
                  </>
                ) : (
                  <h2 className="OrderSuccess__text">
                    Billing address is not required
                  </h2>
                )}
              </div>

              <div className="OrderSuccess__column">
                <h1 className="OrderSuccess__subtitle">Order Date</h1>
                <h1 className="OrderSuccess__text">
                  {recieptData?.date.slice(0, 10)}
                </h1>
              </div>
              <div className="OrderSuccess__column">
                <h1 className="OrderSuccess__subtitle">Payment Method</h1>
                <h1 className="OrderSuccess__text">
                  **** **** **** {paymentNumber}
                </h1>
              </div>
              {recieptData.shipping_address && (
                <div className="OrderSuccess__column">
                  <h1 className="OrderSuccess__subtitle">Shipping Address</h1>
                  <h1 className="OrderSuccess__text">
                    {recieptData.shipping_address?.first_name}{" "}
                    {recieptData.shipping_address?.last_name}
                  </h1>
                  <h1 className="OrderSuccess__text">
                    {recieptData.shipping_address?.address1},{" "}
                    {recieptData.shipping_address?.address2}
                  </h1>
                  <h1 className="OrderSuccess__text">
                    {recieptData.shipping_address?.city},{" "}
                    {recieptData.shipping_address?.state?.name},{" "}
                    {recieptData.shipping_address?.zip}
                  </h1>
                  <h1 className="OrderSuccess__text">
                    {recieptData?.shipping_address?.country.name}
                  </h1>
                </div>
              )}

              {/*   <div className="OrderSuccess__column">
                <h1 className="OrderSuccess__subtitle">Billing Address</h1>
                <h1 className="OrderSuccess__text">
                  {recieptData.billing_address?.address1},{" "}
                  {recieptData.billing_address?.address2}
                </h1>
                <h1 className="OrderSuccess__text">
                  {recieptData.billing_address?.city},{" "}
                  {recieptData.billing_address?.state?.name},{" "}
                  {recieptData.billing_address?.zip}
                </h1>
                <h1 className="OrderSuccess__text">
                  {recieptData?.billing_address?.country.name}
                </h1>
              </div> */}

              <div className="OrderSuccess__column">
                <h1 className="OrderSuccess__subtitle">Order Number</h1>
                <h1 className="OrderSuccess__text">{recieptData.id}</h1>
              </div>
            </div>
            <TotalCart totalLoading={isLoading} recieptData={recieptData} />
          </div>
          <h1 className="OrderSuccess__text OrderSuccess__text--email">
            We've sent an email to {recieptData.customer_email} with your order
            details.
          </h1>
        </>
      )}
    </div>
  );
};

export default OrderSuccess;
