import ContentLoader from "react-content-loader";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

const ProductImageLoader = () => {
  const isMobile = useResponsiveDimensions().isMobile;
  const heightMobile = 300;
  const widthMobile = 170;
  return (
    <ContentLoader
      height={isMobile ? heightMobile : 650}
      width={isMobile ? widthMobile : 550}
      // speed={2}
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
    >
      {isMobile ? (
        <rect x="20" y="100" rx="0" ry="0" width="100%" height="100%" />
      ) : (
        <rect x="100" y="140" rx="0" ry="0" width="40%" height="50%" />
      )}
    </ContentLoader>
  );
};

export default ProductImageLoader;
