import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";

import "./SelectBoxFilter.scss";

let cs = require("classnames");

const SelectBoxFilter = (props) => {
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";
  const { items, selectedItem, className, className2 } = props;
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);

  const onItemSelect = (name, id) => {
    props.onItemSelect(name, id);
    setShowItems(!showItems);
  };

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div ref={dropDownRef} className={cs("SelectBoxFilter", className)}>
      <div
        onClick={() => setShowItems(!showItems)}
        className="SelectBoxFilter__container"
      >
        <div className="SelectBoxFilter__header">{selectedItem.name}</div>
        <div className="SelectBoxFilter__icon">
          <img className="SelectBoxFilter__icon" src={Arrow} alt="arrow" />
        </div>
      </div>

      <div
        className={cs(
          "SelectBoxFilter__items",
          showItems && "active",
          className2
        )}
      >
        {items?.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => onItemSelect(item.name, item.id)}
              className={`SelectBoxFilter__item-container ${
                props.selectedItem.id === item.id ? "activeLabel" : ""
              }`}
            >
              <p className="SelectBoxFilter__item">{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectBoxFilter;
