import React from "react";

import "./RadioButtonSquare.scss";

const RadioButtonSquare = (props) => {
  const { className, style, name, checked } = props;

  return (
    <div
      className={`RadioButtonSquare ${className}`}
      style={style}
      onClick={() => {
        props.setChecked(!checked);
      }}
    >
      <div className="RadioButtonSquare__outer-square">
        <div
          id="Radio-button"
          className={`RadioButtonSquare__inner-square ${
            checked && "RadioButtonSquare__inner-square--active"
          }`}
        />
      </div>
      <div className="RadioButtonSquare__label">{name}</div>
    </div>
  );
};

export default RadioButtonSquare;
