import { useFormik } from "formik";
import * as Yup from "yup";

const CreditAndBillingSchema = Yup.object({
  first_name: Yup.string()
    .required("Please provide first name.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed."),
  last_name: Yup.string()
    .required("Please provide last name.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed."),
  address1: Yup.string().required("Please provide address 1."),
  // .matches(/^[0-9a-zA-Z ]+$/, "Only alphabets and numbers are allowed."),
  address2: Yup.string().nullable(),
  company_name: Yup.string().nullable(),
  zip: Yup.string()
    .required("Please provide ZIP")
    .min(
      5,
      "ZIP is invalid. Please provide a 5 or 9 digit (NNNNN-NNNN) number."
    ),
  city: Yup.string()
    .required("Please provide a City.")
    .required("Please provide last name.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed."),
  phone: Yup.string().required("Please provide phone number."),
  card_holder_name: Yup.string().required("Please provide full name."),
});

export const useCreditAndBillingAddressFormik = (selectedCard, opts) => {
  return useFormik({
    initialValues: {
      first_name: selectedCard ? selectedCard.customer_address.first_name : "",
      last_name: selectedCard ? selectedCard.customer_address.last_name : "",
      address1: selectedCard ? selectedCard.customer_address.address1 : "",
      address2: selectedCard ? selectedCard.customer_address.address2 : "",
      company_name: selectedCard
        ? selectedCard.customer_address.company_name
        : "",
      zip: selectedCard ? selectedCard.customer_address.zip : "",
      city: selectedCard ? selectedCard.customer_address.city : "",
      phone: selectedCard ? selectedCard.customer_address.phone : "",
      // cvv2: "",
      card_holder_name: selectedCard ? selectedCard.card_holder_name : "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CreditAndBillingSchema,
    enableReinitialize: true.valueOf,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
