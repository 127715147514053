import React, { useContext, createContext } from "react";

const AuthContextValues = {
  isAuthenticated: false,
  isGuestAuthenticated: false,
  isLoading: false,
  user: undefined,
  userDetails: [],
  error: undefined,
  customerData: undefined,
  login: () => {},
  checkAuthentication: () => {},
  getCustomer: () => {},
  getDetails: () => {},
  handleFromCheckout: () => {},
  resetUserError: () => {},
  fromCheckout: undefined,
  logOut: () => {},
  register: () => {},
  registerGuest: () => {},
  userError: undefined,
  registerError: undefined,
  billingAddressRequired: undefined,
  checkCheckout: () => {},
};

export const AuthContext = createContext(AuthContextValues);
export const useAuthContext = () => useContext(AuthContext);
