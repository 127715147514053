import React from "react";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./CorporateGift.scss";

const CorporateGift = () => {
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/corporate-gifts"
  );
  return (
    <div className="CorporateGift">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle title="CORPORATE GIFTS" className="CorporateGift__title" />
      <div className="CorporateGift__container">
        <div className="CorporateGift__text">
          Thank everyone you do business with.
          <br />
          Motivate your employees with a great incentive.
          <br />
          Reward your staff for their hard work. <br />
          Congratulate and celebrate throughout the year.
        </div>
        <div className="CorporateGift__border"></div>
        <div className="CorporateGift__options">
          Volume Discounts Available <br />
          Personalized Assistance <br />
          Convenient Ordering/Shipping <br />
          Contact us: elise@gocoffeego.com
        </div>
      </div>
    </div>
  );
};

export default CorporateGift;
