import React, { useRef, useState } from "react";

import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

import "./SelectCard.scss";

let cs = require("classnames");

const SelectCard = (props) => {
  const { items, selectedItem, className, className2, label, autoShip } = props;
  const dropDownRef = useRef(null);

  const [showItems, setShowItems] = useState(false);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (item) => {
    props.onItemSelect(item);
    setShowItems(!showItems);
  };

  const lastNumbers = selectedItem?.number_display?.replace(
    "xxxx-xxxx-xxxx-",
    ""
  );

  return (
    <div
      ref={dropDownRef}
      onClick={() => setShowItems(!showItems)}
      className={cs("SelectCard", className)}
    >
      <div className="SelectCard__header">
        <>
          <h1 className="SelectCard__title"> {label}</h1>
          {selectedItem?.card_holder_first_name ? (
            <div className="SelectCard__title-container">
              <h1 className="SelectCard__title2">
                {selectedItem.card_holder_first_name}{" "}
                {selectedItem.card_holder_last_name}{" "}
              </h1>
              <h1 className="SelectCard__last-numbers">**{lastNumbers}</h1>
            </div>
          ) : (
            <h1 className="SelectCard__title2">{selectedItem?.initMessage}</h1>
          )}
        </>

        <img className="SelectCard__icon" alt="arrow" src={Arrow} />
      </div>

      <div
        className={cs("SelectCard__items", showItems && "active", className2)}
      >
        {items?.map((item) => {
          return (
            <>
              <div
                onClick={() => onItemSelect(item)}
                className={`SelectCard__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectCard__item">
                  {item.card_holder_first_name} {item.card_holder_last_name}
                </p>
                <h1 className="SelectCard__last-numbers">
                  **{item.number_display?.replace("xxxx-xxxx-xxxx-", "")}
                </h1>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCard;
