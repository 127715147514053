import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../lib/helpers/isEmpty";
import cs from "classnames";

import CreditAndBillingAddress from "../../Checkout/CreditAndBillingAddress/CreditAndBillingAddress";

import { autoshipUpdatedMessage } from "../../../lib/helpers/messages";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./MyCreditCards.scss";

const MyCreditCards = () => {
  const [creditCards, setCreditCards] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [selectedAutoShipCardId, setSelectedAutoShipCardId] = useState(0);
  const [selectedCard, setSelectedCard] = useState();
  const [editForm, setEditForm] = useState(false);
  const [addressBook, setAddressBook] = useState([]);
  const [subscriptionAddress, setSubscriptionAddress] = useState();
  const [defaultAddress, setDefaultAddress] = useState({});
  const location = useLocation();
  const msgCtx = useMessageContext();

  const NoCard =
    "https://assets.gocoffeego.com/v2/assets/images/no-card.png";
  const Visa =
    "https://assets.gocoffeego.com/v2/assets/images/visa.png";
  const Mastercard =
    "https://assets.gocoffeego.com/v2/assets/images/mastercard.png";
  const Discover =
    "https://assets.gocoffeego.com/v2/assets/images/credit-cards/discover.svg";
  const AmericanExpress =
    "https://assets.gocoffeego.com/v2/assets/images/credit-cards/american-express.svg";

  useEffect(() => {
    fetchCreditCards();
    fatchAddressBook();
  }, []);

  const fatchAddressBook = () => {
    API.getAddressBookInfo()
      .then((res) => {
        setAddressBook(res.data);
        let subAddress = res.data.find((item) => {
          return item.gift_subscription_address === 1;
        });
        setSubscriptionAddress(subAddress);

        let defAddress = res.data.find((item) => {
          return item.default_address === 1;
        });
        setDefaultAddress(defAddress);
      })
      .catch((err) => console.log("err"));
  };

  const fetchCreditCards = () => {
    API.getCreditCards()
      .then((res) => {
        setCreditCards(res.data);
      })
      .catch((err) => console.log("err"));
  };

  const deleteCard = (id) => {
    API.deleteCreditCard(id).then(() => {
      fetchCreditCards();
    });
  };

  const editCreditCard = (card) => {
    setOpenModal(true);
    setSelectedCard(card);
    setEditForm(true);
  };

  const setClubCreditCard = (id, addressId) => {
    if (isEmpty(subscriptionAddress)) {
      API.setGiftSubscriptionAddress(addressId).then((res) => {
        if (res.status === 200) {
          API.joinClubCard(id)
            .then((res) => {
              fetchCreditCards();
            })
            .catch((err) => msgCtx.handleError(err));
        }
      });
    } else {
      setSelectedCardId(id);
      if (!isEmpty(subscriptionAddress)) {
        API.joinClubCard(id)
          .then((res) => {
            fetchCreditCards();
          })
          .catch((err) => msgCtx.handleError(err));
      }
    }
  };

  const setAutoShipCard = (id, addressId) => {
    if (isEmpty(defaultAddress)) {
      API.setDefaultAddress(addressId).then((res) => {
        if (res.status === 200) {
          setSelectedAutoShipCardId(id);
          API.setAutoShipCreditCard(id)
            .then((res) => {
              msgCtx.handleSuccessMessage(autoshipUpdatedMessage);
              fetchCreditCards();
            })
            .catch((err) => msgCtx.handleError(err));
        }
      });
    } else {
      setSelectedAutoShipCardId(id);
      API.setAutoShipCreditCard(id).then((res) => {
        msgCtx.handleSuccessMessage(autoshipUpdatedMessage);

        fetchCreditCards();
      });
    }
  };

  return (
    <>
      {location.pathname === "/account/credit-cards/auto-ship" && (
        <Link to="/auto-ship">
          <div className="goBack">&#8592; Go Back</div>
        </Link>
      )}

      <div className="MyCreditCards">
        {openModal ? (
          <div className="MyCreditCards__forms-wrap">
            <CreditAndBillingAddress
              setOpenModal={setOpenModal}
              openModal={openModal}
              selectedCard={selectedCard}
              setEditForm={setEditForm}
              editForm={editForm}
              fetchCreditCards={fetchCreditCards}
            />
          </div>
        ) : (
          <div className="MyCreditCards__cards-wrap">
            <div className="MyCreditCards__cards">
              {/*   No card */}
              <div className="MyCreditCards__no-cards">
                <img
                  src={NoCard}
                  alt="no card placeholder"
                  className="MyCreditCards__no-cards-img"
                />
                <button
                  className="MyCreditCards__button"
                  onClick={() => setOpenModal(!openModal)}
                >
                  Add a card
                </button>
              </div>
              {creditCards &&
                creditCards.map((card) => (
                  <div className="MyCreditCards__card" key={card.id}>
                    <div className="MyCreditCards__image-wrap">
                      {card?.credit_card_type?.id === 1 && (
                        <img
                          src={Visa}
                          alt="card type"
                          className="MyCreditCards__type-card-img"
                        />
                      )}
                      {card?.credit_card_type?.id === 2 && (
                        <img
                          src={Mastercard}
                          alt="card type"
                          className="MyCreditCards__type-card-img"
                        />
                      )}
                      {card?.credit_card_type?.id === 3 && (
                        <img
                          src={AmericanExpress}
                          alt="card type"
                          className="MyCreditCards__type-card-img"
                        />
                      )}
                      {card?.credit_card_type?.id === 4 && (
                        <img
                          src={Discover}
                          alt="card type"
                          className="MyCreditCards__type-card-img"
                        />
                      )}
                      <div>{card.expiration_date_display}</div>
                    </div>
                    <div className="MyCreditCards__card-info">
                      <p className="MyCreditCards__card-info--detail">
                        {card.card_holder_name}
                      </p>
                      <p className="MyCreditCards__card-info--detail">
                        {card.number_display}
                      </p>
                      <p className="MyCreditCards__card-info--detail">
                        {card.customer_address.address1}
                      </p>
                      {card.customer_address.address2 && (
                        <p className="MyCreditCards__card-info--detail">
                          {card.customer_address.address2}
                        </p>
                      )}
                      <p className="MyCreditCards__card-info--detail">
                        {card.customer_address.city},{" "}
                        {card.customer_address.zip}
                      </p>
                      {card.customer_address.state && (
                        <p className="MyCreditCards__card-info--detail">
                          {card.customer_address.state.name},{" "}
                          {card.customer_address.country.name}
                        </p>
                      )}
                      <span></span>
                    </div>
                    <div className="MyCreditCards__buttons-wrap">
                      <button
                        className="MyCreditCards__edit-button"
                        onClick={() => editCreditCard(card)}
                      >
                        Edit
                      </button>
                      <button
                        className="MyCreditCards__delete-button"
                        onClick={() => deleteCard(card.id)}
                      >
                        Delete
                      </button>
                    </div>

                    <form className="MyCreditCards__club-card-wrapper">
                      <div
                        className="MyCreditCards__radio-button"
                        onClick={() => {
                          setClubCreditCard(card.id, card.customer_address.id);
                        }}
                      >
                        <div className="MyCreditCards__outer-circles">
                          <div
                            id="Radio-button"
                            className={cs(
                              "MyCreditCards__inner-circle",
                              card.id === selectedCardId ||
                                card?.subscription_card === 1
                                ? "MyCreditCards__inner-circle--active"
                                : null
                            )}
                          />
                        </div>
                        <div className="MyCreditCards__labels">
                          {card.id === selectedCardId ||
                          card.subscription_card === 1
                            ? "This Card is for Coffee Clubs"
                            : "Use this Card for Coffee Club"}{" "}
                        </div>
                      </div>
                    </form>
                    <form className="MyCreditCards__auto-ship-wrapper">
                      <div
                        className="MyCreditCards__radio-button"
                        onClick={() => {
                          setAutoShipCard(card.id, card.customer_address.id);
                        }}
                      >
                        <div className="MyCreditCards__outer-circles">
                          <div
                            id="Radio-button"
                            className={cs(
                              "MyCreditCards__inner-circle",
                              card.id === selectedAutoShipCardId ||
                                card?.auto_ship_card === 1
                                ? "MyCreditCards__inner-circle--active"
                                : null
                            )}
                          />
                        </div>
                        <div className="MyCreditCards__labels">
                          {card.id === selectedAutoShipCardId ||
                          card.auto_ship_card === 1
                            ? "This Card is for Auto-Ship"
                            : "Use this Card for Auto-Ship"}{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyCreditCards;
