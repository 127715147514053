import { useEffect, useState } from "react";
import { useGiftCertificatesFormik } from "../../GiftCertificates/GiftCertificatesForm/useGiftCertificatesFormik";
import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

import { Form, Input } from "reactstrap";

import "./EditGiftCertificate.scss";

const EditGiftCertificate = (props) => {
  const { id, msg, sender, reciever, closeEdit, openEdit } = props;
  const [message, setMessage] = useState();
  const [charsLeft] = useState(60);

  const cartCtx = useCartContext();

  if (message) {
    setTimeout(() => setMessage(""), 2000);
  }

  useEffect(() => {
    formik.setFieldValue("giftMessage", msg);
    formik.setFieldValue("reciever", reciever);
    formik.setFieldValue("sender", sender);
  }, []);

  const formik = useGiftCertificatesFormik({
    onSubmit: async (values) => {
      const cartId = localStorage.getItem("cart_id");

      API.updateGiftCertificates(
        id,
        cartId,
        values.giftMessage,
        values.reciever,
        values.sender
      ).then((res) => {
        if (res.status === 200) {
          setMessage("Your Gift Certificate is updated");
          cartCtx.getCartItems();
          closeEdit();
        }
      });
    },
  });

  return (
    <div className="EditGiftCertificate">
      <Form
        className="EditGiftCertificate__form"
        onSubmit={formik.handleSubmit}
      >
        <div className="EditGiftCertificate__container">
          <div className="EditGiftCertificate__inputs">
            <div>
              <div className="EditGiftCertificate__input-container">
                <label className="EditGiftCertificate__label EditGiftCertificate__label--to">
                  To
                </label>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reciever}
                  className="EditGiftCertificate__input"
                  name="reciever"
                  type="text"
                  maxLength="11"
                  disabled={openEdit ? false : true}
                />
              </div>
              {formik.errors.reciever && formik.touched.reciever && (
                <div className="EditGiftCertificate__required">
                  {formik.errors.reciever}
                </div>
              )}
            </div>
            <div>
              <div className="EditGiftCertificate__input-container">
                <label className="EditGiftCertificate__label EditGiftCertificate__label--from">
                  From
                </label>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sender}
                  className="EditGiftCertificate__input"
                  name="sender"
                  type="text"
                  maxLength="11"
                  disabled={openEdit ? false : true}
                />
              </div>
              {formik.errors.sender && formik.touched.sender && (
                <div className="EditGiftCertificate__required">
                  {formik.errors.sender}
                </div>
              )}
            </div>
          </div>
          <div className="EditGiftCertificate__textarea-wrap">
            <Input
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.giftMessage}
              className="EditGiftCertificate__textarea"
              name="giftMessage"
              type="textarea"
              maxLength="60"
              disabled={openEdit ? false : true}
            />
            {formik.errors.giftMessage && formik.touched.giftMessage && (
              <div className="EditGiftCertificate__required">
                {formik.errors.giftMessage}
              </div>
            )}
          </div>
          <div className="EditGiftCertificate__button-wrap">
            {message && (
              <div className="EditGiftCertificate__message">{message}</div>
            )}

            {openEdit && (
              <div className="EditGiftCertificate__message-remaining">
                {" "}
                Remaining characters:{" "}
                {charsLeft - formik.values.giftMessage.length}
              </div>
            )}
            {openEdit && (
              <button
                type="submit"
                className="EditGiftCertificate__form-button"
              >
                Save Note
              </button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditGiftCertificate;
