import React, { useState } from "react";

import "./Characteristics.scss";

const Characteristics = (props) => {
  const [moreFactoids, setMoreFactoids] = useState(false);
  const { product } = props;

  const checkMoreFactoids = () => {
    if (
      product.coffee_elevation ||
      product.coffee_varietal ||
      product.coffee_farm ||
      product.coffee_agtron ||
      product.coffee_farmer ||
      product.coffee_process
    ) {
      return true;
    }
  };

  return (
    <div className="Characteristics">
      <div className="ProductDetails__title3">CHARACTERISTICS</div>
      {product?.coffee_roast && (
        <div className="Characteristics__row">
          <div className="Characteristics__title">ROAST</div>
          <p className="Characteristics__description">
            {`${
              product?.coffee_roast === "Medium Light"
                ? "Medium-Light"
                : product?.coffee_roast
            }`}
          </p>
        </div>
      )}

      {product?.coffee_body && (
        <div className="Characteristics__row">
          <div className="Characteristics__title">BODY</div>
          <p className="Characteristics__description">{product?.coffee_body}</p>
        </div>
      )}

      {product?.coffee_acidity && (
        <div className="Characteristics__row">
          <div className="Characteristics__title">ACIDITY</div>
          <p className="Characteristics__description">
            {product?.coffee_acidity}
          </p>
        </div>
      )}
      {product?.coffee_notes && (
        <div className="Characteristics__row">
          <div className="Characteristics__title">NOTES</div>
          <p className="Characteristics__description">
            {product?.coffee_notes}
          </p>
        </div>
      )}

      {checkMoreFactoids() && (
        <div className="Characteristics__more-factoids">
          <div
            onClick={() => setMoreFactoids(!moreFactoids)}
            className="Characteristics__more-factoids-title"
          >
            {!moreFactoids ? (
              <p> [CLICK FOR MORE FACTOIDS]</p>
            ) : (
              <p> [CLICK TO HIDE FACTOIDS]</p>
            )}
          </div>
          {moreFactoids && (
            <div>
              {product?.coffee_farm && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">FARM</div>
                  <p className="Characteristics__description">
                    {product?.coffee_farm}
                  </p>
                </div>
              )}
              {product?.coffee_farmer && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">FARMER</div>
                  <p className="Characteristics__description">
                    {product?.coffee_farmer}
                  </p>
                </div>
              )}
              {product?.coffee_agtron && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">AGTRON</div>
                  <p className="Characteristics__description">
                    {product?.coffee_agtron}
                  </p>
                </div>
              )}
              {product?.coffee_elevation && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">ELEVATION</div>
                  <p className="Characteristics__description">
                    {product?.coffee_elevation}
                  </p>
                </div>
              )}
              {product?.coffee_varietal && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">VARIETAL</div>
                  <p className="Characteristics__description">
                    {product?.coffee_varietal}
                  </p>
                </div>
              )}
              {product?.coffee_process && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">PROCESS</div>
                  <p className="Characteristics__description">
                    {product?.coffee_process}
                  </p>
                </div>
              )}
              {product?.coffee_location && (
                <div className="Characteristics__row">
                  <div className="Characteristics__title">LOCATION</div>
                  <p className="Characteristics__description">
                    {product?.coffee_location}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Characteristics;
