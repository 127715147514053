import React, { useState, useEffect } from "react";
import { SeoContext } from "./SeoContext";

export const SeoContextProvider = (props) => {
  const [pageMetadata, setPageMetadata] = useState([]);
  const [imageMetadata, setImageMetadata] = useState([]);

  const context = {
    pageMetadata,
    setPageMetadata,
    imageMetadata,
    setImageMetadata
  };

  return (
    <SeoContext.Provider value={context}>
      {props.children}
    </SeoContext.Provider>
  );
};
