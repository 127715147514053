import React, { useState } from "react";

import Story from "../Story/Story";

import ProductImageLoader from "../../Loaders/ProductImageLoader/ProductImageLoader";

import "./ProductStory.scss";

const ProductStory = (props) => {
  const { product, productIsLoading } = props;
  const [imageLoading, setImageLoading] = useState(true);
  const coffeeReviewBadge = product.coffee_review_image;

  return (
    <div className="ProductStory">
      <div className="ProductStory__image-container">
        {coffeeReviewBadge && (
          // <div className="ProductStory__review">
          <img
            src={coffeeReviewBadge}
            className="ProductStory__review-badge"
            alt="badge"
          />
          // </div>
        )}
        {productIsLoading ? (
          <ProductImageLoader />
        ) : (
          <>
            {product.discount && (
              <div className="ProductStory__sale">
                <div className="ProductStory__sale-wrap">
                  Save
                  <span className="ProductStory__sale-procent">
                    {product.discount}%
                  </span>
                </div>
              </div>
            )}
            {product.coffee_review_image && !product.id === 8964 && (
              <img
                className="ProductStory__coffee-review"
                src={product.coffee_review_image}
                alt="product"
              />
            )}

            <img
              style={!imageLoading ? { display: "block" } : { display: "none" }}
              className="ProductStory__image"
              src={product?.main_image?.xlg}
              alt="product"
              onLoad={() => setImageLoading(false)}
            />
          </>
        )}
      </div>

      <div className="ProductStory__container">
        <Story productIsLoading={productIsLoading} product={product} />
      </div>
    </div>
  );
};

export default ProductStory;
