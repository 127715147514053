import React, { useRef, useState } from "react";

import "./SelectAddress.scss";

import { useOutsideClickDetection } from "../../../../lib/hooks/useOutSideClickDetection";

let cs = require("classnames");

const SelectAddress = (props) => {
  const { items, selectedItem, className, className2, label } = props;
  const dropDownRef = useRef(null);

  const [showItems, setShowItems] = useState(false);

  const handleOutSideClick = () => {
    setShowItems(false);
  };
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";
  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  const onItemSelect = (item) => {
    props.onItemSelect(item);
    setShowItems(!showItems);
  };

  return (
    <div
      ref={dropDownRef}
      onClick={() => setShowItems(!showItems)}
      className={cs("SelectAddress", className)}
    >
      <div className="SelectAddress__header">
        <div>
          <h1 className="SelectAddress__title"> {label}</h1>
          {selectedItem.first_name && (
            <h1 className="SelectAddress__title2">
              {selectedItem.first_name},{selectedItem.last_name},{" "}
              {selectedItem.address1},{selectedItem.address2}
            </h1>
          )}
        </div>
        <span className="SelectCountry__arrow-down" />
        <img className="SelectAddress__icon" src={Arrow} />
      </div>

      <div
        className={cs(
          "SelectAddress__items",
          showItems && "active",
          className2
        )}
      >
        {items?.map((item) => {
          return (
            <>
              <div
                onClick={() => onItemSelect(item)}
                className={`SelectAddress__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectAddress__item">
                  {item.first_name}, {item.last_name}, {item.address1},{" "}
                  {item.address2}
                </p>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SelectAddress;
