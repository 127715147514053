import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const Hawaii = () => {
  const article = {
    title: "Hawaii",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/hawaii"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>Hawaii</h3>
      <p>
        Hawaii - a place unto itself! After morning of snorkeling the reef at
        Molokini, nothing clears my head better than a breakfast of fresh
        pineapple and a hot cup of Kona coffee. Several thousand miles from
        anywhere, Hawaii has recently become a major coffee player. Of all the
        coffees grown here, Kona coffee is my favorite.
      </p>
      <p>
        It is grown on the the Big Island, which has more than 630 farms ranging
        in elevation from 500 to 3000 feet. Many of these are family farms and
        are less than 5 acres in size. The Kona variety is internationally known
        and commands some of the highest prices in the world. It is Medium in
        body and known for its buttery finish.
      </p>
      <p>Best known varietals: Kona Typica.</p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/coffee-regions"
      >
        <h3> Click here to explore Coffee Regions. </h3>
      </Link>
    </div>
  );
};

export default Hawaii;
