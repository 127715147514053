import React, { useEffect, useState } from "react";

import * as API from "../../../api/Api";
import ViewAddress from "../ViewAddress/ViewAddress";
import AddressForm from "../AddressForm/AddressForm";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./BillingAddress.scss";
import { addressCreated, addressUpdated } from "../../../lib/helpers/messages";

const BillingAddress = () => {
  const [newAddress, setNewAddress] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [choosenAddress, setChoosenAddress] = useState({});
  const [viewForm, setViewForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const isMobile = useResponsiveDimensions().isMobile;
  const authCtx = useAuthContext();
  const [addressesLoading, setAddressesLoading] = useState(false);
  const msgCtx = useMessageContext();

  const billingAddress = authCtx.customerData?.billing_address;

  useEffect(() => {
    if (billingAddress) {
      setSelectedAddress(billingAddress);
    } else {
      setSelectedAddress(null);
    }
  }, [billingAddress]);

  const getUpdatedData = () => {
    authCtx.getCustomer();
    setTimeout(() => {
      setAddressesLoading(false);
    }, 2000);
  };

  const onFormSubmit = (values, selectedState, selectedCountry) => {
    const data = new FormData();
    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    data.append("zip", values.zip);
    data.append("address1", values.address1);
    data.append("address2", values.address2);
    data.append("location_country_id", selectedCountry.id);
    data.append(
      "location_state_id",
      selectedState.name === "no-id" ? null : selectedState.id
    );
    data.append("city", values.city);
    data.append("phone", values.phone);
    data.append("company_name", values.company_name);

    setAddressesLoading(true);

    if (updateForm) {
      API.updateAddress(
        selectedAddress,
        values,
        selectedState?.id ? selectedState.id : selectedAddress?.state?.id,
        selectedCountry
          ? selectedCountry.id
          : choosenAddress.location_country_id
      )
        .then(() => {
          msgCtx.handleSuccessMessage(addressUpdated);

          authCtx.getCustomer();
          if (selectedAddress?.country?.id === 64) {
            API.selectAddressToBilling(selectedAddress.id).then(() => {
              getUpdatedData();
            });
          }
          setViewForm(false);
          localStorage.setItem("billing-form-opened", "false");
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
          }
        });
      return;
    }

    if (newAddress) {
      API.addAddressBookContact(data)
        .then((res) => {
          msgCtx.handleSuccessMessage(addressCreated);

          API.selectAddressToBilling(res.data.address_id).then(() => {
            getUpdatedData();
            setViewForm(false);
            localStorage.setItem("billing-form-opened", "false");
          });
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
          }
        });
    } else {
      if (selectedAddress?.country?.id === 64) {
        API.selectAddressToBilling(selectedAddress.id).then(() => {
          getUpdatedData();
          setViewForm(false);
          localStorage.setItem("billing-form-opened", "false");
        });
      }
    }
  };

  const handleEdit = () => {
    setUpdateForm(true);
    setNewAddress(false);
    setViewForm(true);
    localStorage.setItem("billing-form-opened", "true");
  };

  return (
    <div className="BillingAddress" id="billing-address">
      <div className="Checkout__title-container">
        <h1 className="Checkout__title">Billing Address</h1>

        {selectedAddress && (
          <div className="BillingAddress__buttons">
            <button onClick={handleEdit} className="Checkout__change-button">
              Edit {!isMobile ? "address" : ""}
            </button>

            <>
              <p className="BillingAddress__or"> or </p>
              <button
                onClick={() => {
                  setViewForm(true);
                  localStorage.setItem("billing-form-opened", "true");
                  setNewAddress(true);
                  setSelectedAddress(null);
                  setUpdateForm(false);
                  setChoosenAddress(null);
                  setNewAddress(true);
                }}
                className="Checkout__change-button"
              >
                Add new {!isMobile && "address"}
              </button>
            </>
          </div>
        )}
      </div>

      {selectedAddress?.id && !viewForm ? (
        <ViewAddress address={selectedAddress} />
      ) : null}

      {!selectedAddress || viewForm ? (
        <AddressForm
          billingAddress
          addressesLoading={addressesLoading}
          newAddress={newAddress}
          updateForm={updateForm}
          addressName="billing"
          selectedAddress={selectedAddress}
          setSelectedAddress={(item) => {
            setSelectedAddress(item);
          }}
          getUpdatedData={getUpdatedData}
          onSubmit={onFormSubmit}
        />
      ) : null}
    </div>
  );
};

export default BillingAddress;
