import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { scrollInto } from "../../lib/helpers/scrollTo";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    this.handleViewScroll(prevProps.location.pathname);
  }

  handleViewScroll(prevPath) {
    const path = this.props.location.pathname;

    if (path === prevPath) {
      return;
    }

    /*   if (path.startsWith("/shop/")) {
      document.getElementById("Shop__wrapper").scrollIntoView();
      return;
    } */

    if (path === "/learn/category/the-history-of-coffee") {
      scrollInto("coffee-knowledge", 125);
      return;
    }

    if (path.startsWith("/learn/category")) {
      return;
    }

    window.scrollTo(0, 0);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const WrappedScrollToTop = withRouter(ScrollToTop);
