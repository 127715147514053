import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import { useLocation, Link } from "react-router-dom";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Videos.scss";

const Videos = () => {
  const [videos, setVideos] = useState([]);

  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    API.getVideos().then((res) => {
      setVideos(res.data);
    }).catch(err => console.log(err));
  }, []);

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url.toLowerCase() === pathname);

  return (
    <div className="Videos">
       {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
        {foundSeo && foundSeo?.h1 && (
      <h1 className="Videos__seo-title">
        {foundSeo?.h1}
      </h1>)}
      {foundSeo && foundSeo?.blurb && (
      <p className="Videos__main-description">
        {foundSeo?.blurb}
      </p>)}
      <div className="Videos__main-wrapper">
      {videos?.map((video, index) => (
        <div className="Videos__modifier" key={index}>
          <div className="Videos__wrap">
            <div className="Videos__header">
              {" "}
              <Link to={`/channel-videos/${video.slug}`}>
                <img
                  className="Videos__image"
                  src={video.tv_image}
                  alt="tv video"
                />

                <div className="Videos__channel">{video.tv_title}</div>
              </Link>
            </div>
            <div className="Videos__content">
              <Link to={`/channel-videos/${video.slug}`}>
                <div className="Videos__channels">{video.tv_title}</div>
                <div className="Videos__title">{video.title}</div>

                <img
                  className="Videos__category-image"
                  src={video.category_image}
                  alt="video"
                />
              </Link>
              <div className="Videos__description">{video.description}</div>
              <div className="Videos__count">{video.videos_count} videos.</div>
              <a
                href={`/channel-videos/${video.slug}`}
                className="Videos__watch-videos"
              >
                Watch Videos!
              </a>
            </div>
          </div>
        </div>
      ))}
   </div>
    </div>
  );
};

export default Videos;
