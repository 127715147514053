import React, { useState } from "react";
import RadioButton from "../../../shared/RadioButton/RadioButton";
import { useFilterProductsContext } from "../../../../lib/context/FilterProductsContext/FilterProductsContext";

import cs from "classnames";
import "./FilterCategory.scss";

const FilterCategory = (props) => {
  const { items, name, selectedItem, purveyor } = props;
  const [openFilter, setOpenFilter] = useState(false);
  const filtersCtx = useFilterProductsContext();

  return (
    <div className="FilterCategory">
      <div
        className={cs("FilterCategory__wrap", openFilter && "open")}
        onClick={() => setOpenFilter(!openFilter)}
      >
        <div className="FilterCategory__name"> {name} </div>
        {items ? (
          <div className={cs("FilterCategory__close", openFilter && "open")}>
            <div className="FilterCategory__line1" />
            <div className="FilterCategory__line2" />
          </div>
        ) : null}
      </div>
      {items?.map((i) => (
        <div
          className={cs("FilterCategory__items", openFilter && "open__items")}
          key={i?.id}
        >
          <RadioButton
            selectedItem={selectedItem}
            onClick={() => {
              if (purveyor) {
                props.onItemSelect(i.name, "purveyors", i?.id, i?.slug);
                filtersCtx.setSelectedRoaster(i);
                return;
              }
              props.onItemSelect(i.name, i.old_category_name, i?.id, i?.slug);
              if (i.category_name === "coffee_roast_id") {
                filtersCtx.setSelectedRoast(i);
                return;
              }
              if (i.category_name === "coffee_region_id") {
                filtersCtx.setSelectedRegion(i);
                return;
              }
            }}
            id={i?.id}
            name={i.name}
            categoryName={i.category_name}
            slug={i.slug}
          />
        </div>
      ))}
    </div>
  );
};

export default FilterCategory;
