import React from "react";

import { Icon } from "../shared/Icon/Icon";

import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";

import "./AlertMessage.scss";

const AlertMessage = () => {
  const msgCtx = useMessageContext();
  const isOpen = msgCtx.isOpen;
  const text = msgCtx.text;
  const typeOfMessage = msgCtx.typeOfMessage;

  return (
    <div
      className={`AlertMessage ${isOpen && "AlertMessage--isOpen"} ${
        typeOfMessage === "success" && "AlertMessage--success"
      }
      ${typeOfMessage === "warning" && "AlertMessage--warning"}
      ${typeOfMessage === "error" && "AlertMessage--error"}
      `}
    >
      <p className="AlertMessage__text">{text}</p>
      <Icon
        onClick={() => msgCtx.toggle()}
        className="AlertMessage__close-icon"
        icon="close"
      />
    </div>
  );
};

export default AlertMessage;
