const History =
  "https://assets.gocoffeego.com/v2/assets/icons/history.svg";
const Roasting =
  "https://assets.gocoffeego.com/v2/assets/icons/roasting.svg";
const Regions =
  "https://assets.gocoffeego.com/v2/assets/icons/regions.svg";
const Recipes =
  "https://assets.gocoffeego.com/v2/assets/icons/recipes.svg";
const Espresso =
  "https://assets.gocoffeego.com/v2/assets/icons/expresso.svg";
const Grinding =
  "https://assets.gocoffeego.com/v2/assets/icons/grinding.svg";
const Brew =
  "https://assets.gocoffeego.com/v2/assets/icons/brew.svg";
const Test =
  "https://assets.gocoffeego.com/v2/assets/icons/test.svg";

const History2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/history.svg";
const Roasting2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/roasting.svg";
const Regions2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/regions.svg";
const Recipes2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/recipes.svg";
const Espresso2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/expresso.svg";
const Grinding2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/grinding.svg";
const Brew2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/brew.svg";
const Test2 =
  "https://assets.gocoffeego.com/v2/assets/icons/icons-hover/brewing.svg";

export const COFFE_KNOWLEDGE_ITEMS = [
  {
    name: "THE HISTORY OF COFFEE",
    slug: "the-history-of-coffee",
    img: History,
    img2: History2,
  },
  {
    name: "ROASTING",
    slug: "roasting",
    img: Roasting,
    img2: Roasting2,
  },
  {
    name: "COFFEE BREWING TIPS",
    slug: "brewing-tips",
    img: Test,
    img2: Test2,
  },
  {
    name: "REGIONS",
    slug: "regions",
    img: Regions,
    img2: Regions2,
  },
  {
    name: "RECIPES",
    slug: "recipes",
    img: Recipes,
    img2: Recipes2,
  },
  {
    name: "ESPRESSO DRINKS",
    slug: "espresso-drinks",
    img: Espresso,
    img2: Espresso2,
  },
  {
    name: "GRINDING",
    slug: "grinding",
    img: Grinding,
    img2: Grinding2,
  },
  {
    name: "MAKING YOUR BREW",
    slug: "making-your-brew",
    img: Brew,
    img2: Brew2,
  },
];
