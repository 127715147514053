import React, { useEffect, useState } from "react";

import { useContactUsFormik } from "./useContactUsFormik";
import * as API from "../../api/Api";

import { Form, Input, Spinner } from "reactstrap";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";

import "./ContactUsForm.scss";

const ContactUsForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [reasonError, setReasonError] = useState();
  const { inputSelected } = props;
  const msgCtx = useMessageContext();

  useEffect(() => {
    if (inputSelected > 0) {
      props.reasonError(false);
      formik.setFieldValue("reason", inputSelected);
      return;
    }
  }, [inputSelected]);

  const formik = useContactUsFormik({
    onSubmit: async (values) => {
      setIsLoading(true);

      if (inputSelected > 0) {
        API.addContactForm(values, inputSelected)
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
            }
            setMessage(res.data.result);
          })
          .catch((err) => {
            msgCtx.handleError(err);
          });
      } else {
        setReasonError("Please select one of the topics");
      }
    },
  });

  return (
    <Form
      onClick={() => {
        if (inputSelected <= 0) {
          props.reasonError(true);
        }
      }}
      className="ContactUsForm"
      onSubmit={formik.handleSubmit}
    >
      <div className="ContactUsForm__grid">
        <div className="ContactUsForm__input-container ContactUsForm__input-container--sign-in">
          <label className="ContactUsForm__label" htmlFor="name">
            Full Name
          </label>
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className="ContactUsForm__input "
            name="name"
            type="text"
          />
          {formik.errors.name && formik.touched.name && (
            <div className="ContactUsForm__required">
              {" "}
              {formik.errors.name}{" "}
            </div>
          )}
        </div>

        <div className="ContactUsForm__input-container ContactUsForm__input-container--sign-up">
          <label className="ContactUsForm__label" htmlFor="email">
            Email Address
          </label>
          <Input
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            value={formik.values.email}
            className="ContactUsForm__input"
            name="email"
            type="email"
          />
          {formik.errors.email && formik.touched.email && (
            <div className="ContactUsForm__required">
              {" "}
              {formik.errors.email}{" "}
            </div>
          )}
        </div>
      </div>

      <div className="ContactUsForm__grid">
        <div className="ContactUsForm__input-container ContactUsForm__input-container--sign-in">
          <label className="ContactUsForm__label">Phone Number</label>
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            className="ContactUsForm__input "
            name="phone"
            type="text"
          />
          {formik.errors.phone && formik.touched.phone && (
            <div className="ContactUsForm__required">
              {" "}
              {formik.errors.phone}{" "}
            </div>
          )}
        </div>

        <div className="ContactUsForm__input-container ContactUsForm__input-container--sign-up">
          <label className="ContactUsForm__label">Order Number</label>
          <Input
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            value={formik.values.order_number}
            className="ContactUsForm__input"
            name="order_number"
            type="text"
          />
          {formik.errors.order_number && formik.touched.order_number && (
            <div className="ContactUsForm__required">
              {" "}
              {formik.errors.order_number}{" "}
            </div>
          )}
        </div>
      </div>

      <div className="ContactUsForm__input-container ContactUsForm__input-container--comment">
        <label className="ContactUsForm__label">Questions & Comments</label>

        <Input
          onChange={(e) => {
            formik.handleChange(e);
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
          value={formik.values.comments}
          className="ContactUsForm__input ContactUsForm__input--comment"
          name="comments"
          type="textarea"
        />

        {formik.errors.comments && formik.touched.comments && (
          <div className="ContactUsForm__required">
            {" "}
            {formik.errors.comments}{" "}
          </div>
        )}
      </div>

      <div className="ContactUsForm__thankyouNote">{message}</div>

      <button type="submit" className="ContactUsForm__button">
        {isLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #00d1d6",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : (
          <span>SUBMIT</span>
        )}
      </button>
    </Form>
  );
};

export default ContactUsForm;
