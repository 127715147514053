const AwardWinners =
  "https://assets.gocoffeego.com/v2/assets/images/award-winners.png";
const AdvancedSearch =
  "https://assets.gocoffeego.com/v2/assets/images/advanced-search.png";
const Roasters =
  "https://assets.gocoffeego.com/v2/assets/images/roasters-icon.png";
export const MORE_COFFEES = [
  {
    id: 7,
    title: "Peaberry Recommends",
    image:
      "https://assets.gocoffeego.com/v2/brilliant-recommendations/peaberry.png",
    url: `${process.env.REACT_APP_API_URL}/recommendations`,
    category: null,
    category_id: null,
  },
  {
    id: 8,
    title: "Member Picks",
    image:
      "https://assets.gocoffeego.com/v2/brilliant-recommendations/member-picks.png",
    url: `${process.env.REACT_APP_API_URL}/member-picks`,
    category: null,
    category_id: null,
  },
  {
    id: 6,
    title: "Top 25",
    image:
      "https://assets.gocoffeego.com/v2/brilliant-recommendations/top-25.png",
    url: `${process.env.REACT_APP_API_URL}/products?top_coffees=25`,
    category: "top_coffees",
    category_id: "25",
  },
  {
    id: 11,
    title: "All Roasters",
    image: Roasters,
    category: "all_roasters",
  },
  {
    id: 12,
    title: "Advanced Search",
    image: AdvancedSearch,
    category: "advanced_search",
  },
  {
    id: 13,
    title: "Award Winners",
    image: AwardWinners,
    category: "award_winners",
  },
];
