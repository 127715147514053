import React, { useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import Filter from "../../components/Shop/Filter/Filter";
import Product from "../../components/Shop/Product/Product";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";
import Seo from "../../lib/helpers/SEO/Seo";

import "./BulkCoffees.scss";

const BulkCoffees = () => {
  const SFTrolley =
    "https://assets.gocoffeego.com/v2/assets/images/Trolley.png";

  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const pathnamesArray = pathname.split("/");

  useEffect(() => {
    FiltersCtx.getInitialProducts();

    return history.listen((location) => {
      if (history.action === "POP" && location.pathname === "/bulk-coffees") {
        history.push("/");
      }
      FiltersCtx.setSelectedFilter([]);
    });
  }, []);

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === pathname
  );

  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/bulk-coffees/")
  );

  return (
    <div className="BulkCoffees">
      <Link to={pathname}></Link>
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        title="Bulk Coffees"
        className="BulkCoffees__title"
        notMainTitle
      />
      {/*       {seoLinks &&
        seoLinks.map((item) => <Link key={item?.id} to={item?.url} />)} */}
      <div className="BulkCoffees__container">
        <Filter bulkCoffees />
        <div>
          {pathname.startsWith("/bulk-coffees") && pathnamesArray.length <= 2 && (
            <>
              <div className="BulkCoffees__banner-wrapper">
                <img
                  className="BulkCoffees__banner"
                  src={SFTrolley}
                  alt="Bulk Coffees banner"
                />
              </div>
              <MiddleLine className="BulkCoffees__middle-line" />
              {foundSeo && (
                <>
                  <h1 className="BulkCoffees__main-title">{foundSeo?.h1}</h1>
                  <p className="BulkCoffees__main-description">
                    {foundSeo?.blurb}
                  </p>
                </>
              )}
              <h2 className="BulkCoffees__subtitle">
                "Coffee keeps one alert and productive. &#8594; Productivity
                leads to money. &#8594; Give your staff motivation through
                caffeine. &#8594; Drinking coffee will make you rich. &#8594;
                Hip-Hip Hooray!"
              </h2>
            </>
          )}
          {foundSeo && pathnamesArray.length > 2 && (
            <>
              <h1 className="BulkCoffees__main-title">{foundSeo?.h1}</h1>
              <p className="BulkCoffees__main-description">{foundSeo?.blurb}</p>
            </>
          )}
          {FiltersCtx.isLoading ? (
            <div className="BulkCoffees__products-wrapper">
              {Array(12)
                .fill(0)
                .map((item, index) => (
                  <ProductLoader key={index} />
                ))}
            </div>
          ) : (
            <div className="BulkCoffees__products-wrapper">
              {FiltersCtx.bulkCoffees?.map((product) => (
                <Product product={product} key={product?.id} />
              ))}
            </div>
          )}
        </div>
      </div>
      {FiltersCtx.hasMorePage && (
        <ViewMoreButton
          handleClick={FiltersCtx.handleLoadMore}
          style={{ marginTop: "2rem", marginBottom: "5rem" }}
        />
      )}
    </div>
  );
};

export default BulkCoffees;
