import React, { useEffect, useState } from "react";

import RecipesItem from "./RecipesItem/RecipesItem";
import ViewMoreButton from "../../shared/ViewButton/ViewMoreButton";
import RecipeImageLoader from "../../Loaders/RecipeImageLoader/RecipeImageLoader";
import ArticleContainer from "../ArticleContainer/ArticleContainer";
import Seo from "../../../lib/helpers/SEO/Seo";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";
import * as API from "../../../api/Api";
import { Link } from "react-router-dom";
import SelectBox from "../../shared/SelectBox/SelectBox";

import "./Recipes.scss";

const Recipes = () => {
  const seoCtx = useSeoContext();
  const Flowers =
    "https://assets.gocoffeego.com/v2/assets/images/three-flowers.png";
  const SittingPeaberry =
    "https://assets.gocoffeego.com/v2/assets/images/sitting-peaberry.svg";

  const [recipes, setRecipes] = useState([]);
  const [recipeCategories, setRecipeCategories] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState({
    name: "All Recipe Categories ",
    id: 0,
  });

  useEffect(() => {
    API.getRecipesCategories().then((res) => setRecipeCategories(res.data));
  }, []);

  const getRecipes = () => {
    API.getRecipes().then((res) => {
      setRecipes(res.data);
      if (res.data) {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getRecipes();
  }, []);

  const handleViewMore = () => {
    API.getRecipes(currentPage + 1).then((res) => {
      if (res.data) {
        setIsLoading(false);
      }
      setRecipes((recipes) => recipes.concat(res.data));
      setHasMore(res.pagination.has_more);
    });
    setCurrentPage(currentPage + 1);
  };

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/professor-peaberry/brewing-tips/coffee-recipes"
  );

  return (
    <ArticleContainer shapeStyle={{ background: "#4d95c8" }} title="Recipes">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <div id="Recipes" className="Recipes">
        <div className="Recipes__categories">
          <SelectBox
            className="Recipes__select-box"
            selectedItem={selectedItem}
            onItemSelect={(value, id) => {
              if (id === 0) {
                getRecipes();
              }
              API.getRecipesByTag(id).then((res) => setRecipes(res.data));
              setSelectedItem({ name: value, id: id });
            }}
            items={recipeCategories}
          />
        </div>
        <div>
          <p className="Recipes__description">
            How to brew the perfect cup of coffee, espresso, latte and other
            concoctions...
          </p>
        </div>
        <div className="Recipes__items">
          {isLoading ? (
            <>
              {Array(6)
                .fill(0)
                .map((item) => (
                  <RecipeImageLoader />
                ))}
            </>
          ) : (
            <>
              {recipes.map((item) => (
                <Link to={`/recipe/${item?.slug}-${item.id}`}>
                  <RecipesItem
                    id={item.id}
                    name={item.name}
                    image={item.image.image_url}
                    prepTime={item.prep_time}
                    yieldd={item.yield}
                    slug={item?.slug}
                  />
                </Link>
              ))}
            </>
          )}
        </div>
        {hasMore && <ViewMoreButton handleClick={handleViewMore} />}

        <Link
          to="/learn/category/making-your-brew"
          onClick={() => {
            window.scrollTo({ top: 1000, behavior: "smooth" });
          }}
        >
          <h3 className="Recipes__bottom-link"> Back to making your brew. </h3>
        </Link>
        {/*<div className="Recipes__line">*/}
        {/*  <Line />*/}
        {/*  <Line />*/}
        {/*</div>*/}
      </div>{" "}
      <img
        className="Recipes__peaberry-sitting"
        src={SittingPeaberry}
        alt="peabyerry-man"
      />
      <img className="Recipes__flowers" src={Flowers} alt="flowers" />
      <img
        className="Recipes__background"
        src="https://assets.gocoffeego.com/v2/assets/images/articles-background.svg"
        alt="yellow background"
      />
    </ArticleContainer>
  );
};

export default Recipes;
