import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import * as API from "../../../api/Api";

import FlickitySlider from "../../FlickitySlider/FlickitySlider";
import ViewMoreButton from "../../shared/ViewButton/ViewMoreButton";

import "./PeopleReview.scss";

const PeopleReview = () => {
  const [allReviews, setAllReviews] = useState([]);
  const history = useHistory();

  const Stars =
    "https://assets.gocoffeego.com/v2/assets/icons/stars.png";

  useEffect(() => {
    API.getAllReviews()
      .then((res) => setAllReviews(res.data))
      .catch((err) => console.log(err, "err"));
  }, []);

  let options = {
    draggable: true,
    freeScroll: false,
    contain: true,
    groupCells: false,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: true,
    wrapAround: true,
    adaptiveHeight: true,
    // imagesLoaded: true,
  };

  return (
    <div className="PeopleReview">
      <h2 className="PeopleReview__title font-cairo">WHAT PEOPLE ARE SAYING</h2>
      <div className="PeopleReview__slider">
        <FlickitySlider options={options}>
          {allReviews.map((item) => (
            <div key={item.id} className="PeopleReview__review">
              <img className="PeopleReview__stars" src={Stars} alt="stars" />
              <p className="PeopleReview__quote">{item.content}</p>
              <div className="PeopleReview__author font-cairo">{item.name}</div>
            </div>
          ))}
        </FlickitySlider>
        <ViewMoreButton
          label="VIEW ALL"
          handleClick={() => history.push("/customer-reviews")}
        />
      </div>
    </div>
  );
};

export default PeopleReview;
