import { apiRequest } from "./Api";

//get credit users credit cards
export const getCreditCards = async () => {
  return apiRequest("get", `account/credit-cards`, null);
};

//add new credit card account page
export const addCreditCard = async (data) => {
  return apiRequest("post", `account/credit-cards`, data);
};

//edit credit card
export const editCreditCard = async (id, data) => {
  return apiRequest("put", `account/credit-cards/${id}`, data);
};

//add new credit card account page
export const deleteCreditCard = async (id) => {
  return apiRequest("delete", `account/credit-cards/${id}`, null);
};

// check what type is card
export const checkCreditCard = async (number) => {
  return apiRequest("post", "account/credit-cards/get-cc-type", {
    number: number,
  });
};

//use credit card to join club
export const joinClubCard = async (id) => {
  return apiRequest(
    "put",
    `account/credit-cards/${id}/set_subscription_card`,
    null
  );
};

//set credit card to auto-ship card
export const setAutoShipCreditCard = async (id) => {
  return apiRequest(
    "put",
    `account/credit-cards/${id}/set_auto_ship_card`,
    null
  );
};

//Show details for 1 card based on ID
export const getCardById = async (id) => {
  return apiRequest("get", `checkout/pay/${id}`, null);
};

//Add new card
export const addNewCard = async (data) => {
  return apiRequest("post", `checkout/pay/add`, data);
};

//Place order
export const placeOrder = async () => {
  return apiRequest("post", `checkout/confirm/place_order`, null);
};

//Update card
export const updateCard = async (id, data) => {
  return apiRequest("put", `checkout/pay/update`, null);
};

//Delete existing card based on credit-card ID
export const deleteCard = async (id) => {
  return apiRequest("delete", `checkout/pay/delete`, null);
};

//get card types
export const getCardTypes = async () => {
  return apiRequest("get", `credit-card-types`, null);
};
