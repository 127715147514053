import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { Icon } from "../../shared/Icon/Icon";
import ReactPlayer from "react-player";

import "./VideosModal.scss";

const VideosModal = (props) => {
  const { video } = props;
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="VideosModal">
        <div className="VideosModal__header">
          <div className="VideosModal__banner">{video.title} </div>
          <Icon
            style={{ width: "20px", height: "20px" }}
            onClick={props.toggle}
            className="VideosModal__close"
            icon="close"
          />
        </div>
        <div className="VideosModal__video">
          <ReactPlayer
            width="1000px"
            height="500px"
            url={video.embed}
            controls
          />
        </div>
        <div className="VideosModal__title">{video.title}</div>
        <div className="VideosModal__description">{video.description}</div>
      </div>
    </ModalWrapper>
  );
};

export default VideosModal;
