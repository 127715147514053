import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ShippingMessaging } from "../../../lib/helpers/ShippingMessaging";

import { Icon } from "../../shared/Icon/Icon";
import "./ShippingInfoModal.scss";

const ShippingInfoModal = (props) => {
  const shippingMessage = ShippingMessaging();
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="ShippingInfoModal">
        <div className="ShippingInfoModal__container">
          <div className="ShippingInfoModal__header">
            <h1 className="ShippingInfoModal__header-title">
              USPS Priority Flat Rate Shipping And International Shipping:
            </h1>
            <Icon
              style={{ width: "20px", height: "20px" }}
              onClick={props.toggle}
              className="ShippingInfoModal__close"
              icon="close"
            />
          </div>
          <div className="ShippingInfoModal__body">
            <div className="ShippingInfoModal__paragraph-section">
              <p>
                We ship to everywhere in the United States, as well as to the US
                Military Worldwide and all US Territories. (For International
                shipping see below.) USPS Priority Delivery to your home, office
                or P.O. Box is usually 1 to 3 days from pick-up. USPS delivers
                six day a week, including Saturday!
              </p>
              <br />
              <p>
                To ensure the highest quality coffee, all orders are fresh
                roasted to order and shipped direct from the Roaster to you.
                Most Roasters, roast coffee Monday thru Friday. Some roast
                Monday thru Thursday. Limited roasting schedules are indicated
                on product pages or in the cart.{" "}
                <span className="ShippingInfoModal__underline">
                  A separate shipping charge applies from each individual
                  Roaster
                </span>
              </p>
            </div>
            <div className="ShippingInfoModal__bags-list">
              <p className="ShippingInfoModal__bags-title">
                Regular Size Bags (8 oz., 10 oz., 12 oz.):
              </p>
              <ul>
                <li>1 Bag = {shippingMessage.bags.one}</li>
                <li>2 Bags = {shippingMessage.bags.two}</li>
                <li>3 Bags = {shippingMessage.bags.three}</li>
                <li>4 Bags = {shippingMessage.bags.four}</li>
                <li>5+ Bags = {shippingMessage.bags.fivePlus}</li>
              </ul>
            </div>
            <div className="ShippingInfoModal__paragraph-section">
              <p className="ShippingInfoModal__bags-title">Bulk Coffee Bags:</p>
              <p>
                {shippingMessage.bulkMessage.lineOne} <br />
                {shippingMessage.bulkMessage.lineTwo}
              </p>
              <br />
              <p>{shippingMessage.bulkMessage.fiveOrMore}</p>
              <br />
              <p>
                * When a percentage off offer or promo code is used, whichever
                is greater, whether it's the free shipping deal or percentage
                off offer will be applied at checkout. You will always get the
                most savings.
              </p>
              <br />
              <p>
                For questions please email:{" "}
                <a
                  href="mailto:elise@gocoffeego.com"
                  className="ShippingInfoModal__email"
                >
                  elise@gocoffeego.com
                </a>
              </p>
            </div>
            <div className="ShippingInfoModal__list-container">
              <h2 className="ShippingInfoModal__list-title">
                Worldwide Shipping For International Customers:
              </h2>
              <p className="ShippingInfoModal__list-paragraph">
                We currently ship to:
              </p>
              <p className="ShippingInfoModal__paragraph-section-important">
                PLEASE NOTE: For all billing addresses outside the USA, you must
                choose PAYPAL as your payment method.
              </p>
              <ul>
                <li>ALBANIA</li>
                <li>AUSTRALIA</li>
                <li>AUSTRIA</li>
                <li>BELGIUM</li>
                <li>BRAZIL</li>
                <li>BRUNEI DARUSSALAM</li>
                <li>BURMA (MYANMAR)</li>
                <li>CANADA</li>
                <li>CHILE</li>
                <li>CHINA</li>
                <li>COLOMBIA</li>
                <li>CYPRUS</li>
                <li>DENMARK</li>
                <li>DOMINICA</li>
                <li>EGYPT</li>
                <li>FINLAND</li>
                <li>FRANCE</li>
                <li>GERMANY</li>
                <li>GREECE</li>
                <li>HONG KONG</li>
                <li>HUNGARY</li>
                <li>ICELAND</li>
                <li>INDIA</li>
                <li>INDONESIA</li>
                <li>IRELAND</li>
                <li>ISRAEL</li>
                <li>ITALY</li>
                <li>JAMAICA</li>
                <li>JAPAN</li>
                <li>JORDAN</li>
                <li>KUWAIT</li>
                <li>MALAWI</li>
                <li>MALAYSIA</li>
                <li>MALTA</li>
                <li>MEXICO</li>
                <li>NETHERLANDS</li>
                <li>NEW ZEALAND</li>
                <li>NORWAY</li>
                <li>PHILIPPINES</li>
                <li>POLAND</li>
                <li>PORTUGAL</li>
                <li>QATAR</li>
                <li>ROMANIA</li>
                <li>SAUDI ARABIA</li>
                <li>SEYCHELLES</li>
                <li>SINGAPORE</li>
                <li>SLOVENIA</li>
                <li>SOUTH AFRICA</li>
                <li>SOUTH KOREA</li>
                <li>SPAIN</li>
                <li>SWEDEN</li>
                <li>SWITZERLAND</li>
                <li>TAIWAN</li>
                <li>THAILAND</li>
                <li>TURKEY</li>
                <li>UNITED ARAB EMIRATES</li>
                <li>UNITED KINGDOM</li>
                <li>UNITED STATES</li>
              </ul>
            </div>
            <p className="ShippingInfoModal__paragraph-section-important">
              PLEASE NOTE: For all billing addresses outside the USA, you must
              choose PAYPAL as your payment method.
            </p>
            <p>
              GoCoffeeGo ships to customers across the globe via USPS Priority
              International. Delivery times can vary and we are not responsible
              for lost packages once they leave the United States. Issues with
              International delivery are very rare. We will always do our best
              to help.
            </p>
            <div className="ShippingInfoModal__paragraph-section">
              <h3 className="ShippingInfoModal__title-section">
                INTERNATIONAL SHIPPING FROM EACH ROASTER
              </h3>
              <p>
                To ensure the highest quality coffee, all orders are fresh
                roasted to order and shipped direct from the Roaster to you. A
                separate shipping charge applies from each individual Roaster.
              </p>
            </div>
            <div className="ShippingInfoModal__paragraph-section">
              <h3 className="ShippingInfoModal__title-section">
                SHIPPING TO CANADA
              </h3>
              <p>
                Ship up to three, 12-ounce coffees from an individual roaster or
                one, 2-pound bag for $20.95 USD. *JBC Roasters and PT's Coffee
                ship two, 12-ounce coffees for $20.95 USD.
                <br /> Ship up to nine, 12-ounce bags from an individual roaster
                or five, 1-pound bags or two, 2-pound bags or one, 5-pound bag,
                or various combinations of these for $42.95 USD.
                <br /> Ship up to twelve, 12-ounce bags or eight, 1-pound bags
                or four, 2-pound bags or two, 5-pound bags, or various
                combinations of these for $56.75 USD.
                <br /> Please Note: The shipping price will be calculated based
                on the various combinations of weights and bag quantities you
                choose and will be presented on the final page of checkout. The
                shipping prices listed above are based on the latest information
                we have. The pricing at checkout will be the most accurate and
                up-to-date.
              </p>
            </div>

            <div className="ShippingInfoModal__paragraph-section">
              <h3 className="ShippingInfoModal__title-section">
                SHIPPING TO ALL OTHER COUNTRIES
              </h3>
              <p>
                Ship up to three, 12-ounce coffees from an individual roaster or
                one, 2-pound bag for $25.25 USD. *JBC Roasters and PT's Coffee
                ship two, 12-ounce coffees for $25.25 USD.
                <br /> Ship up to nine, 12-ounce bags from an individual roaster
                or five, 1-pound bags or two, 2-pound bags or one, 5-pound bag,
                or various combinations of these for $62.95 USD.
                <br /> Ship up to twelve, 12-ounce bags or eight, 1-pound bags
                or four, 2-pound bags or two, 5-pound bags, or various
                combinations of these for $81.95 USD.
                <br /> Please Note: The shipping price will be calculated based
                on the various combinations of weights and bag quantities you
                choose and will be presented on the final page of checkout. The
                shipping prices listed above are based on the latest information
                we have. The pricing at checkout will be the most accurate and
                up-to-date.
              </p>
            </div>

            <div className="ShippingInfoModal__paragraph-section">
              <h3 className="ShippingInfoModal__title-section">
                IF YOU DO NOT SEE YOUR COUNTRY LISTED
              </h3>
              <p>
                We're always willing to help get you your coffee wherever you
                are. Please contact our can-do girl, Elise: elise@gocoffeego.com
                for facilitation of these type of shipments around the world.{" "}
                <br /> *International Space Station deliveries excluded. Call
                NASA for low-orbit freight delivery options. (202) 358-0000.{" "}
                <br />
                Please Note: Auto-Ship, Gift Subscription, Subscription programs
                and Free Shipping Deals are excluded from International
                Shipping.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ShippingInfoModal;
