import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const CentralAmericaMexico = () => {
  const article = {
    title: "Central America & Mexico",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/central-america-mexico"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>Central America & Mexico</h3>
      <h3>Costa Rica</h3>
      <p>
        Through the years in my travels, I have heard many a coffee snoot pule
        incessantly about the coffees of Costa Rica. The mere thought of it
        could lead them to doze off in their Sarchi chairs. When awoken from
        their slumber, they balked that the coffees were too traditional and
        middle of the road to ever thrill their persnickety high maintenance
        palates. But that was years ago before the rise of the Costa Rican
        micro-mills, which are groups of small farmers who have formed
        cooperatives.
      </p>
      <p>
        They control the production process by growing and processing their own
        coffee. The cherries are separated by the quality and elevation they
        were grown at, to meet strict standards. Every grower's coffee is cupped
        numerous times before it goes to market. This process educates the
        farmer on how to enhance the unique characteristics of the coffee they
        bring to the marketplace. Understanding the notes that the coffee buyers
        are looking for helps them continually improve their coffees to create a
        better end product.
      </p>
      <p>
        As a result, the reputations of these micro-mills are helping small
        farmers stand out and make a name for themselves. Specialty coffee
        roasters seek out premium quality micro-lots from small farms and
        happily pay substantially more than the going price for their quality
        and uniqueness. The extra money goes a long way for the farmer to
        reinvest in his business and creates a huge level of pride when the name
        of their tiny farm becomes known, respected and celebrated by coffee
        enthusiasts across the globe.
      </p>
      <p>
        So let the jaded coffee drinkers of the world rejoice, because now
        whether your hiking the smoldering crater at Poas or sunning on the
        sandy beaches of Manuel Antonio, you can enjoy some of the finest
        varieties of coffee in the world, right here in Costa Rica. And variety
        it is! Whether its sweet and fruity with floral notes, or the highly
        sought after berry-like flavors or nutty and yummy chocolaty flavors,
        you can be sure that you'll never find me face down asleep in my cup!
      </p>
      <p>
        Ranked 9th in the world. * Cup of Excellence Country Best known
        varietals: Caturra,Catuai,Tarrazu, Villa Sarchi, Typica.
      </p>

      <h3>El Salvador</h3>
      <p>
        It has been quite awhile since I've manned my balloon over the Gulf of
        Fonseca into El Salvador to float over the winding mountain roads above
        the Ruta de Las Flores (Route of the flowers) that traverses El
        Salvador's coffee country. In May, coffee blossoms cover the Volcanoes
        to create a most beautiful and spectacular sight. The 36 kilometer/
        22-mile road is a quick ride in my balloon, but I am never too busy to
        stop for a few cups of the premium coffee. Sadly, this charming country,
        which is the smallest in Central America, has had much political
        instability and thus, although the coffees are excellent, the supply has
        not always been consistent. But things are changing.
      </p>
      <p>
        And no doubt a woman is behind it. No discussion of El Salvadorian
        coffee is complete without mentioning, Aida Batlle, who runs 3 of her
        family's farms located on the slopes of the Santa Ana volcano. Aida has
        carried on the Batlle family's 40-year tradition with an unwavering
        commitment to excellence in the growing and handling of the beans. She
        has also made some changes: by converting to organic farming practices.
        Tending to her plants with love, like they are her children, and
        respecting their history. While it would be more profitable to replace
        her very low yielding plants with new higher producing coffee plants,
        she will not.
      </p>
      <p>
        Many of these varietals have prospered there for decades and she
        understands the importance of preserving them. When she is not walking
        her fields, she is often experimenting with berries of different
        ripeness, de-pulping them by hand, and drying them to find what is the
        optimum time to handpick the cherries, when the fruity sweetness is at
        its peak. I would say her berries are truly made with love.
      </p>
      <p>
        Cup of Excellence Country Best known varietals: Bourbon, Pacas,
        Pacamara, Typica.
      </p>

      <h3>Guatemala</h3>
      <p>
        Ah...Guatemala. Just thinking about the superior complexity of the
        Guatemalan cup thrills me. Some of the world's finest specialty coffees
        are grown in the Highlands of this small but respected coffee growing
        country. The climate is ideal. The sunny days and cool nights, high
        altitude and rich volcanic soil make Guatemala a great place to grow.
      </p>
      <p>
        I so enjoy packing on my well-fed mule, Ramon, onto the steep, rugged
        hills of the Antigua or Coban regions to visit the origin of their
        lively spicy, floral and chocolaty brews. The coffees from Guatemala are
        sold using a system that separates them by grade. The elevation that the
        beans are grown at is the determining factor.
      </p>
      <p>
        Strictly hard bean (SHB) is the highest quality and is grown above 4500
        feet. Beans grown at these higher elevations are more valued because
        they are denser and harder. The lower grade is Hard bean (HB), which is
        grown between 4000- 4500 feet. Because of Guatemala's proximity to the
        U.S., much is imported directly to your local roaster. Lucky
      </p>
      <p>Ranked 8th in the world. * Cup of Excellence Country</p>
      <p>
        High quality coffees here are produced using the Wet- Processed. Best
        known varietals: Antigua, Atitlan, Arabigo, Bourbon, Caturra, Catuai,
        Coban, Huehuetenango, Pacamara, Maragogype, Typica.
      </p>

      <h3>Honduras</h3>
      <p>
        If you are a fan of mild and full-bodied coffees with distinct sweet
        caramel flavors then you will enjoy the high grown coffees of Honduras.
        These coffees can be quite good yet have been devalued and punished in
        the past for their low quality wrap. Because of this, quality farmers
        have struggled and have often received less than market rate for their
        beans. They have also had to overcome a reputation for historically
        producing cheaper lower quality Arabica but now with the support of the
        Honduran government, NGO organizations and the Cup of Excellence things
        are changing rapidly.
      </p>
      <p>
        A group of Honduras cooperatives are leading the way with an exciting
        goal - That 20 percent of next year's crops are to be sold as specialty
        varieties. Recently growers have been able to increase their profits by
        tapping into the socially conscious trend by certifying their products
        as organic, fair trade, shade grown or rain-forest friendly. A number of
        American specialty roasters have forged strong relationships with farms
        and work with them to continually refine their process in order to bring
        you the very best coffee.
      </p>
      <p>
        One of my favorite cups in Honduras has notes of sweet citrus and dark
        fruit and is grown in the quiet mountain town of Marcala. The coffee of
        Marcala was the first in Central America to receive the prestigious
        "Denomination Of Origin" stamp, protecting the authenticity of the beans
        from this region. If this information does not thrill you, maybe this
        will: Much of the lingerie sold in the US is made in Honduras. A tasty
        cup in more ways than one.
      </p>
      <p>
        Cup of Excellence Country Best known varietals: Bourbon and Caturra.
      </p>

      <h3>Nicaragua</h3>
      <p>
        The country of Nicaragua is situated a prime coffee growing region of
        Central America. Once the producer of some of the finest coffees in the
        world, Nicaragua is working its way back after the Sandinistas and years
        of political unrest. At the time, many growers and their families fled
        the country and abandoned their farms. This country in the 1970's was
        producing over a million pounds of coffee beans a year!
      </p>
      <p>
        With the return of Democratic rule in the 1990's, things are back on
        track with a focus on quality and sustainability as they produce a wide
        variety of cup flavors cultivated on thousands of small to modest-sized
        farms mostly from the noted Nuevo Segovia, Jinotega and Matagalpa
        regions. The Miraflor nature preserve is an excellent example of a
        protected area that utilizes Sustainable agriculture practices to
        promote the success of the ecotourism trade and the well being of its
        inhabitance. Their Miraflor coffee is an outstanding balanced cup of
        tart cherry and sugar sweetness.
      </p>
      <p>
        Cup of Excellence Country Best known varietals: Typica, Caturra,
        Bourbon, Java Cultivar, Pacamara and Maragogype.
      </p>

      <h3>Panama</h3>
      <p>
        Panama once historically overlooked, is no longer. The Best of Panama
        competition is held here ever year attracting people from across the
        globe competing for the best lots. Ask any coffee aficionado and they
        will tell you that the world famous Panama Esmeralda is considered by
        most to be the Holy grail of coffees. Its origin is a mystery and often
        debated. Its mystery makes them that much more enticing... Like women.
      </p>
      <p>
        The Hartmann family farm, in Volcan, Panama, has 3 generations of family
        members who together are producing some truly exceptional coffee. The
        Honey Hartmann is a shade grown coffee with notes of sweet honey. Also
        notable is the distinct and unique coffees produced by Wolford
        Lamastus's Elida Estate in Boquete. Together, they are joined by a
        number estates producing some beautiful coffees many with bright tone
        floral and fruity notes.
      </p>
      <p>Cup of Excellence Country Best known varietals: Catuai, Caturra.</p>

      <h3>Mexico</h3>
      <p>"Bésame... bésame mucho, Como si fuera esta noche la última vez..."</p>
      <p>
        Ah... Spanish love songs. It's hard not to fall in love with the
        passionate people of Mexico and their rich, nutty, chocolaty coffees.
        Mexican coffee is grown in the southern, tropical regions and most often
        used dark roasts and blends. However, coffees in Mexico cannot easily be
        summed up. They vary greatly depending on which Mexican coffee region
        grows them. Oaxaca Pluma is quite different than Coatepec, as are the
        excellent coffees from the southern most regions of Chiapas, near
        Guatemala.
      </p>
      <p>
        If your passport isn't in order and you detest mosquitoes, take heart:
        Mexico is presently the largest importer of coffee to the U.S.
      </p>
      <p>
        Ranked 5th in the world.* Best known varietals: Altura, Bourbon,
        Carnica, Coatepec, Magagogype, Mundo Nuevo, Oaxaca Pluma, Typica.
      </p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/caribbean"
      >
        <h3> Click here to explore the Caribbean. </h3>
      </Link>
    </div>
  );
};

export default CentralAmericaMexico;
