import { apiRequest } from "./Api";

export const getAllRecommends = () => {
  return apiRequest("get", "recommendations");
};

/* export const getRecommendsByFilter = async (search, page) => {
  if (!search && page) {
    return apiRequest("get", `recommendations?page=${page}`);
  }
  return apiRequest(
    "get",
    `recommendations${search}${page ? `&page=${page}` : ""}`
  );
}; */

export const getRecommendsByFilter = async (id, id2, id3, page) => {
  if (!id && !id2 && !id3) {
    return apiRequest("get", `recommendations?${page ? `&page=${page}` : ""}`);
  }

  return apiRequest(
    "get",
    `recommendations?coffee_roast_id=${id}&coffee_type_id=${id2}&coffee_region_id=${id3}${
      page ? `&page=${page}` : ""
    }`
  );
};

export const getRecommendOfTheWeek = () => {
  return apiRequest("get", "recommendations/recommend_of_the_week");
};
