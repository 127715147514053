import { apiRequest } from "./Api";

//get profile based on ID to change info
export const getProfileById = async () => {
  return apiRequest("get", `account/profile`, null);
};

//get profile based on ID to change info
export const getMyReviews = async (page) => {
  return apiRequest("get", `account/profile/my-reviews?page=${page}`, null);
};

//put profile based on ID to change info
export const changeProfileById = async (
  firstname,
  lastname,
  email,
  oldpassword,
  password,
  passwordconfirmation
) => {
  return apiRequest("put", `account/profile`, {
    first_name: firstname,
    last_name: lastname,
    email: email,
    old_password: oldpassword,
    password: password,
    password_confirmation: passwordconfirmation,
  });
};

//select as paypal payment
export const selectAsPaypalPayment = async () => {
  return apiRequest("put", `checkout/pay/select_paypal`, null);
};

//select address as billing address
export const selectAddressToBilling = async (id) => {
  return apiRequest("put", `checkout/address-book/${id}/select-billing`, null);
};

//select card
export const selectCard = async (id) => {
  return apiRequest("put", `checkout/pay/${id}/select`, null);
};

//select card for autoship
export const selectCardAsAutoShip = async (id) => {
  return apiRequest(
    "put",
    `account/credit-cards/${id}/set_auto_ship_card`,
    null
  );
};

//select address as shipping address
export const selectAddressToShipping = async (id) => {
  return apiRequest("put", `checkout/address-book/${id}/select-shipping`, null);
};

//address book get
export const getAddressBookInfo = async () => {
  return apiRequest("get", `account/address-book`);
};

//get state of country
export const getStateOfCountry = async (id) => {
  return apiRequest("get", `countries/${id}`, null);
};

export const deleteAddressBookContact = async (id) => {
  return apiRequest("delete", `account/address-book/${id}`, null);
};

//add address book
export const addAddressBookContact = async (data) => {
  // @TODO Write tests for api call  here

  return apiRequest("post", `account/address-book`, data);
};

//set address as gift subscription address
export const setAddressForGiftSubscription = async (id) => {
  return apiRequest("put", `account/address-book/${id}/set-gs-address`, null);
};

// get customer data
export const getCustomerData = () => {
  return apiRequest("get", `customer`);
};

//update address book
export const editAddressBookContact = async (
  id,
  first_name,
  last_name,
  selectedCountry,
  company_name,
  address1,
  address2,
  city,
  selectedState,
  zip,
  phone
) => {
  return apiRequest("put", `account/address-book/${id}`, {
    id: id,
    first_name: first_name,
    last_name: last_name,
    location_country_id: selectedCountry,
    company_name: company_name,
    address1: address1,
    address2: address2,
    city: city,
    location_state_id: selectedState,
    zip: zip,
    phone: phone,
  });
};

//update address
export const updateAddress = async (
  selectedAddress,
  values,
  selectedState,
  selectedCountry
) => {
  return apiRequest("put", `account/address-book/${selectedAddress?.id}`, {
    id: selectedAddress?.id,
    first_name: values.first_name,
    last_name: values.last_name,
    location_country_id: selectedCountry,
    company_name: values.company_name,
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    location_state_id: selectedState,
    zip: values.zip,
    phone: values.phone,
  });
};

//address set default
export const setDefaultAddress = async (id) => {
  return apiRequest("put", `account/address-book/${id}/set-default`, null);
};

//set gift subscription address
export const setGiftSubscriptionAddress = async (id) => {
  return apiRequest("put", `account/address-book/${id}/set-gs-address`, null);
};

//get countries list for address page
export const getCountries = async () => {
  return apiRequest("get", "countries");
};

export const uploadImage = async (id, image) => {
  const data = new FormData();
  data.append("image", image);
  data.append("id", id);

  return apiRequest("post", "account/profile/add-image", data);
};

export const updateImage = async (data) => {
  return apiRequest("post", "account/profile/update-image", data);
};

//delete image
export const deleteImage = async (id) => {
  return apiRequest("post", "account/profile/delete-image", { id: id });
};

//get my coffee clubs
export const getMyCoffeeClubs = (async) => {
  return apiRequest("get", "account/my-coffee-clubs", null);
};

//get all coffee clubs
export const getAllCoffeeClubs = (async) => {
  return apiRequest("get", "coffee-clubs", null);
};

//get my coffee club
export const getCoffeeClub = async (searchParams) => {
  return apiRequest("get", `coffee-club?${searchParams}`);
};

//Join Club
export const joinClub = async (cartId, id) => {
  return apiRequest("post", `account/coffee-club/join?cart_id=${cartId}`, {
    id: id,
  });
};

//update coffee club
export const updateCoffeeClub = async (
  id,
  subscription_plan_id,
  subscription_tour_id,
  active
) => {
  return apiRequest("put", `account/my-coffee-clubs	`, {
    id: id,
    subscription_plan_id: subscription_plan_id,
    subscription_tour_id: subscription_tour_id,
    active: active,
  });
};

//delete coffee club
export const deleteCoffeeClub = async (id) => {
  return apiRequest("delete", `account/my-coffee-clubs`, { id: id });
};

//get my account balance
export const getAccountBalance = async () => {
  return apiRequest("get", "account/check-balance", null);
};

//post account balance
export const postAccountBalance = async (data) => {
  return apiRequest("post", "account/check-balance", data);
};

export const redeemCard = async (code) => {
  return apiRequest("post", "account/redeem", { code: code });
};

//get email preferences
export const emailPreferences = async () => {
  return apiRequest("get", "account/email-preferences", null);
};

//change email preferences
export const changeEmailPreferences = async (data) => {
  return apiRequest("put", `account/email-preferences`, {
    send_emails_coffee_reviews: data,
  });
};

//show list of star rating
export const showStarRating = async () => {
  return apiRequest("get", "account/profile/espresso-yourself", null);
};

//post review
export const postReview = async (data) => {
  return apiRequest("post", "account/profile/espresso-yourself", data);
};

//update review
export const updateReview = async (id, data) => {
  return apiRequest("put", `account/profile/espresso-yourself/${id}`, data);
};

//review filter
export const reviewFilter = async (id, status, page) => {
  return apiRequest(
    "get",
    `account/profile/my-reviews?filter_ratings=${id}&filter_status=${status}&page=${page}`,
    null
  );
};
