import React from "react";
import "./Item.scss";

const Item = (props) => {
  const { image, title, description } = props;
  return (
    <div className="Item">
      <img src={image} className="Item__image" alt="item" />
      <div className="Item__title font-cairo"> {title}</div>
      <p className="Item__description"> {description}</p>
    </div>
  );
};

export default Item;
