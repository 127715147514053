import React, { useState } from "react";

import { useCreditCardFormik } from "../../Account/MyCreditCards/AddCreditCardForm/useCreditCardFormik";
import CreditCardInput from "react-credit-card-input";
import { useStripe } from "@stripe/react-stripe-js";

import * as API from "../../../api/Api";
import { Form, Input, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { convertMonthAndYear } from "../../../lib/helpers/convertDate";
import Payment from "payment";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./CreditCardForm.scss";
import { autoshipActivatedMessage } from "../../../lib/helpers/messages";

const CreditCardForm = (props) => {
  const { setCoffeeCard, pathParam, joinClub, canProceed } = props;
  const [expireNumber, setExpireNumber] = useState();
  const [cvc, setCvc] = useState();
  const [number, setNumber] = useState();
  const cartCtx = useCartContext();
  const authCtx = useAuthContext();
  const cartItems = cartCtx.cartItems;
  const [paymentisLoading, setPaymentIsLoading] = useState(false);
  const history = useHistory();
  const [cardError, setCardError] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const msgCtx = useMessageContext();
  const stripe = useStripe();
  const formik = useCreditCardFormik({
    async onSubmit(values) {
      setFormSubmitted(true);
      const creditData = new FormData();
      const gift_subscription_adress_id = localStorage.getItem(
        "gift_subscription_adress_id"
      );

      const addressId = authCtx.customerData?.billing_address?.id;

      const issuer = Payment.fns.cardType(number);
      let cardTypeId;
      switch (issuer) {
        case "visa":
          cardTypeId = 1;
          break;
        case "mastercard":
          cardTypeId = 2;
          break;
        case "amex":
          cardTypeId = 3;
          break;
        case "discover":
          cardTypeId = 4;
          break;
        default:
          cardTypeId = 10;
      }

      creditData.append("credit_card_type_id", cardTypeId);

      joinClub
        ? creditData.append(
            "customer_address_id",
            authCtx.customerData?.gift_subscription_adress?.id
          )
        : creditData.append("customer_address_id", addressId);
      creditData.append("number", number.replace(/\D+/g, ""));
      creditData.append("expiration_date", convertMonthAndYear(expireNumber));
      creditData.append("cvv2", cvc);
      creditData.append("card_holder_name", values.card_holder_name);
      setPaymentIsLoading(true);

      if (pathParam === "auto-ship") {
        API.addCreditCard(creditData)
          .then((res) => {
            const id = res.data.credit_card_id;
            if (id) {
              API.selectCardAsAutoShip(id).then((res) => {
                if (res.status === 200) {
                  setPaymentIsLoading(false);
                  history.push({
                    pathname: "/auto-ship",
                    // autoshipActivated: true,
                  });
                  msgCtx.handleSuccessMessage(autoshipActivatedMessage);
                }
              });
            }
          })
          .catch((err) => {
            if (err) {
              msgCtx.handleError(err);
              setPaymentIsLoading(false);
            }

            setPaymentIsLoading(false);
          });
        return;
      }

      if (joinClub) {
        API.addCreditCard(creditData)
          .then((res) => {
            const id = res.data.credit_card_id;
            if (id) {
              API.joinClubCard(id).then((res) => {
                if (res.status === 200) {
                  setPaymentIsLoading(false);
                  props.joinClubNow();
                }
              });
            }
          })
          .catch((err) => {
            if (err) {
              msgCtx.handleError(err);
              setPaymentIsLoading(false);
            }
            //@TODO: replace these errors with general error handling component
            setPaymentIsLoading(false);
          });
        return;
      }

      API.addCreditCard(creditData)
        .then((res) => {
          const id = res.data.credit_card_id;
          if (stripe) {
            API.selectCard(id).then((res) => {
              if (stripe) {
                API.getPaymentIntent()
                  .then((res) => {
                    const clientSecret = res?.data?.client_secret;
                    stripe.confirmCardPayment(clientSecret).then((res) => {
                      const formData = new FormData();
                      formData.append(
                        "payment_intent_id",
                        res.paymentIntent?.id
                      );

                      API.postPayment(formData)
                        .then((res) => {
                          setPaymentIsLoading(false);
                          if (res.data.order_id) {
                            cartCtx.getCartItems();

                            setPaymentIsLoading(false);
                            history.push(`/order-success/${res.data.order_id}`);
                            localStorage.setItem("order_id", res.data.order_id);
                          }
                        })
                        .catch((err) => {
                          if (err) {
                            msgCtx.handleError(err);
                            setPaymentIsLoading(false);
                          }
                        });
                    });
                  })
                  .catch((err) => {
                    if (err) {
                      msgCtx.handleError(err);
                      setPaymentIsLoading(false);
                    }
                  });
              }
            });
          }
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
            setPaymentIsLoading(false);
            // return <ContactUsModal isOpen={true} />;
          }

          setPaymentIsLoading(false);
        });

      setCoffeeCard();
    },
  });

  return (
    <>
      <Form
        className={`${
          (joinClub && !canProceed) ||
          (cartItems?.shipping_address_id === null &&
            cartItems?.shipping_address_required &&
            !joinClub)
            ? "CreditCardForm CreditCardForm--disabled"
            : "CreditCardForm"
        }`}
        onSubmit={formik.handleSubmit}
      >
        <h1 className="CreditCardForm__title">Enter new credit card details</h1>
        <div className="CreditCardForm__input-container">
          <label className="CreditCardForm__label">Name on card: </label>
          <Input
            name="card_holder_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.card_holder_name}
            placeholder="Full name"
            className="CreditCardForm__input"
            type="text"
          />
          {formik.errors && formik.touched.card_holder_name && (
            <div className="CreditCardForm__error">
              {formik.errors.card_holder_name}
            </div>
          )}
        </div>
        <div>
          <label className="CreditCardForm__label">Card: </label>
        </div>
        <CreditCardInput
          containerClassName="CreditCardForm__react-input-container"
          customTextLabels={{
            invalidCardNumber: "Please enter correct credit card number",
            emptyCardNumber: "Please enter credit card number",
          }}
          containerStyle={{ width: "100%" }}
          cardNumberInputProps={{
            value: number,
            onChange: (e) => setNumber(e.target.value),
          }}
          cardExpiryInputProps={{
            value: expireNumber,
            onChange: (e) => setExpireNumber(e.target.value),
          }}
          cardCVCInputProps={{
            value: cvc,
            onChange: (e) => setCvc(e.target.value),
          }}
          fieldClassName="CreditCardForm__react-input"
        />{" "}
        {joinClub && (
          <button
            disabled={joinClub && !canProceed}
            type="submit"
            className="CreditAndBillingAddress__button4"
          >
            {paymentisLoading ? (
              <Spinner
                style={{
                  border: "0.25em solid #ffffff",
                  borderRightColor: "transparent",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              />
            ) : (
              [
                pathParam === "auto-ship"
                  ? "Use this card to activate auto-ship"
                  : [joinClub ? "Use this card to join club" : "place order"],
              ]
            )}
          </button>
        )}
        {!joinClub && (
          <button
            disabled={
              cartItems?.shipping_address_required === true &&
              cartItems?.shipping_address_id === null &&
              !joinClub
                ? true
                : false
            }
            type="submit"
            className="CreditAndBillingAddress__button4"
          >
            {paymentisLoading ? (
              <Spinner
                style={{
                  border: "0.25em solid #ffffff",
                  borderRightColor: "transparent",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              />
            ) : (
              [
                pathParam === "auto-ship"
                  ? "Use this card to activate auto-ship"
                  : [joinClub ? "Use this card to join club" : "place order"],
              ]
            )}
          </button>
        )}
        {/*card errors.js*/}
        {cardError && formSubmitted && (
          <div className="CreditCardForm__error CreditCardForm__error--card">
            {cardError}
          </div>
        )}
      </Form>
    </>
  );
};

export default CreditCardForm;
