import React, { useContext, createContext } from "react";

const MessageValues = {
  status: undefined,
  isOpen: false,
  text: undefined,
  toggle: () => {},
  updateText: () => {},
  updateTypeOfMessage: () => {},
  hideModal: () => {},
  handleError: () => {},
  error: undefined,
  updateModalMessage: () => {},
  handleSuccessMessage: () => {},
  handleWarningMessage: () => {},
  notAllowedMessage: () => {},
  typeOfMessage: undefined,
  openContactUsModal: false,
  setOpenContactUsModal: () => {},
};

export const MessageModalContext = createContext(MessageValues);
export const useMessageContext = () => useContext(MessageModalContext);
