import React, { useState } from "react";
import AudioTrack from "../AudioTrack/AudioTrack";
//import Song from "../../../assets/music/WhoAreYou.mp3";

import "./AudioPlayer.scss";

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const Song = "https://assets.gocoffeego.com/v2/music/audio/WhoAreYou.mp3";

  const onPlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div id="AudioPlayer" className="AudioPlayer">
      <AudioTrack
        song={Song}
        isPlaying={isPlaying}
        onPlayPauseClick={onPlayPauseClick}
      />
    </div>
  );
};

export default AudioPlayer;
