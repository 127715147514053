import React, { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { setGaUser } from "../../helpers/GoogleAnalytics/user";

import * as API from "../../../api/Api";
import { useHistory } from "react-router-dom";
import { useCartContext } from "../CartContext/CartContext";
import { useFilterProductsContext } from "../FilterProductsContext/FilterProductsContext";
import { loggedIn, signedUp } from "../../helpers/messages";
import { useMessageContext } from "../MessageModalContext/MessageModalContext";

export const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isGuestAuthenticated, setIsGuestAuthenticated] = useState(false);
  const [customerData, setCustomerData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [userError, setUserError] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  const guestUserFromLocalStorage = JSON.parse(
    localStorage.getItem("guest_user")
  );
  const [user, setUser] = useState(userFromLocalStorage);
  const [guestUser, setGuestUser] = useState(guestUserFromLocalStorage);
  const [fromCheckout, setFromCheckout] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const cartCtx = useCartContext();
  const productCtx = useFilterProductsContext();
  const history = useHistory();
  const msgCtx = useMessageContext();

  /*  useEffect(() => {
    getDetails();
  }, []);
 */
  const getCustomer = () => {
    API.getCustomerData().then((res) => {
      setCustomerData(res.data);
    });
  };

  const getDetails = () => {
    API.getProfileById()
      .then((res) => {
        setUserDetails(res.data);
        localStorage.setItem("image", res.data.image);
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = () => {
    if (!user) {
      setIsLoading(false);
      return;
    }
    if (user.guest) {
      setIsGuestAuthenticated(true);
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
      setIsGuestAuthenticated(false);
    }

    try {
      // get userData from API
      //getDetails();
    } catch (err) {
      setError(err.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (values, cartId) => {
    const loginData = new FormData();
    loginData.append("email", values.email);
    loginData.append("password", values.password);
    loginData.append("cart_id", cartId);
    try {
      const res = await API.login(loginData);
      doLogin(res);
      setUserError(false);
    } catch (err) {
      if (err) {
        setUserError(true);
      }
    }
  };

  const doLogin = async (res) => {
    const route_from = localStorage.getItem("route_from");

    getCustomer();

    try {
      // if (res.data.guest) {
      //   setGuestUser(res.data);
      //   localStorage.setItem("guest_user", JSON.stringify(res.data));
      // } else {
      //   setUser(res.data);
      //   localStorage.setItem("user", JSON.stringify(res.data));
      // }
      setUser(res.data);
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("guest", res.data.guest ? true : false);

      localStorage.setItem("cart_id", res.data.cart_id);
      localStorage.setItem("access_token", res.data.access_token);

      productCtx.fetchQuizPreferences();

      //set user for google analytics
      setGaUser(res.data.id);

      if (res.data.access_token) {
        if (res.data.guest) {
          setIsGuestAuthenticated(true);
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
          setIsGuestAuthenticated(false);
        }

        msgCtx.handleSuccessMessage(loggedIn);

        getDetails();
        cartCtx.getCartItems(res.data.cart_id);

        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("cart_id", res.data.cart_id);
        // if (res.data.guest) {
        //   localStorage.setItem("guest_user", JSON.stringify(res.data));
        // } else {
        //   localStorage.setItem("user", JSON.stringify(res.data));
        // }
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("guest", res.data.guest ? true : false);

        if (route_from) {
          history.push(`${route_from}`);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const logOut = () => {
    localStorage.clear();
    cartCtx.clearCartItems();
    setUser(null);
    setGuestUser(null);
    setUserDetails(null);
    setIsAuthenticated(false);
    setIsGuestAuthenticated(false);
    history.push("/");
  };

  const register = async (values) => {
    const registerData = new FormData();
    registerData.append("first_name", values.firstName);
    registerData.append("last_name", values.lastName);
    registerData.append("email", values.email);
    registerData.append("password", values.password);
    const cartId = localStorage.getItem("cart_id");
    if (cartId) {
      registerData.append("cart_id", cartId);
    }

    try {
      const res = await API.register(registerData);
      localStorage.setItem("user", JSON.stringify(res.data));
      // localStorage.setItem("cart_id", res.data.cart_id);
      localStorage.setItem("access_token", res.data.access_token);

      setUser(res.data);

      // const cartId = localStorage.getItem("cart_id");
      if (res.data.access_token) {
        // login(values, cartId);
        doLogin(res);
        msgCtx.handleSuccessMessage(signedUp);
        getDetails();
        setRegisterError(false);
      }
    } catch (err) {
      setRegisterError(true);
    }
  };

  const registerGuest = async (values) => {
    const registerData = new FormData();
    registerData.append("email", values.email);
    const cartId = localStorage.getItem("cart_id");
    if (cartId) {
      registerData.append("cart_id", cartId);
    }

    try {
      const res = await API.registerGuest(registerData);
      if (res.data.access_token) {
        doLogin(res);
        getDetails();
        setRegisterError(false);
      }
    } catch (err) {
      setRegisterError(true);
    }
  };

  const handleFromCheckout = () => {
    setFromCheckout(true);
  };

  const resetUserError = () => {
    setUserError(false);
  };

  const context = {
    isAuthenticated,
    isGuestAuthenticated,
    isLoading,
    login,
    user,
    guestUser,
    error,
    logOut,
    register,
    registerGuest,
    resetUserError,
    userError,
    setUser,
    setGuestUser,
    userDetails,
    registerError,
    handleFromCheckout,
    fromCheckout,
    customerData,
    getCustomer,
    getDetails,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
