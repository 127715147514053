import React from "react";
import LinkButton from "../../shared/LinkButton/LinkButton";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import "./NewsletterSuccessModal.scss";

const NewsletterSuccessModal = (props) => {
  
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="NewsletterSuccessModal">
        <div className="NewsletterSuccessModal__container">
            <h1 className="NewsletterSuccessModal__title">{props.name}, thank you for signing up for our newsletter!</h1>
            <p className="NewsletterSuccessModal__desc">In a few moments you will receive a Welcome e-mail with your promo code. Please look for it. You can use your "$5.00 off your First Purchase" now or for a future purchase.</p>
            <LinkButton path="/shop" label="CONTINUE SHOPPING" className="NewsletterSuccessModal__button" />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default NewsletterSuccessModal;
