import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const CoffeRegions = () => {
  const article = {
    title: "Coffee Regions",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/coffee-regions"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>Coffee Regions</h3>
      <p>
        Coffee grows in the sunny equatorial belt around the world. It's 1000
        miles big and runs from The Tropic of Cancer to The Tropic of Capricorn.
        Through the years many have tried and failed to proliferate this bean
        outside of its warm and snuggly comfort zone and thankfully have failed,
        ensuring that we only have the best coffee regions.
      </p>
      <p>
        While dimming the lights and soft music is in order to encourage
        reproduction of many species, this is quite the opposite of the coffee
        plant. With the exception of shade grown, the promiscuous, yet fickle
        coffee plant most often produces its cherries in hot, passionate sunny
        regions whilst in the mood. Tropical mist and fruity drinks encourage
        such frisky behavior. They have the same effect on me!... Warm,
        year-round climates and generous rainfall are commonplace here, and
        required of coffee regions. But did you know that coffee grown at higher
        elevations in these tropical areas produces some of the finest brews?
        Some say the best. Think of the rugged mountains of Colombia and
        Guatemala or the volcanic slopes of Java and Sumatra -- high places
        known for their full-flavored, robust coffee. Does altitude make
        cultivation more difficult? Does working on a hillside require one leg
        to be shorter than the other? Yes and no.
      </p>
      <p>
        There's no doubt that coffees grown at lower altitudes can also be
        exceptional, though the conditions at lower elevations are often less
        than ideal. Beans can over-ripen or receive too much moisture. And
        because the trees produce beans non-stop, the flavor and quality can be
        diluted. Some say that's why these types of coffees are best suited for
        mass production, where low elevation coffee plantations are easier and
        more efficient to farm.
      </p>
      <p>
        There are over 75 countries where coffee beans are grown and the world's
        best farmers cultivate specialty beans. These farmers are often from
        coffee regions that are not the largest volume producers of beans but I
        believe they are the best. They nurture their plants with love and are
        fueled on a quest to create the ultimate cup of excellence. Quality over
        quantity is what specialty coffee farmers are about.
      </p>
      <p>
        Where are coffee beans grown? Hop on my balloon and join me as we float
        quickly over many extraordinary coffee growing regions...
      </p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/central-america-mexico"
      >
        <h3> Click here to explore Central America & Mexico. </h3>
      </Link>
    </div>
  );
};

export default CoffeRegions;
