import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ShippingMessaging } from "../../../lib/helpers/ShippingMessaging";

import { Icon } from "../../shared/Icon/Icon";
import "./CoffeeClubInfoModal.scss";

const CoffeeClubInfoModal = (props) => {
  const shippingMessage = ShippingMessaging();
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="CoffeeClubInfoModal">
        <div className="CoffeeClubInfoModal__container">
          <div className="CoffeeClubInfoModal__header">
            <h1 className="CoffeeClubInfoModal__header-title">
              How the Coffee Club works?
            </h1>
            <Icon
              style={{ width: "20px", height: "20px" }}
              onClick={props.toggle}
              className="CoffeeClubInfoModal__close"
              icon="close"
            />
          </div>
          <div className="CoffeeClubInfoModal__body">
            <div className="CoffeeClubInfoModal__list">
              <ol>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    How does the Coffee Club work, you ask?
                  </h2>
                  <p>
                    Kick back and relax. We curate and carefully select
                    different coffees for you every shipment from our roasters.
                    If you have two coffees in your shipment, there will be two
                    unique coffees in that shipment from that individual
                    roaster. We keep it fun and mix it up. All coffee is fresh
                    roasted to order for you and sent directly from the various
                    roasteries via USPS Priority Mail. Once it ships, it
                    generally arrives in 2 to 3 days. A shipping confirmation
                    will be sent to you. If you are not happy with your coffee,
                    contact us and we will do our best to help.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Why is there a coffee price range instead of a flat price?
                  </h2>
                  <p>
                    We have so many amazing coffees and roasters to choose from,
                    a price range approach is the best way to go, in order to
                    have as many coffees as possible to offer for the Coffee
                    Clubs.
                  </p>
                  <p>
                    In the Coffee Club you pay the exact price listed on the
                    website for the selected coffee. There is no price mark-up
                    on coffees selected for the club. You pay the price of the
                    coffee and the posted shipping and that's all. It truly is
                    "Pay as You Go." We just make it easy and do the choosing
                    for you.
                  </p>
                  <p>
                    Your coffee will be roasted to order just for you and sent
                    USPS Priority, so you will get it fresh and fast!
                  </p>
                  <p>
                    There are two different plans to choose from: The Supreme
                    Coffee Plan and The Ultimate Coffee Plan.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    What's the difference between The Supreme Coffee Plan and
                    The Ultimate Coffee Plan?
                  </h2>
                  <p>
                    While all coffees on GoCoffeeGo are amazing and our roasters
                    are top notch, certain coffees are just more expensive. We
                    have two coffee plans, so you can choose which plan is best
                    for your budget. The Ultimate Coffee Plan allows us a
                    greater latitude for coffees that we love and are a bit more
                    pricy. The Supreme Plan will deliver great coffees, just
                    within a somewhat tighter budget price range.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    When will my credit card be charged?
                  </h2>
                  <p>
                    You only will be charged when your coffee order is in
                    process to be shipped, not before. You pay as you go. It's
                    as simple as that.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    How does the shipping work and what's the cost?
                  </h2>
                  <p>
                    The shipping is {shippingMessage.bags.one} per 1 coffee
                    shipment and {shippingMessage.bags.two} for 2 coffees bags
                    per shipment. The Coffee Club ships to all 50 states
                    including US Territories, APO and AE addresses. Delivery to
                    your home, office or P.O. Box is usually 1 to 3 days from
                    pick-up. USPS delivers six days a week, including Saturday!
                    To ensure the highest quality coffee, all orders are fresh
                    roasted to order and shipped direct from the Roaster to you.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Does the Coffee Club ship to international addresses?
                  </h2>
                  <p>
                    Unfortunately at this time the Coffee Club does not ship to
                    international addresses.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Will PayPal or PayPal Express Checkout work?
                  </h2>
                  <p>
                    Not at this time. Only a credit card in your GoCoffeeGo
                    account selected for the Coffee Club will work.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Can I use a Gift Card or Gift Certificate for the Coffee
                    Club?
                  </h2>
                  <p>
                    You sure can. Just redeem your Gift Card or Gift
                    Certificate. Go to the Gift Cards & Certificates tab, then
                    click on "Redeem" and enter your code. These funds will be
                    credited to your account at GoCoffeeGo. Once all used up,
                    the Credit Card you dedicated for the Coffee Club will be
                    charged in order to keep your Coffee Club plan on-going.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    What size is the coffee for the Coffee Club?
                  </h2>
                  <p>
                    It is either 12 ounces, 10 ounces, and sometimes 310 grams (
                    10.935 ounces ) depending on the size the roaster uses as
                    their standard bag size. Most of our roasters use 12-ounce
                    bags.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Is it possible to have a grind preference for my coffee?
                  </h2>
                  <p>
                    Unfortunately at this time no. All coffees are shipped whole
                    bean, as not all on roasters on GoCoffeeGo grind coffee.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    How do I place my Coffee Club Subscription On Hold?
                  </h2>
                  <p>
                    It's easy. In your "My Account" go to "Manage My Coffee
                    Clubs," just click [Pause Coffee Club]. To start your coffee
                    delivery again click the [Resume Coffee Club] button.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Can I cancel my Coffee Club Subscription?
                  </h2>
                  <p>
                    Sure, at anytime you can. It's simple and straight-forward.
                    In "My Account" go to "Manage My Coffee Clubs," and click
                    [Delete Coffee Club].
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Can I have more than one Coffee Club Subscription at a time?
                  </h2>
                  <p>
                    Yes! You can all of them if you'd like. Just add another
                    Subscription at the Coffee Club page.
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    How do I select a different credit card to be used for my
                    Coffee Club Subscription.
                  </h2>
                  <p>
                    It's easy, just select that card as the one to be used for
                    Coffee Club in "Manage My Credit Cards."
                  </p>
                </li>
                <li className="CoffeeClubInfoModal__list-item">
                  <h2 className="CoffeeClubInfoModal__list-title">
                    Will the price ranges change over time?
                  </h2>
                  <p>
                    The price ranges may increase minimally as coffee prices and
                    shipping can change and we want to make sure that as many
                    coffees are available per plan tier. We will do our best to
                    maintain the pricing.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CoffeeClubInfoModal;
