import React, { useEffect, useState } from "react";
import CreditCardInput from "react-credit-card-input";
import * as API from "../../../api/Api";
import { Form, Input, Spinner } from "reactstrap";
import Payment from "payment";

import { useCreditAndBillingAddressFormik } from "./useCreditAndBillingAddressFormik";
import SelectCountry2 from "./SelectCountry2/SelectCountry2";
import SelectAddress from "./SelectAddress/SelectAddress";
import { isEmpty } from "../../../lib/helpers/isEmpty";
import { convertMonthAndYear } from "../../../lib/helpers/convertDate";
import { useHistory, useParams } from "react-router-dom";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./CreditAndBillingAddress.scss";

const CreditAndBillingAddress = (props) => {
  const {
    autoShip,
    setOpenModal,
    openModal,
    selectedCard,
    editForm,
    setEditForm,
    fetchCreditCards,
  } = props;
  const params = useParams();
  const pathParam = params.pathParam;
  const history = useHistory();
  const [numberBlur, setNumberBlur] = useState();
  const [expireBlur, setExpireBlur] = useState();
  const [expireNumber, setExpireNumber] = useState();
  const [number, setNumber] = useState();
  const [countries, setCountries] = useState([]);
  const [addressBook, setAddressBook] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    id: 64,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cvc, setCvc] = useState();
  const authCtx = useAuthContext();
  const msgCtx = useMessageContext();

  useEffect(() => {
    API.getCountries().then((res) => setCountries(res.data));
    API.getAddressBookInfo().then((res) => {
      const addresses = res.data.filter((item) => {
        return item?.country?.id === 64;
      });

      setAddressBook(addresses);
    });
  }, []);

  useEffect(() => {
    API.getStateOfCountry(selectedCountry.id).then((res) => {
      setStates(res.data.states);
    });
  }, [selectedCountry]);

  useEffect(() => {
    if (!isEmpty(selectedCard) && isEmpty(selectedAddress) && editForm) {
      setSelectedAddress(selectedCard?.customer_address);
    }
    if (!isEmpty(selectedCard) && editForm) {
      formik.setFieldValue(
        "first_name",
        selectedCard.customer_address.first_name
      );
      formik.setFieldValue(
        "last_name",
        selectedCard.customer_address.last_name
      );
      formik.setFieldValue("address1", selectedCard.customer_address.address1);
      formik.setFieldValue("address2", selectedCard.customer_address.address2);
      formik.setFieldValue("zip", selectedCard.customer_address.zip);
      formik.setFieldValue("city", selectedCard.customer_address.city);
      formik.setFieldValue("phone", selectedCard.customer_address.phone);
      formik.setFieldValue(
        "company_name",
        selectedCard.customer_address.company_name
      );
      formik.setFieldValue("card_holder_name", selectedCard.card_holder_name);
    }
    if (!isEmpty(selectedAddress) && !editForm) {
      formik.setFieldValue("first_name", selectedAddress.first_name);
      formik.setFieldValue("last_name", selectedAddress.last_name);
      formik.setFieldValue("address1", selectedAddress.address1);
      formik.setFieldValue("address2", selectedAddress.address2);
      formik.setFieldValue("zip", selectedAddress.zip);
      formik.setFieldValue("city", selectedAddress.city);
      formik.setFieldValue("phone", selectedAddress.phone);
      formik.setFieldValue("company_name", selectedAddress.company_name);
    }
  }, [selectedCard, selectedAddress]);

  const redirectToPathParam = () => {
    history.push(`/${pathParam}`);
    authCtx.getCustomer();
  };

  const formik = useCreditAndBillingAddressFormik(selectedCard, {
    onSubmit: async (values) => {
      if (!selectedState) {
        setError(true);
      }

      const issuer = Payment.fns.cardType(number);
      let cardTypeId;
      switch (issuer) {
        case "visa":
          cardTypeId = 1;
          break;
        case "mastercard":
          cardTypeId = 2;
          break;
        case "amex":
          cardTypeId = 3;
          break;
        case "discover":
          cardTypeId = 4;
          break;
        default:
          cardTypeId = 10;
      }

      const creditData = new FormData();
      // const expiration = `${values.year}-${values.month}`;
      creditData.append("first_name", values.first_name);
      creditData.append("last_name", values.last_name);
      creditData.append("zip", values.zip);
      creditData.append("address1", values.address1);
      creditData.append("location_country_id", selectedCountry.id);
      creditData.append(
        "location_state_id",
        selectedState.id ? selectedState.id : selectedAddress.state.id
      );
      creditData.append("city", values.city);
      creditData.append("phone", values.phone);
      creditData.append("credit_card_type_id", cardTypeId);
      creditData.append("number", number.replace(/\D+/g, ""));
      creditData.append("expiration_date", convertMonthAndYear(expireNumber));
      creditData.append("cvv2", cvc);
      creditData.append("card_holder_name", values.card_holder_name);
      creditData.append("default_address", 1);

      if (autoShip) {
        setIsLoading(true);

        API.addCreditCard(creditData)
          .then((res) => {
            const id = res.data.credit_card_id;
            props.onSubmit(id);
            fetchCreditCards();
            setIsLoading(false);
            setOpenModal(!openModal);

            if (pathParam) {
              redirectToPathParam();
            }
          })
          .catch((err) => {
            if (err.response) {
              msgCtx.handleError(err);
              setIsLoading(false);
            }
          });
      }

      if (openModal && !selectedCard && selectedState && !selectedAddress) {
        setIsLoading(true);
        API.addCreditCard(creditData)
          .then((res) => {
            fetchCreditCards();
            setIsLoading(false);
            setOpenModal(!openModal);
          })
          .catch((err) => {
            if (err) {
              msgCtx.handleError(err);
              setIsLoading(false);
            }
          });
      }

      if (openModal && !selectedCard && selectedAddress) {
        setIsLoading(true);

        API.addCreditCard(creditData)
          .then((res) => {
            fetchCreditCards();
            setIsLoading(false);
            setOpenModal(!openModal);
          })
          .catch((err) => {
            if (err) {
              msgCtx.handleError(err);
              setIsLoading(false);
            }
          });
      }

      if (editForm && !isEmpty(addressBook)) {
        let editData = {
          first_name: values.first_name,
          last_name: values.last_name,
          zip: values.zip,
          address1: values.address1,
          address2: values.address2,
          company_name: values.company_name,
          location_country_id: selectedCountry.id,
          location_state_id: selectedState.id
            ? selectedState.id
            : selectedAddress.state.id,
          city: values.city,
          phone: values.phone,
          credit_card_type_id: cardTypeId,
          number: number.replace(/\D+/g, ""),
          expiration_date: convertMonthAndYear(expireNumber),
          cvv2: cvc,
          card_holder_name: values.card_holder_name,
        };
        setIsLoading(true);

        if (!selectedState) {
          setError(true);
        }

        API.editCreditCard(selectedCard.id, editData)
          .then(() => {
            fetchCreditCards();
            setIsLoading(true);
            setEditForm(false);
            setOpenModal(!openModal);
            if (pathParam) {
              redirectToPathParam();
            }
          })
          .catch((err) => {
            if (err) {
              msgCtx.handleError(err);
              setIsLoading(false);
            }
          });
      }
    },
  });

  const {
    first_name,
    last_name,
    address1,
    address2,
    zip,
    city,
    phone,
    company_name,
    year,
    month,
    cvv2,
    card_holder_name,
  } = formik.values;

  return (
    <Form
      id="credit-card-form"
      className="CreditAndBillingAddress"
      onSubmit={formik.handleSubmit}
    >
      <div className="CreditAndBillingAddress__card">
        <h1 className="CreditAndBillingAddress__title">
          ENTER CREDIT CARD DETAILS
        </h1>
        <CreditCardInput
          containerClassName="CreditCardForm__react-input-container"
          containerStyle={{ width: "100%" }}
          cardNumberInputProps={{
            value: number,
            onChange: (e) => setNumber(e.target.value),
          }}
          cardExpiryInputProps={{
            value: expireNumber,
            onChange: (e) => setExpireNumber(e.target.value),
          }}
          cardCVCInputProps={{
            value: cvc,
            onChange: (e) => setCvc(e.target.value),
          }}
          fieldClassName="CreditCardForm__react-input"
        />
        <div className="CreditCardInput__error-container">
          {numberBlur && !number && (
            <div className="CreditCardInput__error">
              Please provide credit card number
            </div>
          )}
          {expireBlur && !expireNumber && (
            <div className="CreditCardInput__error   CreditCardInput__error--expire">
              Please provide month & year
            </div>
          )}
        </div>

        {/*    {cardType.id === 0 && (
          <div className="CreditCardInput__error">
            Please enter a valid card number.
          </div>
        )} */}

        <div className="CreditAndBillingAddress__input-container">
          <label className="CreditAndBillingAddress__label">
            Name on credit card:{" "}
          </label>
          <Input
            name="card_holder_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={card_holder_name}
            placeholder="Full name"
            className="CreditAndBillingAddress__input"
            type="text"
          />
          {formik.errors && formik.touched.card_holder_name && (
            <div className="CreditAndBillingAddress__error">
              {formik.errors.card_holder_name}
            </div>
          )}
        </div>
      </div>

      <div className="CreditAndBillingAddress__billing">
        <h1 className="CreditAndBillingAddress__title CreditAndBillingAddress__title--billing ">
          {" "}
          Billing Address{" "}
        </h1>

        {!isEmpty(addressBook) && !editForm && (
          <SelectAddress
            label="Already have an address?"
            onItemSelect={(item) => {
              setSelectedAddress(item);
            }}
            selectedItem={selectedAddress}
            items={addressBook}
          />
        )}

        <div className="CreditAndBillingAddress__grid">
          <div className="CreditAndBillingAddress__input-container">
            <Input
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={first_name}
              placeholder="First Name"
              className="CreditAndBillingAddress__input"
              type="text"
            />
            {formik.errors && formik.touched.first_name && (
              <div className="CreditAndBillingAddress__error">
                {formik.errors.first_name}
              </div>
            )}
          </div>
          <div className="CreditAndBillingAddress__input-container">
            <Input
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={last_name}
              placeholder="Last Name"
              className="CreditAndBillingAddress__input"
              type="text"
            />
            {formik.errors && formik.touched.last_name && (
              <div className="CreditAndBillingAddress__error">
                {formik.errors.last_name}
              </div>
            )}
          </div>
        </div>

        <div className="CreditAndBillingAddress__input-container">
          <Input
            name="company_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={company_name}
            placeholder="Company"
            className="CreditAndBillingAddress__input"
            type="text"
          />
        </div>
        <div className="CreditAndBillingAddress__input-container">
          <Input
            name="address1"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={address1}
            placeholder="Address"
            className="CreditAndBillingAddress__input"
            type="text"
          />
          {formik.errors && formik.touched.address1 && (
            <div className="CreditAndBillingAddress__error">
              {" "}
              {formik.errors.address1}{" "}
            </div>
          )}
        </div>
        <div className="CreditAndBillingAddress__input-container">
          <Input
            name="address2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={address2}
            placeholder="Apartment, Suite, Floor, etc (optional)"
            className="CreditAndBillingAddress__input"
            type="text"
          />
        </div>
        <div className="CreditAndBillingAddress__input-container">
          <Input
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={city}
            placeholder="City"
            className="CreditAndBillingAddress__input"
            type="text"
          />
          {formik.errors && formik.touched.city && (
            <div className="CreditAndBillingAddress__error">
              {" "}
              {formik.errors.city}{" "}
            </div>
          )}
        </div>
        <div className="CreditAndBillingAddress__grid">
          <SelectCountry2
            onItemSelect={(name, id) =>
              setSelectedCountry({ name: name, id: id })
            }
            selectedItem={
              selectedAddress.country
                ? selectedAddress.country.name
                : selectedCountry.name
            }
            items={countries}
            disabled
          />
          <div>
            <SelectCountry2
              label="Select State"
              onItemSelect={(name, id) =>
                setSelectedState({ name: name, id: id })
              }
              /*   selectedItem={
                selectedAddress.state
                  ? selectedAddress.state.name
                  : selectedState.name
              } */
              selectedItem={
                selectedState?.name
                  ? selectedState?.name
                  : selectedAddress?.state?.name
              }
              items={states}
            />
            {!selectedState && isEmpty(selectedAddress) && error && (
              <p className="CreditAndBillingAddress__error">
                Please provide state
              </p>
            )}
          </div>
        </div>

        <div className="CreditAndBillingAddress__input-container">
          <Input
            name="zip"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={zip}
            placeholder="ZIP"
            className="CreditAndBillingAddress__input"
            type="text"
          />
          {formik.errors && formik.touched.zip && (
            <div className="CreditAndBillingAddress__error">
              {formik.errors.zip}
            </div>
          )}
        </div>

        <div className="CreditAndBillingAddress__input-container">
          <Input
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={phone}
            placeholder="Phone"
            className="CreditAndBillingAddress__input"
            type="text"
          />
          {formik.errors && formik.touched.phone && (
            <div className="CreditAndBillingAddress__error">
              {formik.errors.phone}
            </div>
          )}
        </div>

        <button
          className="CreditAndBillingAddress__button4"
          type="submit"
          disabled={isLoading ? true : false}
        >
          {isLoading ? (
            <Spinner
              style={{
                border: "0.25em solid #fffff",
                borderRightColor: "transparent",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
          ) : (
            "Save Changes"
          )}
        </button>
      </div>
    </Form>
  );
};

export default CreditAndBillingAddress;
