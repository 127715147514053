import React from "react";
import { Link } from "react-router-dom";
import Seo from "../../../../lib/helpers/SEO/Seo";

const CaribBean = () => {
  const article = {
    title: "The Caribbean",
    image: "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png",
    url: "https://www.gocoffeego.com/learn/category/regions/caribbean"
  }
  return (
    <div>
      <Seo article={article} />
      <h3>The Caribbean</h3>
      <p>
        Coffee growing in the Caribbean Americas began in this chain of tropical
        islands once ruled by Europe's colonial powers -- Britain, France and
        The Netherlands. But today is what matters and I happily island hop by
        seaplane between these now independent states to sample their mellow,
        fruity and brightly acidic coffees.
      </p>

      <h3>Dominican Republic</h3>
      <p>
        Lets first land on the Dominican half of the Island of Hispaniola where
        the land and people produce a variety of complex, low altitude coffees.
        Flavor sensations abound, but we mostly notice their bright, light or
        mellow finish with spicy hints. There are seven specific growing regions
        established by the government with various microclimates.
      </p>
      <p>Best known varietals: Santo Domingo.</p>

      <h3>Haiti</h3>
      <p>
        Traveling by motorbike, my lovely assistant sitting on the handlebars,
        we cross the muddy highlands of Hispaniola and end up in Haiti where the
        coffee is characteristically mellow and sweet and very popular on the
        Continent, you know.
      </p>

      <h3>Jamaica</h3>
      <p>
        I like to come back to Jamaica and sample their coffees with a brightly
        acidic, delightfully nutty flavor and its surprising, uniquely
        beefy-flavored notes. The eastern end of the island produces hard to
        find - at least in the U.S. - Jamaican Blue Mountain coffee, considered
        one of the finest in the world. Sweet and mellow, mon! You'll have
        better luck finding it in Europe or Japan where they crave it like
        bloodthirsty zombies!
      </p>
      <p>
        Best known varietals: Jamaican Blue Mountain, High Mountain Supreme,
        Typica.
      </p>

      <h3>Puerto Rico</h3>
      <p>
        After touring the streets of Old San Juan on my scooter, I head into the
        wonderfully eclectic cafes in this contrasting city of old and new to
        sample Café Rico and other notable local coffees. Mellow, crisp and
        fruity cup.
      </p>
      <p>
        Best known varietals: Café Rico, Yauco Selecto, Typica, Bourbon, Limon
      </p>
      <Link
        onClick={() =>
          document
            .getElementById("Regions")
            .scrollIntoView({ behavior: "smooth" })
        }
        className="BottomLinks"
        to="/learn/category/regions/south-america"
      >
        <h3> Click here to explore South America. </h3>
      </Link>
    </div>
  );
};

export default CaribBean;
