import React from "react";
import Line from "../../shared/Line/Line";

import "./ArticleContainer.scss";

const ArticleContainer = (props) => {
  const { children, title, shapeStyle } = props;
  return (
    <div className="ArticleContainer">
      <div className="ArticleContainer__content">
        <h1 id="article-container" className="ArticleContainer__title font-cairo">
          {title}
          <div style={shapeStyle} className="ArticleContainer__shape" />
        </h1>
        {children}
      </div>
      <div className="EspressoDrinks__line">
        <Line />
        <Line />
      </div>
    </div>
  );
};

export default ArticleContainer;
