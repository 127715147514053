import { useFormik } from "formik";
import * as Yup from "yup";

const CreditCardSchema = Yup.object().shape({
  // number: Yup.string().required("Please provide number."),
  // year: Yup.string()
  //   .required("Please provide expiration year.")
  //   .max(4, "Maximum is 4 digits"),
  // month: Yup.string()
  //   .required("Please provide expiration month.")
  //   .max(2, "Maximum is 2 digits"),
  // cvv2: Yup.string()
  //   .required("Please provide  CVV.")
  //   .max(3, "Maximum is 3 digits"),
  card_holder_name: Yup.string().required("Please provide full name."),
});

export const useCreditCardFormik = (opts) => {
  return useFormik({
    initialValues: {
      // number: "",
      // year: "",
      // month: "",
      cvv2: "",
      card_holder_name: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CreditCardSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
