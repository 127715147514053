import React from "react";

import "./CoffeStyleCircle.scss";

const CoffeStyleCircle = (props) => {
  const { name, onSelectItem, image } = props;

  return (
    <div onClick={onSelectItem} className="CoffeStyleCircle">
      <div className="CoffeStyleCircle__wrap">
        <img src={image} alt={name} className="CoffeStyleCircle__image" loading="lazy" />
      </div>
      <p className="CoffeStyleCircle__title"> {name}</p>
    </div>
  );
};

export default CoffeStyleCircle;
