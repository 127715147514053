import { Link } from "react-router-dom";
import "./RoastersItem.scss";
import PopUp from "../../shared/PopUp/PopUp";

const RoastersItem = (props) => {
  const { roaster } = props;

  return (
    <Link
      to={`/roaster/${roaster.slug}-${roaster?.id}`}
      className="RoastersItem"
    >
      <PopUp roaster={roaster} />
      <h2 className="RoastersItem__name">{roaster.name}</h2>
      <img
        src={roaster.main_image?.md}
        alt={roaster.name}
        className="RoastersItem__image"
      />
      <div className="RoastersItem__desc">{roaster?.short_message}</div>
    </Link>
  );
};

export default RoastersItem;
