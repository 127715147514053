import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LinkButton from "../../shared/LinkButton/LinkButton";
import Button from "../../shared/Button/Button";
import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { Spinner } from "reactstrap";
import { isEmpty } from "../../../lib/helpers/isEmpty";
//import { motion } from "framer-motion/dist/framer-motion";

import "./CartPopUp.scss";
import Quantity from "../../shared/Quantity/Quantity";

const CartPopUp = (props) => {
  const { items } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const cartCtx = useCartContext();
  const history = useHistory();
  const ScooterGirl =
    "https://assets.gocoffeego.com/v2/assets/images/scooter-girl1.png";

  const handleDelete = (id) => {
    API.deleteItemFromCart(id, items.cart_id).then((res) => {
      cartCtx.getCartItems();
      setIsLoading(false);
    });
  };

  const firstItemSlug = isEmpty(items)
    ? undefined
    : items?.items[0]?.product?.purveyor?.slug;

  const firstItemPurveyor = isEmpty(items)
    ? undefined
    : items?.items[0]?.product?.purveyor;
  const firstItemName = isEmpty(items)
    ? undefined
    : items?.items[0]?.product?.purveyor?.name;

  const firstItemId = isEmpty(items)
    ? undefined
    : items?.items[0]?.product?.purveyor?.id;

  return (
    <div className="CartPopUp">
      <div
        className={
          cartCtx.cartModalOpen
            ? "CartPopUp__overlay"
            : "CartPopUp__overlay overlay-hide"
        }
        onClick={() => {
          cartCtx.toggleCartModal();
        }}
      />
      <div>
        <div
          className={
            cartCtx.cartModalOpen
              ? "CartPopUp__container"
              : "CartPopUp__container closePopUp"
          }
        >
          <div className="CartPopUp__wrapper">
            <h2>Order Summary</h2>

            <button
              className="CartPopUp__close-btn"
              onClick={() => {
                cartCtx.toggleCartModal();
              }}
            >
              Close
            </button>
          </div>

          {items?.items?.length === 0 || isEmpty(items) ? (
            <div className="CartPopUp__message">
              <p className="CartPopUp__empty-message"> Your cart is empty.</p>
              <img
                className="CartPopUp__scooter-girl"
                src={ScooterGirl}
                alt="scooter girl"
              />
            </div>
          ) : (
            <div className="CartPopUp__content">
              {items?.items?.map((item) => (
                <div className="CartPopUp__item" key={item?.id}>
                  {item?.gift_from && item?.gift_subscription === null && (
                    <img
                      src={item?.product?.image}
                      alt={item?.product?.name}
                      className="CartPopUp__image"
                    />
                  )}
                  {item?.product?.main_image?.md && item?.gift_subscription && (
                    <img
                      src={item?.product?.main_image?.md}
                      alt={item?.product?.name}
                      className="CartPopUp__image"
                    />
                  )}
                  {item?.product?.main_image?.sm &&
                    item?.gift_subscription === null && (
                      <Link to={`/${item?.product?.product_url}`}>
                        {" "}
                        <img
                          src={item?.product?.main_image?.sm}
                          alt={item?.product?.name}
                          className="CartPopUp__image"
                        />
                      </Link>
                    )}

                  <div>
                    {item?.gift_from === null &&
                    item?.product?.purveyor?.slug ? (
                      <Link
                        to={`/roaster/${item?.product?.purveyor?.slug}-${item?.product?.purveyor?.id}`}
                      >
                        {" "}
                        <div className="CartPopUp__subtitle">
                          {item?.product?.purveyor?.name}
                        </div>
                      </Link>
                    ) : (
                      <div className="CartPopUp__subtitle">
                        {item?.product?.purveyor?.name}
                      </div>
                    )}
                    {item?.gift_from === null && item?.product?.product_url ? (
                      <Link to={`/${item?.product?.product_url}`}>
                        {" "}
                        <div className="CartPopUp__title">
                          {item?.product?.name}
                        </div>
                      </Link>
                    ) : (
                      <div className="CartPopUp__title">
                        {item?.product?.name}
                      </div>
                    )}

                    {item?.gift_from === null &&
                      item?.gift_subscription === null && (
                        <div className="CartPopUp__details">
                          {`$${item?.product?.retail_price?.toFixed(2)} / ${
                            item?.product?.weight
                          }`}
                        </div>
                      )}
                    {item?.gift_from && item?.gift_subscription && (
                      <>
                        <div className="CartPopUp__details">
                          {item?.gift_subscription?.description}
                        </div>
                        <div className="CartPopUp__details">
                          {`$${item?.gift_subscription?.price?.toFixed(2)}
                    `}
                        </div>
                      </>
                    )}
                    {item?.gift_from && item?.gift_subscription === null && (
                      <div className="CartPopUp__details">
                        {`$${item?.product?.gift_amount?.toFixed(2)} 
                      `}
                      </div>
                    )}

                    <Quantity
                      quantity={item?.quantity}
                      cartId={items?.cart_id}
                      coffeeGrind={item?.coffee_grind?.id}
                      itemId={item?.id}
                    />
                  </div>
                  {items.cart_id && (
                    <button
                      className="CartPopUp__close"
                      onClick={() => {
                        handleDelete(item?.id);
                        setSelectedItem(item?.id);
                        setIsLoading(true);
                      }}
                    >
                      {" "}
                      {isLoading && item?.id === selectedItem ? (
                        <Spinner animation="grow" variant="dark" />
                      ) : (
                        <>&#10005;</>
                      )}
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="CartPopUp__subtotal-wrapper">
            <div className="CartPopUp__subtotal">
              Subtotal:{" "}
              {items?.totals?.subtotal
                ? `$${items?.totals?.subtotal?.toFixed(2)}`
                : `$0.00`}
            </div>
            <div className="CartPopUp__buttons-wrapper">
              <Button
                label="CART"
                className="CartPopUp__button"
                onClick={() => history.push("/cart")}
              />
              {items?.items?.length > 0 ? (
                <LinkButton
                  label="CHECKOUT"
                  path="/checkout"
                  className="CartPopUp__button"
                  onClick={() => window.gtag("event", "conversion", { "send_to": "AW-11011335274/Q3wLCJaN9IAYEOrIzoIp" }) }
                />
              ) : (
                <Button
                  label="CHECKOUT"
                  className="CartPopUp__button"
                  disabled
                />
              )}
            </div>

            {items && firstItemPurveyor && (
              <div className="CartPopUp__continue-link-text">
                Go back to:{" "}
                <Link
                  to={`/roaster/${firstItemSlug}-${firstItemId}`}
                  className="CartPopUp__continue-purveyor-link"
                >
                  {firstItemName}
                </Link>
              </div>
            )}
            <Link to="/shop" className="CartPopUp__continue-link">
              Continue Shopping{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopUp;
