import React from "react";
import { Link } from "react-router-dom";

import cs from "classnames";

import "./LinkButton.scss";

const LinkButton = (props) => {
  const { label, style, className, path } = props;
  return (
    <Link
      onClick={props.onClick}
      to={path}
      style={style}
      className={cs("LinkButton", className)}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
