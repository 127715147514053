import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import { Icon } from "../../shared/Icon/Icon";
import "./FAQGiftCertModal.scss";

const FAQGiftCertModal = (props) => {
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="FAQGiftCertModal">
        <div className="FAQGiftCertModal__container">
          <div className="FAQGiftCertModal__header">
            <h1 className="FAQGiftCertModal__header-title">
              Gift Certificate FAQ's
            </h1>
            <Icon
              style={{ width: "20px", height: "20px" }}
              onClick={props.toggle}
              className="FAQGiftCertModal__close"
              icon="close"
            />
          </div>
          <div className="FAQGiftCertModal__body">
            <div className="FAQGiftCertModal__list">
              <ol>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    {" "}
                    How do I print a Gift Certificate?
                  </h2>
                  <p>
                    For best results with the most vibrant colors, use any color
                    printer with 8 ½” by 11” high-quality inkjet photo paper.
                    Don't worry, if you don have this type of paper, plain works
                    really well too. There are two pages, the Gift Certificate
                    followed by the instructions for the lucky recipient on how
                    to redeem their gift.
                  </p>
                </li>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    {" "}
                    How do I print multiple Gift Certificates?
                  </h2>
                  <p>
                    After you checkout, go to “My Account” click on “Download
                    Gift Certificate” in "View Orders"/"View Order". You will
                    have a choice to print or re-print the different Gift
                    Certificates by recipient for up to 24 hours.
                  </p>
                </li>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    {" "}
                    How do I redeem a Gift Certificate?
                  </h2>
                  <p>
                    If you don't already have a GoCoffeeGo account, create one.
                    Go to the Gift Cards tab and select Redeem Gift Cards Enter
                    the 12-digit code on the front of the Gift Certificate. The
                    monies are automatically placed in your GoCoffeeGo account.
                    You can go shopping now, or keep it for later. Also if you
                    want, you can enter the Gift Certificate code during the
                    checkout process when asked.
                  </p>
                </li>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    How do I know how much balance is left on my Gift
                    Certificate ?
                  </h2>
                  <p>
                    If you don't already have a GoCoffeeGo account, create one.
                    Go to the Gift Cards tab, click Check Balance. It's a good
                    idea after you check the balance to go ahead and Redeem Gift
                    CardsRedeem Gift Cards to keep your money for safe keeping
                    in your account.
                  </p>
                </li>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    What if my Gift Certificate monies are not enough for my
                    purchase?
                  </h2>
                  <p>
                    If there is not enough money for your purchase, during
                    checkout you will be asked to enter a Credit Card, if you
                    don't already have one on file.
                  </p>
                </li>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    Can I use Gift Certificate monies towards Auto-Ship?
                  </h2>
                  <p>
                    Yes, absolutely! The Gift Certificate monies in your account
                    will be used first, followed by your on file Credit Card for
                    Auto-Ship.
                  </p>
                </li>
                <li className="FAQGiftCertModal__list-item">
                  <h2 className="FAQGiftCertModal__list-title">
                    How long is the gift message, I can write?
                  </h2>
                  <p>
                    Your gift message can be up to 10 to 12 words, or 70
                    characters. We have a character countdown for you, so you
                    know when it’s time to “wrap it up.”
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FAQGiftCertModal;
