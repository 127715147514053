import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import { Icon } from "../../shared/Icon/Icon";
import "./TermsOfUseModal.scss";

const TermsOfUseModal = (props) => {
  return (
    <ModalWrapper isOpen={props.isOpen} toggle={props.toggle}>
      <div className="TermsOfUseModal">
        <div className="TermsOfUseModal__container">
          <div className="TermsOfUseModal__header">
            <h1 className="TermsOfUseModal__header-title">
              Gift Cards and Gift Certificates Terms of Use
            </h1>
            <Icon
              style={{ width: "20px", height: "20px" }}
              onClick={props.toggle}
              className="TermsOfUseModal__close"
              icon="close"
            />
          </div>
          <div className="TermsOfUseModal__body">
            <div className="TermsOfUseModal__list">
              <ol type="1">
                <li className="TermsOfUseModal__list-item">
                  These Terms of use are supplementary to the GoCoffeeGo Terms
                  and Conditions, and apply specifically to Gift Cards and Gift
                  Certificates.
                </li>
                <li className="TermsOfUseModal__list-item">
                  {" "}
                  Gift Cards and Gift Certificates must be redeemed through our
                  Web site toward the purchase of products listed at
                  GoCoffeeGo.com.
                </li>
                <li className="TermsOfUseModal__list-item">
                  {" "}
                  Access to redemption of Gift Cards and Gift Certificates, or
                  purchases from, is subject to the acceptance of Terms of
                  Conditions presented at time of redemption.
                </li>
                <li className="TermsOfUseModal__list-item">
                  Gift Cards and Gift Certificates cannot be used to purchase
                  other Gift Cards or Gift Certificates.
                </li>
                <li className="TermsOfUseModal__list-item">
                  Gift Cards and Gift Certificates are not redeemable for cash
                  and cannot be returned for a refund.
                </li>
                <li className="TermsOfUseModal__list-item">
                  Any unused balance will be placed in the recipient's account.
                </li>
                <li className="TermsOfUseModal__list-item">
                  If your order exceeds the amount of your Gift Card or Gift
                  Certificate, you must pay for the balance with a credit card.
                </li>
                <li className="TermsOfUseModal__list-item">
                  Gift Cards and Gift Certificates cash value is 1/10 of one
                  cent.
                </li>
                <li className="TermsOfUseModal__list-item">
                  GoCoffeeGo is not responsible for lost or stolen Gift Cards
                  and Gift Certificates, however, if provided with sufficient
                  information, we will cancel the subject Gift Card or Gift
                  Certificate and issue a replacement, subject to our ability to
                  validate and authenticate the claim.
                </li>
                <li className="TermsOfUseModal__list-item">
                  {" "}
                  GoCoffeeGo reserves the right to close customer accounts and
                  request alternate forms of payment if a Gift Card or Gift
                  Certificate is fraudulently obtained or used at the GoCoffeeGo
                  website.
                </li>
                <li className="TermsOfUseModal__list-item">
                  GOCOFFEGO MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH
                  RESPECT TO GIFT CARDS OR GIFT CERTIFICATES INCLUDING, WITHOUT
                  LIMITATION, ANY EXPRESS OR IMPLIED WARRANTY OF MERCHANTABILITY
                  OR FITNESS FOR A PARTICULAR PURPOSE. IN THE EVENT A GIFT CARD
                  OR GIFT CERTIFICATE CODE IS NON-FUNCTIONAL, YOUR SOLE REMEDY,
                  AND OUR SOLE LIABILITY, SHALL BE THE REPLACEMENT OF SUCH GIFT
                  CARD OR GIFT CERTIFICATE. THESE LIMITATIONS MAY NOT APPLY TO
                  YOU. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                  DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
                  AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                </li>
                <li className="TermsOfUseModal__list-item">
                  GoCoffeeGo.com reserves the right to change these terms and
                  conditions from time to time without notice.
                </li>
                <li className="TermsOfUseModal__list-item">
                  Any of these Terms and Conditions may be void where prohibited
                  by law.
                </li>
                <li className="TermsOfUseModal__list-item">
                  Risk of loss and title for Gift Cards and Gift Certificates
                  transmitted electronically, pass to the purchaser in
                  California upon electronic transmission to the recipient.
                </li>
                <li className="TermsOfUseModal__list-item">
                  {" "}
                  *** Gift Cards and Gift Certificates purchased, including
                  unused portions, expire fifteen years from the date of
                  issuance. (Expiration date does not apply in California,
                  Connecticut, Louisiana, Maine, Massachusetts, Rhode Island,
                  Washington; Expiration date does not apply in any other states
                  where prohibited by law.)
                </li>
                <li className="TermsOfUseModal__list-item">
                  *** GiftCards and Gift Certificates issued as part of
                  promotional fulfillment may have a different expiration date.
                  See specific promotion for details.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TermsOfUseModal;
