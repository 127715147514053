import React, { useState } from "react";

import ViewMoreButton from "../../shared/ViewButton/ViewMoreButton";
import PopUp from "../../shared/PopUp/PopUp";
import SubscriptionDetailsModal from "../../Modals/SubscriptionDetailsModal/SubscriptionDetailsModal";
import RadioButton from "../../ProductPage/ProductDetails/RadioButton/RadioButton";

import cs from "classnames";

import "./PrePaidSubscription.scss";

const PrePaidSubscription = (props) => {
  const {
    image,
    id,
    selectedSubscriptionId,
    name,
    shortMessage,
    detail,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="PrePaidSubscription">
      <SubscriptionDetailsModal
        name={name}
        shortMessage={shortMessage}
        detail={detail}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />

      <div
        onClick={() => props.onClick()}
        className="PrePaidSubscription__image-container"
      >
        <PopUp
          prePaid
          prePaidName={name}
          PrePaidMessage={shortMessage}
          PrePaidDetail={detail}
          handleClick={() => props.onClick()}
        />
        <RadioButton
          onClick={() => props.onClick()}
          className="PrePaidSubscription__radio-button"
          selectedItem={selectedSubscriptionId}
          name=""
          id={id}
        />
        <img
          className={cs(
            "PrePaidSubscription__image",
            selectedSubscriptionId === id &&
              "PrePaidSubscription__image--selected"
          )}
          src={image}
          alt="subscription"
        />
      </div>

      <ViewMoreButton
        className="PrePaidSubscription__view-more"
        handleClick={() => setIsOpen(!isOpen)}
        label="Read more"
      />
    </div>
  );
};

export default PrePaidSubscription;
