import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import CreditCardForm from "../CreditCardForm/CreditCardForm";
import PaypalButton from "../../shared/PaypalButton/PaypalButton";
import SelectCard from "../CreditCardForm/SelectCard/SelectCard";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import * as API from "../../../api/Api";
import { Spinner } from "reactstrap";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { Event } from "../../../lib/helpers/GoogleAnalytics/event";
import { setGaUser } from "../../../lib/helpers/GoogleAnalytics/user";
import { useAddressFormik } from "../AddressForm/useAddressFormik";

import "./PaymentMethod.scss";

const PaymentMethod = (props) => {
  const { pathParam, joinClub, canProceed, autoShip, checkout } = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const [creditCards, setCreditCards] = useState([]);
  const [creditCardsLoading, setCreditCardsLoading] = useState(true);
  const [showCreditCardForm, setShowCreditCardForm] = useState(false);
  const [paymentisLoading, setPaymentIsLoading] = useState(false);
  const [cardAlreadySelected, setCardAlreadySelected] = useState(false);

  const history = useHistory();
  const [selectedCard, setSelectedCard] = useState({
    id: 0,
    initMessage: isMobile
      ? "Select previous  cards"
      : "Select previous saved credit cards",
  });
  const cartCtx = useCartContext();
  const msgCtx = useMessageContext();
  const authCtx = useAuthContext();
  const billingAddressRequired = cartCtx.cartItems?.billing_address_required;
  const shippingAddressRequired = cartCtx.cartItems?.shipping_address_required;
  const shippingAddress = authCtx.customerData?.shipping_address;
  const shippingAddressId = authCtx.customerData?.shipping_address_id;
  const totalCredits = cartCtx.cartItems?.totals?.credits;
  const mainTotal = cartCtx.cartItems?.totals?.total;
  const countedTotal =
    cartCtx.cartItems?.totals?.delivery +
    cartCtx.cartItems?.totals?.subtotal +
    cartCtx.cartItems?.totals?.tax;
  ReactGA.plugin.require("ecommerce");
  const stripe = useStripe();

  const formik = useAddressFormik();

  useEffect(() => {
    API.getCreditCards()
      .then((res) => {
        setCreditCards(res.data);

        if (res.data) {
          if (joinClub && canProceed) {
            const card = res.data.find((item) => {
              return item?.subscription_card === 1;
            });

            if (card?.id) {
              setSelectedCard(card);
              setCardAlreadySelected(card);
              setCreditCardsLoading(false);
              return;
            }
          }
        }
        if (
          cartCtx.cartItems?.credit_card === null &&
          res?.data?.length !== 0
        ) {
          setSelectedCard(res.data[0]);
          API.selectCard(res.data[0].id);
          authCtx.getCustomer();
        }

        if (res.data) {
          setCreditCardsLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          //  msgCtx.handleError(err);
          setPaymentIsLoading(false);
        }
      });
    if (cartCtx.cartItems?.credit_card?.length !== 0) {
      setSelectedCard(cartCtx.cartItems?.credit_card);
    }
  }, [cartCtx.cartItems]);

  const handlePlaceOrder = (selectedCardId) => {
    if (joinClub) {
      setPaymentIsLoading(true);

      if (cardAlreadySelected.id === selectedCard.id) {
        props.joinClubNow();

        return;
      }

      API.joinClubCard(selectedCardId)
        .then((res) => {
          if (res.status === 200) {
            props.joinClubNow();
          }
        })
        .catch((err) => {
          if (err) {
            msgCtx.handleError(err);
            setPaymentIsLoading(false);
          }
        });
      return;
    }

    setPaymentIsLoading(true);
    API.selectCard(selectedCardId).then(() => {
      API.getPaymentIntent()
        .then((res) => {
          const clientSecret = res?.data?.client_secret;
          stripe.confirmCardPayment(clientSecret).then((res) => {
            const formData = new FormData();
            formData.append("payment_intent_id", res.paymentIntent?.id);

            API.postPayment(formData)
              .then((res) => {
                setPaymentIsLoading(false);
                if (res.data.order_id) {
                  cartCtx.getCartItems();

                  setPaymentIsLoading(false);
                  history.push(`/order-success/${res.data.order_id}`);
                  localStorage.setItem("order_id", res.data.order_id);

                  Event({
                    category: "ORDER",
                    action: "CREATE_ORDER",
                    label: "PLACE_ORDER",
                  });

                  window.fbq("track", "Purchase", {
                    value: mainTotal,
                    currency: "USD",
                    transaction_id: res.data.order_id,
                  });

                  cartCtx?.cartItems?.items.map((item) =>
                    ReactGA.plugin.execute("ecommerce", "addItem", {
                      id: res.data?.order_id,
                      name: item?.product?.name,
                      sku: "--",
                      price: item?.product?.retail_price,
                      currency: "USD",
                      quantity: item?.quantity,
                    })
                  );

                  ReactGA.plugin.execute("ecommerce", "addTransaction", {
                    id: res.data.order_id,
                    revenue: mainTotal,
                  });
                  ReactGA.plugin.execute("ecommerce", "send");
                  ReactGA.plugin.execute("ecommerce", "clear");

                  window.gtag("event", "conversion", {
                    send_to: "AW-1037851689/k82RCJGNpQEQqbjx7gM",
                    value: mainTotal,
                    currency: "USD",
                    transaction_id: res.data.order_id,
                  });
                  window.gtag("event", "order_success", {
                    order_value: mainTotal,
                    order_id: res.data.order_id,
                    currencyCode: "USD",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                setPaymentIsLoading(false);
                msgCtx.handleError(err);
              });
          });
        })
        .catch((err) => {
          setPaymentIsLoading(false);
          msgCtx.handleError(err);
        });
    });
  };

  const placeOrderWithCredits = () => {
    setPaymentIsLoading(true);
    API.placeOrder()
      .then((res) => {
        const id = res.data.order_id;

        if (id) {
          history.push(`/order-success/${id}`);
          cartCtx.getCartItems();
          localStorage.setItem("order_id", res.data.order_id);
          Event({
            category: "ORDER",
            action: "CREATE_ORDER",
            label: "PLACE_ORDER",
          });
          setGaUser(authCtx.customerData?.id);

          cartCtx?.cartItems?.items.map((item) =>
            ReactGA.plugin.execute("ecommerce", "addItem", {
              id: res.data?.order_id,
              name: item?.product?.name,
              sku: "--",
              price: item?.product?.retail_price,
              currency: "USD",
              quantity: item?.quantity,
            })
          );

          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: res.data.order_id,
            revenue: mainTotal,
          });
          ReactGA.plugin.execute("ecommerce", "send");
          ReactGA.plugin.execute("ecommerce", "clear");

          window.gtag("event", "conversion", {
            send_to: "AW-1037851689/k82RCJGNpQEQqbjx7gM",
            value: mainTotal,
            currency: "USD",
            transaction_id: res.data.order_id,
          });
          window.gtag("event", "order_success", {
            order_value: mainTotal,
            order_id: res.data.order_id,
            currencyCode: "USD",
          });
        }
      })
      .catch((err) => {
        msgCtx.handleError(err);
        setPaymentIsLoading(false);
        msgCtx.setOpenContactUsModal(true);
      });
  };

  const placeOrderWithPaypal = (id) => {
    if (id) {
      history.push(`/order-success/${id}`);
      Event({
        category: "ORDER",
        action: "CREATE_ORDER",
        label: "PLACE_ORDER_PAYPAL",
      });

      cartCtx?.cartItems?.items.map((item) =>
        ReactGA.plugin.execute("ecommerce", "addItem", {
          id: id,
          name: item?.product?.name,
          sku: "--",
          price: item?.product?.retail_price,
          currency: "USD",
          quantity: item?.quantity,
        })
      );
      ReactGA.plugin.execute("ecommerce", "addTransaction", {
        id: id,
        revenue: mainTotal,
      });
      ReactGA.plugin.execute("ecommerce", "send");
      ReactGA.plugin.execute("ecommerce", "clear");

      window.gtag("event", "conversion", {
        send_to: "AW-1037851689/k82RCJGNpQEQqbjx7gM",
        value: mainTotal,
        currency: "USD",
        transaction_id: id,
      });
      window.gtag("event", "order_success", {
        order_value: mainTotal,
        order_id: id,
        currencyCode: "USD",
      });
    }
  };

  //helper
  const scrollInto = (id, offset) => {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: "smooth",
        });
      }
    }, 1000);
  };

  return (
    <div className="PaymentMethod">
      <div
        className={`PaymentMethod__content ${
          autoShip && "PaymentMethod__content--autoship"
        }`}
      >
        {/*   {!joinClub && <TotalCart pathParam={pathParam} showCode />} */}
        <div>
          <div className="PaymentMethod__container">
            {creditCards.length > 0 && !creditCardsLoading && (
              <>
                {!checkout && (
                  <div className="CreditCardForm__select">
                    <h1 className="PaymentMethod__title">
                      Credit Card Details
                    </h1>
                    <button
                      onClick={() => {
                        setShowCreditCardForm(true);
                        setSelectedCard({
                          id: 0,
                          initMessage: isMobile
                            ? "Select previous cards"
                            : "Select previous saved credit cards",
                        });
                      }}
                      className="Checkout__change-button"
                    >
                      {isMobile ? "Add new" : "Add new credit card"}
                    </button>
                  </div>
                )}

                {checkout && billingAddressRequired && (
                  <div className="CreditCardForm__select">
                    <h1 className="PaymentMethod__title">
                      Credit Card Details
                    </h1>
                    <button
                      disabled={
                        shippingAddressRequired === true &&
                        shippingAddressId === null
                          ? true
                          : false
                      }
                      onClick={() => {
                        setShowCreditCardForm(true);
                        setSelectedCard({
                          id: 0,
                          initMessage: isMobile
                            ? "Select previous cards"
                            : "Select previous saved credit cards",
                        });
                      }}
                      className="Checkout__change-button"
                    >
                      {isMobile
                        ? "Select or Add new"
                        : "Select or add new card"}
                    </button>
                  </div>
                )}
                {checkout && billingAddressRequired && showCreditCardForm && (
                  <div style={{ marginBottom: "2rem" }}>
                    <SelectCard
                      onItemSelect={(item) => {
                        setSelectedCard(item);
                        setShowCreditCardForm(false);
                      }}
                      selectedItem={selectedCard}
                      items={creditCards}
                    />
                  </div>
                )}

                {!checkout && (
                  <SelectCard
                    onItemSelect={(item) => {
                      setSelectedCard(item);
                      setShowCreditCardForm(false);
                    }}
                    selectedItem={selectedCard}
                    items={creditCards}
                  />
                )}
              </>
            )}
            {!showCreditCardForm && creditCards.length > 0 && (
              <div className="DefaultAddress">
                {checkout && billingAddressRequired && (
                  <>
                    <p className="DefaultAddress__title">
                      {selectedCard && selectedCard?.card_holder_first_name
                        ? `${selectedCard?.card_holder_first_name} ${selectedCard?.card_holder_last_name}`
                        : `${selectedCard?.card_holder_name}`}
                    </p>
                    <p className="DefaultAddress__title">
                      {selectedCard?.number_display}
                    </p>
                    <p className="DefaultAddress__expiriation">
                      Expiration Date
                    </p>
                    <p className="DefaultAddress__expiriation DefaultAddress__expiriation--date">
                      {selectedCard?.expiration_date_display}
                    </p>
                  </>
                )}
                {!checkout && (
                  <button
                    className="CreditAndBillingAddress__button4"
                    onClick={() => {
                      if (billingAddressRequired === false && checkout) {
                        placeOrderWithCredits();
                      } else {
                        handlePlaceOrder(selectedCard.id);
                      }
                    }}
                  >
                    {paymentisLoading ? (
                      <Spinner
                        style={{
                          border: "0.25em solid #ffffff",
                          borderRightColor: "transparent",
                          width: "2.5rem",
                          height: "2.5rem",
                        }}
                      />
                    ) : (
                      [
                        pathParam === "auto-ship"
                          ? "Use this card to activate auto-ship"
                          : [
                              joinClub
                                ? "Use this card to join club"
                                : "place order",
                            ],
                      ]
                    )}
                  </button>
                )}
                {checkout && billingAddressRequired && (
                  <button
                    disabled={
                      shippingAddressRequired === true &&
                      shippingAddressId === null
                        ? true
                        : false
                    }
                    className="CreditAndBillingAddress__button4"
                    onClick={() => {
                      const shippingAddressFormOpened = localStorage.getItem(
                        "shipping-form-opened"
                      );
                      const billingAddressFormOpened = localStorage.getItem(
                        "billing-form-opened"
                      );

                      if (billingAddressFormOpened === "true") {
                        scrollInto("billing-address", 0);
                        msgCtx.handleWarningMessage(
                          "Please save billing address before placing order!"
                        );
                        return;
                      }
                      //if form is open user need to save the data and continue
                      if (
                        shippingAddressFormOpened === "true" &&
                        shippingAddressRequired === true
                      ) {
                        scrollInto("shipping-address", 0);
                        msgCtx.handleWarningMessage(
                          "Please save shipping address before placing order!"
                        );
                      } else {
                        if (billingAddressRequired === false && checkout) {
                          placeOrderWithCredits();
                        } else {
                          handlePlaceOrder(selectedCard.id);
                        }
                      }
                    }}
                  >
                    {paymentisLoading ? (
                      <Spinner
                        style={{
                          border: "0.25em solid #ffffff",
                          borderRightColor: "transparent",
                          width: "2.5rem",
                          height: "2.5rem",
                        }}
                      />
                    ) : (
                      [
                        pathParam === "auto-ship"
                          ? "Use this card to activate auto-ship"
                          : [
                              joinClub
                                ? "Use this card to join club"
                                : "place order",
                            ],
                      ]
                    )}
                  </button>
                )}
              </div>
            )}

            {checkout &&
              billingAddressRequired === false &&
              !showCreditCardForm && (
                /*  creditCards.length > 0 && */
                <button
                  disabled={
                    shippingAddressRequired === true &&
                    shippingAddressId === null
                      ? true
                      : false
                  }
                  className="CreditAndBillingAddress__button4"
                  onClick={() => {
                    if (billingAddressRequired === false && checkout) {
                      placeOrderWithCredits();
                    } else {
                      handlePlaceOrder(selectedCard.id);
                    }
                  }}
                >
                  {paymentisLoading ? (
                    <Spinner
                      style={{
                        border: "0.25em solid #ffffff",
                        borderRightColor: "transparent",
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                    />
                  ) : (
                    [
                      pathParam === "auto-ship"
                        ? "Use this card to activate auto-ship"
                        : [
                            joinClub
                              ? "Use this card to join club"
                              : "place order",
                          ],
                    ]
                  )}
                </button>
              )}

            {!checkout && creditCards.length <= 0 && (
              <>
                <CreditCardForm
                  canProceed={canProceed}
                  joinClubNow={() => props.joinClubNow()}
                  joinClub={joinClub}
                  pathParam={pathParam}
                />
              </>
            )}
            {checkout &&
              billingAddressRequired !== false &&
              creditCards.length <= 0 && (
                <>
                  <CreditCardForm
                    canProceed={canProceed}
                    joinClubNow={() => props.joinClubNow()}
                    joinClub={joinClub}
                    pathParam={pathParam}
                  />
                </>
              )}
            {!checkout && showCreditCardForm && (
              <>
                <CreditCardForm
                  canProceed={canProceed}
                  joinClubNow={() => props.joinClubNow()}
                  joinClub={joinClub}
                  pathParam={pathParam}
                />
              </>
            )}

            {checkout &&
              billingAddressRequired !== false &&
              showCreditCardForm && (
                <>
                  {/*  {creditCards.length !== 0 && (
                    <p
                      style={{
                        marginBottom: "2rem",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Or
                    </p>
                  )} */}
                  <CreditCardForm
                    canProceed={canProceed}
                    joinClubNow={() => props.joinClubNow()}
                    joinClub={joinClub}
                    pathParam={pathParam}
                  />
                </>
              )}
          </div>

          {!joinClub && !checkout && (
            <p id="paypal-msg" className="PaymentMethod__message">
              For all billing addresses outside USA you must choose PAYPAL as
              your payment method below.
            </p>
          )}
          {checkout && (totalCredits === 0 || totalCredits < countedTotal) ? (
            <>
              <p className="PaymentMethod__message2">
                *Please review all information on this page and make changes as
                necessary. Clicking the Place Order button will complete your
                order.
              </p>
              <p className="PaymentMethod__message">
                For all billing addresses outside the USA, you must choose
                PAYPAL as your payment method.
              </p>
            </>
          ) : null}

          {pathParam === "auto-ship" ||
            !joinClub ||
            (!checkout && (
              <>
                <div className="PaymentMethod__paypal">
                  <p className="PaymentMethod__paypal-title">
                    {" "}
                    Other Options:{" "}
                  </p>

                  <PaypalButton
                    handleOrder={(id) => placeOrderWithPaypal(id)}
                  />
                </div>
              </>
            ))}

          {checkout && (totalCredits === 0 || totalCredits < countedTotal) ? (
            <>
              <div
                className={
                  shippingAddress === null && shippingAddressRequired
                    ? "PaymentMethod__paypal PaymentMethod__paypal-overlay"
                    : "PaymentMethod__paypal"
                }
              >
                <p className="PaymentMethod__paypal-title"> Other Options: </p>

                <PaypalButton handleOrder={(id) => placeOrderWithPaypal(id)} />
              </div>
            </>
          ) : null}

          <figure className="PaymentMethod__quote-container">
            <q className="PaymentMethod__quote">
              Go-Coffee-Go.com... fresh whole-bean coffee ... country’s best
              roasters... never been easier to get a perfect shot of espresso.
            </q>
            <figcaption className="PaymentMethod__quote-author">
              &mdash; New York Times
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
