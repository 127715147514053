export const HISTORY_OF_COFFEE_YEARS = [
  {
    name: "850-1599",
    slug: "850",
  },
  {
    name: "1600s",
    slug: "1600",
  },
  {
    name: "1700s",
    slug: "1700",
  },
  {
    name: "1800s",
    slug: "1800",
  },
  {
    name: "1900s-Present",
    slug: "1900",
  },
];
