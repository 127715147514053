import React from "react";
import { Link } from "react-router-dom";

import ArticleContainer from "../ArticleContainer/ArticleContainer";
import Seo from "../../../lib/helpers/SEO/Seo";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";

import "./MakingYourBrew.scss";

const MakingYourBrew = () => {
  const seoCtx = useSeoContext();

  const FrenchPress =
    "https://assets.gocoffeego.com/v2/assets/images/french-press.jpg";
  const Tehcnivorm =
    "https://assets.gocoffeego.com/v2/assets/images/tehcnivorm-moccamaster-coffee-ma.jpg";
  const Ocean =
    "https://assets.gocoffeego.com/v2/assets/images/beautiful-clear-ocean-water.jpg";
  const PaperCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/paper-coffee-filters.jpg";
  const ReusableCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/reusable-coffee-filter.jpg";
  const CoffeeThermos =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-thermos-1.jpg";
  const Chemex =
    "https://assets.gocoffeego.com/v2/assets/images/chemex-coffee-maker.jpg";
  const Moka =
    "https://assets.gocoffeego.com/v2/assets/images/moka-pot-coffee-maker.jpg";
  const Marzocco =
    "https://assets.gocoffeego.com/v2/assets/images/la-marzocco-g3-espresso-machine.jpg";

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/professor-peaberry/brewing-tips/how-to-make-coffee"
  );

  const article = {
    title: foundSeo?.title || "Making Your Brew",
    image: "https://assets.gocoffeego.com/v2/assets/images/making-your-brew.jpg",
    url: "https://www.gocoffeego.com/learn/category/making-your-brew"
  };

  return (
    <ArticleContainer title="Making Your Brew">
      <Seo
        title={foundSeo?.title || null}
        description={foundSeo?.description || null}
        keywords={foundSeo?.keywords || null}
        article={article}
      />
      <div className="MakingYourBrew">
        <h2 className="MakingYourBrew__title">French Press</h2>
        <div>
          <img
            src={FrenchPress}
            alt="french-press"
            className="MakingYourBrew__image right"
          />
          <p className="MakingYourBrew__description">
            If the French are known for refinement and elegance, than the French
            Press is positively emblematic! In fact, it’s a personal favorite.
            Few methods of brewing coffee allow such precise control of the
            process. So let’s press on, shall we?
          </p>
          <p className="MakingYourBrew__description">
            If you want to learn how to brew the perfect cup of coffee, you'll
            need your French Press, hot water and coarse ground coffee.
          </p>
          <p className="MakingYourBrew__description">
            1. Heat the water by whatever means necessary, a lighting bolt comes
            to mind… A whistling kettle actually is my personal favorite. Use
            only the purest water, free of chlorine and hard minerals, so that
            nothing gets in the way of the coffee flavor. Distilled water is a
            no-no: it can leave your coffee flat.
          </p>
        </div>
        <p className="MakingYourBrew__description">
          2. We’ll assume you’ve ground your coffee to perfection - a somewhat
          coarse grind is best for the press. It should look like coarse sand
          with fine chunks… So now it’s time to measure or weigh the coffee for
          the most accuracy and place in the press. Remove the plunger from the
          carafe and set it aside. Carafes vary in size, but the measure is the
          same: about 2 round tablespoons per 8 oz of water. More precise: 7
          grams per cup. Or for a 20 oz French Press use 2 oz or 50 grams of
          coffee. Which is approximately 1 oz per 10 oz of water.
        </p>
        <p className="MakingYourBrew__description">
          3. When your water boils - or whistles - let it rest a moment to cool.
          The best brewing temperatures are between 201-205 degrees. Pour the
          water SLOWLY into the carafe and let it sit over the ground coffee.
          Not too full! You still need room for the plunger.
        </p>
        <p className="MakingYourBrew__description">
          4. Notice the foaming action of the coffee and water, known as
          “blooming” or, for the more technically minded, “off-gassing.” Stir
          with a spoon to rid the foam.
        </p>
        <p className="MakingYourBrew__description">
          5. Place the plunger/lid on top of the carafe to help keep in the heat
          and wait 4 minutes. Oh, the agony of waiting to brew the perfect cup
          of coffee!
        </p>
        <p className="MakingYourBrew__description">
          6. At four minutes… take the plunge! SLOWLY press the plunger to the
          bottom of the carafe and leave it there. Don’t be a fool and retract
          the plunger.
        </p>
        <p className="MakingYourBrew__description">
          7. Pour yourself and your friends - if they’re near - a cup of
          delicious coffee. Put a lovely record on your stereophonic device,
          exhale and ENJOY! Wait, one more thing--
        </p>
        <p className="MakingYourBrew__description">
          8. I recommend decanting the coffee into a thermos or heat saving
          pitcher of some kind as even the grounds at the bottom of your French
          Press will continue to brew the rest of the coffee and-gasp! -
          over-brew it.
        </p>
        <h2 className="MakingYourBrew__title">French Press</h2>
        <p className="MakingYourBrew__description">
          <img
            src={Tehcnivorm}
            alt="tehcnivorm"
            className="MakingYourBrew__image right"
          />
          Machines that heat the water to between 195 and 205 degrees - and can
          do it in less than 6 minutes - are ideal. If you have something
          lesser, it’s not a total disaster, but it will be something to ask
          Santa about come the holidays.
        </p>
        <h2 className="MakingYourBrew__title">Clean Water</h2>
        <p className="MakingYourBrew__description">
          <img src={Ocean} alt="ocean" className="MakingYourBrew__image left" />
          This can’t be stressed enough-- use clean, filtered water, but not
          necessarily pure water, such as distilled or ionized water. Coffee
          flavor is best if there are some minerals to bind with. If the water
          is too hard, it affects not only flavor but also encrusts the
          machine’s heating elements. The words coffee and crust are not
          pleasant bedfellows.
        </p>
        <h2 className="MakingYourBrew__title">Measure Up</h2>
        <p className="MakingYourBrew__description">
          I detest measuring the water to the four-cup mark on a strange pot and
          only getting one 1 ½ cups of brewed coffee! A cruel joke, indeed.
          Every machine is different, so you may have to test just what THEY
          mean when THEY say “a cup” or “a scoop.”
        </p>
        <h2 className="MakingYourBrew__title">Filter Pros and Cons</h2>
        <p className="MakingYourBrew__description">
          Paper filters are the least expensive kind, but can also filter out
          the best flavors found in the Paper coffee
          <img
            src={PaperCoffee}
            alt="paper-coffee"
            className="MakingYourBrew__image left"
          />
          filters.coffee by trapping the oil. Cheap papers can make your coffee
          taste like, err, paper. Wetting the paper first can cure the latter by
          rinsing away any residuals
          <img
            src={ReusableCoffee}
            alt="reusable-coffee"
            className="MakingYourBrew__image right"
          />
          from the manufacturing process.Gold mesh reusable coffee filter. Cloth
          filters need a lot of care, they must be thoroughly washed before each
          brew, but many consider them a very green alternative. Stainless steel
          or gold-plated filters provide a more costly but imminently reusable
          alternative and only need to be washed and scrubbed with water. The
          quality of the cup is good, but some of the finer grounds can get
          through - so use a medium-coarse grind.
        </p>
        <h2 className="MakingYourBrew__title">Carafe It</h2>
        <p className="MakingYourBrew__description">
          <img
            src={CoffeeThermos}
            alt="CoffeeThermos"
            className="MakingYourBrew__image left"
          />
          After brewing, it’s best to empty the pot into a thermos or insulated
          carafe. Coffee machines are notorious for overheating the finished
          coffee even while set to “warm” and ruining a perfectly good pot.
          Sinful.
        </p>
        <h2 className="MakingYourBrew__title">Clean up</h2>
        <p className="MakingYourBrew__description">
          Periodic cleaning of your machine to free it of rancid coffee oils and
          mineral build-up in the heating elements is essential for making the
          best possible cup every time. Whistle while you scrub-a-dub.
        </p>
        <h2 className="MakingYourBrew__title">
          Pour-Over/Filter/Manual Brewing
        </h2>
        <div>
          <img
            src={Chemex}
            alt="Chemex"
            className="MakingYourBrew__image right"
          />
          <p className="MakingYourBrew__description">
            This is an easy and effective way to brew the perfect cup of coffee
            with precision and employs the most basic rules of coffee
            temperature and time. Some say it’s he best! There’s very little to
            it, really, so let’s get to it.
          </p>
          <p className="MakingYourBrew__description">
            1. As always, use coffee ground only moments before preparation. For
            this method, coarse to medium fine is best.
          </p>
        </div>
        <p className="MakingYourBrew__description">
          2. Place your brewing cone, either plastic or ceramic, over your cup
          or a storage vessel.
        </p>
        <p className="MakingYourBrew__description">
          3. Place your preferred filter type, either paper, cloth or
          metal/synthetic mesh.
        </p>
        <p className="MakingYourBrew__description">
          4. Measure coffee and place it in the filter bed. As a rule of thumb,
          about 2 tablespoons for a cup or 7 grams per six ounces of water.
          Level the coffee in the filter.
        </p>
        <p className="MakingYourBrew__description">
          5. Heat water to boil and then let cool for a moment, so it settles to
          between 195-205 degrees. You can start the water boiling first and
          cover the other steps so the water is ready when you are to pour.
        </p>
        <p className="MakingYourBrew__description">
          6. Pour a small amount of hot water over the grounds first. Let it
          soak and allow any foam to dissipate. Then—
        </p>
        <p className="MakingYourBrew__description">
          7. Pour all of the water on the grounds being sure to get them all
          thoroughly soaked. Your brew will drip through to the bottom then down
          into your vessel.
        </p>
        <p className="MakingYourBrew__description">
          Remove the brewing cone, stir with a spoon and enjoy a wonderful cup.
        </p>
        <h2 className="MakingYourBrew__title">The Moka Pot</h2>
        <div>
          <img src={Moka} alt="Moka" className="MakingYourBrew__image left" />
          <p className="MakingYourBrew__description">
            A “moka” pot has two chambers. Water goes into the bottom chamber
            with the finely ground coffee suspended in metal basket above. Screw
            the bottom chamber together with the top chamber and place the
            entire pot over medium heat.
          </p>
          <p className="MakingYourBrew__description">
            As the water boils, steam pressure forces the water through the
            coffee above it, into a narrow tube, and eventually collects in the
            upper chamber. Remove the entire assembly from the heat and allow
            the coffee to settle. Serve immediately. Great straight and strong
            or as a café con leche with hot milk. Impressed?
          </p>
          <h2 className="MakingYourBrew__title">Espresso</h2>
          <p className="MakingYourBrew__description">
            The art and science of a good shot of espresso could fill volumes,
            so we’ll only delve into a few basic principles and in no particular
            order. Life is too short and I would rather be drinking espresso
            than lecturing on it. On with the lecture...
          </p>
          <p className="MakingYourBrew__description">
            Espresso is not a coffee roast per se, but there are certainly
            roasts that make a better, bolder shot. The best espresso comes from
            a blend of Arabica beans that balance dark roasted flavors with
            sweeter, milder roasts.
          </p>
          <p className="MakingYourBrew__description">
            <img
              src={Marzocco}
              alt="Marzocco"
              className="MakingYourBrew__image right"
            />
            More than any other brewing method, espresso demands a very fine,
            consistent grind some say is close to a powder. Those small hand
            grinders will not do when making the perfect espresso, you’ll need
            an adjustable Burr grinder.
          </p>
          <p className="MakingYourBrew__description">
            Keep your machinery clean. That grinder, the steaming nozzle, et al.
          </p>
          <p className="MakingYourBrew__description">
            The correct “dose” of espresso grinds for a single shot is 6-8 grams
            per cup. Double for a double shot, naturally.
          </p>
          <p className="MakingYourBrew__description">
            Tamp or pack the grinds into a puck with moderate, consistent
            pressure. Turn the tamper to “polish” the puck.
          </p>
          <p className="MakingYourBrew__description">
            Extraction time is between 20 and 25 seconds for either single or
            double shots. Be consistent.
          </p>
          <p className="MakingYourBrew__description">
            Finished espresso is thick and creamy, with a foamy, orange-brown
            crema on top.
          </p>
        </div>
      </div>
      <Link
        to="/learn/category/recipes"
        onClick={() => {
          window.scrollTo({ top: 1000, behavior: "smooth" });
        }}
      >
        <h3 className="MakingYourBrew__bottom-link">Click here for Recipes.</h3>
      </Link>
    </ArticleContainer>
  );
};

export default MakingYourBrew;
