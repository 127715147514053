import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import QuizQuestionTitle from "../QuizQuestionTitle/QuizQuestionTitle";
import QuizLines from "../QuizLines/QuizLines";
import PayAsYouGoCart from "../../PayAsYouGo/PayAsYouGoCart/PayAsYouGoCart";

import "./QuizQuestion3.scss";

const QuizQuestion3 = (props) => {
  const { selectedAnswer } = props;
  const [answer, setAnswer] = useState();

  useEffect(() => {
    API.getQuizQuestionThree().then((res) => {
      setAnswer(res.data);
    });
  }, []);

  return (
    <div id="question-3" className="QuizQuestion3">
      <QuizQuestionTitle questionNumber={3} question={"HOW DO YOU BREW?"} />
      <div className="QuizQuestion3__answers">
        {answer &&
          answer.map((item) => (
            <PayAsYouGoCart
              key={item.id}
              id={item.id}
              selectedAnswer={selectedAnswer}
              onItemSelect={() => {
                props.onItemSelect(item.id);
              }}
              quiz
              title={item.name}
              img={item.image}
              description={item.description}
            />
          ))}
      </div>

      <div className="QuizQuestion3__lines">
        <QuizLines />
      </div>
    </div>
  );
};

export default QuizQuestion3;
