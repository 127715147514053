import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useSignUpFormik } from "./useSignUpFormik";

import { Form, Input, Spinner } from "reactstrap";

import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./SignUpForm.scss";

const SignUpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const AuthCtx = useAuthContext();
  const msgCtx = useMessageContext();

  const formik = useSignUpFormik({
    onSubmit: async (values) => {
      setIsLoading(true);
      AuthCtx.register(values).then(() => {
        setIsLoading(false);
        // msgCtx.handleSuccessMessage(signedUp);
      });
    },
  });

  return (
    <Form className="SignUpForm" onSubmit={formik.handleSubmit}>
      <div className="SignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          className="SignUpForm__input "
          name="firstName"
          type="text"
          placeholder="First Name"
        />
        {formik.errors.firstName && formik.touched.firstName && (
          <div className="SignUpForm__required">{formik.errors.firstName}</div>
        )}
      </div>

      <div className="SignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          className="SignUpForm__input"
          name="lastName"
          type="text"
          placeholder="Last Name"
        />
        {formik.errors.lastName && formik.touched.lastName && (
          <div className="SignUpForm__required">{formik.errors.lastName}</div>
        )}
      </div>

      <div className="SignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="SignUpForm__input"
          name="email"
          type="email"
          placeholder="E-mail"
          novalidate
        />
        {formik.errors.email && formik.touched.email && (
          <div className="SignUpForm__required">{formik.errors.email}</div>
        )}
      </div>
      <div className="SignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmEmail}
          className="SignUpForm__input"
          name="confirmEmail"
          type="email"
          placeholder="Confirm E-mail"
        />
        {formik.errors.confirmEmail && formik.touched.confirmEmail && (
          <div className="SignUpForm__required">
            {formik.errors.confirmEmail}
          </div>
        )}
      </div>
      <div className="SignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className="SignUpForm__input"
          name="password"
          type="password"
          placeholder="Create Password"
        />
        {formik.errors.password && formik.touched.password && (
          <div className="SignUpForm__required SignUpForm__required--password">
            {formik.errors.password}
          </div>
        )}
      </div>
      <div className="SignUpForm__input-container ">
        <Input
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          className="SignUpForm__input"
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
        />
        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
          <div className="SignUpForm__required">
            {formik.errors.confirmPassword}
          </div>
        )}

        {AuthCtx.registerError && (
          <div className="LoginForm__required">
            The email has already been taken
          </div>
        )}
      </div>
      <button
        disabled={isLoading ? true : false}
        type="submit"
        className="SignUpForm__button"
      >
        {isLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #000000",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : (
          "SIGN UP"
        )}
      </button>
      <div className="SignUpForm__link-container">
        Already have an account?
        <Link to="/login" className="SignUpForm__link">
          Log in!
        </Link>
      </div>
    </Form>
  );
};

export default SignUpForm;
