import React, { useState } from "react";

import "./SelectBoxAddressBook.scss";

let cs = require("classnames");

const SelectBoxAddressBook = (props) => {
  const {
    items,
    selectedItem,
    className,
    className2,
    label,
    openDropdown,
    setOpenDropdown,
    selectedBoxId,
    onClick,
    selectedAddress,
    selectedCountry,
    selectedState,
    state,
    disabled,
  } = props;
  const Arrow =
    "https://assets.gocoffeego.com/v2/assets/icons/drop-down-arrow.svg";

  const [showItems, setShowItems] = useState(false);
  const [countryEdit, setCountryEdit] = useState(selectedCountry);
  const [stateEdit, setStateEdit] = useState(selectedState);

  const onItemSelect = (name, id, states) => {
    props.onItemSelect(name, id, states);
    setShowItems(!showItems);
    if (!showItems) setOpenDropdown(null);
    if (showItems && openDropdown === 1) {
      setCountryEdit(null);
    }
    if (showItems && openDropdown === 2) {
      setStateEdit(null);
    }
  };

  return (
    <div
      onClick={() => {
        if (!disabled) {
          onClick();
        }

        if (disabled) {
          return;
        }

        setShowItems(!showItems);
      }}
      className={cs(
        `SelectBoxAddressBook`,
        className && disabled && `SelectCountry2__header-disabled`
      )}
    >
      <div className="SelectBoxAddressBook__header">
        <div>
          <h1 className="SelectBoxAddressBook__title"> {label}</h1>
          <h1 className="SelectBoxAddressBook__title2">
            {" "}
            {countryEdit ? countryEdit : selectedItem}
          </h1>
          {stateEdit && !showItems && !selectedItem && (
            <h1 className="SelectBoxAddressBook__title2">
              {" "}
              {stateEdit && !showItems ? stateEdit : selectedItem}
            </h1>
          )}
        </div>
        <img className="SelectBoxAddressBook__icon" src={Arrow} alt="arrow" />
      </div>

      <div
        className={cs(
          "SelectBoxAddressBook__items",
          showItems && selectedBoxId === openDropdown && "active",
          className2
        )}
      >
        {selectedBoxId === 1 &&
          items &&
          items?.map((item) => {
            return (
              <div
                onClick={() => onItemSelect(item.name, item.id, item.states)}
                className={`SelectBoxAddressBook__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectBoxAddressBook__item">{item.name}</p>
              </div>
            );
          })}
        {state &&
          items?.map((item) => {
            return (
              <div
                onClick={() => onItemSelect(item.name, item.id)}
                className={`SelectBoxAddressBook__item-container ${
                  props.selectedItem === item.id ? "activeLabel" : ""
                }`}
              >
                <p className="SelectBoxAddressBook__item">{item.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectBoxAddressBook;
