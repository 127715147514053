import ReactGA from "react-ga";

export const pageViewsTracking = (pathname) => {
  let pageView;

  if (pathname === "*") pageView = "/not-found";
  else pageView = pathname;

  ReactGA.pageview(pageView);
};
