export const ROASTERS_MESSAGE = [
  {
    name: "2019 Multiple Golden Bean Award Winner!!",
    slug: "1000faces-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/45/1000faces%2Byummy%2Bcoffee%2Bshot.jpg",
    id: 45,
  },
  {
    name: "Atomic's Beans are Top Rated!",
    slug: "atomic-coffee-roasters",
    image:
      "https://assets.gocoffeego.com/purveyors/images/24/atomic-coffee.jpg",
    id: 24,
  },
  {
    name: "Top Rated by Food and Wine Mag. for Best Gourmet Coffees.",
    slug: "barefoot-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/19/barefoot-coffee.jpg",
    id: 19,
  },
  {
    name: "2017 Good Food Award Winner!",
    slug: "beanfruit-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/53/beanfruitteam.jpg",
    id: 53,
  },
  {
    name: "Fantastic San Diego Roaster!",
    slug: "bird-rock-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/43/bird-rock-coffee.jpg",
    id: 43,
  },
  {
    name: "Three Bronze and One Silver Golden Bean Awards - 2019",
    slug: "brandywine-coffee-roasters",
    image:
      "https://assets.gocoffeego.com/purveyors/images/59/brandywine-collage1.jpg",
    id: 59,
  },
  {
    name: "Best Coffee in Washington State by Food & Wine Magazine in 2018.",
    slug: "camber-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/70/Sunset-camber.jpg",
    id: 70,
  },
  {
    name: "Celebrating 107 Years Of  Excellence!",
    slug: "dallis-bros-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/50/dallis-bros-coffee.jpg",
    id: 50,
  },
  {
    name: "Fantastic Little Tokyo Roaster!",
    slug: "demitasse",
    image:
      "https://assets.gocoffeego.com/purveyors/images/69/Demitasse+Coffee+Collage.jpg",
    id: 69,
  },
  {
    name: "Top Rated by Food & Wine Magazine For Their Artisan Coffee.",
    slug: "doma-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/40/Doma---Learn.jpg",
    id: 40,
  },
  {
    name: "New Roaster! Nordic Style Roaster!",
    slug: "flatlands-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/72/flatlands-coffee.jpg",
    id: 72,
  },
  {
    name: "Award-Winning Healdsburg, CA Roaster!",
    slug: "flying-goat-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/74/Flying-+Goat-+Hero.jpg",
    id: 74,
  },
  {
    name: "Golden Bean Finalist!",
    slug: "ghost-town-coffee-roasters",
    image:
      "https://assets.gocoffeego.com/purveyors/images/61/ghost-town-coffee.jpg",
    id: 61,
  },
  {
    name: "Top Rated Coffee Beans by Coffee Review. Multiple 92+ scores",
    slug: "jbc-coffee-roasters",
    image:
      "https://assets.gocoffeego.com/purveyors/images/29/jbc-coffee.jpg",
    id: 29,
  },
  {
    name: "Good Food Awards Winner - 2012, 2016, 2017 & 2020",
    slug: "klatch-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/15/klatch-coffee.jpg",
    id: 15,
  },
  {
    name: "Good Food Awards Winner!",
    slug: "kuma-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/42/Kuma-Coffee-Cupping.jpg",
    id: 42,
  },
  {
    name: "Fantastic New Roaster!!",
    slug: "looking-homeward-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/67/looking-homeward-coffee.jpg",
    id: 67,
  },
  {
    name: "Off The Charts Denver Colorado Roaster!",
    slug: "novo-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/30/novo-coffee.jpg",
    id: 30,
  },
  {
    name: "2019 Best Coffee in Washington State - Food & Wine",
    slug: "olympia-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/49/olympia.jpg",
    id: 49,
  },
  {
    name: "Fantastic Champion Arkansas Roaster!",
    slug: "onyx-coffee-lab",
    image:
      "https://assets.gocoffeego.com/purveyors/images/57/Onyx-Coffee-Lab---Praise-Coffee.jpg",
    id: 57,
  },
  {
    name: "Great Chicago Roaster Discovery.",
    slug: "passion-house-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/65/Passion-House-Coffee-Roasters-Shopfront.jpg",
    id: 65,
  },
  {
    name: "2020 Good Food Award Finalist",
    slug: "pts-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/18/PT-bags.jpg",
    id: 18,
  },
  {
    name: "2019 Multiple Golden Bean Award Winner",
    slug: "red-bay-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/66/Red+Bay+Photo+Gallery.jpg",
    id: 66,
  },
  {
    name: "2020 Good Food Award-Winning Virginia Roaster!",
    slug: "red-rooster-coffee-roaster",
    image:
      "https://assets.gocoffeego.com/purveyors/images/68/RR-banner10.jpg",
    id: 68,
  },
  {
    name: "Top Rated by CNN & Fortune For Gourmet Coffee.",
    slug: "ritual-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/22/ritual-coffee.jpg",
    id: 22,
  },
  {
    name: "Free Shipping on $50 or more!",
    slug: "roseline-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/62/roseline.jpg",
    id: 62,
  },
  {
    name: "Lexington, Kentucky Breakout Roaster!",
    slug: "smokin-aces-coffee-co",
    image:
      "https://assets.gocoffeego.com/purveyors/images/63/smokin-aces.png",
    id: 63,
  },
  {
    name: "Free shipping over $30",
    slug: "temple-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/41/temple.jpg",
    id: 41,
  },
  {
    name: "2017 Roaster of the Year from Roast Magazine!",
    slug: "thanksgiving-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/47/Thanksgiving-Paul-and-Joan-1972.jpg",
    id: 47,
  },
  {
    name: "Amazing 3rd Wave Discovery!",
    slug: "third-wave-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/71/third-wave.jpg",
    id: 71,
  },
  {
    name: "2021 Roaster Of The Year Winner - Roast Magazine.",
    slug: "tonys-coffee",
    image:
      "https://assets.gocoffeego.com/purveyors/images/60/tonys.jpg",
    id: 60,
  },
  {
    name: "Roast Magazine's 2010 Roaster of The Year Award Winner!",
    slug: "equator-coffees",
    image:
      "https://assets.gocoffeego.com/purveyors/images/35/EQ-latte-art-2.jpg",
    id: 35,
  },
  {
    name: "Golden Bean Winner!",
    slug: "torque-coffees",
    image:
      "https://assets.gocoffeego.com/v2/purveyors/images/75/image1.jpg",
    id: 75,
  }
];
