import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSubscriptionFormik } from "./useSubscriptionFormik";
import * as API from "../../../api/Api";
import { Form, Input, Spinner } from "reactstrap";
import SelectCountry2 from "../../Checkout/AddressForm/SelectCountry2/SelectCountry2";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

import "./SubscriptionForm.scss";

const SubscriptionForm = (props) => {
  const { itemDetails, fromCart, toggle } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([
    { name: "United States", id: 64 },
  ]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    id: 64,
  });
  const [selectedState, setSelectedState] = useState({ name: null, id: null });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [charsLeft, setCharsLeft] = useState(60);
  const history = useHistory();
  const cartCtx = useCartContext();

  const formik = useSubscriptionFormik(itemDetails, {
    onSubmit: async (values) => {
      setFormSubmitted(true);
      const planId = localStorage.getItem("coffee-plan");
      const cartId = localStorage.getItem("cart_id");
      const selectedSubscription = localStorage.getItem(
        "selected-subscription"
      );
      if (!fromCart) {
        const data = {
          product_id: selectedSubscription,
          plan_id: planId,
          gift_msg: values.gift_msg,
          gift_to: values.gift_to,
          gift_from: values.gift_from,
          gift_recipient: {
            first_name: values.first_name,
            last_name: values.last_name,
            address1: values.address1,
            city: values.city,
            zip: values.zip,
            location_state_id: selectedState.id,
            location_country_id: selectedCountry.id,
            email: values.email,
          },
        };
        if (selectedState.id) {
          setIsLoading(true);
        }
        API.addGiftSubscriptionToCart(cartId, data).then((res) => {
          if (res.status === 200) {
            if(res?.data?.cart_id && cartId !== res?.data?.cart_id) {
              localStorage.setItem("cart_id", res.data.cart_id);
            }
            cartCtx.getCartItems();
            setIsLoading(false);
            history.push("/cart");
          }
        });
      }

      if (fromCart && itemDetails?.id) {
        const updateData = {
          gift_recipient_id: itemDetails.gift_recipient.id,
          gift_recipient_first_name: values.first_name,
          gift_recipient_last_name: values.last_name,
          gift_recipient_address1: values.address1,
          gift_recipient_city: values.city,
          gift_recipient_location_state_id: selectedState.id
            ? selectedState.id
            : itemDetails?.gift_recipient?.state?.id,
          gift_recipient_zip: values.zip,
          gift_recipient_location_country_id: selectedCountry.id,
          gift_msg: values.gift_msg,
          gift_to: values.gift_to,
          gift_from: values.gift_from,
          gift_recipient_email: values.email,
        };
        if (selectedState.id || itemDetails?.gift_recipient?.state?.id) {
          setIsLoading(true);
          API.updateGiftSubcription(itemDetails.id, cartId, updateData).then(
            (res) => {
              if (res.status === 200) {
                setIsLoading(false);
                cartCtx.getCartItems();
                toggle();
              }
            }
          );
        }
      }
    },
  });

  // @TODO commented for testing purpose (many api calls)

  // useEffect(() => {
  //   API.getCountries().then((res) => setCountries(res.data));
  // }, []);

  // useEffect(() => {
  //   API.getStateOfCountry(selectedCountry.id).then((res) => {
  //     setStates(res.data.states);
  //   });
  // }, []);

  useEffect(() => {
    API.getStateOfCountry(selectedCountry.id).then((res) => {
      setStates(res.data.states);
    });
  }, [selectedCountry]);

  return (
    <Form className="SubscriptionForm" onSubmit={formik.handleSubmit}>
      <div className="SubscriptionForm__recipient">
        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            className="SubscriptionForm__input"
            name="first_name"
            type="text"
            placeholder="Recipient’s First Name"
          />
          {formik.errors.first_name && formik.touched.first_name && (
            <div className="SubscriptionForm__required">
              {formik.errors.first_name}
            </div>
          )}
        </div>

        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            className="SubscriptionForm__input"
            name="last_name"
            type="text"
            placeholder="Recipient’s Last Name"
          />
          {formik.errors.last_name && formik.touched.last_name && (
            <div className="SubscriptionForm__required">
              {formik.errors.last_name}
            </div>
          )}
        </div>
        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address1}
            className="SubscriptionForm__input"
            name="address1"
            type="text"
            placeholder="Address"
          />
          {formik.errors.address1 && formik.touched.address1 && (
            <div className="SubscriptionForm__required">
              {formik.errors.address1}
            </div>
          )}
        </div>
        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            className="SubscriptionForm__input"
            name="city"
            type="text"
            placeholder="City"
          />
          {formik.errors.city && formik.touched.city && (
            <div className="SubscriptionForm__required">
              {" "}
              {formik.errors.city}{" "}
            </div>
          )}
        </div>
        <div className="SubscriptionForm__input-container">
          <SelectCountry2
            className="SubscriptionForm__select-country"
            label="Select Country"
            onItemSelect={(name, id) =>
              setSelectedCountry({ name: name, id: id })
            }
            selectedItem={selectedCountry.name}
            items={countries}
          />
        </div>

        <div className="SubscriptionForm__input-container">
          <SelectCountry2
            className="SubscriptionForm__select-country"
            label="Select State"
            onItemSelect={(name, id) =>
              setSelectedState({ name: name, id: id })
            }
            selectedItem={
              selectedState.name
                ? selectedState.name
                : itemDetails?.gift_recipient?.state?.name
            }
            items={states}
          />

          {formSubmitted && !selectedState?.name && !fromCart && (
            <div className="SubscriptionForm__required">
              Please provide state
            </div>
          )}
        </div>

        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zip}
            className="SubscriptionForm__input"
            name="zip"
            type="text"
            placeholder="Postal Code"
          />

          {formik.errors.zip && formik.touched.zip && (
            <div className="SubscriptionForm__required">
              {formik.errors.zip}
            </div>
          )}
        </div>
        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className="SubscriptionForm__input"
            name="email"
            type="text"
            placeholder="Recipient’s email address"
          />
          {formik.errors.email && formik.touched.email && (
            <div className="SubscriptionForm__required">
              {formik.errors.email}
            </div>
          )}
        </div>

        {/*<div className="SubscriptionForm__input-container">*/}
        {/*  <Input*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    onBlur={formik.handleBlur}*/}
        {/*    value={formik.values.company}*/}
        {/*    className="SubscriptionForm__input"*/}
        {/*    name="company"*/}
        {/*    type="text"*/}
        {/*    placeholder="Company"*/}
        {/*  />*/}
        {/*  {formik.errors.js.company && formik.touched.company && (*/}
        {/*    <div className="SubscriptionForm__required">*/}
        {/*      {formik.errors.js.company}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
      <div className="SubscriptionForm__message">
        <h1 className="SubscriptionForm__title">Gift Message</h1>
        <div className="SubscriptionForm__grid">
          <div className="SubscriptionForm__input-container">
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gift_to}
              className="SubscriptionForm__input"
              name="gift_to"
              type="text"
              placeholder="Recipient"
            />
            {formik.errors.gift_to && formik.touched.gift_to && (
              <div className="SubscriptionForm__required">
                {formik.errors.gift_to}
              </div>
            )}
          </div>
          <div className="SubscriptionForm__input-container">
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gift_from}
              className="SubscriptionForm__input"
              name="gift_from"
              type="text"
              placeholder="Sender"
            />
            {formik.errors.gift_from && formik.touched.gift_from && (
              <div className="SubscriptionForm__required">
                {formik.errors.gift_from}
              </div>
            )}
          </div>
        </div>
        <div className="SubscriptionForm__input-container">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gift_msg}
            className="SubscriptionForm__textarea"
            name="gift_msg"
            type="textarea"
            placeholder="Gift Message"
            maxLength="60"
          />
          <div>
            Characters Remaining:{" "}
            {charsLeft - formik.values.gift_msg.length}
          </div>
          {formik.errors.gift_msg && formik.touched.gift_msg && (
            <div className="SubscriptionForm__required">
              {formik.errors.gift_msg}
            </div>
          )}
        </div>
      </div>
      <button
        disabled={isLoading ? true : false}
        type="submit"
        className="SubscriptionForm__button"
      >
        {isLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #00d1d6",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : fromCart ? (
          "Save Changes"
        ) : (
          "Add to cart"
        )}
      </button>
    </Form>
  );
};

export default SubscriptionForm;
