import { useFormik } from "formik";
import * as Yup from "yup";

const AddressFormSchema = Yup.object({
  first_name: Yup.string()
    .required("Please provide first name.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed."),
  last_name: Yup.string()
    .required("Please provide last name.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed."),
  address1: Yup.string().required("Please provide address 1."),
  // .matches(/^[0-9a-zA-Z ]+$/, "Only alphabets and numbers are allowed."),
  address2: Yup.string().nullable(),
  company_name: Yup.string().nullable(),
  zip: Yup.string()
    .required("Please provide ZIP")
    .min(
      5,
      "ZIP is invalid. Please provide a 5 or 9 digit (NNNNN-NNNN) number."
    ),
  city: Yup.string()
    .required("Please provide a City.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed."),
  phone: Yup.string().required("Please provide phone number."),
  // state: Yup.string().required("Please provide state."),
});

export const useAddressFormik = (selectedAddress, opts) => {
  return useFormik({
    initialValues: {
      first_name: selectedAddress ? selectedAddress.first_name : "",
      last_name: selectedAddress ? selectedAddress.last_name : "",
      address1: selectedAddress ? selectedAddress.address1 : "",
      address2: selectedAddress ? selectedAddress.address2 : "",
      zip: selectedAddress ? selectedAddress.zip : "",
      city: selectedAddress ? selectedAddress.city : "",
      phone: selectedAddress ? selectedAddress.phone : "",
      company_name: selectedAddress ? selectedAddress.company_name : "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: AddressFormSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
