const logo1 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/1.svg";
const logo2 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/2.svg";
const logo3 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/3.svg";
const logo4 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/4.svg";
const logo5 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/5.svg";
const logo6 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/6.svg";
const logo7 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/7.svg";
const logo8 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/8.svg";
const logo9 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/9.svg";
const logo10 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/11.png";
const logo11 =
  "https://assets.gocoffeego.com/v2/assets/images/company-logo/10.svg";

export const COMPANIES = [
  {
    image: logo1,
  },
  {
    image: logo2,
  },
  {
    image: logo3,
  },
  {
    image: logo4,
  },
  {
    image: logo5,
  },
  {
    image: logo6,
  },
  {
    image: logo7,
  },
  {
    image: logo8,
  },
  {
    image: logo9,
  },
  {
    image: logo10,
  },
  {
    image: logo11,
  },
];
