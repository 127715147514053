import React, { useEffect, useState } from "react";

import ArticleContainer from "../ArticleContainer/ArticleContainer";
import { useHistory, useParams } from "react-router-dom";
import { HISTORY_OF_COFFEE_YEARS } from "../../../api/TheHistoryOfCoffeeYears";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";
import Seo from "../../../lib/helpers/SEO/Seo";

import FirstEra from "./TheHistoryOfCoffeeYears/FirstEra";
import SecondEra from "./TheHistoryOfCoffeeYears/SecondEra";
import ThirdEra from "./TheHistoryOfCoffeeYears/ThirdEra";
import FourthEra from "./TheHistoryOfCoffeeYears/FourthEra";
import FifthEra from "./TheHistoryOfCoffeeYears/FifthEra";

import "./TheHistoryOfCoffee.scss";
import cs from "classnames";

const TheHistoryOfCoffee = () => {
  const HistoryOfCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/history-of-coffee.png";
  const history = useHistory();
  const isMobile = useResponsiveDimensions().isMobile;
  const seoCtx = useSeoContext();

  const onItemClick = (slug) => {
    setTimeout(() => {
      const el = document.getElementById("cover");
    }, 500);

    history.push(`/learn/category/the-history-of-coffee/${slug}`);
  };

  const params = useParams();

  const slug = params.pathParam3;

  const foundSeo = seoCtx.pageMetadata.find(
    (el) =>
      el.url.toLowerCase() === `/professor-peaberry/history-of-coffee/${slug}`
  );

  const foundSeo2 = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === `/professor-peaberry/history-of-coffee`
  );

  return (
    <ArticleContainer
      shapeStyle={{ background: "#FFEC00" }}
      title="The History of coffee"
    >
      <div className="TheHistoryOfCoffee">
        <img
          className="TheHistoryOfCoffee__main-image"
          src={HistoryOfCoffee}
          alt="history-of-coffee"
        />
        <div id="years" className="TheHistoryOfCoffee__years">
          {HISTORY_OF_COFFEE_YEARS.map((item, index) => (
            <div
              key={`item--${index}`}
              className="TheHistoryOfCoffee__year"
              onClick={() => onItemClick(item.slug)}
            >
              <h2
                className={cs(
                  "TheHistoryOfCoffee__year-title",
                  params.pathParam3 === item.slug &&
                    "TheHistoryOfCoffee__year-title--active"
                )}
              >
                {item.name}
              </h2>
            </div>
          ))}
        </div>
        <div className="TheHistoryOfCoffee__years-content">
          {foundSeo2 && (
            <Seo
              title={foundSeo2?.title}
              description={foundSeo2?.description}
              keywords={foundSeo2?.keywords}
            />
          )}
          {foundSeo && (
            <Seo
              title={foundSeo?.title}
              description={foundSeo?.description}
              keywords={foundSeo?.keywords}
            />
          )}

          {slug?.includes("850") && <FirstEra />}
          {slug?.includes("1600") && <SecondEra />}
          {slug?.includes("1700") && <ThirdEra />}
          {slug?.includes("1800") && <FourthEra />}
          {slug?.includes("1900") && <FifthEra />}
        </div>
      </div>
    </ArticleContainer>
  );
};

export default TheHistoryOfCoffee;
