import { apiRequest } from "./Api";

export const getProducts = async () => {
  return apiRequest("get", `products?per_page=50`);
};

export const getProductsByPathParams = async (
  filterName,
  filterValue,
  page
) => {
  if (filterName && filterName !== "top-coffees") {
    return apiRequest("get", `${filterName}/${filterValue}`);
  } else if (filterName === "top-coffees") {
    return apiRequest("get", `products?top_coffees=25`);
  } else {
    return apiRequest("get", `products`);
  }
};

export const getProductsFilter = async (id, id2, id3, id4, page) => {
  if (!id && !id2 && !id3 && !id4) {
    return apiRequest("get", `products?${page ? `page=${page}` : ""}`);
  }

  if (id === "search") {
    return apiRequest(
      "get",
      `products${id2}${!id2 ? `?per_page=100` : `&per_page=100`}&page=${id3}`
    );
  }

  return apiRequest(
    "get",
    `products?coffee_roast_id=${id}&coffee_type_id=${id2}&coffee_region_id=${id3}&purveyor_id=${
      id4 ? id4 : 0
    }${id4 ? `&per_page=100` : ""}${page ? `&page=${page}` : ""}`
  );
};

export const getTop25ByFilter = async (id, id2, id3, id4, page) => {
  if (!id && !id2 && !id3 && !id4) {
    return apiRequest(
      "get",
      `products?top_coffees=25${page ? `&page=${page}` : ""}`
    );
  }

  return apiRequest(
    "get",
    `products?top_coffees=25&coffee_roast_id=${id}&coffee_type_id=${id2}&coffee_region_id=${id3}&purveyor_id=${
      id4 ? id4 : 0
    }${id4 ? `&per_page=100` : ""}${page ? `&page=${page}` : ""}`
  );
};

export const getProductById = async (id) => {
  return apiRequest("get", `products/${id}`);
};

export const getRockstarCoffees = async () => {
  return apiRequest("get", "products?top_coffees=3");
};

export const getProductsByFilter = async (roast, type, coffeeRegion, page) => {
  return apiRequest(
    "get",
    `products?coffee_roast_id=${roast}&coffee_type_id=${type}&coffee_region_id=${coffeeRegion}&per_page=55&page=${page}`
  );
};

export const getProductsByRoaster = async (id, perPage, page) => {
  return apiRequest(
    "get",
    `products?purveyor_id=${id}&per_page=${perPage}&page=${page}`
  );
};

export const getProductsByAdvancedSearch = async (
  roast,
  type,
  coffeeRegion,
  bagSize,
  notes,
  acidity,
  body,
  purveyors,
  page
) => {
  return apiRequest(
    "get",
    `products?coffee_roast_id=${roast}&coffee_type_id=${type}&coffee_region_id=${coffeeRegion}&bulk_coffee=${bagSize}&coffee_note_id=${notes}&coffee_acidity_id=${acidity}&coffee_body_id=${body}&purveyor_id=${purveyors}&per_page=24&page=${page}`
  );
};

export const queryProductsByName = async (name) => {
  return apiRequest("get", `products?q=${name}`);
};
