import React from "react";
import ArticleContainer from "../ArticleContainer/ArticleContainer";
import Seo from "../../../lib/helpers/SEO/Seo";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";


import "./Grinding.scss";

const Grinding = () => {
  const seoCtx = useSeoContext();

  const Blade =
    "https://assets.gocoffeego.com/v2/assets/images/blade-from-coffee-grinder.jpg";
  const BladeGrinder =
    "https://assets.gocoffeego.com/v2/assets/images/coffee-beans-blade-grinder.jpg";
  const BaratzaCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/baratza-coffee-grinder.jpg";
  const Burr =
    "https://assets.gocoffeego.com/v2/assets/images/burr-from+coffee-grinder.jpg";
  const TurkishCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/turkish-coffee-grinder.jpg";
  const FreshGround =
    "https://assets.gocoffeego.com/v2/assets/images/fresh-ground-coffee.jpg";
  const ManualHand =
    "https://assets.gocoffeego.com/v2/assets/images/manual-hand-crank-coffee-grinder.jpg";
  const CoarseCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/coarse-coffee-grind.jpg";
  const MediumCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/medium-coffee-grind.jpg";
  const FineCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/fine-coffee-grind.jpg";
  const PulverizedCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/super-fine-pulverized-coffee-gri.jpg";

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/professor-peaberry/brewing-tips/coffee-grinding"
  );

  const article = {
    title: foundSeo?.title || "Coffee Grinding",
    image: "https://assets.gocoffeego.com/v2/assets/images/grinding.jpg",
    url: "https://www.gocoffeego.com/learn/category/grinding"
  };

  return (
    <ArticleContainer
      shapeStyle={{ background: "#FF8900" }}
      title="Coffee Grinding"
    >
      <Seo
        title={foundSeo?.title || null}
        description={foundSeo?.description || null}
        keywords={foundSeo?.keywords || null}
        article={article}
      />
      <div className="Grinding">
        <p className="Grinding__description">
          We’ll start with the two basic types of grinders and the various ways
          you can grind coffee beans at home.
        </p>
        <h2 className="Grinding__title">Coffee Grinders:</h2>
        <p className="Grinding__description">
          It’s practically unanimous: every great cup of coffee starts with
          freshly ground coffee. And when I say fresh, I mean grinding your
          beans just moments before brewing. Too much trouble, you say?
          Nonsense! Poppycock! Where shall we begin?
        </p>
        <p className="Grinding__description">
          ~The Various Types of Coffee Grind~
        </p>
        <h2 className="Grinding__title">Blade Grinders</h2>
        <p className="Grinding__description">
          <img
            className="Grinding__image left"
            src={BladeGrinder}
            alt="blade"
          />
          That high-speed whir heard round the world each morning is a blade
          grinder. These are the cheapest grinders for general-purpose coffee
          making.
          <img className="Grinding__image right" src={Blade} alt="blade" />
          Blade from coffee grinder. Hacks and dices your coffee beans.They come
          with perky names like Krups or Braun. You probably have one. You
          shouldn’t. Perhaps you should relegate it to grinding Grandpa’s gruel.
          They can be very handy but they are not always precise and I do They
          horribly hack and slice your beans, leaving an uneven grind with
          course and fine particles in the same batch. The motors run hot;
          grinding too long can scorch the coffee.
        </p>
        <h2 className="Grinding__title">Blade Grinders</h2>
        <p className="Grinding__description">
          <img
            className="Grinding__image left"
            src={BaratzaCoffee}
            alt="bratza"
          />
          <img className="Grinding__image right" src={Burr} alt="burr" />
          For a step up in precision, now we're moving in the right direction,
          tally-ho! Burr grinders are the answer to a more perfect union of bean
          and grind. Disk (a.k.a. plate) and conical burr grinders are your
          basic choices. Flat disk grinders use two spinning disks to smash the
          coffee into precise uniform grinds. We'll start with beans, of course!
          (You may be interested in whole coffee beans from our selection.) Once
          you have your beans, you're ready to learn about the two basic types
          of grinders and the notable variations within. you can even get a
          truly fine espresso grind. But, alas, they can also run hot and, if
          not careful, can scorch the beans.
        </p>

        <h2 className="Grinding__title">Blade Grinders</h2>
        <p className="Grinding__description">
          These are a bit more expensive, but are the choice of both coffee
          professionals and enthusiasts alike and well worth the price. These
          are the workhorses. Precision grinds, even for Turkish coffee, and a
          slow, cool motor.
        </p>
        <p style={{ textAlign: "center" }}>
          <img
            className="Grinding__image left"
            src={TurkishCoffee}
            alt="turkish-coffee"
          />
          <img
            className="Grinding__image"
            src={FreshGround}
            alt="fresh-ground"
          />
        </p>
        <h2 className="Grinding__title">Hand Grinders</h2>
        <p className="Grinding__description">
          <img
            className="Grinding__image right"
            src={ManualHand}
            alt="manual-hand"
          />
          If you’re both counting pennies and are also in need of a way to work
          out your flabby upper arms, perhaps you could try a hand grinder. They
          work on the same principle, except your arm substitutes for an
          electric motor. Watch those biceps bulge! The trouble here is that it
          takes an awful lot of effort to get even a small brew under way and in
          that time you could be drinking coffee.
        </p>
        <p className="Grinding__description">
          This type of coffee grind leaves the largest granules of coffee and is
          preferred for French Presses (a.k.a. plungers) or the percolator
          method of brewing.
        </p>
        <h2 className="Grinding__title">Coarse</h2>
        <p className="Grinding__description">
          <img
            className="Grinding__image left"
            src={CoarseCoffee}
            alt="coarse"
          />
          Precision is good for grinding coffee at home;
        </p>
        <h2 className="Grinding__title">Medium</h2>
        <p className="Grinding__description">
          <img
            className="Grinding__image left"
            src={MediumCoffee}
            alt="medium-coffee"
          />
          Medium grinds have a consistency of granulated sugar and are primarily
          recommended for vacuum and certain types of drip coffee makers.
          Because of its versatile size, it can also be used for other brewing
          methods, but not espresso.
        </p>
        <h2 className="Grinding__title">Fine</h2>
        <p className="Grinding__description">
          <img className="Grinding__image left" src={FineCoffee} alt="fine" />
          Also known as an espresso grind, this is a grind with a powdery/mealy
          consistency used in espresso makers and Neapolitan flip-drips though
          electric drip and filter brews can use it as well.
        </p>
        <h2 className="Grinding__title">Pulverized</h2>
        <p className="Grinding__description">
          <img
            className="Grinding__image left"
            src={PulverizedCoffee}
            alt="pulverized-coffee"
          />
          Like fine flour, this extremely fine grind is the province of Turkish
          coffee and usually needs to be ground in a special grinder.
        </p>
      </div>
    </ArticleContainer>
  );
};

export default Grinding;
