import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import * as API from "../../../api/Api";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import ModalWrapper from "../../Modals/ModalWrapper/ModalWrapper";

import "./CheckBalance.scss";

const CheckBalanceSchema = Yup.object().shape({
  code: Yup.string()
    .min(12, "Code is too short")
    .max(16, "Code is too long")
    .required("Code is required"),
});

const CheckBalance = () => {
  const [accountBalance, setAccountBalance] = useState(0.0);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isMaxTV = useResponsiveDimensions().isMaxTV;

  useEffect(() => {
    API.getAccountBalance().then((res) =>
      setAccountBalance(res.data.total_credits)
    );
  }, []);

  if (message || successMessage) {
    setTimeout(() => {
      setMessage("");
      setSuccessMessage("");
    }, 3000);
  }

  return (
    <div className="CheckBalance">
      <div
        className={
          isMaxTV
            ? "CheckBalance__main-container maxTv"
            : "CheckBalance__main-container"
        }
      >
        <div className="CheckBalance__wrap">
          <p className="CheckBalance__text">
            Your Account Balance is{" "}
            <span className="CheckBalance__balance">${accountBalance}</span>.
            Let's go{" "}
            <Link to="/shop" className="CheckBalance__shopping">
              shopping
            </Link>
            !
          </p>

          <Formik
            initialValues={{
              code: "",
            }}
            validationSchema={CheckBalanceSchema}
            onSubmit={(values) => {
              const data = new FormData();
              data.append("code", values.code);
              setIsLoading(true);
              API.postAccountBalance(data)
                .then((res) => {
                  if (res.status === 200) {
                    setSuccessMessage(
                      `Success! You have $${res.data.amount} on your ${res.data.name}`
                    );
                    setIsLoading(false);
                  } else if (res.error) {
                    setMessage(res.message);
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  setMessage(err.response.data.error.message);
                  setIsLoading(false);
                });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="CheckBalance__container">
                  <div className="CheckBalance__form-group">
                    <span className="CheckBalance__form-text">
                      Please enter your 16-digit Gift Card or 12-digit Gift
                      Certificate claim code.
                    </span>
                  </div>
                  <div className="CheckBalance__form-group2">
                    <Field
                      className="CheckBalance__input"
                      type="text"
                      name="code"
                    />
                    {errors.code && touched.code ? (
                      <div className="CheckBalance__required">
                        {errors.code}
                      </div>
                    ) : null}
                    {message && (
                      <div className="CheckBalance__required">{message}</div>
                    )}
                    {successMessage && (
                      <ModalWrapper
                        isOpen={isOpen}
                        toggle={() => setIsOpen(!isOpen)}
                      >
                        <div className="CheckBalance__success-modal">
                          <p className="CheckBalance__success-modal-title">
                            {successMessage}.
                          </p>{" "}
                          <br />
                          <Link
                            to="/account/redeem"
                            className="CheckBalance__success-modal-link"
                          >
                            <div>Redeem</div>
                          </Link>
                        </div>
                      </ModalWrapper>
                    )}
                  </div>
                </div>
                <button
                  disabled={isLoading ? true : false}
                  className="CheckBalance__button"
                  type="submit"
                >
                  {isLoading ? (
                    <Spinner
                      style={{
                        border: "0.25em solid #00d1d6",
                        borderRightColor: "transparent",
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CheckBalance;
