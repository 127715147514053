import React, { useState } from "react";
import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import Button from "../../shared/Button/Button";

import cs from "classnames";
import "./ProductItem.scss";

const ProductItem = (props) => {
  const {
    title,
    price,
    weight,
    image,
    savedItem,
    id,
    giftFrom,
    alsoLike,
    giftSubscription,
    giftSubscriptionPrice,
  } = props;
  const Close =
    "https://assets.gocoffeego.com/v2/assets/images/close-icon-2.svg";
  const [iconActive, setIconActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cartId = localStorage.getItem("cart_id");

  const cartCtx = useCartContext();

  const handleMoveToCart = () => {
    setIsLoading(true);
    API.moveToCart(id).then(() => {
      cartCtx.getCartItems(cartId);
      cartCtx.getSavedItems(cartId);
      cartCtx.getCartSuggestions(cartId);
      setIsLoading(false);
    });
  };

  const handleDelete = (id) => {
    const cartId = localStorage.getItem("cart_id");

    API.deleteItemFromCart(id, cartId).then(() => {
      cartCtx.getSavedItems(cartId);
      cartCtx.getCartItems();
    });
  };

  const handleAddToCart = () => {
    const cartId = localStorage.getItem("cart_id");
    const data = {
      cart_id: cartId,
      product_id: id,
      coffee_grind_id: 1,
      price: price,
    };
    setIsLoading(true);
    API.addItemToCart(data).then((res) => {
      if(!cartId && res?.data?.cart_id) {
        localStorage.setItem("cart_id", res.data.cart_id);
      }
      setIsLoading(false);
      window.gtag("event", "conversion", {
        send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
      });
    });
    cartCtx.getSavedItems();
    cartCtx.getCartItems();
  };

  return (
    <div className="ProductItem">
      {/*{alsoLike && (*/}
      {/*  <Icon*/}
      {/*    onClick={() => handleSaveForLater(id)}*/}
      {/*    className={cs(*/}
      {/*      "ProductItem__heart",*/}
      {/*      iconActive && "ProductItem__heart--active"*/}
      {/*    )}*/}
      {/*    icon="heart"*/}
      {/*  />*/}
      {/*)}*/}

      {savedItem && (
        <div onClick={() => handleDelete(id)} className="Remove">
          <h1 className="Remove__title">Remove</h1>
          <img className="Remove__icon" src={Close} alt="" />
        </div>
      )}
      <img
        className={cs(
          "ProductItem__image",
          giftFrom && "ProductItem__image--gift"
        )}
        src={image}
        alt=""
      />
      <h1 className="ProductItem__title">{title}</h1>
      {giftFrom ? (
        <h1 className="ProductItem__description">
          {giftSubscription ? `$${giftSubscription?.price}` : `$${price}`}
        </h1>
      ) : (
        <h1 className="ProductItem__description">
          ${price}/{weight}
        </h1>
      )}
      {alsoLike ? (
        <Button
          onClick={handleAddToCart}
          className="ProductItem__button"
          label="Add to cart"
          disabled={isLoading ? true : false}
          isLoading={isLoading}
          spinner
          color={"#00d1d6"}
        />
      ) : (
        <Button
          onClick={handleMoveToCart}
          className="ProductItem__button"
          label="Move to cart"
          disabled={isLoading ? true : false}
          isLoading={isLoading}
          spinner
          color={"#00d1d6"}
        />
      )}
    </div>
  );
};

export default ProductItem;
