import React, { useState, useEffect } from "react";
import * as API from "../../api/Api";
import { useHistory, Link } from "react-router-dom";

import Line from "../../components/shared/Line/Line";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import OrderLoader from "../../components/Loaders/OrderLoader/OrderLoader";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";

import "./Orders.scss";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const msgCtx = useMessageContext();

  let history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    API.getOrders(currentPage)
      .then((res) => {
        setOrders(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);
        setIsLoading(false);
      })
      .catch((err) => msgCtx.handleError(err));
  }, []);

  const loadMore = () => {
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });
    API.getOrders(currentPage + 1)
      .then((res) => {
        setOrders((orders) => orders.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      })
      .catch((err) => msgCtx.handleError(err));
  };

  return (
    <div className="Orders">
      <Line />
      <Line />
      <div className="Orders__title-wrap">
        <MiddleLine />
        <h1 className="Orders__title">Orders</h1>
      </div>
      {isLoading ? (
        <div className="Orders__order-container">
          {Array(12)
            .fill(0)
            .map((item) => (
              <OrderLoader />
            ))}
        </div>
      ) : (
        <>
          {orders && orders?.length !== 0 && (
            <div className="Orders__order-container">
              {orders.map((item) => (
                <div className="Orders__item" key={item?.id}>
                  <div className="Orders__total-container">
                    <div className="Orders__description">
                      Order Date:{" "}
                      <span className="Orders__order-text">
                        {item.order_date}
                      </span>
                    </div>
                    <div className="Orders__description">
                      Ship to:{" "}
                      <span className="Orders__order-text">
                        {item.recipient}
                      </span>
                    </div>
                    <div className="Orders__description">
                      Order Total:{" "}
                      <span className="Orders__order-text">
                        ${item.order_total}
                      </span>
                    </div>
                  </div>

                  <div className="Orders__item-container">
                    <div className="Orders__product-info">
                      Order #:{" "}
                      {item?.items?.map((order) => (
                        <span
                          className={
                            item?.order_refunded || item?.order_voided
                              ? "Orders__product-info-text refunded"
                              : "Orders__product-info-text"
                          }
                          key={order.id}
                        >
                          {order?.order_number}
                        </span>
                      ))}
                      {item?.order_voided && (
                        <img
                          src="https://assets.gocoffeego.com/v2/assets/images/voided.png"
                          alt="voided"
                          className="Orders__voided-image"
                        />
                      )}
                    </div>
                    <div className="Orders__product-info">
                      Products:{" "}
                      {item?.items?.map((order) => (
                        <span
                          className={
                            item?.order_refunded || item?.order_voided
                              ? "Orders__product-info-text refunded"
                              : "Orders__product-info-text"
                          }
                        >
                          {order?.product}
                        </span>
                      ))}
                      {item?.order_refunded && (
                        <img
                          src="https://assets.gocoffeego.com/v2/assets/images/refunded.png"
                          alt="refunded"
                          className="Orders__refunded-image"
                        />
                      )}
                    </div>
                    <div className="Orders__product-info">
                      Tracking #:{" "}
                      {item?.items?.map((order) => (
                        <span
                          className="Orders__product-info-text tracking"
                          onClick={() => {
                            history.push(
                              `orders/tracking/${order?.tracking_number}`
                            );
                          }}
                        >
                          {order?.tracking_number
                            ? order?.tracking_number
                            : "Not Available"}
                        </span>
                      ))}
                    </div>
                  </div>

                  <button
                    className="Orders__items-button"
                    onClick={() => {
                      history.push(`/order/${item.id}`);
                    }}
                  >
                    View order
                  </button>
                </div>
              ))}
              {hasMorePage && (
                <div className="Orders__view-more-btn-container">
                  <ViewMoreButton
                    className="Orders__view-more-btn"
                    handleClick={loadMore}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
      {!isLoading && orders?.length === 0 && (
        <div className="Orders__no-purchase">
          {" "}
          You haven't made any purchase yet
        </div>
      )}
    </div>
  );
};

export default Orders;
