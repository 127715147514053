import ChangeInfoForm from "./ChangeInfoForm/ChangeInfoForm";
import "./ChangeInfo.scss";

const ChangeInfo = () => {
  return (
    <div className="ChangeInfo">
     <ChangeInfoForm  />
    </div>
  );
};

export default ChangeInfo;