import { apiRequest } from "./Api";

export const getOrders = async (page) => {
  return apiRequest("get", `account/orders?page=${page}`, null);
};

export const getOrderTracking = async (trackingNumber, token) => {
  return apiRequest("get", `account/orders/tracking/${trackingNumber}`, null, token);
};

export const getOrdersById = async (id) => {
  return apiRequest("get", `account/orders/${id}`, null);
};

export const getReciept = async (orderId, token) => {
  return apiRequest("get", `checkout/receipt/${orderId}`, null, token);
};
