import React from "react";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import SignUpForm from "../../components/SignUp/SignUpForm/SignUpForm";

import "./SignUp.scss";

const SignUp = () => {
  return (
    <div className="SignUp">
      <PrimaryTitle primaryClassName="SignUp__title" title="SIGN UP" />

      <div className="SignUp__form">
        <SignUpForm />
      </div>
    </div>
  );
};

export default SignUp;
