import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import AutoShipInfoModal from "../../components/Modals/AutoShipInfoModal/AutoShipInfoModal";
import FAQGiftCertModal from "../../components/Modals/FAQGiftCertModal/FAQGiftCertModal";
import ShippingInfoModal from "../../components/Modals/ShippingInfoModal/ShippingInfoModal";
import TermsOfUseModal from "../../components/Modals/TermsOfUseModal/TermsOfUseModal";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Help.scss";

const Help = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenShippingInfo, setIsOpenShippingInfo] = useState(true);
  const [openGiftCert, setOpenGiftCert] = useState(false);
  const [openGiftCert2, setOpenGiftCert2] = useState(false);
  const HelpBanner =
    "https://assets.gocoffeego.com/v2/assets/images/help-banner.jpeg";
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  return (
    <div className="Help">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <div className="Help__banner-image">
        <img className="Help__banner" src={HelpBanner} alt="help banner" />
      </div>
      <div className="Help__content">
        <div className="Help__your-account">
          <h2 className="Help__title">YOUR ACCOUNT </h2>
          <ul className="Help__links">
            <Link to="/account/change-info">
              <li className="Help__link">Change your e-mail address</li>
            </Link>
            <Link to="/sign-up">
              <li className="Help__link">Create an Account</li>
            </Link>
            <Link to="/account/address-book">
              <li className="Help__link">Manage your address book</li>
            </Link>
            <Link to="/account">
              <li className="Help__link">Manage Your Account</li>
            </Link>
            <Link to="/forgot-password">
              <li className="Help__link">Forgot Your Password</li>
            </Link>
          </ul>
        </div>

        <div className="Help__returns">
          <h2 className="Help__title"> RETURNS AND REFUNDS</h2>
          <ul className="Help__links">
            <li>
              <a className="Help__link" href="#fresh-roasted">
                Fresh Roasted Guarantee
              </a>
            </li>
            <li>
              <a className="Help__link" href="#refund-policy">
                Return and Refund Policy
              </a>
            </li>
          </ul>
        </div>

        <div id="fresh-roasted" className="Popup__overlay">
          <div className="Popup__container">
            <a className="Popup__close" href="#">
              &times;
            </a>
            <div className="Popup__content">
              <h2 className="Popup__title">Fresh Roasted Guarantee</h2>
              <div className="Popup__text">
                <p>
                  Our commitment is to bring to you the freshest and highest
                  quality coffee we can. Coffees are fresh roasted within 1 day
                  of your order, (prior to the order, same day, or next),
                  excluding weekends and certain holidays. If the Roaster has
                  posted a “limited” roasting schedule, your coffee may be
                  roasted later than 1 day of your order, whereas it is roasted
                  fresh and shipped that same day. We guarantee the coffee you
                  receive is fresh roasted, verified by the roast date indicated
                  on the bag.
                </p>
                <p>
                  “Limited Offerings” may ship later than 1 day of your order
                  due to the narrow schedule and day(s) of the week these rare
                  or seasonal beans are roasted. If your order includes one of
                  these items, your entire shipment from that specific roaster
                  will be held, then fresh roasted, and subsequently sent to you
                  to ensure that all the coffee you receive is fresh roasted.
                </p>
                <p>
                  If you find that you coffee was not shipped in a timely manner
                  as defined by our Shipping Policy, or that the indication of
                  the Roast Date is not on the bag, we will have the Roaster
                  ship a replacement order of that coffee at no expense to you.
                  This is the agreement we have with our Roasters and with you.
                </p>
                <p>Please Contact Us for further information.</p>
              </div>
            </div>
          </div>
        </div>
        <div id="refund-policy" className="Popup__overlay">
          <div className="Popup__container">
            <a className="Popup__close" href="#">
              &times;
            </a>
            <div className="Popup__content">
              <h2 className="Popup__title">Return and Refund Policy</h2>
              <div className="Popup__text">
                <p>
                  We at GoCoffeeGo have tasted and cupped every coffee on our
                  site to pass our stringent standards.
                </p>
                <p>
                  Prior to your selection, in order to aid and assist you, we
                  have done our best to provide you with in-depth information,
                  from a profile of the Roaster, to descriptions of the Coffees,
                  including the notes, body and acidity, to reviews from
                  Professor Peaberry Recommends and Members' Picks, to the Top
                  25 Sellers, as well as various searches by region, by type and
                  finally by your unique preferences and information provided by
                  you in Coffees You'll Love.
                </p>
                <p>
                  We not only respect our Roasters, we respect you. We value our
                  customers and truly want you to be happy - that's what we're
                  all about at GoCoffeeGo; having fun, enjoying life and taking
                  our customers across the world on a coffee adventure.
                </p>
                <p>
                  We can't accept returns on coffee due to its perishable
                  nature. However, we really want you to be happy with your
                  purchase. If you have any issues with your coffee, or if there
                  are errors in your order, please contact us at
                  contactus@gocoffeego.com within 10 days of receiving your
                  order. We're happy to help you find something you'll love.{" "}
                </p>
                <p>
                  We are happy to replace any items damaged in transit. We
                  typically ask for a photo of the damage to pass along to our
                  shipping department in case there is a trend in breakage.
                  Additionally, if the box was also damaged, a photo of that can
                  help us get information to our shipping partners. Please let
                  us know the original order number and the best address for the
                  return shipment. We will cover the shipping costs.
                </p>

                <h3 className="Popup__subtitle">
                  Forwarding to another address
                </h3>
                <p>
                  Was your order returned to us because of an address problem?
                  Please Contact Us and let us know. Please notify us first to
                  ensure we have received your coffee back.
                </p>
                <Link to="products/gocoffeego/re-shipping-padded-envelope-5688">
                  {" "}
                  <p className="Popup__link">
                    Create a forwarding shipping label here.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="Help__ordering">
          <h2 className="Help__title">ORDERING</h2>
          <ul className="Help__links">
            <li>
              <a className="Help__link" href="#checkout">
                Checkout
              </a>
            </li>
            <div id="checkout" className="Popup__overlay">
              <div className="Popup__container">
                <a className="Popup__close" href="#">
                  &times;
                </a>
                <div className="Popup__content">
                  <h2 className="Popup__title">Checkout</h2>
                  <p className="Popup__text">Content TBD.</p>
                </div>
              </div>
            </div>
            <li>
              <a className="Help__link" href="#redeeming">
                Redeeming Promotion Codes
              </a>
            </li>

            <Link to="/cart">
              <li className="Help__link">Shopping Cart</li>
            </Link>
          </ul>
        </div>
        <div id="redeeming" className="Popup__overlay">
          <div className="Popup__container">
            <a className="Popup__close" href="#">
              &times;
            </a>
            <div className="Popup__content">
              <h2 className="Popup__title">Redeeming Promotion Codes</h2>
              <p className="Popup__text">
                Please enter your code on the last page of checkout to receive
                the promotional credit.
              </p>
            </div>
          </div>
        </div>
        <div className="Help__your-order">
          <h2 className="Help__title">YOUR ORDER</h2>
          <ul className="Help__links">
            <li>
              <a className="Help__link" href="#popup2">
                Delivery
              </a>
            </li>
            <div id="popup2" className="Popup__overlay">
              <div className="Popup__container">
                <a className="Popup__close" href="#">
                  &times;
                </a>
                <div className="Popup__content">
                  <h2 className="Popup__title">Delivery</h2>
                  <p className="Popup__text">
                    All orders are delivered by USPS Priority Mail. You can
                    track you order by clicking on the tracking number in the
                    "Your Order Has Shipped" e-mail or by going to "My Account"
                    and clicking on "My Orders - View Orders." GoCoffeeGo does
                    not require a signature for delivery.
                  </p>
                </div>
              </div>
            </div>
            <li>
              <a className="Help__link" href="#tracking-order">
                {" "}
                Tracking your order
              </a>
            </li>
            <div id="tracking-order" className="Popup__overlay">
              <div className="Popup__container">
                <a className="Popup__close" href="#">
                  &times;
                </a>
                <div className="Popup__content">
                  <h2 className="Popup__title">Tracking Your Order</h2>
                  <p className="Popup__text">
                    You can track you order by clicking on the tracking number
                    in the "Your Order Has Shipped" e-mail or by going to "My
                    Account" and clicking on "Track Shipments / View Orders"
                    GoCoffeeGo does not require a signature for delivery.
                  </p>
                </div>
              </div>
            </div>
            <li>
              <a className="Help__link" href="#availability">
                Product Availability
              </a>
            </li>
            <div id="availability" className="Popup__overlay">
              <div className="Popup__container">
                <a className="Popup__close" href="#">
                  &times;
                </a>
                <div className="Popup__content">
                  <h2 className="Popup__title">Product Availability</h2>
                  <p className="Popup__text">
                    It is our policy to only list coffees on our website that
                    are available from our Roasters. In the rare event that a
                    coffee you selected becomes unavailable, you will be
                    notified and the order will be cancelled. If multiple items
                    were ordered from that specific Roaster, the remainder of
                    the items in the order will be filled. In the unlikely event
                    that a coffee you placed in your Auto-Ship queue is
                    unavailable; the next coffee in the queue will be shipped.
                  </p>
                </div>
              </div>
            </div>
            <li>
              <a className="Help__link" href="#canceling-order">
                Changing or Canceling
              </a>
            </li>
            <div id="canceling-order" className="Popup__overlay">
              <div className="Popup__container">
                <a className="Popup__close" href="#">
                  &times;
                </a>
                <div className="Popup__content">
                  <h2 className="Popup__title">Changing or Canceling</h2>
                  <p className="Popup__text">
                    Once you place your order by clicking "Place Your Order" on
                    the final page of checkout, it is usually final. However if
                    you do need to cancel, we will do our best to cancel it or
                    change it. Please contact Elise at: elise@gocoffeego.com.
                  </p>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <div className="Help__gift-cards">
          <h2 className="Help__title">GIFT CERTIFICATES</h2>
          <ul className="Help__links">
            <li className="Help__link" onClick={() => setOpenGiftCert(true)}>
              Terms of Use
            </li>
            <li className="Help__link" onClick={() => setOpenGiftCert2(true)}>
              Gift Certificates FAQ's
            </li>
          </ul>
        </div>

        <TermsOfUseModal
          isOpen={openGiftCert}
          toggle={() => setOpenGiftCert(!openGiftCert)}
        />

        <FAQGiftCertModal
          isOpen={openGiftCert2}
          toggle={() => setOpenGiftCert2(!openGiftCert2)}
        />

        <div className="Help__autoship">
          <h2 className="Help__title">AUTO-SHIP</h2>
          <ul className="Help__links">
            <li className="Help__link" onClick={() => setIsOpen(!isOpen)}>
              How Does Auto-Ship Work?
            </li>

            <li>
              <a className="Help__link" href="#auto-ship-policy">
                Auto-Ship Policy
              </a>
            </li>
            <div id="auto-ship-policy" className="Popup__overlay">
              <div className="Popup__container">
                <a className="Popup__close" href="#">
                  &times;
                </a>
                <div className="Popup__content">
                  <h2 className="Popup__title">Auto-Ship Policy</h2>
                  <div className="Popup__text">
                    <p>
                      Customers with Auto-Ship also enjoy the benefits of weekly
                      “Specials” and “promotions,” for free coffee, shipping, or
                      other goodies, if their order meets the requirements for
                      that specific roaster's promotion. No worry about missing
                      out with Auto-Ship. If it's in your queue and there is a
                      special, you get the deal.
                    </p>
                    <p>
                      In the unlikely event that a coffee you placed in your
                      Auto-Ship queue is temporarily or no longer unavailable;
                      the next selection in your queue will be shipped. If your
                      Auto-Ship queue ever becomes empty, the queue will be
                      considered concluded.
                    </p>
                    <p>
                      If you order encompasses multiple items from different
                      roasters and something becomes unavailable, that item will
                      be canceled and the rest of the items will be sent.
                    </p>
                    <p>
                      Coffees listed as “Limited Offerings” are not available
                      for Auto-Ship, due to limited supplies and roasting
                      schedules.{" "}
                    </p>
                    <p>See How Auto Ship Works? for further information.</p>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>

        <AutoShipInfoModal isOpen={!isOpen} toggle={() => setIsOpen(!isOpen)} />
        <div className="Help__shipping">
          <h2 className="Help__title">SHIPPING</h2>
          <ul className="Help__links">
            <li
              className="Help__link"
              onClick={() => setIsOpenShippingInfo(!isOpenShippingInfo)}
            >
              Shipping Info
            </li>
          </ul>
        </div>
        <ShippingInfoModal
          isOpen={!isOpenShippingInfo}
          toggle={() => setIsOpenShippingInfo(!isOpenShippingInfo)}
        />
        <div className="Help__security-privacy">
          <h2 className="Help__title">SECURITY AND PRIVACY</h2>
          <ul className="Help__links">
            <Link to="/privacy-policy">
              {" "}
              <li className="Help__link">Privacy Policy</li>
            </Link>
          </ul>
        </div>

        <div className="Help__contact-us">
          <h2 className="Help__title">CONTACT US</h2>
          <ul className="Help__links">
            <Link to="/contact-us">
              {" "}
              <li className="Help__link">Contact Us</li>
            </Link>
          </ul>
        </div>

        <div className="Help__payment">
          <h2 className="Help__title">PAYMENT</h2>
          <ul className="Help__links">
            <li>
              <a className="Help__link" href="#payment">
                Methods of Payment
              </a>
            </li>
          </ul>
        </div>
        <div id="payment" className="Popup__overlay">
          <div className="Popup__container">
            <a className="Popup__close" href="#">
              &times;
            </a>
            <div className="Popup__content">
              <h2 className="Popup__title">Methods of Payment</h2>
              <div className="Popup__text">
                GoCoffeeGo accepts Visa, MaserCard, American Express and
                Discover.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
