import React, { createRef, useEffect } from "react";
import "./AudioTrack.scss";

const AudioTrack = ({ song, isPlaying, onPlayPauseClick }) => {
  const myRef = createRef();

  useEffect(() => {
    if (isPlaying) {
      myRef.current.load();
      myRef.current.play().catch((error) => {
        console.log(error);
      });
    } else {
      myRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <>
      <div className="AudioTrack__song-text">"THE WHO - WHO ARE YOU"</div>
      <audio id="audio" ref={myRef} src={song} preload="metadata" loop />
      <div className="AudioTrack__controls">
        {isPlaying ? (
          <div className="AudioTrack__play-wrap">
            <button
              type="button"
              className="AudioTrack__pause-icon"
              onClick={() => {
                myRef.current.pause();
                onPlayPauseClick(false);
              }}
              aria-label="Pause"
            />
            <div className="AudioTrack__play">Stop music</div>
          </div>
        ) : (
          <div className="AudioTrack__play-wrap">
            <button
              type="button"
              className="AudioTrack__play-icon"
              onClick={() => {
                //myRef.current.load();
                myRef.current.play();
                onPlayPauseClick(true);
              }}
              aria-label="Play"
            />
            <div className="AudioTrack__play">Play music</div>
          </div>
        )}
      </div>
    </>
  );
};

export default AudioTrack;
