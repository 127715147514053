import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";

import "./Order.scss";

import * as API from "../../api/Api";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import WriteReview from "../../components/Orders/WriteReview/WriteReview";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import Line from "../../components/shared/Line/Line";
import Button from "../../components/shared/Button/Button";
import LinkButton from "../../components/shared/LinkButton/LinkButton";
import { downloadPdf } from "../../lib/helpers/downloadPdf";

const Order = () => {
  const [isLoading, setIsLoading] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [orderData, setOrderData] = useState();
  const [cartLoading, setCartLoading] = useState(false);
  const [autoShipLoading, setAutoShipLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isDownloading, setDowloading] = useState({
    giftCert: false,
    subscription: false,
  });
  const cartCtx = useCartContext();
  const params = useParams();
  const orderId = params.pathParam1;
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    API.getOrdersById(orderId).then((res) => {
      if (res.data) {
        setIsLoading(false);
      }
      setOrderData(res.data);
    }).catch(err => console.log(err));
  }, []);

  const taxPrice = orderData?.totals?.tax;
  const shippingPrice = orderData?.totals?.delivery;
  const itemsPrice = orderData?.totals?.subtotal.toFixed(2);
  const creditPrice = orderData?.totals?.credits.toFixed(2);
  const orderPlaced = orderData?.order_date;

  const getReviews = () => {
    API.getMyReviews().then((res) => {
      setDetails(res.data);
    });
  };

  const handleAddToCart = (id, grind) => {
    const cartId = localStorage.getItem("cart_id");

    const data = {
      cart_id: cartId,
      product_id: id,
      coffee_grind_id: grind,
    };
    setCartLoading(true);

    if (!cartId) {
      API.addItemToCart(data).then((res) => {
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
        });
        localStorage.setItem("cart_id", res.data.cart_id);
        cartCtx.getCartItems();
        setCartLoading(false);
        history.push("/cart");
      });
    } else {
      API.addItemToCart(data).then((res) => {
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
        });
        cartCtx.getCartItems();
        setCartLoading(false);
        history.push("/cart");
      });
    }
  };

  const handleAddToAutoShip = (purveyorId, productId, grindId) => {
    const data = {
      purveyor_id: purveyorId,
      product_id: productId,
      coffee_grind_id: grindId,
    };
    setAutoShipLoading(true);
    API.addItemToAutoShip(data).then((res) => {
      localStorage.setItem("cart_id", res.data.cart_id);
      setAutoShipLoading(false);
      history.push("/auto-ship");
    });
  };

  return (
    <div className="Order">
      <Line />
      <Line />

      <div className="Orders__title-wrap">
        <MiddleLine />
        <h1 className="Orders__title">Order</h1>
      </div>

      <div className="Order__container">
        <div className="Order__header">
          <h1 className="Order__title">Order Placed: {orderPlaced}</h1>

          <div className="Order__button-wrap">
            <LinkButton path="/orders" className="Order__button" label="Back" />
            <Button
              className="Order__button"
              label="Print Order"
              onClick={() => {
                window.print();
              }}
            />
          </div>
        </div>
        <div className="Order__details">
          <div className="Order__column-1-container">
            {orderData?.shipments.map((shipment) => (
              <div className="Order__column-1" key={shipment?.id}>
                <div className="OrderShippingDetails">
                  <h1 className="OrderShippingDetails__title">
                    {" "}
                    Shipping Details:{" "}
                  </h1>

                  <div className="OrderShippingDetails__columns">
                    {orderData?.shipments[0]?.company_name !==
                      "Downloadable Product" && (
                      <div className="OrderShippingDetails__column">
                        <h1 className="OrderShippingDetails__title2">
                          {" "}
                          Shipping to:{" "}
                        </h1>
                        <div className="OrderShippingDetails__info">
                          <p className="Order__title3">
                            {" "}
                            {shipment?.full_name}
                          </p>
                          <p className="Order__title3">
                            {" "}
                            {shipment?.address1}, {shipment?.address2}
                          </p>
                          <p className="Order__title3">
                            {" "}
                            {shipment?.city}, {shipment?.state?.code}{" "}
                            {shipment?.zip}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="OrderShippingDetails__column">
                      <h1 className="OrderShippingDetails__title2">
                        Shipping Method:
                      </h1>
                      <div className="OrderShippingDetails__info">
                        <p className="Order__title3">
                          {shipment?.shipping_method}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="OrderProductDetails">
                  {shipment?.order_products.map((product) => (
                    <div
                      className="OrderProductDetails__container"
                      key={product?.id}
                    >
                      {product?.product?.main_image?.md && (
                        <div className="OrderProductDetails__image-wrap">
                          <img
                            src={product?.product?.main_image?.md}
                            alt="order product"
                            className="OrderProductDetails__image"
                          />
                          <button
                            className="OrderProductDetails__review-button"
                            onClick={() => {
                              setSelectedProduct(product);
                              setIsOpen(true);
                            }}
                          >
                            [Write Review]
                          </button>
                        </div>
                      )}

                      {isOpen && (
                        <WriteReview
                          closeIcon
                          selectedProduct={selectedProduct}
                          isOpen={isOpen}
                          toggle={() => setIsOpen(!isOpen)}
                          fetchDetails={getReviews}
                        />
                      )}
                      {product?.product?.image && (
                        <img
                          src={product?.product?.image}
                          alt="order product"
                          className="OrderProductDetails__image"
                        />
                      )}
                      <div>
                        <div className="OrderProductDetails__product-purveyor">
                          {product?.product?.purveyor?.name}
                        </div>
                        <div className="OrderProductDetails__product-name">
                          {product?.product?.name}
                        </div>
                        <div className="OrderProductDetails__button-wrap">
                          {product?.coffee_grind && (
                            <Button
                              className="OrderProductDetails__button"
                              label="Order Again"
                              style={{ marginRight: "1rem" }}
                              onClick={() => {
                                setSelectedProduct(product?.product);
                                handleAddToCart(
                                  product.product.id,
                                  product.coffee_grind.id
                                );
                              }}
                              isLoading={cartLoading}
                              disabled={
                                cartLoading &&
                                selectedProduct?.id === product?.product?.id
                                  ? true
                                  : false
                              }
                              spinner={
                                selectedProduct?.id === product?.product?.id
                                  ? true
                                  : false
                              }
                              color={"#00d1d6"}
                            />
                          )}
                          {product?.coffee_grind && (
                            <Button
                              className="OrderProductDetails__button"
                              label="Add to Auto-Ship"
                              onClick={() => {
                                setSelectedProduct(product?.product);
                                handleAddToAutoShip(
                                  product?.product?.purveyor?.id,
                                  product?.product?.id,
                                  product?.coffee_grind?.id
                                );
                              }}
                              isLoading={autoShipLoading}
                              disabled={
                                autoShipLoading &&
                                selectedProduct?.id === product?.product?.id
                                  ? true
                                  : false
                              }
                              spinner={
                                selectedProduct?.id === product?.product?.id
                                  ? true
                                  : false
                              }
                              color={"#00d1d6"}
                            />
                          )}
                          {product?.gs_id && product?.gs_plan_text && (
                            <div className="OrderProductDetails__download-btn-wrapper">
                              <Button
                                className="OrderProductDetails__download-button"
                                label="Download Coffee Subscription"
                                onClick={() => {
                                  setDowloading({ subscription: true });
                                  API.downloadSubscriptionGift(
                                    product?.gs_id
                                  ).then((res) => {
                                    downloadPdf(
                                      res,
                                      "GoCoffeeGo Coffee Subscription"
                                    );
                                    setDowloading({ subscription: false });
                                  });
                                }}
                                isLoading={isDownloading.subscription}
                                disabled={
                                  isDownloading.subscription ? true : false
                                }
                                spinner={
                                  isDownloading.subscription ? true : false
                                }
                                color={"#ffffff"}
                              />
                              <div className="OrderProductDetails__download-note">
                                *To print Coffee Subscription please download it
                                here.
                              </div>
                            </div>
                          )}
                          {product?.gc_id && (
                            <div className="OrderProductDetails__download-btn-wrapper">
                              <Button
                                className="OrderProductDetails__download-button"
                                label="Download Gift Certificate"
                                onClick={() => {
                                  setDowloading({ giftCert: true });
                                  API.downloadGift(product?.gc_id).then(
                                    (res) => {
                                      downloadPdf(
                                        res,
                                        "GoCoffeeGo Gift Certificate"
                                      );
                                      setDowloading({ giftCert: false });
                                    }
                                  );
                                }}
                                isLoading={isDownloading.giftCert}
                                disabled={isDownloading.giftCert ? true : false}
                                spinner={isDownloading.giftCert ? true : false}
                                color={"#ffffff"}
                              />
                              <div className="OrderProductDetails__download-note">
                                *To print, or redeem and use Gift Certificate
                                please download it here and follow instructions
                                written in gift certificate .pdf file.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {product?.coffee_grind?.name && (
                        <div className="OrderProductDetails__product-details">
                          {product?.coffee_grind?.name}
                        </div>
                      )}
                      {product?.product?.retail_price &&
                      !product?.gift_subscription ? (
                        <div className="OrderProductDetails__product-details">
                          ${product?.product?.retail_price.toFixed(2)} /
                          <div> {product?.product?.weight} </div>
                        </div>
                      ) : null}

                      {product?.gift_subscription && (
                        <div className="OrderProductDetails__product-details">
                          ${product?.gift_subscription?.price.toFixed(2)}
                        </div>
                      )}

                      {product?.product?.gift_amount && (
                        <div className="OrderProductDetails__product-details">
                          ${product?.product?.gift_amount.toFixed(2)}
                        </div>
                      )}

                      <div className="OrderProductDetails__product-details">
                        <div>Quantity</div>
                        <div>{product?.quantity}</div>
                      </div>

                      {product?.order_voided && (
                        <img
                          src="https://assets.gocoffeego.com/v2/assets/images/voided.png"
                          alt="voided"
                          className="Order__voided-image"
                        />
                      )}

                      {product?.order_refunded && (
                        <img
                          src="https://assets.gocoffeego.com/v2/assets/images/refunded.png"
                          alt="refunded"
                          className="Order__refunded-image"
                        />
                      )}
                    </div>
                  ))}
                </div>

                <div className="OrderTracking">
                  <h2 className="Order__title3">
                    {" "}
                    Order #: {shipment?.order_products[0]?.order_number}
                  </h2>
                  <h2 className="Order__title3">
                    {" "}
                    Order Status: {shipment?.order_products[0]?.order_status}
                  </h2>
                  <Link
                    to={`/orders/tracking/${shipment?.order_products[0]?.tracking_number}`}
                  >
                    <h2
                      className="Order__title3"
                      onClick={() => {
                        if (shipment?.order_products[0]?.tracking_number) {
                          history.push(
                            `orders/tracking/${shipment?.order_products[0]?.tracking_number}`
                          );
                        }
                      }}
                    >
                      {" "}
                      Tracking #:{" "}
                      {shipment?.order_products[0]?.tracking_number
                        ? shipment?.order_products[0]?.tracking_number
                        : "Not Available"}
                    </h2>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="Order__column-2">
            <div className="OrderSummary">
              <h2 className="OrderSummary__title"> Order Summary </h2>
              <div className="OrderSummary__row">
                <h2 className="Order__title3"> Items: </h2>
                <h2 className="Order__title3"> ${itemsPrice}</h2>
              </div>
              <div className="OrderSummary__row">
                <h2 className="Order__title3"> Tax: </h2>
                <h2 className="Order__title3"> ${taxPrice}</h2>
              </div>
              <div className="OrderSummary__row">
                <h2 className="Order__title3"> Shipping: </h2>
                <h2 className="Order__title3"> ${shippingPrice}</h2>
              </div>
              <div className="OrderSummary__row">
                <h2 className="Order__title3"> Specials: </h2>
                <h2 className="Order__title3">
                  {" "}
                  - ${orderData?.totals?.specials}
                </h2>
              </div>
              <div className="OrderSummary__row">
                <h2 className="Order__title3"> Account Credit: </h2>
                <h2 className="Order__title3"> - ${creditPrice}</h2>
              </div>
              <h2 className="OrderSummary__subtitle">
                Order Total: ${orderData?.totals?.total?.toFixed(2)}
              </h2>
            </div>
            {orderData?.order_specials_promos && (
              <div className="OrderSpecials">
                <h2 className="OrderSpecials__title"> Order Specials </h2>
                <div className="OrderSummary__row">
                  <h2 className="Order__title3">
                    {" "}
                    {orderData?.order_specials_promos}{" "}
                  </h2>
                </div>
              </div>
            )}

            <div className="OrderPaymentMethod">
              <h2 className="OrderPaymentMethod__title"> Payment Method </h2>

              <div className="OrderPaymentMethod__row">
                <h2 className="Order__title3"> {orderData?.payment_method}</h2>
              </div>
            </div>
            {orderData?.billing_address && (
              <div className="OrderBillingAddress">
                <h2 className="OrderBillingAddress__title">
                  {" "}
                  Billing Address{" "}
                </h2>
                <div className="OrderBillingAddress__row">
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.full_name}{" "}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.company_name}{" "}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.address1}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.address2}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.zip}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.city}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {" "}
                    {orderData?.billing_address?.state?.name}
                  </h2>
                  <h2 className="OrderBillingAddress__billing-details">
                    {orderData?.billing_address?.country?.name}
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
