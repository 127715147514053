import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import QuizQuestionTitle from "../QuizQuestionTitle/QuizQuestionTitle";

import QuizLines from "../QuizLines/QuizLines";

import cs from "classnames";
import "./QuizQuestion1.scss";

const QuizQuestion1 = (props) => {
  const WhoButton =
    "https://assets.gocoffeego.com/v2/assets/images/the-who-button.png";
  const QuizMens =
    "https://assets.gocoffeego.com/v2/assets/images/quiz-mens.svg";
  const Target =
    "https://assets.gocoffeego.com/v2/assets/images/target.svg";
  const { selectedAnswer } = props;
  const [answer, setAnswer] = useState();

  useEffect(() => {
    API.getQuizQuestionOne().then((res) => {
      setAnswer(res.data);
    });
  }, []);

  return (
    <div id="question-1" className="QuizQuestion1">
      <img
        className="QuizQuestion1__main-image"
        src={WhoButton}
        alt="who button"
      />
      <QuizQuestionTitle
        questionNumber={1}
        question={"What's your coffee drinking know-how?"}
      />
      <div className="QuizQuestion1__answers">
        {typeof answer === "object" &&
          answer.map((item) => (
            <div
              key={item.id}
              className={cs(
                "QuizQuestion1__answer",
                selectedAnswer === item.id && "QuizQuestion1__answer--active"
              )}
              onClick={() => props.onItemSelect(item.id)}
            >
              <div className="QuizQuestion1__answer-title font-cairo"> {item.name}</div>
              <p className="QuizQuestion1__description"> {item.description}</p>
            </div>
          ))}
      </div>

      <div className="QuizQuestion1__lines">
        <img className="QuizQuestion1__mens" src={QuizMens} alt="quiz-mens" />
        <img className="QuizQuestion1__target" src={Target} alt="target" />
        <QuizLines className="QuizQuestion1__lines-style" />
      </div>
    </div>
  );
};

export default QuizQuestion1;
