import { apiRequest } from "./Api";

export const getRecipes = async (page) => {
  return apiRequest("get", `recipes?per_page=${12}&page=${page}`);
};

export const getRecipesCategories = async () => {
  return apiRequest("get", "recipe-categories");
};

export const getRecipeById = async (id) => {
  return apiRequest("get", `recipes/${id}`);
};

export const getRecipesByTag = async (id) => {
  return apiRequest("get", `recipes?recipe_tag_id=${id}`);
};
