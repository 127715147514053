import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Img from "react-cool-img";
import Line from "../../shared/Line/Line";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

import { COFFE_KNOWLEDGE_ITEMS } from "../../../api/CoffeKnowledgeItems";

import ColorThief from "../../../../node_modules/colorthief/dist/color-thief";

import cs from "classnames";
import "./CoffeKowledge.scss";

const CoffeKnowledge = () => {
  const Peaberry =
    "https://assets.gocoffeego.com/v2/assets/images/prof-peaberry-with-a-cup-new.png";
  const Recipes =
    "https://assets.gocoffeego.com/v2/assets/images/recipes-banner.jpg";
  const BrewingBg =
    "https://assets.gocoffeego.com/v2/assets/images/brewing-tips-bg.png";
  const Regions =
    "https://assets.gocoffeego.com/v2/assets/images/regions-bg.png";
  const Esspresso =
    "https://assets.gocoffeego.com/v2/assets/images/espresso-drinks.png";
  const Grinding =
    "https://assets.gocoffeego.com/v2/assets/images/grinding.jpg";
  const MakingYourBrew =
    "https://assets.gocoffeego.com/v2/assets/images/making-your-brew.jpg";

  const history = useHistory();
  const params = useParams();

  const colorThief = new ColorThief();

  const isMobile = useResponsiveDimensions().isMobile;

  const onItemClick = (slug) => {
    if (isMobile) {
      setTimeout(() => {
        const el = document.getElementById("cover");
        const offset = 100;
        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: "smooth",
        });
      }, 500);
    } else {
      setTimeout(() => {
        const el = document.getElementById("cover");
        const offset = 50;
        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: "smooth",
        });
      }, 200);
    }

    if (slug === "the-history-of-coffee") {
      history.push(`/learn/category/${slug}/850`);
      return;
    }

    if (slug === "regions") {
      history.push(`/learn/category/${slug}/coffee-regions`);
      return;
    }
    history.push(`/learn/category/${slug}`);
  };

  const loaderStyle = (color) => {
    return {
      backgroundColor: color,
      width: "480",
      height: "320",
    };
  };

  const rgbToHex = (r, g, b) =>
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("");

  const getDominantColor = (id) => {
    setTimeout(() => {
      const img = document.getElementById(id);

      const color = colorThief.getColor(img);
      const hexColor = rgbToHex(color[0], color[1], color[2]);
      return hexColor;
    }, 200);
  };

  return (
    <div className="CoffeKnowledge">
      <div className="CoffeKnowledge__line">
        <Line />
        <Line />
        <span className="CoffeKnowledge__title">COFFEE KNOWLEDGE</span>

        <img
          className="CoffeKnowledge__image"
          src={Peaberry}
          alt="peaberry-man"
        />
      </div>
      <div className="CoffeKnowledge__content">
        <div className="CoffeKnowledge__description">
          “Professor Peaberry here, GoCoffeeGo’s fabulous connoisseur of all
          things coffee (named after the unique, exceptional and rare bean
          itself). I’d be happy to show you around my world and teach you a
          thing or two while we’re at it.”
        </div>
        <div id="coffee-knowledge" className="CoffeKnowledge__items">
          {COFFE_KNOWLEDGE_ITEMS.map((item, index) => (
            <div
              key={`item--${index}`}
              onClick={() => onItemClick(item.slug)}
              className="CoffeKnowledge__item"
            >
              {params.pathParam2 === item.slug ? (
                <img
                  className="CoffeKnowledge__item-image"
                  src={item.img2}
                  alt={item.name}
                />
              ) : (
                <img
                  className="CoffeKnowledge__item-image"
                  src={item.img}
                  alt={item.name}
                />
              )}
              <span
                className={cs(
                  "CoffeKnowledge__item-title",
                  params.pathParam2 === item.slug &&
                    "CoffeKnowledge__item-title--active"
                )}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>

        <div id="cover">
          {params.pathParam2 === "making-your-brew" && (
            <div className="CoffeKnowledge__background-container">
              {/*<img className="CoffeKnowledge__background" src={MakingYourBrew} />*/}
              <Img
                id="making-your-brew"
                className="CoffeKnowledge__background--making-your-brew"
                src={MakingYourBrew}
                alt="REACT COOL IMG"
              />
            </div>
          )}
          {params.pathParam2 === "brewing-tips" && (
            <div className="CoffeKnowledge__background-container">
              <Img
                className="CoffeKnowledge__background--brewing"
                // style={loaderStyle("#c2bab3")}
                src={BrewingBg}
                alt="REACT COOL IMG"
              />
            </div>
          )}

          {params.pathParam2 === "recipes" && (
            <div className="CoffeKnowledge__background-container">
              <Img
                id="recipes"
                className="CoffeKnowledge__background--recipes"
                style={loaderStyle("#1b2127")}
                src={Recipes}
                alt="REACT COOL IMG"
              />
            </div>
          )}
          {params.pathParam2 === "regions" && (
            <div className="CoffeKnowledge__background-container">
              <Img
                id="regions"
                className="CoffeKnowledge__background--regions"
                src={Regions}
                alt="REACT COOL IMG"
              />
            </div>
          )}

          {params.pathParam2 === "espresso-drinks" && (
            <div className="CoffeKnowledge__background-container">
              <Img
                id="espresso"
                className="CoffeKnowledge__background--espresso"
                src={Esspresso}
                alt="REACT COOL IMG"
              />
            </div>
          )}

          {params.pathParam2 === "grinding" && (
            <div className="CoffeKnowledge__background-container">
              <Img
                id="grinding"
                className="CoffeKnowledge__background--grinding"
                style={loaderStyle("#2f2921")}
                src={Grinding}
                alt="REACT COOL IMG"
              />
            </div>
          )}
        </div>

        {/*{params.pathParam2 && (*/}
        {/*  <div className="CoffeKnowledge__item-selected">*/}
        {/*    {params.pathParam2}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default CoffeKnowledge;
