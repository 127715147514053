import QuizButton from "../../shared/QuizButton/QuizButton";
import "./QuizPreferences.scss";

const QuizPreferences = ({ quiz }) => {
  return (
    <div className="QuizPreferences">
      <QuizButton className="QuizPreferences__quiz-btn" />
      <h2 className="QuizPreferences__title">
        Preferences based on quiz results
      </h2>
      <ul className="QuizPreferences__list">
        <li className="QuizPreferences__item">
          {" "}
          Coffee know how:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.coffee_know_how ? quiz.coffee_know_how.name : "No preference"}
          </span>
        </li>
        <li className="QuizPreferences__item">
          {" "}
          Coffee brew:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.coffee_brew ? quiz.coffee_brew.name : "No preference"}
          </span>
        </li>
        <li className="QuizPreferences__item">
          {" "}
          Roast:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.coffee_roast ? quiz.coffee_roast.name : "No preference"}
          </span>
        </li>
        <li className="QuizPreferences__item">
          {" "}
          Blends:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.blends ? quiz.blends : "No preference"}
          </span>
        </li>
        <li className="QuizPreferences__item">
          {" "}
          Organic:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.organic ? quiz.organic : "No preference"}
          </span>
        </li>
        {/*   <li className="QuizPreferences__item"> Fair trade/Direct trade/Relationship: 
             <span className="QuizPreferences__preference"> 
            {" "} {quiz.fair_trade_direct_trade_relationship_coffees ? quiz.fair_trade_direct_trade_relationship_coffees : "No preference"} 
             </span>
          </li>
          <li className="QuizPreferences__item"> Shade grown:
            <span className="QuizPreferences__preference"> 
             {" "} {quiz.shade_grown ? quiz.shade_grown  : "No preference"} 
            </span>
          </li> */}
        <li className="QuizPreferences__item">
          {" "}
          Single origin:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.single_origin ? quiz.single_origin : "No preference"}
          </span>
        </li>
        {/*    <li className="QuizPreferences__item"> Sustainable: 
            <span className="QuizPreferences__preference"> 
            {" "} {quiz.sustainable ? quiz.sustainable : "No preference"}
            </span>
          </li> */}
        <li className="QuizPreferences__item">
          {" "}
          Decaffeinated:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.decaffeinated ? quiz.decaffeinated : "No preference"}
          </span>
        </li>
        <li className="QuizPreferences__item">
          {" "}
          Espresso:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.espresso ? quiz.espresso : "No preference"}
          </span>
        </li>
        <li className="QuizPreferences__item">
          {" "}
          Region:
          <span className="QuizPreferences__preference">
            {" "}
            {quiz.coffee_region ? quiz.coffee_region.name : "No preference"}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default QuizPreferences;
