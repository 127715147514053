import { apiRequest } from "./Api";

// get coffe brands
export const getCoffeePurveyors = () => {
  return apiRequest("get", `purveyors?per_page=500`);
};

export const getCoffeePurveyorsPerPage = (page) => {
  return apiRequest("get", `purveyors?page=${page}`);
};

export const getAllCoffeePurveyors = (page) => {
  return apiRequest("get", `purveyors?per_page=33&page=${page}`);
};

export const getCoffeePurveyorsById = (id) => {
  return apiRequest("get", `purveyors/${id}`);
};
