import React, { useState, useEffect } from "react";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import * as API from "../../../api/Api";
import "./Quantity.scss";

const Quantity = (props) => {
  const { quantity, cartId, coffeeGrind, itemId } = props;

  const [itemQuantity, setItemQuantity] = useState(quantity);

  useEffect(() => {
    // manually deep compare here before updating state
    if (quantity === itemQuantity) {
      return;
    }
    setItemQuantity(quantity);
  }, [quantity]);

  const cartCtx = useCartContext();
  const handleQuentityIncrease = (increment) => {
    if (itemQuantity <= 14) {
      setItemQuantity(increment);

      API.updateItemInCart(itemId, coffeeGrind, increment, cartId).then(
        (res) => {
          cartCtx.getCartItems();
          cartCtx.updateCartSubtotal(res.data.subtotal);
          cartCtx.updateSpecialMessages(res.data.specials_messages);
        }
      );
    }
  };

  const handleQuentityDecrease = (increment) => {
    if (itemQuantity > 1) {
      setItemQuantity(increment);
      API.updateItemInCart(itemId, coffeeGrind, increment, cartId).then(
        (res) => {
          cartCtx.getCartItems();
          cartCtx.updateCartSubtotal(res.data.subtotal);
          cartCtx.updateSpecialMessages(res.data.specials_messages);
        }
      );
    }
  };

  return (
    <div className="Quantity">
      <div className="Quantity__input-container">
        <button
          onClick={() => handleQuentityDecrease(itemQuantity - 1)}
          className="Quantity__minus"
          disabled={itemQuantity === 1 ? true : false}
        >
          -
        </button>
        <input
          className="Quantity__input"
          type="number"
          value={itemQuantity}
          min={1}
          disabled={true}
        />
        <button
          onClick={() => handleQuentityIncrease(itemQuantity + 1)}
          className="Quantity__plus"
          disabled={itemQuantity === 15 ? true : false}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default Quantity;
