import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import SelectBox2 from "./SelectBox2/SelectBox2";
import * as API from "../../../api/Api";
import cs from "classnames";
import { Icon } from "../../shared/Icon/Icon";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import EditGiftCertificate from "../EditGiftCertificate/EditGiftCertificate";
import SubscriptionFormModal from "../../Modals/SubscriptionFormModal/SubscriptionFormModal";
import { updateAutoShipOrder } from "../../../api/Api";
import SelectBoxShipments from "../../shared/SelectBoxShipments/SelectBoxShipments";

import "./CartItem.scss";

const CartItem = (props) => {
  const {
    shipmentsData,
    shipmentNumber,
    product,
    coffee_grind,
    quantity,
    itemId,
    autoShip,
    cart,
    giftFrom,
    giftTo,
    giftMsg,
    giftSubscription,
    item,
    specialMessages,
    combine,
    parent_id,
    shipments,
    refetchAutoShips,
  } = props;
  const Close =
    "https://assets.gocoffeego.com/v2/assets/images/close-icon-2.svg";
  const [iconActive, setIconActive] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSubscriptionEdit, setOpenSubscriptionEdit] = useState(false);
  const [selectedGrind, setSelectedGrind] = useState(coffee_grind?.name);
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [maxMessage, setMaxMessage] = useState(
    itemQuantity > 15 ? true : false
  );
  const cartCtx = useCartContext();
  const cartId = localStorage.getItem("cart_id");

  useEffect(() => {
    // manually deep compare here before updating state
    if (quantity === itemQuantity) {
      return;
    }
    setItemQuantity(quantity);
  }, [quantity]);

  // @TODO - commented for testing purpose of api calls
  // useEffect(() => {
  //   cartCtx.getCartItems();
  // }, []);

  const handleUpdateGrind = (name, id) => {
    setSelectedGrind(name);
    if (!autoShip) {
      API.updateItemInCart(itemId, id, null, cartId).then(() => {
        cartCtx.getCartItems();
      });
    }

    if (autoShip) {
      API.updateAutoShipQuantity(itemId, id, itemQuantity, cartId).then(() => {
        cartCtx.getCartItems();
        refetchAutoShips();
      });
    }
  };

  //@TODO  should  refactor
  const handleQuantityChange = (increment) => {
    if (itemQuantity < 15 && itemQuantity <= 15) {
      setMaxMessage(false);
      if (increment) {
        setItemQuantity((prev) => prev + 1);
        if (autoShip) {
          API.updateAutoShipQuantity(
            itemId,
            selectedGrind,
            itemQuantity + 1,
            cartId
          ).then((res) => refetchAutoShips());
        } else {
          API.updateItemInCart(
            itemId,
            selectedGrind,
            itemQuantity + 1,
            cartId
          ).then((res) => {
            cartCtx.updateCartSubtotal(res.data.subtotal);
            cartCtx.updateSpecialMessages(res.data.specials_messages);
            cartCtx.getCartItems();
          });
        }
      }

      if (!increment && itemQuantity !== 1) {
        setItemQuantity((prev) => prev - 1);
        if (autoShip) {
          API.updateAutoShipQuantity(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          ).then((res) => {
            refetchAutoShips();
          });
        } else {
          API.updateItemInCart(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          ).then((res) => {
            cartCtx.updateCartSubtotal(res.data.subtotal);
            cartCtx.updateSpecialMessages(res.data.specials_messages);
            cartCtx.getCartItems();
          });
        }
      }
    } else {
      setMaxMessage(true);

      if (!increment && itemQuantity !== 1) {
        setItemQuantity((prev) => prev - 1);
        if (autoShip) {
          API.updateAutoShipQuantity(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          ).then((res) => refetchAutoShips());
        } else {
          API.updateItemInCart(
            itemId,
            selectedGrind,
            itemQuantity - 1,
            cartId
          ).then((res) => {
            cartCtx.updateCartSubtotal(res.data.subtotal);
            cartCtx.updateSpecialMessages(res.data.specials_messages);
            cartCtx.getCartItems();
          });
        }
      }
    }
  };

  const handleDelete = (id) => {
    if (autoShip) {
      API.deleteProductById(id, cartId).then((res) => {
        props.refetchAutoShips();
        setIsLoadingSpinner(false);
      });
      return;
    }

    if (cart) {
      API.deleteItemFromCart(id, cartId).then((res) => {
        cartCtx.getCartItems();
        cartCtx.getCartSuggestions(cartId);
      });
      return;
    }
  };

  const handleSaveForLater = (id) => {
    setIconActive(!iconActive);
    API.moveToSaved(id, cartId).then(() => {
      cartCtx.getCartItems();
      cartCtx.getSavedItems();
      window.fbq("track", "AddToWishlist", {
        content_ids: product?.id,
        content_name: product?.name,
        currency: "USD",
        value: product?.retail_price,
      });
    });
  };

  return (
    <div
      className={
        combine?.active && itemId === combine?.id
          ? "CartItem__combine"
          : shipments?.length > 1 &&
            combine?.active &&
            combine?.shipmentNumber === shipmentNumber
          ? "CartItem__add-more"
          : "CartItem"
      }
    >
      <div className="CartItem__container">
        <div
          className={cs(
            "CartItem__row",
            giftFrom && "CartItem__row--gift",
            autoShip && "CartItem__row--autoship"
          )}
        >
          {autoShip && (
            <div className="CartItem__input-container--modifier">
              {parent_id === 0 && (
                <h1 className="CartItem__shipment-number">{shipmentNumber}</h1>
              )}
            </div>
          )}

          <div className="CartItem__column-1">
            {!giftFrom && !giftSubscription ? (
              <Link to={`/${product?.product_url}`}>
                <img
                  src={
                    giftFrom && product?.image
                      ? product?.image
                      : product?.main_image?.sm
                  }
                  className={cs(
                    "CartItem__image",
                    giftFrom && "CartItem__image--gift"
                  )}
                  alt="cart item"
                />
              </Link>
            ) : (
              <img
                src={
                  giftFrom && product?.image
                    ? product?.image
                    : product?.main_image?.md
                }
                className={cs(
                  "CartItem__image",
                  giftFrom && "CartItem__image--gift"
                )}
                alt="cart item"
              />
            )}
            <div
              className={cs(
                "CartItem__details",
                giftFrom && "CartItem__details--gift"
              )}
            >
              {!giftFrom && !giftSubscription && (
                <>
                  <Link
                    to={`/roaster/${product?.purveyor?.slug}-${product?.purveyor?.id}`}
                  >
                    <h1 className="CartItem__name CartItem__name--purveyor">
                      {product?.purveyor?.name}
                    </h1>
                  </Link>
                  <Link to={`/${product?.product_url}`}>
                    <h1 className="CartItem__name">{product?.name}</h1>
                  </Link>
                </>
              )}

              {giftFrom && giftSubscription && (
                <h1
                  className={cs(
                    "CartItem__name",
                    giftFrom && giftSubscription && "CartItem__name--gift"
                  )}
                >
                  {product?.name}
                </h1>
              )}
              {giftFrom && giftSubscription && (
                <div className="CartItem__giftSubscription">
                  <div className="CartItem__gift-grid">
                    <div className="CartItem__gift-to-wrapper CartItem__gift-to-wrapper--to">
                      To <div className="CartItem__gift-to-name">{giftTo}</div>
                    </div>
                    <div className="CartItem__gift-from-wrapper">
                      From{" "}
                      <div className="CartItem__gift-to-name">{giftFrom}</div>
                    </div>
                  </div>
                  <div className="CartItem__gift-msg">
                    <span className="CartItem__gift-msg--msg">{giftMsg}</span>
                  </div>
                </div>
              )}
              {giftFrom && !giftSubscription && (
                <h1
                  className={cs(
                    "CartItem__name",
                    giftFrom && "CartItem__name--gift"
                  )}
                >
                  Gift Certificate
                </h1>
              )}
              {giftFrom && !giftSubscription && (
                <EditGiftCertificate
                  id={props.itemId}
                  msg={props.giftMsg}
                  reciever={props.giftTo}
                  sender={props.giftFrom}
                  closeEdit={() => setOpenEdit(!openEdit)}
                  openEdit={openEdit}
                />
              )}

              <h1 className="CartItem__name">{product?.coffee_roast}</h1>
            </div>
            {giftFrom && !giftSubscription && !openEdit && (
              <div
                className="CartItem__edit"
                onClick={() => setOpenEdit(!openEdit)}
              >
                Edit Note
              </div>
            )}
            {giftFrom && giftSubscription && !openSubscriptionEdit && (
              <div
                className="CartItem__edit"
                onClick={() => setOpenSubscriptionEdit(true)}
              >
                Edit Note
              </div>
            )}
            {openSubscriptionEdit && (
              <SubscriptionFormModal
                isOpen={openSubscriptionEdit}
                toggle={() => setOpenSubscriptionEdit(!openSubscriptionEdit)}
                image={product?.main_image?.md}
                months={giftSubscription?.description.slice(0, 2)}
                item={item}
                fromCart
              />
            )}
          </div>

          {autoShip && (
            <div className="CartItem__column-5">
              <SelectBoxShipments
                shipment
                selectedItem={shipmentNumber}
                onItemSelect={(number) => {
                  updateAutoShipOrder(itemId, number, cartId).then((res) => {
                    props.setShipmentsData(res?.data?.shipments);
                    props.refetchAutoShips();
                  });
                }}
                items={shipmentsData}
              />
            </div>
          )}

          {!giftFrom && product?.coffee_grinds ? (
            <div className="CartItem__column-2">
              <SelectBox2
                selectedItem={selectedGrind}
                onItemSelect={(name, id) => handleUpdateGrind(name, id)}
                items={product?.coffee_grinds}
              />
            </div>
          ) : null}
          <div className="CartItem__column-3">
            {giftFrom ? (
              <h1 className="CartItem__title">
                {!giftSubscription
                  ? `$ ${product?.gift_amount?.toFixed(2)}`
                  : `$ ${giftSubscription?.price?.toFixed(2)}`}
              </h1>
            ) : (
              <>
                {product?.retail_price && product?.retail_price !== 0 ? (
                  <h1 className="CartItem__title">
                    <span
                      className={
                        product?.sale?.active
                          ? "CartItem__retail-price--sale"
                          : "CartItem__retail-price"
                      }
                    >
                      {" "}
                      $ {product?.retail_price?.toFixed(2)}
                    </span>
                    {product?.sale?.active && product?.sale_price && (
                      <>
                        <span className="CartItem__sale-price">
                          {" "}
                          ${product?.sale_price.toFixed(2)}
                        </span>
                        <br />
                      </>
                    )}
                    <span> / {product?.weight}</span>
                  </h1>
                ) : (
                  <h1 className="CartItem__title">
                    $ {product?.retail_price?.toFixed(2)}
                  </h1>
                )}
              </>
            )}
          </div>
          <div className="CartItem__column-4">
            {!giftSubscription && (
              <div className="CartItem__input-container">
                {maxMessage && (
                  <span className="CartItem__max-message">
                    You reached the purchase limit for this item!{" "}
                  </span>
                )}
                <button
                  onClick={() => handleQuantityChange(false)}
                  className="CartItemMobile__minus"
                >
                  -
                </button>
                <input
                  className="CartItemMobile__input"
                  type="number"
                  value={itemQuantity}
                  disabled={true}
                />
                <button
                  onClick={() => handleQuantityChange(true)}
                  className="CartItemMobile__plus"
                >
                  +
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="CartItem__icons">
          {!autoShip && (
            <div className="CartItem__heart-container">
              <p className="CartItem__heart-save">Save for later</p>
              <Icon
                onClick={() => handleSaveForLater(itemId)}
                className={cs(
                  "CartItem__heart",
                  iconActive && "CartItem__heart--active"
                )}
                icon="heart"
              />
            </div>
          )}
          {isLoadingSpinner ? (
            <Spinner
              style={{
                border: `0.25em solid #000000`,
                borderRightColor: "transparent",
                width: "2rem",
                height: "2rem",
              }}
            />
          ) : (
            <img
              onClick={() => {
                setIsLoadingSpinner(true);
                handleDelete(itemId);
              }}
              className="CartItem__icon"
              src={Close}
              alt=""
            />
          )}
        </div>
      </div>
      {specialMessages
        ? specialMessages.map((message) => (
            <>
              {message?.purveyor_id === product?.purveyor?.id && (
                <div className="CartItem__specials-message">
                  {message?.add_more && message?.add_more !== 0 && (
                    <>
                      {`Add ${message.add_more} more and qualify for "${message?.special_name}" from`}{" "}
                      <Link
                        to={`/roaster/${message?.purveyor_id}`}
                        className="CartItem__specials-message--link"
                      >{`${message.purveyor_name}.`}</Link>
                    </>
                  )}
                  {message?.text && (
                    <>
                      {`You qualify for "${message?.special_name}" from`}{" "}
                      <Link
                        to={`/roaster/${message?.purveyor_id}`}
                        className="CartItem__specials-message--link"
                      >
                        {`${message.purveyor_name}.`}
                      </Link>{" "}
                      <br />
                      {message?.text}
                    </>
                  )}
                </div>
              )}
            </>
          ))
        : null}
      <div
        className={
          shipments?.length > 1 ||
          (combine?.active &&
            combine?.active &&
            itemId === combine?.id &&
            shipments?.length >= 1)
            ? "CartItem__display-none"
            : "CartItem__bottom-line"
        }
      />
      {shipments &&
        shipments?.length > 1 &&
        !combine?.active &&
        shipments[shipments.length - 1]?.id === itemId && (
          <div className="CartItem__bottom-line" />
        )}
      {autoShip &&
      combine?.active &&
      shipments?.length > 1 &&
      shipments[shipments.length - 1]?.id === itemId &&
      itemId !== combine?.id &&
      shipmentNumber === combine?.shipmentNumber ? (
        <div className="CartItem__combine-drop">
          {" "}
          Grab a coffee bag and drop it here to combine shipments
        </div>
      ) : null}
      {autoShip &&
      combine?.active &&
      itemId === combine?.id &&
      shipments?.length === 1 &&
      shipmentNumber === combine?.shipmentNumber ? (
        <div className="CartItem__combine-drop">
          {" "}
          Grab a coffee bag and drop it here to combine shipments
        </div>
      ) : null}{" "}
    </div>
  );
};

export default CartItem;
