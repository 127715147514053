import Button from "../../../shared/Button/Button";

import "./MySubscriptionPlan.scss";

const MySubscriptionPlan = (props) => {
  const JetSetTour =
    "https://assets.gocoffeego.com/v2/assets/images/jetset-tour.png";
  const ThreeCoffees =
    "https://assets.gocoffeego.com/v2/assets/images/3coffees.png";
  const SixCoffees =
    "https://assets.gocoffeego.com/v2/assets/images/6coffees.png";
  const TwelveCoffees =
    "https://assets.gocoffeego.com/v2/assets/images/12coffees.png";
  const { open, setOpenPlan } = props;
  return (
    <div className="MySubscriptionPlan">
      <div className="MySubscriptionPlan__container">
        <div className="MySubscriptionPlan__tour">
          <div className="MySubscriptionPlan__image-wrap">
            <img
              className="MySubscriptionPlan__image"
              src={JetSetTour}
              alt="name"
            />
          </div>
          <div className="MySubscriptionPlan__text-wrap">
            <div className="MySubscriptionPlan__tour-name">Jet Set Tour</div>
            <div className="MySubscriptionPlan__plan">
              Premium Single Origin & Micro-lots
            </div>
            <div className="MySubscriptionPlan__text">
              Travel the world on a whirlwind Jet Set Tour. No passport needed.
              Sit back and relax. Experience premium fresh roasted coffee
              delivered to your door from far away exotic locals spanning the
              Tropic of Cancer to the Tropic of Capricorn. Coffees from Africa,
              to Sumatra, Hawaii, Central and South Ammerica and beyond. For
              those that like to travel first class, these Single Origin and
              Micro-Lot coffees are some of the finest from around the world.
            </div>
          </div>
        </div>
        <div className="MySubscriptionPlan__shipments">
          <div className="MySubscriptionPlan__shipment-one-coffee">
            <div className="MySubscriptionPlan__shipment-one-coffee-plan">
              <div className="MySubscriptionPlan__shipment-item">
                <img
                  src={ThreeCoffees}
                  alt="three coffees"
                  className="MySubscriptionPlan__shipment-3coffee-img"
                />
                <Button
                  label="3 Months - $72"
                  className="MySubscriptionPlan__shipment-btn"
                />
              </div>
              <div className="MySubscriptionPlan__shipment-item">
                <img
                  src={SixCoffees}
                  alt="three coffees"
                  className="MySubscriptionPlan__shipment-6coffee-img"
                />
                <Button
                  label="6 Months - $140"
                  className="MySubscriptionPlan__shipment-btn"
                />
              </div>
              <div className="MySubscriptionPlan__shipment-item">
                <img
                  src={TwelveCoffees}
                  alt="three coffees"
                  className="MySubscriptionPlan__shipment-12coffee-img"
                />
                <Button
                  label="12 Months - $276"
                  className="MySubscriptionPlan__shipment-btn"
                />
              </div>
            </div>
            <div className="MySubscriptionPlan__shipment-text">
              One Coffee Per Shipment
            </div>
          </div>
          <div className="MySubscriptionPlan__shipment-two-coffees">
            <div className="MySubscriptionPlan__shipment-two-coffees-plan">
              <div className="MySubscriptionPlan__shipment-item">
                <img
                  src={ThreeCoffees}
                  alt="three coffees"
                  className="MySubscriptionPlan__shipment-3coffee-img"
                />
                <Button
                  label="3 Months - $72"
                  className="MySubscriptionPlan__shipment-btn"
                />
              </div>
              <div className="MySubscriptionPlan__shipment-item">
                <img
                  src={SixCoffees}
                  alt="three coffees"
                  className="MySubscriptionPlan__shipment-6coffee-img"
                />
                <Button
                  label="6 Months - $140"
                  className="MySubscriptionPlan__shipment-btn"
                />
              </div>
              <div className="MySubscriptionPlan__shipment-item">
                <img
                  src={TwelveCoffees}
                  alt="three coffees"
                  className="MySubscriptionPlan__shipment-12coffee-img"
                />
                <Button
                  label="12 Months - $276"
                  className="MySubscriptionPlan__shipment-btn"
                />
              </div>
            </div>
            <div className="MySubscriptionPlan__shipment-text">
              Two Coffee Per Shipment
            </div>
            <div className="MySubscriptionPlan__shipment-subtext">
              Not the same coffees - each coffee will be different
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySubscriptionPlan;
