import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAddressFormik } from "./useAddressFormik";
import SelectCountry2 from "./SelectCountry2/SelectCountry2";
import SelectAddress from "./SelectAddress/SelectAddress";
import * as API from "../../../api/Api";
import { isEmpty } from "../../../lib/helpers/isEmpty";

import { Form, Input, Spinner } from "reactstrap";
import "./AddressForm.scss";
import RadioButtonSquare from "../../shared/RadioButtonSquare/RadioButtonSquare";

const AddressForm = (props) => {
  const {
    billingAddress,
    addressName,
    selectedAddress,
    updateForm,
    newAddress,
    joinClub,
    addressesLoading,
    shippingAddress,
  } = props;
  const [countries, setCountries] = useState([]);
  const [addressBook, setAddressBook] = useState([]);
  const [states, setStates] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const params = useParams();
  const pathParam = params.pathParam;
  const autoship = pathParam === "auto-ship";
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  let defaultCountry = countries.find((country) => country.id === 64);
  if (defaultCountry && !selectedCountry) {
    setSelectedCountry(defaultCountry);
  }

  useEffect(() => {
    if (selectedCountry?.states) {
      let stateMatch = selectedCountry?.states.filter(
        (state) => state.id === selectedAddress?.state?.id
      );

      let selectedStateMatch = selectedCountry?.states.filter(
        (state) => state.id === selectedState?.id
      );

      if (stateMatch?.length === 0 || selectedStateMatch?.length === 0) {
        setError("Please select correct state");
      }

      if (
        stateMatch?.length === 1 ||
        selectedStateMatch?.length === 1 ||
        isEmpty(selectedState) ||
        selectedCountry?.states?.length === 0
      ) {
        setError(" ");
      }
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    API.getCountries().then((res) => {
      if (autoship || joinClub) {
        setCountries([{ name: "United States", id: 64 }]);
        return;
      }
      setCountries(res.data);
    });

    API.getAddressBookInfo().then((res) => {
      if (joinClub) {
        const addresses = res.data.filter((item) => {
          return item?.gift_subscription_address === 1;
        });

        setAddressBook(addresses);
        return;
      }

      if (billingAddress) {
        const addresses = res.data.filter((item) => {
          return item?.country?.id === 64;
        });

        setAddressBook(addresses);
        return;
      }

      setAddressBook(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      API.getStateOfCountry(selectedCountry.id).then((res) => {
        setStates(res.data.states);
        if (isEmpty(res.data.states)) {
          setDisabled(true);
          // formik.setFieldValue("state", "no-state");
          setSelectedState({ name: "no-state" });
        } else {
          setDisabled(false);
        }
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (isEmpty(selectedState) || selectedAddress) {
      setSelectedState(selectedAddress?.state);
    }

    if (selectedAddress && updateForm) {
      setSelectedCountry(selectedAddress?.country);
    }
  }, [selectedAddress]);

  const formik = useAddressFormik(selectedAddress, {
    onSubmit: async (values) => {
      setFormSubmitted(true);

      if (!selectedState?.name) {
        return;
      }

      props.onSubmit(values, selectedState, selectedCountry, checked);
      props?.setActiveStep(1);

      const el = document.getElementById("Checkout__content");
      el.scrollIntoView({ behavior: "smooth" });
    },
  });

  return (
    <Form
      id="credit-card-form"
      className="AddressForm"
      onSubmit={formik.handleSubmit}
    >
      {shippingAddress && !isEmpty(addressBook) && (
        <SelectAddress
          label="Select Saved Addresses"
          onItemSelect={(item) => {
            if (shippingAddress) {
              props.setSelectedAddress(item);
            }
          }}
          selectedItem={selectedAddress}
          items={addressBook}
        />
      )}
      {billingAddress && !isEmpty(addressBook) && (
        <SelectAddress
          label="Select Saved Addresses"
          onItemSelect={(item) => {
            if (item?.country?.id === 64)
              API.selectAddressToBilling(item?.id).then((res) => {
                props.setSelectedAddress(item);
                props.getUpdatedData();
              });
          }}
          selectedItem={selectedAddress}
          items={addressBook}
        />
      )}
      <div className="AddressForm__grid">
        <div className="AddressForm__input-container">
          <Input
            name="first_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            placeholder="First Name"
            className={`AddressForm__input ${
              formik.values.first_name && "AddressForm__input--highlight"
            }`}
            type="text"
          />
          {formik.errors && formik.touched.first_name && (
            <p className="AddressForm__error">{formik.errors.first_name}</p>
          )}
        </div>
        <div className="AddressForm__input-container">
          <Input
            name="last_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            placeholder="Last Name"
            className={`AddressForm__input ${
              formik.values.last_name && "AddressForm__input--highlight"
            }`}
            type="text"
          />
          {formik.errors && formik.touched.last_name && (
            <p className="AddressForm__error">{formik.errors.last_name}</p>
          )}
        </div>
      </div>
      <div className="AddressForm__input-container">
        <Input
          name="company_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.company_name}
          placeholder="Company"
          className={`AddressForm__input ${
            formik.values.company_name && "AddressForm__input--highlight"
          }`}
          type="text"
        />
        {formik.errors && formik.touched.company_name && (
          <p className="AddressForm__error"> {formik.errors.company_name} </p>
        )}
      </div>
      <div className="AddressForm__input-container">
        <Input
          name="address1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.address1}
          placeholder="Address"
          className={`AddressForm__input ${
            formik.values.address1 && "AddressForm__input--highlight"
          }`}
          type="text"
        />
        {formik.errors && formik.touched.address1 && (
          <p className="AddressForm__error"> {formik.errors.address1} </p>
        )}
      </div>
      <div className="AddressForm__input-container">
        <Input
          name="address2"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.address2}
          placeholder="Apartment, Suite, Floor, etc (optional)"
          className={`AddressForm__input ${
            formik.values.address2 && "AddressForm__input--highlight"
          }`}
          type="text"
        />
        {formik.errors && formik.touched.address2 && (
          <p className="AddressForm__error"> {formik.errors.address2} </p>
        )}
      </div>

      <div className="AddressForm__grid">
        {billingAddress ? (
          <SelectCountry2
            onItemSelect={(name, id) =>
              setSelectedCountry({ name: name, id: id })
            }
            selectedItem={
              selectedCountry?.name
                ? selectedCountry.name
                : selectedAddress?.country?.name
            }
            items={countries}
          />
        ) : (
          <SelectCountry2
            label="Select Country"
            onItemSelect={(name, id, states) =>
              setSelectedCountry({ name: name, id: id, states: states })
            }
            selectedItem={
              selectedCountry?.name
                ? selectedCountry.name
                : selectedAddress?.country?.name
            }
            items={countries}
          />
        )}
        <SelectCountry2
          state
          setTouch={() => {
            formik.setFieldTouched(true);
          }}
          label="Select State"
          onItemSelect={(name, id) => {
            setSelectedState({ name: name, id: id });
          }}
          selectedItem={
            selectedState?.name
              ? selectedState.name
              : selectedAddress?.state?.name
          }
          disabled={disabled}
          items={states}
        />

        {formSubmitted && isEmpty(selectedState) && !selectedAddress && (
          <p className="AddressForm__error-2">Please provide state</p>
        )}

        {error && <p className="AddressForm__error-2">{error}</p>}
      </div>
      <div className="AddressForm__grid">
        <div className="AddressForm__input-container">
          <Input
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            placeholder="City"
            className={`AddressForm__input ${
              formik.values.city && "AddressForm__input--highlight"
            }`}
            type="text"
          />
          {formik.errors && formik.touched.city && (
            <p className="AddressForm__error"> {formik.errors.city} </p>
          )}
        </div>
        <div className="AddressForm__input-container">
          <Input
            name="zip"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zip}
            placeholder="ZIP"
            className={`AddressForm__input ${
              formik.values.zip && "AddressForm__input--highlight"
            }`}
            type="text"
          />
          {formik.errors && formik.touched.zip && (
            <p className="AddressForm__error">{formik.errors.zip}</p>
          )}
        </div>
      </div>
      <div className="AddressForm__input-container">
        <Input
          name="phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          placeholder="Phone"
          className={`AddressForm__input ${
            formik.values.phone && "AddressForm__input--highlight"
          }`}
          type="text"
        />
        {formik.errors && formik.touched.phone && (
          <p className="AddressForm__error"> {formik.errors.phone} </p>
        )}
      </div>
      {shippingAddress && !joinClub && selectedCountry?.id === 64 && (
        <RadioButtonSquare
          setChecked={(value) => {
            setChecked(value);
          }}
          checked={checked}
          className="BillingAddress__radio-button"
          name="My billing information is the same as shipping information"
        />
      )}
      <button
        onClick={() => {
          if (!error) {
            setFormSubmitted(true);
          }
        }}
        className="ShippingAddress__button"
        type="submit"
        disabled={error?.length > 1 ? true : false}
      >
        {addressesLoading ? (
          <Spinner
            style={{
              border: "0.25em solid #ffffff",
              borderRightColor: "transparent",
              width: "2.5rem",
              height: "2.5rem",
            }}
          />
        ) : (
          <>
            {/*   {!updateForm
              ? `Use this as ${addressName} address`
              : `Update and use as  ${addressName} address`} 
             */}
            {"Save and Continue"}
          </>
        )}
      </button>
    </Form>
  );
};

export default AddressForm;
