import { Link } from "react-router-dom";
import "./Purveyor.scss";
import PopUp from "../../shared/PopUp/PopUp";

const Purveyor = (props) => {
  const { purveyor } = props;
  const coffeeReviewBadge = purveyor.coffee_review_image;

  return (
    <Link to={`/${purveyor.product_url}`} className="Purveyor">
      <PopUp product={purveyor} purveyor={purveyor} />
      <span className="Purveyor__background" />
      {coffeeReviewBadge && (
        <div className="Purveyor__review">
          <img
            src={coffeeReviewBadge}
            alt="coffee review badge"
            className="Purveyor__review-badge"
          />
        </div>
      )}
      {purveyor.discount ? (
        <div className="Purveyor__label-sale">
          <div className="Purveyor__label-sale-wrap">
            Save{" "}
            <span className="Purveyor__label-sale-procent">
              {purveyor.discount}%
            </span>
          </div>
        </div>
      ) : null}
      <img
        src={purveyor.main_image?.xlg}
        alt={purveyor?.name}
        className="Purveyor__image"
      />
      <div className="Purveyor__title">{purveyor.name}</div>
      {purveyor?.coffee_types.length > 1 && (
        <span className="Purveyor__title">
          {" "}
          {`(${purveyor?.coffee_types.map((item) => `${item}`)}) `}
        </span>
      )}
      <div className="Purveyor__details">{purveyor?.coffee_roast}</div>
      <div className="Purveyor__details">
        <span
          className={
            purveyor?.sale?.active
              ? "Purveyor__price Purveyor__price--sale"
              : "Purveyor__price"
          }
        >
          {`$${purveyor.retail_price.toFixed(2)}`}
        </span>
        {purveyor?.sale?.active && purveyor?.sale_price && (
          <span className="Purveyor__sale-price">
            {` $${purveyor?.sale_price.toFixed(2)}`}
          </span>
        )}
        <span>{` /${purveyor.weight}`}</span>
      </div>
      {purveyor?.sale?.active && purveyor?.sale?.notes && (
        <div className="Purveyor__sale-note">{purveyor?.sale?.notes}</div>
      )}
      {!purveyor?.peaberry_recommends && purveyor?.member_picks && (
        <img
          src={purveyor?.member_picks?.pick_banner}
          alt="member picks tag"
          className="Purveyor__pick_banner"
        />
      )}

      {purveyor?.peaberry_recommends && (
        <img
          src={purveyor?.peaberry_recommends?.recommend_banner}
          alt="peaberry recommends tag"
          className="Purveyor__recommend_banner"
        />
      )}
    </Link>
  );
};

export default Purveyor;
