import React, { useEffect, useState } from "react";

import FlickitySlider from "../../FlickitySlider/FlickitySlider";
import SectionTitle from "../SectionTitle/SectionTitle";
import RecentlyProduct from "./RecentlyProduct/RecentlyProduct";

import * as API from "../../../api/Api";

import "./RecentlyViewed.scss";

const RecentlyViewed = (props) => {
  const [items, setItems] = useState([]);

  let options = {
    draggable: false,
    freeScroll: false,
    contain: true,
    groupCells: true,
    pageDots: false,
    prevNextButtons: items.length > 2 ? true : false,
  };
  const AirBallon =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-hot-air-ballon.svg";

  useEffect(() => {
    API.getRecenltyViewedItems().then((res) => {
      if (res !== undefined) {
        setItems(res.data);
      }
    });
  }, []);

  return (
    <div className="RecentlyViewed">
      {items.length > 0 && (
        <img
          className="RecentlyViewed__image"
          src={AirBallon}
          alt="air balloon"
        />
      )}
      <SectionTitle
        middleLine
        titleClassName="RecentlyViewed__title"
        title="RECENTLY VIEWED ITEMS"
      />
      {items.length <= 0 ? (
        <p className="RecentlyViewed__message">No Items Recently Viewed.</p>
      ) : (
        <div className="RecentlyViewed__slider">
          <FlickitySlider options={options}>
            {items.map((item) => (
              <RecentlyProduct
                refetchCartItems={props.refetchCartItems}
                id={item.id}
                image={item.main_image.xlg}
                price={item.retail_price}
              />
            ))}
          </FlickitySlider>
        </div>
      )}
    </div>
  );
};

export default RecentlyViewed;
