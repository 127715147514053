import React from "react";
import cs from "classnames";

import "./MiddleLine.scss";

const MiddleLine = (props) => {
  const { style, className } = props;
  return <div style={style} className={cs("MiddleLine", className)} />;
};

export default MiddleLine;
