import React from "react";
import { useLocation } from "react-router-dom";

import LoginForm from "../../components/Login/LoginForm/LoginForm";
import GuestSignUpForm from "../../components/SignUp/GuestSignUpForm/GuestSignUpForm";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import { useCartContext } from "../../lib/context/CartContext/CartContext";

import "./Login.scss";

const Login = () => {
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;
  const cartCtx = useCartContext();

  let showGuestCheckout = true
  const route_from = localStorage.getItem("route_from");
  if (route_from?.startsWith("/join-club") || route_from?.startsWith("/auto-ship")) {
    showGuestCheckout = false
    localStorage.removeItem("route_from");
  }

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);
  return (
    <div className="Login">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle primaryClassName="Login__title" title="SIGN IN" />

      <div className="Login__form">
        {cartCtx.quantity}
        <LoginForm />
        {showGuestCheckout && cartCtx.quantity > 0 && (
          <>
            <div className="Login__or"><span>Or</span></div>
            <GuestSignUpForm />
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
