import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as API from "../../../api/Api";
import Seo from "../../../lib/helpers/SEO/Seo";

import "./Recipe.scss";

const Recipe = () => {
  const [recipe, setRecipe] = useState({});
  const params = useParams();
  const [directions, setDirections] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    API.getRecipeById(params.pathParam)
      .then((res) => {
        setRecipe(res.data);

        const directions = res.data.directions.split("\n");
        setDirections(directions);

        const ingredients = res.data.ingredients.split("\n");
        setIngredients(ingredients);
      })
      .catch((res) => console.log(res, "error"));
  }, []);

  return (
    <div className="Recipe">
      <Seo recipe={recipe} />
      <div>
        <h1 className="Recipe__title"> {recipe.name} </h1>
        {recipe.courtesy_of_name && (
          <div className="Recipe__by">By {recipe.courtesy_of_name}</div>
        )}
      </div>
      <div className="Recipe__grid">
        <img className="Recipe__image" src={recipe.image?.image_url} alt="" />
        <div className="Recipe__image-container">
          <p className="Recipe__description">
            <span style={{ color: "#f60" }}>Prep Time:</span> {recipe.prep_time}
            minutes
          </p>
          <p className="Recipe__description">
            <span style={{ color: "#f60" }}>Yield:</span> {recipe.yield} serving
          </p>
        </div>
      </div>
      <p className="Recipe__description">{recipe.description}</p>
      <div className="Recipe__grid2">
        <div>
          <h2 className="Recipe__title2">Ingredients</h2>
          <ul className="Recipe__ingredients-list">
            {ingredients.map((item) => (
              <li className="Recipe__item"> {item}</li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="Recipe__title2">Directions</h2>
          <ol className="Recipe__directions-list">
            {directions.map((item) => (
              <li className="Recipe__item"> {item}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Recipe;
