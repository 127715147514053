import React from "react";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import ForgotPasswordForm from "../../components/Login/ForgotPasswordForm/ForgotPasswordForm";

import "./ForgotPassword.scss";

const ForgotPassword = () => {
  return (
    <div className="ForgotPassword">
      <PrimaryTitle
        primaryClassName="ForgotPassword__title"
        title="FORGOT PASSWORD"
      />

      <div className="ForgotPassword__form">
        <ForgotPasswordForm />
      </div>
    </div>
  );
};

export default ForgotPassword;
