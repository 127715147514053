import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as API from "../../api/Api";
import Line from "../../components/shared/Line/Line";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";

import "./Post.scss";

const Post = () => {
  const [details, setDetails] = useState([]);
  const params = useParams();
  const postId = params.pathParam;

  useEffect(() => {
    API.getBlogPostById(postId)
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => console.log(err, "error"));
  }, []);

  return (
    <div className="Post">
      <Line />
      <Line />

      <div className="Post__title-wrap">
        <MiddleLine />
        <h1 className="Post__title">{details?.title}</h1>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: details?.content }}
        className="Post__content"
      />
    </div>
  );
};

export default Post;
