import { useFormik } from "formik";
import * as Yup from "yup";

const AddressBookSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Please provide first name")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed.")
    .min(2, "Too Short!")
    .max(40, "Too Long!"),
  last_name: Yup.string()
    .required("Please provide last name.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed.")
    .min(2, "Too Short!")
    .max(40, "Too Long!"),
  company_name: Yup.string()
    .nullable()
    .min(2, "Too Short!")
    .max(40, "Too Long!"),
  address1: Yup.string()
    .required("Please provide address.")
    // .matches(/^[0-9a-zA-Z ]+$/, "Only alphabets and numbers are allowed.")
    .min(2, "Too Short!")
    .max(40, "Too Long!"),
  address2: Yup.string().nullable().min(2, "Too Short!").max(40, "Too Long!"),
  city: Yup.string()
    .required("Please provide city.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed.")
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
  zip: Yup.string()
    .required("This field Required")
    .min(
      5,
      "ZIP is invalid. Please provide a 5 or 9 digit (NNNNN-NNNN) number."
    )
    .max(10, "Too Long!"),
  phone: Yup.string()
    .required("Please provide phone number.")
    .min(2, "Too Short!")
    .max(30, "Too Long!"),
});

export const useAddressBookFormik = (selectedAddress, opts) => {
  return useFormik({
    initialValues: {
      first_name: selectedAddress ? selectedAddress.first_name : "",
      last_name: selectedAddress ? selectedAddress.last_name : "",
      company_name:
        selectedAddress && selectedAddress !== null
          ? selectedAddress.company_name
          : "",
      address1: selectedAddress ? selectedAddress.address1 : "",
      address2:
        selectedAddress && selectedAddress !== null
          ? selectedAddress.address2
          : "",
      city: selectedAddress ? selectedAddress.city : "",
      zip: selectedAddress ? selectedAddress.zip : "",
      phone: selectedAddress ? selectedAddress.phone : "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddressBookSchema,
    enableReinitialize: true.valueOf,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
