import { apiRequest } from "./Api";

export const addItemToAutoShip = (data) => {
  return apiRequest("post", "autoship", data);
};

export const getAutoShips = (cartId) => {
  return apiRequest("get", `autoship?cart_id=${cartId}`);
};

export const deleteProductById = (id, cartId) => {
  return apiRequest("delete", `autoship/${id}`, { cart_id: cartId });
};

export const updateAutoShip = (
  statusId,
  activeTimePeriodId,
  autoShipNextRun
) => {
  return apiRequest("post", "autoship/status", {
    auto_ship_status_id: 1,
    auto_ship_active_time_period_id: activeTimePeriodId,
    auto_ship_next_run: autoShipNextRun,
  });
};

export const holdAutoShip = (statusId, autoShipResumeOn, setResumeDate) => {
  if (statusId === 3) {
    return apiRequest("post", "autoship/status", {
      auto_ship_status_id: statusId,
      auto_ship_resume_on: autoShipResumeOn,
      set_resume_date: setResumeDate,
    });
  }
};

//update autoship shipment quantity based on id
export const updateAutoShipQuantity = (id, coffeeGrind, quantity, cardId) => {
  return apiRequest("put", `autoship/${id}`, {
    coffee_grind_id: coffeeGrind,
    quantity: quantity,
    cart_id: cardId,
  });
};

//autoship group shipment
export const updateAutoShipOrder = (id, shipment_number, cartId) => {
  return apiRequest("put", `autoship/${id}`, {
    shipment_number: shipment_number,
    cart_id: cartId,
  });
};

//reorder autoship
export const reorderAutoShipOrder = (oldShip, newShip, cartId) => {
  return apiRequest(
    "post",
    `autoship/reorder_shipments?old_shipment_number=${oldShip}&new_shipment_number=${newShip}`,
    {
      cart_id: cartId,
    }
  );
};
