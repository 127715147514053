import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../lib/helpers/isEmpty";
import OrderProduct from "../../Orders/OrderProduct/OrderProduct";

import WriteReview from "../../Orders/WriteReview/WriteReview";

import "./PurchaseHistory.scss";

const PurchaseHistory = ({ orders, history, fetchDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  let fromPurchaseHistory = true;
  const girlsKick =
    "https://assets.gocoffeego.com/v2/assets/images/girls-kick.png";
  const Stars =
    "https://assets.gocoffeego.com/v2/assets/icons/stars.png";

  const handlePurchaseHistoryClick = () => {
    setIsOpen(true);
  };

  return (
    <div className="PurchaseHistory">
      <div className="PurchaseHistory__order">
        <div className="PurchaseHistory__order-wrap">
          <h2 className="PurchaseHistory__order-title">Orders</h2>
          <Link to={"/orders"}>
            <div className="PurchaseHistory__order-link">
              Track Shipments / View Orders
            </div>
          </Link>
          <Link to="/account/my-reviews">
            <div className="PurchaseHistory__order-link">
              Rate and Review Your Coffees
            </div>
          </Link>
        </div>
        <img
          src={girlsKick}
          alt="girls jumping"
          className="PurchaseHistory__order-image"
        />
      </div>
      <div className="PurchaseHistory__history">
        <div className="PurchaseHistory__history-title-wrap">
          <h2 className="PurchaseHistory__history-title">Purchase History</h2>
          {!isEmpty(history.purchase_history) && (
            <Link to="/orders">
              <button className="PurchaseHistory__history-btn">
                [View all]
              </button>
            </Link>
          )}
        </div>
        {history?.purchase_history && history?.purchase_history[0] ? (
          <div>
            <div className="PurchaseHistory__history-date">
              Purchased on {history?.purchase_history[0]?.date}
            </div>
            <div className="PurchaseHistory__history-products">
              <div className="PurchaseHistory__history-products-wrap">
                <OrderProduct
                  product={history?.purchase_history[0]?.product}
                  fromPurchaseHistory={fromPurchaseHistory}
                  order={history?.purchase_history[0]}
                />

                {!history?.purchase_history[0]?.gc_url &&
                  !history?.purchase_history[0]?.gs_plan_text && (
                    <div className="PurchaseHistory__rate">
                      <img
                        className="PurchaseHistory__stars"
                        src={Stars}
                        alt="stars"
                      />
                      <button
                        className="PurchaseHistory__rate-btn"
                        onClick={() => handlePurchaseHistoryClick()}
                      >
                        [Rate Product]
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        ) : (
          <div className="PurchaseHistory__no-purchase">
            No purchase history yet
          </div>
        )}
      </div>
      {isOpen && (
        <WriteReview
          closeIcon
          selectedProduct={history?.purchase_history[0]}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          fetchDetails={fetchDetails}
        />
      )}
    </div>
  );
};

export default PurchaseHistory;
