import React from "react";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";

import LinkButton from "../../shared/LinkButton/LinkButton";

import "./PeaberryRecommendations.scss";

const PeaberryRecommendations = () => {
  const PeaberrySpiral =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-recommendations-bg-mobile.svg";
  const ManSmellingCoffe =
    "https://assets.gocoffeego.com/v2/assets/images/man-smelling-coffee.png";
  const Background =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-recommendations-background.svg";
  const BackgroundMb =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-recommendations-background-mb.svg";
  return (
    <div className="PeaberryRecommendations">
      <img className="PeaberryRecommendations__bg" src={Background} alt="" />
      <div>
        <img
          className="PeaberryRecommendations__bg-mb"
          src={BackgroundMb}
          alt=""
        />
      </div>
      <div className="PeaberryRecommendations__main-title">
        <MiddleLine className="PeaberryRecommendations__middle-line" />
        <div className="PeaberryRecommendations__title font-cairo">
          PEABERRY’S RECOMMENDATIONS
        </div>
        <LinkButton
          className="PeaberryRecommendations__button"
          path="/peaberry-recommends"
          label="LEARN MORE"
        />
        <div className="PeaberryRecommendations__image-container">
          <img
            className="PeaberryRecommendations__spiral"
            src={PeaberrySpiral}
            alt="peaberry-spiral"
          />
          <img
            className="PeaberryRecommendations__man"
            src={ManSmellingCoffe}
            alt="man-smelling"
          />
        </div>
      </div>
    </div>
  );
};

export default PeaberryRecommendations;
