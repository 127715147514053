import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import "./ChannelVIdeos.scss";
import { useParams } from "react-router-dom";
import VideosModal from "../../../components/Modals/VideosModal/VideosModal";

const ChannelVideos = () => {
  const [channelVideos, setChannelVideos] = useState([]);
  const params = useParams();
  const slug = params.pathParam;
  const [modalOpened, setModalOpened] = useState(false);
  const [videoSelected, setVideoSelected] = useState({});

  useEffect(() => {
    API.getChannelVideos(slug).then((res) => {
      setChannelVideos(res.data.videos);
    });
  }, []);

  return (
    <div className="Channel">
      <div className="Channel__content">
        <VideosModal
          video={videoSelected}
          isOpen={modalOpened}
          toggle={() => setModalOpened(!modalOpened)}
        />{" "}
        {channelVideos?.map((video) => {
          if (video.source !== "Vimeo") {
            return (
              <>
                <div className="Channel__content-details">
                  <div
                    className="Channel__title"
                    onClick={() => {
                      setVideoSelected(video);
                      setModalOpened(!modalOpened);
                    }}
                  >
                    {video.title}
                  </div>
                  <img
                    className="Channel__image"
                    src={video.image}
                    onClick={() => {
                      setVideoSelected(video);
                      setModalOpened(!modalOpened);
                    }}
                  />
                  <div className="Channel__blurb">{video.blurb}</div>

                  <a
                    className="Channel__watch-videos"
                    onClick={() => {
                      setVideoSelected(video);
                      setModalOpened(!modalOpened);
                    }}
                  >
                    Watch The Video
                  </a>
                </div>
              </>
            );
          }
        })}
      </div>
    </div>
  );
};
export default ChannelVideos;
