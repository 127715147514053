import ContentLoader from "react-content-loader";

const ProductLoader = () => {
  return (
    <ContentLoader
      height={400}
      width={240}
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
      // speed={3}

      // primaryColor="#f3f3f3"
      // secondaryColor="#ecebeb"
    >
      <rect x="31" y="48" rx="0" ry="0" width="190" height="93" />
      {/*<rect x="188" y="7" rx="0" ry="0" width="46" height="29" />*/}
      <rect x="42" y="170" rx="0" ry="0" width="100" height="23" />
      <rect x="42" y="200" rx="0" ry="0" width="130" height="20" />
      <rect x="42" y="240" rx="0" ry="0" width="100" height="20" />
      <rect x="31" y="280" rx="0" ry="0" width="190" height="42" />
    </ContentLoader>
  );
};

export default ProductLoader;
