import React from "react";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";

import "./QuizQuestionTitle.scss";

const QuizQuestionTitle = (props) => {
  const { questionNumber, question, id } = props;
  return (
    <div className="QuizQuestionTitle" id={id}>
      <h1 className="QuizQuestionTitle__question-number">
        Question {questionNumber} of 5
        <span className="QuizQuestionTitle__selection">
          [ Click your selection ]
        </span>
      </h1>
      <MiddleLine />
      <h1 className="QuizQuestionTitle__title">{question}</h1>
    </div>
  );
};

export default QuizQuestionTitle;
