import React from "react";
import cs from "classnames";

import "./PayAsYouGoCart.scss";

const PayAsYouGoCart = (props) => {
  const {
    taste,
    img,
    title,
    description,
    brew,
    quantity,
    calendar,
    selectedAnswer,
    id,
    imageClassName,
    border,
    quiz,
    roastStyle,
  } = props;

  return (
    <div
      onClick={() => props.onItemSelect()}
      className={cs(
        "PayAsYouGoCart",
        // roastStyle && selectedAnswer.includes(id) && "PayAsYouGoCart--active",
        brew && "PayAsYouGoCart--brew",
        quiz && "PayAsYouGoCart--quiz",
        calendar && "PayAsYouGoCart--calendar"
      )}
    >
      {!calendar && (
        <img
          className={cs(
            "PayAsYouGoCart__image",
            taste && "PayAsYouGoCart__image--taste",
            brew && "PayAsYouGoCart__image--brew",
            quiz && "PayAsYouGoCart__image--quiz",
            border && "PayAsYouGoCart__image--border",
            border &&
              selectedAnswer === id &&
              "PayAsYouGoCart__image--border--active",
            quantity && "PayAsYouGoCart__image--quantity",
            imageClassName,
            calendar && "PayAsYouGoCart__image--calendar"
          )}
          src={img}
          alt=""
        />
      )}
      <div
        className={cs(
          "PayAsYouGoCart__title",
          selectedAnswer === id && "PayAsYouGoCart__title--active",
          calendar && "PayAsYouGoCart__title--calendar",
          calendar &&
            selectedAnswer === id &&
            "PayAsYouGoCart__title--calendar--active",
          quantity && "PayAsYouGoCart__title--quantity",
          quiz && "PayAsYouGoCart__title--quiz"
        )}
      >
        {title}
        {description && (
          <h3 className="PayAsYouGoCart__description">{description}</h3>
        )}
      </div>
    </div>
  );
};

export default PayAsYouGoCart;
