import React, { useState, useEffect } from "react";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import ViewMoreButton from "../../shared/ViewButton/ViewMoreButton";
import StarsRating from "../../Orders/StarsRating/StarsRating";
import WriteReview from "../../Orders/WriteReview/WriteReview";
import { useHistory, useLocation } from "react-router-dom";

import "./CoffeeReviews.scss";
import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { Spinner } from "reactstrap";
import SelectBoxFilter from "../../shared/SelectBoxFilter/SelectBoxFilter";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";
import Seo from "../../../lib/helpers/SEO/Seo";
const CoffeeReviews = () => {
  const [details, setDetails] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [autoShipLoading, setAutoShipLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [selectedReviewOption, setSelectedReviewOption] = useState({
    name: "Show all reviews",
    id: "all",
    category_name: "filter_ratings",
  });
  const [inputSelected, setInputSelected] = useState("");
  const seoCtx = useSeoContext();
  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/my-coffee-reviews"
  );

  let location = useLocation();
  let pathname = location.pathname;
  let searchParams = new URLSearchParams(location.search);
  //const filterRating = searchParams.get("filter_ratings");

  const msgCtx = useMessageContext();
  const cartCtx = useCartContext();
  const history = useHistory();

  useEffect(() => {
    API.reviewFilter(selectedReviewOption?.id, inputSelected)
      .then((res) => {
        setDetails(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);
      })
      .catch((err) => console.log("err"));
  }, [selectedReviewOption?.id, inputSelected]);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = () => {
    API.getMyReviews()
      .then((res) => {
        setDetails(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);
      })
      .catch((err) => console.log(err, "err"));
  };

  const loadMore = () => {
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });
    API.reviewFilter(selectedReviewOption?.id, inputSelected, currentPage + 1)
      .then((res) => {
        setDetails((orders) => orders.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      })
      .catch((err) => msgCtx.handleError(err));
  };

  const handleAddToCart = (id, grind) => {
    const cartId = localStorage.getItem("cart_id");

    const data = {
      cart_id: cartId,
      product_id: id,
      coffee_grind_id: grind,
    };
    setCartLoading(true);

    if (!cartId) {
      API.addItemToCart(data).then((res) => {
        localStorage.setItem("cart_id", res.data.cart_id);
        cartCtx.getCartItems();
        setCartLoading(false);
        cartCtx.toggleCartModal();
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
        });
      });
    } else {
      API.addItemToCart(data).then((res) => {
        cartCtx.getCartItems();
        setCartLoading(false);
        cartCtx.toggleCartModal();
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp"
        });
      });
    }
  };
  const handleAddToAutoShip = (purveyor, product, grind) => {
    const data = {
      purveyor_id: purveyor,
      product_id: product,
      coffee_grind_id: grind,
    };
    setAutoShipLoading(true);
    API.addItemToAutoShip(data).then((res) => {
      localStorage.setItem("cart_id", res.data.cart_id);
      setAutoShipLoading(false);
      history.push("/auto-ship");
    });
  };

  const reviewFilter = [
    {
      name: "Show all reviews",
      id: "all",
      category_name: "filter_ratings",
    },
    {
      name: <StarsRating totalStars={5} starsSelected={5} disabled />,
      id: 5,
      category_name: "filter_ratings",
    },
    {
      name: <StarsRating totalStars={5} starsSelected={4} disabled />,
      id: 4,
      category_name: "filter_ratings",
    },
    {
      name: <StarsRating totalStars={5} starsSelected={3} disabled />,
      id: 3,
      category_name: "filter_ratings",
    },
    {
      name: <StarsRating totalStars={5} starsSelected={2} disabled />,
      id: 2,
      category_name: "filter_ratings",
    },
    {
      name: <StarsRating totalStars={5} starsSelected={1} disabled />,
      id: 1,
      category_name: "filter_ratings",
    },
    {
      name: "Unreated",
      id: "unrated",
      category_name: "filter_ratings",
    },
  ];

  //filter reviews
  const addQuery = (key, value) => {
    searchParams.set(key, value);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const deleteQuery = (key) => {
    searchParams.delete(key);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const onItemSelect = (name, id) => {
    addQuery("filter_ratings", id);
    setSelectedReviewOption({ name: name, id: id });
  };

  return (
    <div id="CoffeeReviews" className="CoffeeReviews">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <MiddleLine />
      <h2 className="CoffeeReviews__title"> My Coffee reviews</h2>
      {details && details.length !== 0 && (
        <div className="CoffeeReviews__filter-wrapper">
          <SelectBoxFilter
            items={reviewFilter}
            onItemSelect={(name, id) => {
              onItemSelect(name, id);
              deleteQuery("page");
            }}
            selectedItem={selectedReviewOption}
          />
          <div className="CoffeeReviews__checkbox-container">
            <input
              onChange={(e) => {
                if (e.target.checked) {
                  setInputSelected("online");
                  addQuery("filter_status", "online");
                } else {
                  deleteQuery("filter_status");
                  setInputSelected("");
                }
              }}
              name="checkbox"
              className="CoffeeReviews__checkbox-input"
              type="checkbox"
              id="checkbox"
            />

            <label className="CoffeeReviews__checkbox-label" for="checkbox">
              Only show in-stock coffees
            </label>
          </div>
        </div>
      )}

      {details && details.length !== 0 ? (
        <div className="CoffeeReviews__wrap">
          {details &&
            details.map((item) => (
              <div key={item.id} className="CoffeeReviews__text">
                <a href={item?.url}>
                  {" "}
                  <img
                    src={item.main_image?.xlg}
                    alt={item.name}
                    className="CoffeeReviews__image"
                  />
                </a>
                <div className="CoffeeReviews__info-wrapper">
                  <a href={item?.purveyor_url}>
                    <h2 className="CoffeeReviews__slug-title">
                      {item?.purveyor_name}
                    </h2>
                  </a>
                  <a href={item?.url} className="CoffeeReviews__product-name">
                    {item?.name}
                  </a>
                  <h2 className="CoffeeReviews__price">
                    ${item?.price.toFixed(2)} / {item?.weight}
                  </h2>

                  <div className="CoffeeReviews__button-wrap">
                    <button
                      className="CoffeeReviews__order-button"
                      type="button"
                      onClick={() => {
                        setSelectedItem(item);
                        handleAddToCart(
                          item?.product_id,
                          item?.coffee_grind_id
                        );
                      }}
                      disabled={cartLoading ? true : false}
                    >
                      {selectedItem?.id === item?.id && cartLoading ? (
                        <Spinner
                          style={{
                            border: "0.25em solid #00d1d6",
                            borderRightColor: "transparent",
                            width: "2.5rem",
                            height: "2.5rem",
                          }}
                        />
                      ) : (
                        " Order Again"
                      )}
                    </button>

                    <button
                      className="CoffeeReviews__autoship-button"
                      type="button"
                      onClick={() => {
                        setSelectedItem(item);
                        handleAddToAutoShip(
                          item?.purveyor_id,
                          item?.product_id,
                          item?.coffee_grind_id
                        );
                      }}
                      disabled={autoShipLoading ? true : false}
                    >
                      {selectedItem?.id === item?.id && autoShipLoading ? (
                        <Spinner
                          style={{
                            border: "0.25em solid #00d1d6",
                            borderRightColor: "transparent",
                            width: "2.5rem",
                            height: "2.5rem",
                          }}
                        />
                      ) : (
                        "Add to Auto-ship"
                      )}
                    </button>
                  </div>
                </div>

                <div className="CoffeeReviews__review-wrap">
                  <StarsRating
                    totalStars={
                      item?.customer_review?.star_rating?.id
                        ? item?.customer_review?.star_rating?.id
                        : 5
                    }
                    starsSelected={item?.customer_review?.star_rating?.id}
                    disabled
                  />
                  {item?.customer_review?.review ? (
                    <div className="CoffeeReviews__review-text">
                      "{item?.customer_review?.review}"
                    </div>
                  ) : (
                    <div className="CoffeeReviews__review-text">
                      {item?.customer_review}
                    </div>
                  )}

                  <div className="CoffeeReviews__date">
                    &mdash; {item?.date}
                  </div>
                  {item?.customer_review?.review ? (
                    <button
                      className="CoffeeReviews__update-review"
                      onClick={() => {
                        setSelectedItem(item);
                        setIsOpen(true);
                      }}
                    >
                      [ Update Review ]
                    </button>
                  ) : (
                    <button
                      className="CoffeeReviews__update-review"
                      onClick={() => {
                        setSelectedItem(item);
                        setIsOpen(true);
                      }}
                    >
                      [ Write Review ]
                    </button>
                  )}
                </div>
              </div>
            ))}
          {hasMorePage && (
            <ViewMoreButton
              handleClick={loadMore}
              style={{ marginTop: "4rem" }}
            />
          )}
          {isOpen && (
            <WriteReview
              closeIcon
              selectedProduct={selectedItem}
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              fetchDetails={getReviews}
            />
          )}
        </div>
      ) : (
        <p className="CoffeeReviews__no-review-text">
          You haven't purchased any coffees yet.
        </p>
      )}
    </div>
  );
};

export default CoffeeReviews;
