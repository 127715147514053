import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import LinkButton from "../../shared/LinkButton/LinkButton";
import PrimaryTitle from "../../shared/PrimaryTitle/PrimaryTitle";
import Button from "../../shared/Button/Button";
import SelectPlan from "../../AssignedClub/SelectPlan/SelectPlan";

import "./MyCoffeeClubs.scss";

const MyCoffeeClubs = () => {
  const [coffeeClubs, setCoffeeClubs] = useState([]);
  const [allCoffeeClubs, setAllCoffeeClubs] = useState([]);
  const [activeClub, setActiveClub] = useState({
    id: null,
    active: null,
  });
  const [changeClub, setChangeClub] = useState({
    id: null,
    change: false,
  });
  const [message, setMessage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState({
    description: "",
    price: "",
    id: "",
  });
  const [selectedClub, setSelectedClub] = useState();
  const [isChangePlanLoading, setChangePlanIsLoading] = useState(false);
  const [loadingPauseSpinner, setloadingPauseSpinner] = useState(false);
  const [loadingDeleteSpinner, setloadingDeleteSpinner] = useState(false);
  const [creditCards, setCreditCards] = useState([]);

  if (message) {
    setTimeout(() => {
      setMessage("");
    }, 10000);
  }

  useEffect(() => {
    fetchMyCoffeeClub();
    API.getAllCoffeeClubs().then((res) => setAllCoffeeClubs(res.data));

    API.getCreditCards()
      .then((res) => {
        setCreditCards(res.data);
      })
      .catch((err) => console.log("err"));
  }, []);

  const fetchMyCoffeeClub = () => {
    API.getMyCoffeeClubs()
      .then((res) => setCoffeeClubs(res.data))
      .catch((err) => console.log("err"));
  };

  const tier1plan = allCoffeeClubs[0]?.tier_1_plans;
  const tier2plan = allCoffeeClubs[0]?.tier_2_plans;

  const updateClub = (
    id,
    subscription_plan_id,
    subscription_tour_id,
    active
  ) => {
    // let active = activeClub !== 0 ? 0 : 1;
    setActiveClub({
      id: id,
      active: active,
    });
    setloadingPauseSpinner(true);
    API.updateCoffeeClub(
      id,
      subscription_plan_id,
      subscription_tour_id,
      active
    ).then((res) => {
      setMessage(res.data.result);
      setloadingPauseSpinner(false);
      fetchMyCoffeeClub();
    });
  };

  const changePlan = (id, subscription_plan_id, subscription_tour_id) => {
    setChangePlanIsLoading(true);
    API.updateCoffeeClub(
      id,
      subscription_plan_id,
      subscription_tour_id,
      1
    ).then((res) => {
      setMessage(res.data.result);
      setChangePlanIsLoading(false);
      setChangeClub({
        id: null,
        change: false,
      });
      fetchMyCoffeeClub();
    });
  };

  const leaveClub = (id) => {
    setloadingDeleteSpinner(true);
    API.deleteCoffeeClub(id).then((res) => {
      setloadingDeleteSpinner(false);
      fetchMyCoffeeClub();
    });
  };

  return (
    <div className="MyCoffeeClubs">
      <PrimaryTitle title="MY COFFEE CLUBS" />
      {coffeeClubs && coffeeClubs.length !== 0 ? (
        <>
          {coffeeClubs.map((club) => (
            <div className="MyCoffeeClubs__container" key={club.id}>
              <div className="MyCoffeeClubs__wrap">
                <div className="MyCoffeeClubs__club">
                  <img
                    className="MyCoffeeClubs__image"
                    src={club.subscription_tour.image?.image_url}
                    alt={club.subscription_tour_name}
                  />
                  <div className="MyCoffeeClubs__club-name">
                    {club.subscription_tour_name}
                  </div>
                  <div className="MyCoffeeClubs__plan">
                    {club.subscription_plan?.plan_name}
                  </div>
                  <div className="MyCoffeeClubs__plan-coffees">
                    {club.subscription_plan?.description}
                  </div>
                </div>
                <div className="MyCoffeeClubs__club-info">
                  {club.active !== 0 ? (
                    <div className="MyCoffeeClubs__club-info-shipment">
                      Next shipment processes on
                      {club && club?.next_shipment_date ? (
                        <div className="MyCoffeeClubs__club-info-shipment-date">
                          {club?.next_shipment_date}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className="MyCoffeeClubs__club-info-shipment"
                      style={{ marginBottom: "9.5rem" }}
                    >
                      This Club is Paused
                    </div>
                  )}

                  {changeClub.change && changeClub.id === club.id ? (
                    <>
                      <SelectPlan
                        supremeCoffeeClubs={tier1plan}
                        ultimateCoffeeClubs={tier2plan}
                        onItemSelect={(description, price, id) =>
                          setSelectedPlan({
                            description: description,
                            price: price,
                            id: id,
                          })
                        }
                        selectedItem={selectedPlan}
                        clubId={club.id}
                        setSelectedClub={setSelectedClub}
                        selectedClub={selectedClub}
                        label="Please select coffee plan"
                      />
                      <Button
                        label="Save Changes"
                        className="MyCoffeeClubs__club-info-button"
                        onClick={() =>
                          changePlan(
                            club.id,
                            selectedPlan.id,
                            club.subscription_tour.id
                          )
                        }
                        isLoading={isChangePlanLoading}
                        disabled={isChangePlanLoading ? true : false}
                        spinner={changeClub.id === club.id ? true : false}
                        color={"#00d1d6"}
                      />
                    </>
                  ) : (
                    <Button
                      label="Change Plan"
                      className="MyCoffeeClubs__club-info-button"
                      onClick={() =>
                        setChangeClub({
                          id: club.id,
                          change: true,
                        })
                      }
                    />
                  )}
                  <Button
                    label={
                      club.active !== 0 ? "Pause the Club" : "Resume the Club"
                    }
                    className="MyCoffeeClubs__club-info-button"
                    onClick={() => {
                      setSelectedClub(club.id);
                      if (creditCards?.length === 0) {
                        setMessage("Please add credit card to resume the club");
                      } else {
                        updateClub(
                          club.id,
                          club.subscription_plan.id,
                          club.subscription_tour.id,
                          club.active !== 0 ? 0 : 1
                        );
                      }
                    }}
                    isLoading={loadingPauseSpinner}
                    disabled={
                      loadingPauseSpinner && selectedClub === club.id
                        ? true
                        : false
                    }
                    spinner={selectedClub === club.id ? true : false}
                    color={"#00d1d6"}
                  />
                  <Button
                    label="Leave the Club"
                    className="MyCoffeeClubs__club-info-button"
                    onClick={() => {
                      setSelectedClub(club.id);
                      leaveClub(club.id);
                    }}
                    isLoading={loadingDeleteSpinner}
                    disabled={
                      loadingDeleteSpinner && selectedClub === club.id
                        ? true
                        : false
                    }
                    spinner={selectedClub === club.id ? true : false}
                    color={"#00d1d6"}
                  />
                  {message && selectedClub === club.id && (
                    <div className="MyCoffeeClubs__message">{message}</div>
                  )}
                </div>
              </div>
              <div className="MyCoffeeClubs__info">
                <div className="MyCoffeeClubs__info-text">
                  Total: {club.subscription_plan?.description} (
                  {club.subscription_plan?.price}) Plus $2.00 per Shipment{" "}
                  <br />
                  {club.promo_message}
                  <br />
                  {club.subscription_plan?.plan_name} -{" "}
                  {club.subscription_plan?.description}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="MyCoffeeClubs__default-container">
          <h1 className="MyCoffeeClubs__title">
            {" "}
            You haven't joined any Coffee Clubs yet{" "}
          </h1>
          <LinkButton
            path="/pay-as-you-go"
            label="Find a Coffee Club"
            className="MyCoffeeClubs__button"
          />
        </div>
      )}
    </div>
  );
};

export default MyCoffeeClubs;
