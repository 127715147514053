import { apiRequest } from "./Api";

const cartId = localStorage.getItem("cart_id");

export const getCartItems = (cartId, param) => {
  return apiRequest(
    "get",
    `carts?cart_id=${cartId}&${param}&shipments=1`,
    null
  );
};

export const getShipments = async (id) => {
  return apiRequest("get", `carts?cart_id=${id}&shipments=1`);
};

export const addItemToCart = (data) => {
  const result = apiRequest("post", `carts`, data);
  result.then((res) => {
    const cartId = localStorage.getItem("cart_id");
    // console.log('add to cart res', res)
    if(res?.data?.cart_id != cartId) {
      localStorage.setItem("cart_id", res.data.cart_id);
    }
  });
  return result;
};

//add gift certificate to cart
export const addGiftCertificateToCart = (id, gift, cartId) => {
  return apiRequest("post", `carts?cart_id=${cartId}`, {
    product_id: id,
    gift: gift,
  });
};

//update gift certificates in cart
export const updateGiftCertificates = async (
  id,
  cartId,
  msg,
  reciver,
  sender
) => {
  return apiRequest("put", `carts/${id}/save_note?cart_id=${cartId}`, {
    gift_msg: msg,
    gift_to: reciver,
    gift_from: sender,
  });
};

// add gift subscription to cart
export const addGiftSubscriptionToCart = async (cartId, data) => {
  return apiRequest("post", `carts?cart_id=${cartId}`, data);
};

//update gift subscription in cart
export const updateGiftSubcription = async (id, cartId, data) => {
  return apiRequest(
    "put",
    `carts/${id}/save_gift_recipient_info?cart_id=${cartId}`,
    data
  );
};

export const deleteItemFromCart = (id, cartId) => {
  return apiRequest("delete", `carts/${id}`, { cart_id: cartId });
};

export const updateItemFromCart = (
  id,
  coffee_grind_id,
  quantity,
  token,
  cartId
) => {
  return apiRequest(
    "put",
    `carts/${id}`,
    {
      cart_id: cartId,
      coffee_grind_id: coffee_grind_id,
      quantity: quantity,
    },
    token
  );
};

//update item in cart no token
export const updateItemInCart = (id, coffee_grind_id, quantity, cartId) => {
  return apiRequest("put", `carts/${id}`, {
    cart_id: cartId,
    coffee_grind_id: coffee_grind_id,
    quantity: quantity,
  });
};

export const moveToSaved = (id, cartId) => {
  return apiRequest("put", `carts/${id}/move_to_saved`, { cart_id: cartId });
};

export const moveToCart = (id) => {
  return apiRequest("put", `carts/${id}/move_to_cart`, { cart_id: cartId });
};

export const getSavedItems = async (cartId) => {
  return apiRequest("get", `carts?saved=1&cart_id=${cartId}`);
};

//apply promo code
export const applyPromoCode = async (code) => {
  return apiRequest("post", `checkout/confirm/apply_promo_code`, {
    promo_code: code,
  });
};

//apply gift certificate
export const applyGiftCertificate = async (code) => {
  return apiRequest("post", `checkout/pay/apply_gc`, {
    code: code,
  });
};

export const getRecenltyViewedItems = async () => {
  let recentlyViewedIds = JSON.parse(sessionStorage.getItem("recently-viewed"));

  if (recentlyViewedIds && recentlyViewedIds.length > 0) {
    return apiRequest("post", "products/recently-viewed?per_page=200", {
      product_id: recentlyViewedIds,
    });
  }
};

export const getCartSuggestions = (cartId) => {
  return apiRequest("get", `carts?cart_id=${cartId}&suggestions=1`);
};

//send paypal response to backend
// required payer_id & status
export const sendPaypalResponse = (details, token) => {
  return apiRequest(
    "post",
    `checkout/confirm/paypal_express_return`,
    {
      payer_id: details.payer.payer_id,
      status: details.status,
      id: details.id,
      amount: details.purchase_units[0].amount.value,
    },
    token
  );
};
