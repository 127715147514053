import React from "react";
import cs from "classnames";

import "./Line.scss";

const Line = (props) => {
  const { style, className } = props;
  return <div className={cs("Line", className)} style={style} />;
};

export default Line;
