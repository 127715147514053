import React, { useEffect, useState } from "react";
import * as API from "../../../api/Api";
import QuizQuestionTitle from "../QuizQuestionTitle/QuizQuestionTitle";
import QuizLines from "../QuizLines/QuizLines";

import cs from "classnames";
import "./QuizQuestion4.scss";

const QuizQuestion4 = (props) => {
  const RedScooter =
    "https://assets.gocoffeego.com/v2/assets/images/quiz-images/red-scooter.png";
  const UKFlag =
    "https://assets.gocoffeego.com/v2/assets/images/quiz-images/ukFlag.png";
  const BlueRedGirl =
    "https://assets.gocoffeego.com/v2/assets/images/quiz-images/red-blue-girl.png";
  const { selectedAnswer } = props;
  const [answer, setAnswer] = useState();
  const [noPreference, setNoProference] = useState();

  useEffect(() => {
    API.getQuizQuestionFour().then((res) => {
      setAnswer(res.data);
    });
  }, []);

  return (
    <div id="question-4" className="QuizQuestion4">
      <QuizQuestionTitle
        questionNumber={4}
        question={"What's your favorite roast style?"}
      />
      <h2 className="QuizQuestion4__text">choose one!</h2>
      <img
        src={RedScooter}
        alt="red scooter"
        className="QuizQuestion4__scooter-image"
      />
      <img src={UKFlag} alt="uk flag" className="QuizQuestion4__uk-flag" />
      <img
        src={BlueRedGirl}
        alt="uk flag"
        className="QuizQuestion4__girl-img"
      />
      <div className="QuizQuestion4__answers">
        {answer &&
          answer.map((item) => (
            <div
              key={item.id}
              className={cs(
                "QuizQuestion4__answer",
                selectedAnswer === item.id && "QuizQuestion4__answer--active"
              )}
              onClick={() => props.onItemSelect(item.id)}
            >
              <img
                src={item.image}
                alt="roast style"
                className={cs(
                  "QuizQuestion4__image",
                  "QuizQuestion4__image--border",
                  selectedAnswer === item.id &&
                    "QuizQuestion4__image--border--active"
                )}
              />
              <h2
                className={cs(
                  "QuizQuestion4__answer-title",
                  selectedAnswer === item.id && "QuizQuestion4__title--active"
                )}
              >
                {item.name}
              </h2>
            </div>
          ))}

        <div
          className={cs(
            "QuizQuestion4__answer",
            noPreference === 2222 && "QuizQuestion4__answer--active"
          )}
          onClick={() => {
            props.onItemSelect(2222);
            setNoProference(2222);
          }}
        >
          <img
            src="https://assets.gocoffeego.com/pay-as-you-go/Unsure.4139f0b8.png"
            alt="roast style"
            className={cs(
              "QuizQuestion4__image",
              "QuizQuestion4__image--border",
              noPreference === 2222 && "QuizQuestion4__image--border--active"
            )}
          />
          <h2
            className={cs(
              "QuizQuestion4__answer-title",
              noPreference === 2222 && "QuizQuestion4__title--active"
            )}
          >
            UNSURE
          </h2>
        </div>
      </div>
      <QuizLines />
    </div>
  );
};

export default QuizQuestion4;
