import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";

import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";
import { useHistory, useLocation } from "react-router-dom";

import "./SelectItem.scss";

let cs = require("classnames");

const SelectItem = (props) => {
  const {
    items,
    className,
    className2,
    idSelected,
    categoryName,
    style,
  } = props;
  const [selectedBag, setSelectedBag] = useState("");
  const [showItems, setShowItems] = useState(false);

  const dropDownRef = useRef(null);
  let history = useHistory();
  let location = useLocation();
  let pathname = location.pathname;
  let searchParams = new URLSearchParams(location.search);

  const handleOutSideClick = () => {
    setShowItems(false);
  };

  let type1 = searchParams.get("coffee_type_id");
  let type2 = searchParams.get("coffee_type_id2");
  let type3 = searchParams.get("coffee_type_id3");

  const item = items?.find((item) => item.id === parseInt(idSelected));
  const selectedItem = item?.name;

  const deleteQuery = (key) => {
    searchParams.delete(key);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  useOutsideClickDetection(dropDownRef, handleOutSideClick);

  return (
    <div
      ref={dropDownRef}
      className={cs("SelectItem", className)}
      style={style}
    >
      <div
        onClick={() => setShowItems(!showItems)}
        className="SelectItem__container"
      >
        {categoryName === "bulk_coffee" ? (
          <div
            className={cs(
              "SelectItem__header",
              idSelected && "SelectItem__header--active"
            )}
          >
            {parseInt(idSelected) === 0 && "Regular"}
            {parseInt(idSelected) === 1 && "Bulk"}
            {!idSelected && "None"}
          </div>
        ) : (
          <div
            className={cs(
              "SelectItem__header",
              selectedItem && "SelectItem__header--active"
            )}
          >
            {selectedItem || "None"}
          </div>
        )}
      </div>

      <div
        className={cs("SelectItem__items", showItems && "active", className2)}
      >
        <div
          onClick={() => {
            deleteQuery(categoryName);
            setShowItems(!showItems);
          }}
          className={`SelectItem__item-container ${
            props.selectedItem === 0 ? "activeLabel" : ""
          }`}
        >
          <p className="SelectItem__item">None</p>
        </div>
        {items
          ?.filter((type) => {
            if (
              searchParams.has("coffee_type_id") ||
              searchParams.has("coffee_type_id2") ||
              searchParams.has("coffee_type_id3")
            ) {
              if (categoryName.startsWith("coffee_type")) {
                return (
                  type?.id !== parseInt(type1) ||
                  parseInt(type2) ||
                  parseInt(type3)
                );
              }
            }

            return type;
          })
          .map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  props.onItemSelect(categoryName, item.name, item.id);
                  setSelectedBag(item.name);
                  setShowItems(!showItems);
                }}
                className={`SelectItem__item-container ${
                  props.selectedItem === item.name ? "activeLabel" : ""
                }`}
              >
                <p className="SelectItem__item">{item.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectItem;
