import React from "react";
import { Link } from "react-router-dom";

import "./Special.scss";

const Special = (props) => {
  const { item } = props;
  const image = item?.purveyor[0]?.main_image?.sm;

  if (item.purveyor.length > 1) {
    return (
      <>
        {item.purveyor.map((i, index) => {
          return (
            <Link to={`/roaster/${i.slug}-${i?.id}`} key={index}>
              <div className="Special">
                <p className="Special__titles">{i?.name}</p>
                <div className="Special__image-container">
                  <img
                    className="Special__image"
                    src={i.main_image.sm}
                    alt="special"
                  />
                </div>
              </div>
            </Link>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        {item.purveyor.length <= 1 && (
          <Link
            to={`/roaster/${item?.purveyor[0]?.slug}-${item?.purveyor[0]?.id}`}
          >
            <div className="Special">
              <p className="Special__title">{item?.purveyor[0]?.name}</p>
              <div className="Special__image-container">
                <img className="Special__image" src={image} alt="special" />
              </div>

              <p className="Special__text">{item?.name}</p>
            </div>
          </Link>
        )}
      </>
    );
  }
};

export default Special;
