import React, { useEffect, useState } from "react";
import { MessageModalContext } from "./MessageModalContext";
import { useLocation } from "react-router-dom";
import { creditCardDeclined } from "../../helpers/messages";

export const MessageModalContextProvider = (props) => {
  const [isOpen, setIsOpen] = useState();
  const [typeOfMessage, setTypeOfMessage] = useState();
  const [text, setText] = useState(null);
  const [status, setStatus] = useState(null);
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  const hideModal = () => {
    setIsOpen(false);
  };

  const handleError = (error) => {
    /*  if (!error || !error.response) {
      setText("No response");
      updateModalMessage("error");

      return;
    } */

    if (
      error?.response?.status === 422 &&
      error?.response?.data.error?.type === "APPLY_GIFT_CARD_FAILED"
    ) {
      updateModalMessage("error");
      setText(error.response.data.error.message);
      return;
    }

    if (
      error?.response?.status === 422 &&
      error?.response?.data.error?.type === "PAYMENT_INTENT_NOT_FOUND"
    ) {
      updateModalMessage("error");
      setText(error.response.data.error.message);
      return;
    }

    if (error?.response?.status >= 422) {
      updateModalMessage("error");

      // setText(error?.response?.data?.error?.message);
      setText(creditCardDeclined);
      if (pathname === "/checkout" && error) {
        setOpenContactUsModal(true);
      }
      return;
    }

    setStatus(error.response.status);

    if (error?.response?.data?.error?.message) {
      setText(error?.response?.data?.error?.message);
      updateModalMessage("error");
      if (pathname === "/checkout" && error) {
        setOpenContactUsModal(true);
      }
      return;
    }

    //server error
    if (error?.response?.status >= 500) {
      updateModalMessage("error");

      setText(error);
      return;
    }

    if (error?.response?.status === 400) {
      updateModalMessage("error");

      setText("We are having some issues, please try again later!");
      return;
    }

    if (
      error?.response?.data &&
      error?.response?.data?.errors &&
      Object.keys(error?.response?.data?.errors).length > 0
    ) {
      updateModalMessage("error");

      const errorsData = error?.response?.data?.errors;
      const errorsObject = {};

      Object.keys(errorsData).forEach((key) => {
        if (errorsData.hasOwnProperty(key)) {
          errorsObject[key] = errorsData[key][0];
        }
      });

      setText(errorsObject);
      return;
    }

    setText(error?.response?.data?.message || error?.message);
  };

  const updateModalMessage = (typeOfMessage) => {
    setIsOpen(true);
    setTypeOfMessage(typeOfMessage);
  };

  const handleSuccessMessage = (text) => {
    setIsOpen(true);
    setText(text);
    setTypeOfMessage("success");
  };

  const handleWarningMessage = (text) => {
    setIsOpen(true);
    setText(text);
    setTypeOfMessage("warning");
  };

  const notAllowedMessage = (text) => {
    setIsOpen(true);
    setText(text);
    setTypeOfMessage("error");
  };

  const updateTypeOfMessage = (type) => {
    setTypeOfMessage(type);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const updateText = (text) => {
    setText(text);
  };

  const context = {
    handleWarningMessage,
    notAllowedMessage,
    handleSuccessMessage,
    text,
    handleError,
    updateModalMessage,
    isOpen,
    toggle,
    updateText,
    typeOfMessage,
    updateTypeOfMessage,
    status,
    hideModal,
    openContactUsModal,
    setOpenContactUsModal,
  };

  return (
    <MessageModalContext.Provider value={context}>
      {props.children}
    </MessageModalContext.Provider>
  );
};
