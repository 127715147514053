import React, { useState } from "react";
import { PAY_AS_YOU_GO_OPTIONS } from "../../api/PayAsYouGo";
import { useHistory, useLocation, Link } from "react-router-dom";
import PayAsYouGoCart from "../../components/PayAsYouGo/PayAsYouGoCart/PayAsYouGoCart";
import LinkButton from "../../components/shared/LinkButton/LinkButton";

import "./PayAsYouGo.scss";

const PayAsYouGo = () => {
  const [drinkCoffee, setDrinkCoffee] = useState(0);
  const [coffeeDrinker, setCoffeeDrinker] = useState(0);
  const [coffeeTaste, setCoffeeTaste] = useState(0);
  const [brew, setBrew] = useState(0);
  const [grindPreference, setGrindPreference] = useState(0);
  const [roastStyle, setRoastStyle] = useState(0);
  let location = useLocation();
  let history = useHistory();
  let pathname = location.pathname;
  let searchParams = new URLSearchParams(location.search);

  const scrollInto = (id) => {
    setTimeout(() => {
      const el = document.getElementById(id);

      el.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const addQuery = (key, value) => {
    // returns the existing query string
    searchParams.set(key, value);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const firstQuestion = searchParams.get("coffee-drink");
  const secondQuesiion = searchParams.get("buzz");

  return (
    <div className="PayAsYouGo">
      <div className="PayAsYouGo__question">
        <div className="PayAsYouGo__question-container">
          <img
            src={PAY_AS_YOU_GO_OPTIONS[0].image}
            className="PayAsYouGo__question1-image"
            alt="coffee cup"
          />
          <h1 className="PayAsYouGo__question-number">
            QUESTION 1 OF 8
            <span className="PayAsYouGo__question-number--light">
              [ Click your selection ]
            </span>
          </h1>

          <Link to="/assigned-club">
            <div className="PayAsYouGo__all-clubs">See all clubs</div>
          </Link>
        </div>

        <h1 className="PayAsYouGo__title">
          {PAY_AS_YOU_GO_OPTIONS[0].question}
        </h1>
        <div className="PayAsYouGo__row1">
          {PAY_AS_YOU_GO_OPTIONS[0].answerOptions.map((item) => (
            <PayAsYouGoCart
              key={item.id}
              imageClassName="PayAsYouGo__coffee-image"
              id={item.id}
              selectedAnswer={drinkCoffee}
              onItemSelect={() => {
                setDrinkCoffee(item.id);
                addQuery("coffee-drink", item.slug);
                scrollInto("coffee-drinker");
              }}
              title={item.title}
              img={item.image}
            />
          ))}
        </div>
      </div>
      {firstQuestion !== null && (
        <div id="coffee-drinker" className="PayAsYouGo__question">
          <h1 className="PayAsYouGo__question-number">
            QUESTION 2 OF 8
            <span className="PayAsYouGo__question-number--light">
              [ Click your selection ]
            </span>
          </h1>
          <h1 className="PayAsYouGo__title">
            {PAY_AS_YOU_GO_OPTIONS[1].question}
          </h1>
          <div className="PayAsYouGo__row2">
            {PAY_AS_YOU_GO_OPTIONS[1].answerOptions.map((item) => (
              <PayAsYouGoCart
                key={item.id}
                imageClassName="PayAsYouGo__buzz-image"
                id={item.id}
                onItemSelect={() => {
                  setCoffeeDrinker(item.id);
                  addQuery("buzz", item.slug);
                  scrollInto("roast-style");
                }}
                selectedAnswer={coffeeDrinker}
                title={item.title}
                img={item.image}
              />
            ))}
          </div>
        </div>
      )}
      {secondQuesiion !== null && (
        <div id="roast-style" className="PayAsYouGo__question">
          <h1 className="PayAsYouGo__question-number">
            QUESTION 3 OF 8
            <span className="PayAsYouGo__question-number--light">
              [ Click your selection ]
            </span>
          </h1>
          <h1 className="PayAsYouGo__title">
            {PAY_AS_YOU_GO_OPTIONS[2].question} {""}
          </h1>
          <div className="PayAsYouGo__row3">
            {PAY_AS_YOU_GO_OPTIONS[2].answerOptions.map((item) => (
              <PayAsYouGoCart
                key={item.id}
                border
                className="PayAsYouGo__cart1"
                roastStyle
                id={item.id}
                selectedAnswer={roastStyle}
                onItemSelect={() => {
                  addQuery("roast", item.slug);
                  scrollInto("coffee-taste");
                  setRoastStyle(item.id);
                }}
                title={item.title}
                img={item.image}
              />
            ))}
          </div>
        </div>
      )}
      {roastStyle !== 0 && (
        <div id="coffee-taste" className="PayAsYouGo__question">
          <h1 className="PayAsYouGo__question-number">
            QUESTION 4 OF 8
            <span className="PayAsYouGo__question-number--light">
              [ Click your selection ]
            </span>
          </h1>

          <h1 className="PayAsYouGo__title">
            {PAY_AS_YOU_GO_OPTIONS[3].question}
          </h1>
          <div className="PayAsYouGo__row4">
            {PAY_AS_YOU_GO_OPTIONS[3].answerOptions.map((item) => (
              <PayAsYouGoCart
                key={item.id}
                taste
                id={item.id}
                selectedAnswer={coffeeTaste}
                onItemSelect={() => {
                  addQuery("taste", item.slug);
                  scrollInto("brew");
                  setCoffeeTaste(item.id);
                }}
                title={item.title}
                img={item.image}
                description={item.description}
              />
            ))}
          </div>
        </div>
      )}
      {coffeeTaste !== 0 && (
        <div id="brew" className="PayAsYouGo__question">
          <h1 className="PayAsYouGo__question-number">
            QUESTION 5 OF 8
            <span className="PayAsYouGo__question-number--light">
              [ Click your selection ]
            </span>
          </h1>
          <h1 className="PayAsYouGo__title">
            {PAY_AS_YOU_GO_OPTIONS[4].question}
          </h1>
          <div className="PayAsYouGo__row5">
            {PAY_AS_YOU_GO_OPTIONS[4].answerOptions.map((item) => (
              <PayAsYouGoCart
                key={item.id}
                id={item.id}
                selectedAnswer={brew}
                onItemSelect={() => {
                  addQuery("brew", item.slug);
                  setBrew(item.id);
                  scrollInto("grind-preference");
                }}
                brew
                title={item.title}
                img={item.image}
                description={item.description}
              />
            ))}
          </div>
        </div>
      )}
      {brew !== 0 && (
        <div id="grind-preference" className="PayAsYouGo__question">
          <h1 className="PayAsYouGo__question-number">
            QUESTION 6 OF 8
            <span className="PayAsYouGo__question-number--light">
              [ Click your selection ]
            </span>
          </h1>
          <h1 className="PayAsYouGo__title">
            {PAY_AS_YOU_GO_OPTIONS[5].question}
          </h1>
          <div className="PayAsYouGo__row6">
            {PAY_AS_YOU_GO_OPTIONS[5].answerOptions.map((item) => (
              <PayAsYouGoCart
                key={item.id}
                border
                roastStyle
                id={item.id}
                selectedAnswer={grindPreference}
                onItemSelect={() => {
                  addQuery("coffee", item.slug);
                  setGrindPreference(item.id);
                }}
                title={item.title}
                img={item.image}
                description={item.description}
              />
            ))}
          </div>
        </div>
      )}
      {grindPreference !== 0 && (
        <div id="continue-button" className="PayAsYouGo__button-container">
          <LinkButton
            className="PayAsYouGo__button"
            label="GET BREWING!"
            path={`/assigned-club/${searchParams.toString()}`}
          />
        </div>
      )}
    </div>
  );
};

export default PayAsYouGo;
