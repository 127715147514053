import { useFormik } from "formik";
import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter your firstname"),
  lastName: Yup.string().required("Enter your lastname "),
  email: Yup.string()
    .required("Enter your email")
    .email("Enter a valid email address"),
  confirmEmail: Yup.string()
    .required("Enter confirm email")
    .min(6, "Email must be at least 6 characters.")
    .when("email", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("email")],
        "Email and confirm email does not match "
      ),
    }),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      /^(?=.*\d)(?=.*[a-zA-Z_\@\%\&\#]).{6,14}$/,
      "Password must be between 6 and 14 characters in length, with at least one uppercase letter, lowercase letter and one number. Allowed characters are letters, numbers, and @, _, %, &, #."
    ),
  // .max(50, "Username is too Long!"),
  confirmPassword: Yup.string()
    .required("Enter confirm password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and confirm password does not match "
      ),
    }),
});

export const useSignUpFormik = (opts) => {
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      ...opts?.initialValues,
      SignUpSchema,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SignUpSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
